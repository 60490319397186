export default {
	lastName: "Apellido",
	firstName: "Primer nombre",
	email: "Correo electrónico",
	Username: "Nombre de usuario",
	userFunction: "Función",
	supplier_name: "Proveedor",
	client_name: "Nombre del cliente",
	job: "Función",
	manageUsers: " Gestión de usuarios",
	manageSuppliers: "Área de proveedores",
	manageClients: "Área de clientes",
	manageGroups: "Administrar grupos",
	manageRoles: "Administrar roles",
	manageWorkflow: "Gestión del flujo de trabajo",
	manageLicenseAddOn: "Administrar licencias y complementos",
	status: "Estado",
	ACTIVE: "Activo",
	WAITING: "A la espera",
	DISABLE: "Desactivar",
	add_user: "Agregar usuario",
	add_user_button: "AÑADIR USUARIO",
	add_group_button: "AÑADIR ORGANIZACIÓN COMERCIAL",
	add_supplier_button: "AGREGAR PROVEEDOR",
	add_client_button: "AGREGAR CLIENTE",
	add_supplier: "Agregar proveedor",
	add_client: "Agregar cliente",
	edit_supplier: "Modificar proveedor",
	sendEmailCreationUserMessage:
		"Se ha enviado un correo electrónico con el nombre de usuario y la contraseña al usuario para que pueda conectarse",
	CancelActionMessage: "¿Está seguro de que desea cancelar esta acción?",
	searchUser: "Buscar",
	userCivility: "Civilidad",
	administrator: "Administrador",
	user: "Agente",
	Mr: "Señor",
	Ms: "Mademoiselle",
	Mrs: "Señora",
	enableDisableActionMessage: "¿Está seguro de que desea habilitar / deshabilitar este usuario?",
	edit_user: "Editar Usuario",
	// validation messages
	lastName_required: " Se requiere el nombre",
	lastName_notValid: "El nombre no es valido",
	supplierName_notValid: "El nombre del proveedor no es válido",
	phone_notValid: "El teléfono no es válido",
	supplier_required: "El nombre del proveedor es obligatorio",
	firstName_required: "Se requiere el nombre",
	firstName_notValid: "El nombre no es válido",
	email_required: "Correo electronico es requerido",
	email_notValid: "El correo no es válido",
	siren_notValid: "El código de SIREN no es válido",
	siret_notValid: "La empresa SIRET no es válida",
	url_notValid: "La URL no es válida",
	name_notValid: "El nombre no es válido",
	supplier_created:
		"Proveedor agregado con éxito.Si el proveedor no se muestra en su lista de proveedores, comuníquese con su administrador",
	supplier_updated: "Proveedor actualizado correctamente!",
	supplier_error: "Ocurrió un error al guardar el proveedor",
	supplier_update_error: "¡No se pudieron actualizar los detalles del proveedor!",
	job_required: "La función es obligatoria",
	civility_required: "El civismo es obligatorio",
	doctype_required: "Se requiere tipo de documento",
	deleteUsersActionMessage: "¿Está seguro de que desea eliminar este usuario?",
	"admin.settings": "Configuraciones",
	"admin.commercialOrganization": "Organización comercial",
	"admin.config.update-settings": "La configuración se ha actualizado con éxito",
	"admin.config.update-message":
		"La configuración del correo electrónico se ha actualizado correctamente",
	"admin.AUTO_EXPORT": "Exportación automática",
	"admin.email-config": "Configuración de correo electrónico",
	"admin.ftp-config": "Configuración de FTP",
	"admin.import-config": "Importar configuración",
	Host: "Anfitrión",
	Port: "Puerto",
	Email: "Email",
	Password: "Contraseña",
	"Read folder": "Leer carpeta",
	"Processing folder": "Carpeta de procesamientor",
	"Success folder": "Carpeta de éxito",
	"Exception folder": "Carpeta de excepciones",
	"admin.AMOUNT_TOLERANCE": "Tolerancia de los totales",
	"admin.MAXIMUM_SIZE": "Tamaño máximo de un archivo cargado",
	supplierTraining: "Aprendizaje proveedores",
	clientTraining: "Aprendizaje del cliente",
	deleteTraining: "Eliminar entrenamiento",
	deleteTrainingMessage: "¿Estás seguro de que deseas eliminar la capacitación de este proveedor?",
	successdeleteTrainingMessage: "El aprendizaje se ha eliminado correctamente",
	"admin.download-buttons": "Acciones sobre facturas",
	"admin.download-buttons-description":
		"Elija qué acciones le gustaría permitir en sus facturas en el portal.",
	"admin.download-button.excel": "Descargar en formato Excel",
	"admin.download-button.xml": "Descarga en formato XML",
	"admin.download-button.pdf": "Descarga en formato PDF",
	"admin.download-button.edi": "Descarga en formato EDI",
	"admin.invoice.AUTO_EXPORT-description":
		"Este parámetro activa la exportación automática de facturas totalmente extraídas y sin mensaje de error.",
	"admin.invoice.AMOUNT_TOLERANCE-description":
		"Este campo le permite configurar una tolerancia en los cálculos de los totales de una factura. Sobre la base de esta tolerancia se mostrarán los mensajes de error.",
	"admin.MAXIMUM_SIZE-description": "Tamaño máximo de un documento para cargar en MBs",
	"admin.display-indicators": "Visualización de indicadores en el tablero",
	"admin.display-indicators-description":
		"Elija qué indicadores desea visualizar en su Panel de control de la cadena de suministro.",
	"admin.setting.my_tasks": "Mis tareas",
	"My orders": "Mis ordenes",
	"admin.setting.invoices-exported": "Facturas exportadas",
	"admin.setting.invoices-not-exported": "Facturas no exportadas",
	"admin.setting.last-actions": "Últimas acciones",
	"admin.setting.load-invoices": "Cargar facturas",
	"admin.setting.last-5-invoices": "Últimas 5 facturas",
	"admin.setting.download-pdf": "Descargar PDF",
	"admin.setting.delete": "Borrar",
	"admin.setting.delete-all": "Eliminar todas las facturas",
	"admin.setting.AMOUNT_TOLERANCE.required": "Se requiere tolerancia de totales",
	"admin.setting.relaunch-extraction": "Relanzar la extracción",
	"admin.export-buttons": "Exportar botones de descarga",
	"admin.invoice.export-buttons-description":
		"Configure la visualización de posibles accesos directos a descargas después de exportar una factura. Los botones serán visibles después de la exportación en los detalles de la factura.",
	"admin.setting.export_entry_account_excel": "Exportar entrada de contabilidad de Excel",
	"admin.extraction-level-settings": "La importancia de los campos extraídos (nivel de extracción)",
	"admin.extraction-level-settings-desciption":
		"Establezca la importancia de los campos que le parecen más importantes y los campos que es esencial. Esto se tendrá en cuenta en el cálculo del Nivel de extracción.",
	"admin.level-settings": "Configuración del nivel de extracción",
	"admin.extraction-level.champs": "Campos",
	"admin.extraction-level.status": "Estado",
	"admin.extraction-level.mandatory": "Obligatorio",
	"admin.extraction-level.ignored": "Ignorado",
	"admin.extraction-level.important": "Importante",
	"admin.setting.message.confirm": "¿Está seguro de que desea actualizar esta configuración?",
	"admin.user.confirm.message.edit": "¿Está seguro de que desea actualizar este usuario?",
	"admin.user.confirm.message.add": "¿Estás seguro de que quieres guardar a este usuario?",
	"admin.supplier.confirm.message.add": "¿Estás seguro de que quieres guardar a este usuario?",
	"admin.client.confirm.message.add": "¿Estás seguro de que quieres guardar a este usuario?",
	"admin.supplier.confirm.message.edit": "¿Está seguro de que desea actualizar este usuario?",
	"admin.config.message.confirm":
		"¿Está seguro de que desea actualizar esta configuración de correo electrónico?",
	"admin.extraction.message.confirm":
		"¿Está seguro de que desea actualizar estos niveles de extracciones?",
	"invoice.user.updated.success": "El usuario se actualizó correctamente",
	"admin.config.ftp.message.confirm":
		"¿Está seguro de que desea modificar esta configuración del servidor FTP?",
	"admin.config.ftp.update-message":
		"La configuración del servidor FTP se ha actualizado correctamente",
	agent_accounting: "Agente contable",
	agent_purchase: "Agente De Compras",
	"admin.settings.invoice": "Factura",
	"admin.settings.order": "Ordenado",
	"admin.settings.invoices": "Configuración de facturas",
	"admin.settings.orders": "Configuración de pedidos",
	"admin.order.download-buttons": "Acciones sobre pedidos",
	"admin.order.download-buttons-description":
		"Elija qué acciones le gustaría permitir en sus pedidos en el portal.",
	"admin.order.AUTO_EXPORT-description":
		"Este parámetro activa la exportación automática de pedidos que están totalmente extraídos y no tienen mensajes de error.",
	"admin.order.AMOUNT_TOLERANCE-description":
		"Este campo le permite establecer una tolerancia en el cálculo de los totales de un pedido. Sobre la base de esta tolerancia se mostrarán los mensajes de error.",
	"admin.setting.last-5-orders": "Últimos 5 pedidos",
	"admin.order.setting.delete-all": "Eliminar todos los pedidos",
	"admin.order.export-buttons-description":
		"Configure la visualización de posibles accesos directos a descargas después de exportar un pedido. Los botones serán visibles después de la exportación en los detalles del pedido.",
	"admin.settings.order.upload": "Subir pedidos",
	"order.exported": "Pedidos exportados",
	"order.unexported": "Pedidos no exportados",
	"admin.download-button.journal-entry": "Descargar entrada de diario de Excel",
	"pagination.rows.per.page": "Filas por página:",
	"pagination.of": "de",
	fix_errors: "No se puede continuar, verifique los siguientes errores",
	automatic_transition: "Permitir transición automática",
	"rule.orderMissing": "El número de orden no existe",
	"rule.orderNumberAbsent": "El número de pedido no está disponible.",
	"rule.quantityInvalid": "La cantidad facturada es mayor que la cantidad del pedido.",
	"rule.unitPriceLineInvalid":
		"El precio unitario facturado es diferente al precio unitario del pedido.",
	"rule.orderLineMissing": "La línea de pedido no existe",
	"rule.orderLineMissings": "Las líneas de pedido no existen",
	"rule.totalAmountInvalid": "El monto total de la factura es mayor que el monto total del pedido.",
	"rule.cumulativeOrderInvalid":
		"La cantidad total será mayor que el total en el pedido después de la exportación.",
	"rule.accumulatedQuantityLineInvalid":
		"La cantidad calculada será mayor que la cantidad del pedido después de la exportación.",
	"rule.incoherenceAmounts":
		"La cantidad total de impuestos incluidos es diferente a la cantidad libre de impuestos más IVA",
	"rule.totalEmpty": "El monto total de la factura está vacío",
	"rule.DeliveryMissing": "El albarán de entrega no existe",
	"rule.DeliveredQuantityInvalid":
		"La cantidad facturada es mayor que la cantidad entregada como se menciona en el albarán de entrega",
	"rule.sumTotalHtInvalid":
		"El monto total libre de impuestos es diferente a la suma de las líneas libres de impuestos.",
	"rule.duplicatedInvoice": "La factura ya existe",
	"rule.nettopayInvalid": "La red a pagar no es consistente",
	"rule. PrepayAmountInvalid": "El monto prepagado no es consistente",
	"rule.nettopayExist": "La red a pagar no existe",
	transition: "Transición",
	transitionbuttonlabel: "Etiqueta de botón",
	addstatus: "Agregar estado",
	saveworkflow: "Guardar flujo de trabajo",
	asksaveworkflow: "¿Estás segura de que quieres guardar el flujo de trabajo?",
	update: "actualizar",
	deletestatus: "eliminar estado",
	deletetransition: "eliminar transición",
	updatestatus: "estado de actualización",
	updatetransition: "actualización de transición",
	blockedby: "Bloqueado por",
	appliedrules: "Reglas aplicadas",
	errorautotransition: "No puede tener más de una transición automática",
	AbleCorrect: "Elegible para corrección manual",
	workflow: "flujo de trabajo",
	"workflow.delete.error":
		"Este estado tiene una transición {{count}}, elimine la transición antes de eliminar el estado.",
	"workflow.status.success": "Flujo de trabajo actualizado con éxito!",
	"workflow.source.status.litige.message":
		"El estado en litigio no debe tener transición saliente.",
	"workflow.property.isLitige": "Estado de litigios",
	mGerente: "Gerente",
	super_user: "Súper usuario",
	"user.super_user.add": "Añadir super usuario",
	"user.super_user.edit": "Editar super usuario",
	managerSuperusers: "Gerente Super Usuarios",
	"admin.download-button.idoc": "Descarga en formato IDOC",
	"admin.setting.download-idoc": "Descargar IDOC",
	"admin.setting.download-edi": "Descargar EDI",
	"admin.workflow": "Flujo de trabajo",
	"admin.setting.download-ubl": "Descargar en UBL",
	"admin.setting.download-cii": "Descargar en CII16B",
	"admin.setting.download-xcbl": "Descargar en XCBL",
	"admin.setting.download-facturx": "Descargar en Factur-X",
	"user.field.commercialOrganization": "Organización comercial",
	"admin.commercialOrganization.name": "Apellido",
	"admin.commercialOrganization.description": "Descripción",
	"admin.commercialOrganization.suppliers": "Proveedores",
	"admin.commercialOrganization.clients": "Clientela",
	"admin.commercialOrganization.add": "Afectar",
	"admin.commercialOrganization.remove": "Desmantelamiento",
	"admin.commercialOrganization.edit":
		"¿Está seguro de que desea editar esta organización comercial?",
	"admin.commercialOrganization.save":
		"¿Está seguro de que desea guardar esta organización comercial?",
	"admin.commercialOrganization.editRecord": "Añadir organización comercial",
	"admin.commercialOrganization.addRecord": "Editar organización comercial",
	"admin.commercialOrganization.success": "Organización comercial añadida con éxito",
	"admin.commercialOrganizationEdit.success": "La organización comercial cambió con éxito",
	"admin.commercialOrganization.deleteSuccess": "Organización comercial Eliminar con éxito",
	"admin.commercialOrganization.deleteMsg":
		"¿Está seguro de que desea eliminar esta organización comercial?",
	"user.commercialOrganization.exists.message": "La organización comercial ya existe.",
	"user.commercialOrganization.users.exists.message":
		"No se puede eliminar la organización comercial.Algunos usuarios todavía están asignados.",
	"user.commercial_organization_showAll": "Afecta a todos",
	"user.commercial_organization_showAll.description":
		"Al elegir esta opción, el director/administradora tendrá permiso para ver todos los datos relacionados con facturas y pedidos",
	"permissions.admin.commercialOrganization": "Commercial organization",
	"permissions.admin.commercialOrganization.add": "Agregar",
	"permissions.admin.commercialOrganization.edit": "Editar",
	"permissions.admin.commercialOrganization.delete": "Borrar",
	"permissions.admin.manageUsers": "Administrador de usuarios",
	"permissions.admin.manageUsers.add": "Agregar",
	"permissions.admin.manageUsers.edit": "Editar",
	"permissions.admin.manageUsers.delete": "Borrar",
	"permissions.admin.manageUsers.permissions": "Permisos",
	"permissions.admin.manageSuppliers": "Proveedores de Administrador",
	"permissions.admin.manageSuppliers.add": "Agregar",
	"permissions.admin.manageSuppliers.edit": "Editar",
	"permissions.admin.manageSuppliers.delete": "Borrar",
	"permissions.admin.manageClients": "Gerente de clientes",
	"permissions.admin.manageClients.add": "Agregar",
	"permissions.admin.manageClients.edit": "Editar",
	"permissions.admin.manageClients.delete": "Borrar",
	"permissions.admin.supplierTraining": "Capacitación de proveedores",
	"permissions.admin.supplierTraining.toggle": "Habilitar deshabilitar",
	"permissions.admin.supplierTraining.delete": "Borrar",
	"permissions.admin.clientTraining": "Entrenamiento de clientes",
	"permissions.admin.clientTraining.toggle": "Habilitar deshabilitar",
	"permissions.admin.clientTraining.delete": "Borrar",
	"permissions.admin.InvoiceSettings": "Configuración de la factura",
	"permissions.admin.orderSetting": "Configuración de pedido",
	"permissions.admin.invoiceExtractionLevel": "Configuración de nivel de extracción de facturas",
	"permissions.admin.orderExtractionLevel": "Configuración de nivel de extracción de pedido",
	"permissions.admin.invoiceImportConfig": "Configuración de importación de facturas",
	"permissions.admin.orderImportConfig": "Configuración de importación de pedido",
	"permissions.admin.invoiceWorkflow": "Flujo de trabajo de factura",
	"invoice.dashboard": "Tablero",
	"invoice.list": "Facturas",
	"invoice.disputed": "Facturas disputadas",
	"invoice.disputed.reintegrate": "Reintegrar la factura",
	"invoice.disputed.reject": "Rechazar la factura",
	"invoice.disputed.delete": "Eliminar factura",
	"invoice.add": "Agregar",
	"invoice.delete": "Borrar",
	"invoice.deleteAdd": "Eliminar todos",
	"invoice.download": "Exportar pdf",
	"invoice.relaunchExtraction": "Extracción de relanzamiento",
	"invoice.exportXml": "Exportar XML",
	"invoice.exportExcel": "Exportar Excel",
	"invoice.exportJE": "Entradas contables de Export Excel",
	"invoice.exportEDI": "Exportar EDI",
	"invoice.exportIDOC": "Exportar IDOC",
	"invoice.exportUBL": "Exportar ubl",
	"invoice.exportCII": "Exportar CII16b",
	"invoice.exportFactureX": "exportar Factur-X",
	"invoice.supplier": "Proveedoras",
	"invoice.supplier.add": "Agregar",
	"invoice.supplier.edit": "Editar",
	"invoice.supplier.delete": "Borrar",
	"invoice.supplier.import": "Importar proveedores",
	"invoice.supplier.export": "Exportar la lista de proveedores",
	"order.dashboard": "Tablero",
	"permissions.admin.orders": "Pedidos",
	"order.client": "Clientes",
	"order.list": "Facturas",
	"order.add": "Agregar",
	"order.delete": "Borrar",
	"order.deleteAdd": "Eliminar todos",
	"order.download": "Exportar pdf",
	"order.relaunchExtraction": "Extracción de relanzamiento",
	"order.exportXml": "Exportar XML",
	"order.exportExcel": "Exportar Excel",
	"order.exportJE": "Entradas contables de Export Excel",
	"order.exportEDI": "Exportar EDI",
	"order.exportIDOC": "Exportar IDOC",
	"order.client.add": "Agregar",
	"order.client.edit": "Editar",
	"order.client.delete": "Borrar",
	"order.client.import": "Importar clientes",
	"order.client.export": "Exportar la lista de clientes",
	"user.permission.save": "Salvar",
	"user.permission.confirmSave": "¿Realmente desea actualizar los permisos para este usuario?",
	"tooltip.action.permissions": "Permisos",
	ORDER: "PEDIDOS",
	INVOICE: "FACTURAS",
	"admin.emailConfig.addNewEmail": "Agregar",
	"admin.user.toggleActive": "Activar/Desactivar",
	"admin.client.confirm.message.edit": "¿De verdad quieres cambiar a este usuario?",
	edit_client: "Editar cliente",
};
