export const URL_ADMIN_BASE = "/admin";
export const URL_BASE_USERS = "/admin/user";
export const URL_USER_PERMISSIONS = "/admin/user/permission";
export const URL_FETCH_USERS = `${URL_BASE_USERS}/list`;
export const URL_FETCH_JOBS = `${URL_BASE_USERS}/job`;
export const URL_FETCH_CIVILITIES = `${URL_BASE_USERS}/civility`;
export const URL_UPDATE_USER_STATUS = `${URL_BASE_USERS}/status/`;
export const URL_DELETE_TRAINING_SUPPLIER = `/admin/setting/training/supplier/`;
export const URL_DELETE_TRAINING_CLIENT = `/admin/setting/training/client/`;
export const URL_FETCH_COMMERCIAL_ORGANIZATION = `${URL_ADMIN_BASE}/commercial-organization`;

export const URL_BASE_INVOICE = "/invoice";
export const URL_WORKFLOW = `${URL_BASE_INVOICE}/workflow`;
