import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	toolbar: {
		padding: theme.spacing(0.5, 1.5),
		backgroundColor: theme.palette.primary.main,
		borderRadius: "4px",
	},
	resetFilled: {
		right: theme.spacing(4.5),
	},
	reset: {
		position: "absolute",
		color: "rgba(0, 0, 0, 0.75)",
	},
	adornment: {
		width: theme.spacing(6),
		height: theme.spacing(3),
		justifyContent: "right",
	},
	dateInput: {
		backgroundColor: "#fbfbfb",
		boxShadow: "rgba(17, 17, 26, 0.1) 0px 0.5px 0px",
		border: "1px solid lightgray",
	},
	datePickerCalendar: {
		position: "absolute",
		zIndex: 9999,
		top: "calc(100% + 10px)", // Positionner le calendrier en dessous du champ de saisie
		left: 0,
		backgroundColor: "#fff",
		border: "1px solid #ccc",
		borderRadius: "4px",
		boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
	},
}));
