import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
	app: {
		height: "50vh",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		flex: "1 0 auto",
		textAlign: "center",
	},
	container: {
		justifyContent: "center",
		paddingTop: "70px",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "60%",
		flex: "1 1 auto",
	},
	title: {
		margin: "40px 0 20px 0",
	},
	resetButton: {
		margin: "16px",
	},
	formWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		flex: "1 1 auto",
		margin: "0 0",
	},

	formError: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		width: "50%",
		flex: "1 1 auto",
	},
	input: {
		marginButtom: "50px",
	},
}));
