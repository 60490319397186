import React, { useEffect } from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import get from "lodash/get";
import clsx from "clsx";
import { toast } from "utils";
import {
	fetchExtractionLevelConfig,
	updateExtractionLevelParam,
	updateExtractionLevelParamOrder,
	fetchExtractionLevelConfigOrder,
} from "../redux/actions";
import useStyles from "../style";
import ConfirmDialog from "../../components/ConfirmDialog";

export const ExtractionLevelSetting = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);
	const { errors, handleSubmit } = useForm();
	const [extractionLevelParams, setExtractionLevelParams] = React.useState([]);
	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});
	const initExtractionLevelParams = useSelector(({ requests }) =>
		get(
			requests,
			isOrderEnv
				? "queries.FETCH_EXTRACTION_LEVEL_PARAMS_ORDER.data"
				: "queries.FETCH_EXTRACTION_LEVEL_PARAMS.data",
			[]
		)
	);

	useEffect(() => {
		dispatch(
			isOrderEnv ? fetchExtractionLevelConfigOrder() : fetchExtractionLevelConfig()
		).then((res) => setExtractionLevelParams(res.data));
	}, [dispatch, isOrderEnv]);

	const onSubmit = () => {
		setConfirmObject({ message: t("admin.extraction.message.confirm"), openConfirm: true });
	};

	const handleChange = (levelParam, level) => {
		const newList = extractionLevelParams.map((item) => {
			if (item.id === levelParam.id) {
				const updatedItem = {
					...item,
					level,
				};
				return updatedItem;
			}
			return item;
		});
		setExtractionLevelParams(newList);
	};
	const onConfirm = () => {
		if (!confirmObject.cancel) {
			setConfirmObject({ ...confirmObject, isLoading: true });
			dispatch(
				isOrderEnv
					? updateExtractionLevelParamOrder(
							extractionLevelParams,
							() => {
								setConfirmObject({ message: "", openConfirm: false, isLoading: false });
								toast.success(t("admin.config.update-settings"));
							},
							(error) => {
								setConfirmObject({ message: "", openConfirm: false, isLoading: false });
								toast.error(error);
							}
					  )
					: updateExtractionLevelParam(
							extractionLevelParams,
							() => {
								setConfirmObject({ message: "", openConfirm: false, isLoading: false });
								toast.success(t("admin.config.update-settings"));
							},
							(error) => {
								setConfirmObject({ message: "", openConfirm: false, isLoading: false });
								toast.error(error);
							}
					  )
			);
		} else {
			setConfirmObject({ message: "", openConfirm: false, cancel: false });
			setExtractionLevelParams(initExtractionLevelParams);
		}
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	return (
		<>
			<Grid container item xs={12} />
			<Grid
				container
				item
				xs={12}
				className={classes.settingsContainer}
				justify="center"
				alignItems="center"
				alignContent="center"
			>
				{true && (
					<form onSubmit={handleSubmit(onSubmit)} style={{ width: "60%" }}>
						<Grid container item xs={12} className={classes.loginContainer} spacing={6}>
							<Grid item container xs={12}>
								<Grid container item xs={6}>
									<Typography variant="h6" color="primary">
										{t("admin.level-settings")}
									</Typography>
								</Grid>
							</Grid>

							<Grid container item xs={12}>
								<Grid item xs={12}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("admin.extraction-level-settings")}
										<p>{errors.email?.message}</p>
									</Typography>
									<Typography variant="body2">
										{t("admin.extraction-level-settings-desciption")}
									</Typography>
								</Grid>
							</Grid>

							<Grid container item xs={12} spacing={2}>
								<Grid item xs={6} align="center">
									<Typography align="center" variant="subtitle2">
										{t("admin.extraction-level.champs")}
									</Typography>
								</Grid>
								<Grid item xs={6} align="center">
									<Typography align="center" variant="subtitle2">
										{t("admin.extraction-level.status")}
									</Typography>
								</Grid>
							</Grid>
							{extractionLevelParams &&
								extractionLevelParams.map((levelParam) => (
									<Grid
										container
										direction="row"
										justify="center"
										alignItems="center"
										alignContent="center"
									>
										<Grid item xs={6} align="center">
											<Typography align="center">{t(levelParam.key)}</Typography>
										</Grid>

										<Grid item xs={6} align="center">
											<TextField
												className={clsx(
													levelParam.level === "N1" && classes.paramSelectImportanct,
													levelParam.level === "N2" && classes.paramSelectIgnored,
													levelParam.level === "N3" && classes.paramSelect
												)}
												value={levelParam.level}
												onChange={(e) => handleChange(levelParam, e.target.value)}
												variant="outlined"
												select
											>
												<MenuItem value="N3">{t("admin.extraction-level.mandatory")}</MenuItem>
												<MenuItem value="N2">{t("admin.extraction-level.ignored")}</MenuItem>
												<MenuItem value="N1">{t("admin.extraction-level.important")}</MenuItem>
											</TextField>
										</Grid>
									</Grid>
								))}
						</Grid>
						<Grid
							container
							item
							xs={12}
							spacing={2}
							className={classes.actions}
							justify="center"
							alignItems="center"
							alignContent="center"
						>
							<Grid item>
								<Button
									color="primary"
									autoFocus
									onClick={() => {
										setConfirmObject({
											message: t("CancelActionMessage"),
											openConfirm: true,
											cancel: true,
										});
									}}
								>
									{t("cancel")}
								</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" type="submit" color="secondary">
									{t("confirm")}
								</Button>
							</Grid>
						</Grid>

						<ConfirmDialog
							message={confirmObject.message}
							open={confirmObject.openConfirm}
							isLoading={confirmObject.isLoading}
							onConfirm={onConfirm}
							onCancel={onCancel}
						/>
					</form>
				)}
			</Grid>
		</>
	);
};

export default ExtractionLevelSetting;
