import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  icon: {
    position: "relative",
    top: "8px",
    right: "8px",
    fontSize: "3rem",
    opacity: "0.3",
  }
}));