import { createSlice } from "@reduxjs/toolkit";

const initialFormulaire = {
	selectedItem: "",
	orderDto: {
		id: {
			fieldFullName: null,
		},
		client: {
			id: {},
			code: {
				fieldFullName: null,
			},
			label: {
				fieldFullName: "order Layout\\Vendor",
			},
			vatNumber: {},
			companySiret: {},
		},
		orderNo: {
			fieldFullName: "order Layout\\orderNumber",
		},
		issueDate: {
			fieldFullName: "order Layout\\orderDate",
		},
		dueDate: {
			fieldFullName: "order Layout\\orderData",
		},
		vat: {
			fieldFullName: null,
		},
		additionalAmountsHT: [
			{
				amount: {
					fieldFullName: "order Layout\\TOTALHT",
				},
			},
		],
		additionalAmountsTVA: [
			{
				amount: {
					fieldFullName: "order Layout\\TVA",
				},
			},
		],
		additionalAmountsCharge: [
			{
				amount: {
					fieldFullName: "order Layout\\Charge",
				},
			},
		],
		total: {
			fieldFullName: "order Layout\\Total",
		},
		purchaseOrderNo: {
			fieldFullName: null,
		},
		status: {
			code: {
				value: null,
				fieldFullName: null,
				location: null,
			},
			label: {
				value: null,
				fieldFullName: null,
				location: null,
			},
		},
		deliveryAddress: {
			fieldFullName: null,
		},
		billingAddress: {
			fieldFullName: "order Layout\\orderData",
		},
		currency: {
			fieldFullName: "order Layout\\Currency",
		},
		paymentTerm: {
			code: {
				fieldFullName: null,
			},
			label: {
				fieldFullName: "order Layout\\PaymentTerms",
			},
		},
		billingLinesDtos: [],
		discount: {
			fieldFullName: "order Layout\\Discount",
		},
		deliverySlip: {
			fieldFullName: "order Layout\\DeliverySlip",
		},
		country: {
			fieldFullName: "order Layout\\orderData\\Country",
		},
	},
};

// TODO-JAL Fix ESLINT
const formulaireListOrders = createSlice({
	name: "formulaire",
	initialState: initialFormulaire,
	reducers: {
		updateFormulaire: (state, action) => (state = action.payload), // eslint-disable-line
		setItem: () => {},
		initFormulaire: (state) => (state = initialFormulaire), // eslint-disable-line
	},
});

export const { updateFormulaire, initFormulaire, setItem } = formulaireListOrders.actions;

export default formulaireListOrders.reducer;
