import React, { Fragment, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import get from "lodash/get";
// import { useHistory } from "react-router";
import { Query } from "@redux-requests/react";
import { deleteUsers, fetchSuperUsers } from "../redux/actions";
import useStyles from "../style";
import { FETCH_SUPER_USERS } from "../redux/constants";
import CircularLoader from "../../components/Loaders/CircularLoader";
import TableSuperUsers from "./SuperuserTableItem";

const DeleteUsersDialog = ({ open, setOpen, selected, setSelected }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleCloseConfirmationPopup = () => {
		setOpen(!open);
	};
	const onClick = () => {
		dispatch(
			deleteUsers(selected, () => {
				dispatch(fetchSuperUsers());
			})
		).then(() => setSelected([]));
		handleCloseConfirmationPopup();
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseConfirmationPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t("deleteUsersActionMessage")}</DialogTitle>

			<DialogActions>
				<Button onClick={handleCloseConfirmationPopup} color="secondary" autoFocus>
					{t("cancel")}
				</Button>
				<Button onClick={onClick} type="submit" color="secondary">
					{t("confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

function TableSuperUsersList({
	columns,
	withActions = true,
	selected,
	setSelected,
	handleSelectedAll,
	typeUser,
	module,
	unclickeable,
	setUserToModify,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	// const history = useHistory();

	const [checkedAll, setCheckedAll] = React.useState(false);
	const [checkedindeterminate, setCheckedindeterminate] = React.useState(false);
	const [openDeletePopup, setopenDeletePopup] = React.useState(false);

	const dispatch = useDispatch();
	const filterUsers = useSelector(({ filterUsers: filterUsersparams }) => filterUsersparams);

	const idUsers = useSelector(
		({ requests }) =>
			requests.queries.FETCH_SUPER_USERS &&
			requests.queries.FETCH_SUPER_USERS.data &&
			requests.queries.FETCH_SUPER_USERS.data.content
	);

	useEffect(() => {
		dispatch(fetchSuperUsers({ ...filterUsers, typeUser }));
	}, [filterUsers]);

	useEffect(() => {
		setCheckedAll(
			idUsers && selected && selected.length === idUsers.length && idUsers.length !== 0
		);
		setCheckedindeterminate(
			idUsers && selected && !isEmpty(selected) && selected.length < idUsers.length
		);
	}, [selected]);

	return (
		<>
			<Card className={classes.header}>
				<CardContent className={classes.content}>
					<Grid container justify="left" alignItems="flex-end" style={{ height: "50px" }}>
						<Grid
							container
							item
							xs={3}
							align="left"
							alignItems="center"
							spacing={2}
							direction="row-reverse"
						>
							<Grid item xs={6}>
								<Typography variant="body2" align="left" color="textSecondary">
									{t(get(columns, 1))}
								</Typography>
								<Grid item xs={6} />
							</Grid>
						</Grid>
						<Grid item xs={3} align="left">
							<Grid item>
								<Typography variant="body2" align="center" color="textSecondary">
									{t(get(columns, 2))}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={3} align="left">
							<Grid item>
								<Typography variant="body2" align="center" color="textSecondary">
									{t(get(columns, 3))}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions>
					<Grid container xs={12} justify="flex-end" style={{ minWidth: "60px" }}>
						&nbsp;
					</Grid>
				</CardActions>
				{withActions && (
					<CardActions>
						<Grid
							container
							xs={12}
							justify="flex-end"
							alignContent="flex-end"
							alignItems="flex-end"
							style={{ marginLeft: "48px" }}
						>
							<Grid item>
								<Tooltip placement="top" title={t("tooltip.action.delete")}>
									<IconButton
										aria-label="delete"
										disabled={isEmpty(selected)}
										onClick={() => {
											setopenDeletePopup(true);
										}}
									>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
								<DeleteUsersDialog
									selected={selected}
									setSelected={setSelected}
									open={openDeletePopup}
									setOpen={setopenDeletePopup}
									typeUser={typeUser}
									filterUsers={filterUsers}
								/>
							</Grid>
							<Grid item>
								<Checkbox
									checked={checkedAll}
									indeterminate={checkedindeterminate}
									onChange={(e) => {
										handleSelectedAll(e.target.checked);
									}}
								/>
							</Grid>
						</Grid>
					</CardActions>
				)}
			</Card>
			<Query type={FETCH_SUPER_USERS} loadingComponent={CircularLoader}>
				{({ data }) =>
					data.map((row, index) => (
						<TableSuperUsers
							key={index}
							row={row}
							index={index}
							selected={selected}
							setSelected={setSelected}
							typeUser={typeUser}
							module={module}
							unclickeable={unclickeable}
							setIdUserToModify={setUserToModify}
						/>
					))
				}
			</Query>
		</>
	);
}

export default TableSuperUsersList;
