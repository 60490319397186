import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
export default function SelectPayment({
	value,
	onChange,
	name,
	className,
	inputRef,
	List,
	onClick,
	disabled,
}) {
	const [stateValue, setStateValue] = React.useState({});
	const [inputValue, setInputValue] = React.useState("");
	useEffect(() => {
		if (List && value) setStateValue(value);
	}, [List, value]);
	return (
		<Autocomplete
			id="open-on-focus"
			fullWidth
			options={List.map((e) => e.label) ?? []}
			autoHighlight
			getOptionLabel={(option) => option}
			value={stateValue}
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			freeSolo
			onChange={(event, newValue) => {
				setStateValue(event.target.value);
				onChange(newValue);
			}}
			renderOption={(option) => <>{option}</>}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params.inputProps,
						autoComplete: "new-password", // disable autocomplete and autofill
					}}
					onClick={onClick}
					name={name}
					inputRef={inputRef}
					className={className}
					disabled={disabled}
					fullWidth
				/>
			)}
		/>
	);
}
