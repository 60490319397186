import React, { useEffect } from "react";

function PDFViewer(props) {
  const viewerRef = React.useRef();
  const backend = new props.backend(); // eslint-disable-line

  useEffect(() => {
    const { src } = props;
    const element = viewerRef.current;

    backend.init(src, element);
  }, []);

  return (
    <div
      ref={viewerRef}
      id="viewer"
      style={props.style}
    />
  );
}
export default PDFViewer;
