export default {
	countSuppliers: "Gesamtanzahl von den Lieferanten",
	searchSupplier: "Lieferant finden",
	contact: "Kontakt",
	principalContact: "Ansprechspartner",
	outstandingInvoices: "Unbezahlte Rechnungen",
	addSupplier: "Lieferant einfügen",
	"limited.liability.company": "GmbH",
	"single.person.limited.liability": "EURL",
	"public.limited.company": "AG",
	"general.partnership": "G.b.R.",
	"simplified.joint.stock.company": "SAS",
	"single.person.simplified.joint.stock.company": "SASU",
	other: "Sonstiges",
	companySiret: "MwSt-Nr.",
	vatNumber: "USt-IdNr.",
	legalForm: "Rechtsform",
	codeNaf: "Stellung im Beruf",
	email: "EMail",
	address: "Adresse",
	fax: "Fax",
	socialCapital: "Aktienkapital",
	name: "Name",
	function: "Funktion",
	phone: "Telefon",
	bank: "Bank",
	iban: "IBAN",
	bic: "BIC",
	rib: "Bankkoordinaten",
	account_journal_auxiliary: "Zusatzbuchungsjournal",
	account_journal_number: "Buchführungskonto",
	noPrincipalContact: "Keine Kontakte zugeordnet",
	legalInformation: "Identifikationsnummern",
	generalInformation: "Allgemeine Informationen",
	financialInformation: "Finanzielle Informationen",
	associatedContact: "Zugehörige Kontakte",
	return: "Zurück",
	invoice_uploaded_success:
		"Erfolgreiche Zufügung von der Rechnung. Sie wird in Ihrer Liste sichtbar, wenn Sie diese Rechnung ausgestellt haben.",
	"upload.instructions": "Anleitungen",
	"upload.instructions1": "Füllen Sie die Excel-Tabelle mit den Lieferanten-Informationen",
	"upload.instructions2":
		"Füllen Sie die «Logo»-Spalte mit dem Dateiname des Logos. (z.B.: logofilename.jpg)",
	"upload.instructions3": "Alle Felder müssen im TEXT-Format sein",
	"upload.instructions4":
		"Stellen Sie die gefüllte Excel-Tabelle und die Lieferanten-Logos im gleichen Ordner",
	"upload.instructions5": "Komprimieren Sie diesen Ordner:",
	"upload.instructions51":
		'Windows: Rechten Klick auf dem Ordner, dann wählen Sie "Schicken nach " dann klicken Sie auf "komprimierter Ordner "',
	"upload.instructions52":
		"MAC: Klicken Sie darauf und halten sie die Taste gedrückt oder drücken Sie einfach mit zwei Finger, dann wählen Sie Komprimieren",
	"upload.instructions6":
		"Laden Sie den komprimierten Ordner auf dem Portal oder verschieben und stellen Sie ihn im dafür vorgesehenen Bereich",
	"upload.instructions7": 'Klicken Sie auf "Lieferanten aufladen"',
	"supplier.modify": " Lieferanten ändern",
	"invoice.attachment.label": "Wortlaut",
	"invoice.attachment.extension": "Dateityp",
	"invoice.attachment.type": "Art des Dokuments",
	"invoice.attachment.creationDate": "Datum hinzugefügt",
	"invoice.attachment.size": "Schnitt",
	field_required: "Erforderlich",
	"add attach": "Anhang hinzufügen",
	"attachment.upload": "Fügen Sie die Datei hinzu",
	"attachment.upload.success": "Datei erfolgreich hochgeladen",
	"attachment.upload.error": "Datei konnte nicht hochgeladen werden",
	"Add your Attachment": "Fügen Sie Ihren Anhang hinzu",
	"invoice.attachment.delete.confirm": "Möchten Sie diesen Anhang wirklich löschen?",
	"attachment.delete.success": "Anhang erfolgreich gelöscht",
	"workflow.invoice.exists": "Rechnungsstatus-Deletion.",
	"workflow.invoice.exists.message":
		"Der Rechnungsstatus kann nicht löschen.Eine oder mehrere Rechnung sind in diesem Status.",
	"workflow.changeInvoicesStatus": "Rechnungsstatus ändern",
	"workflow.updateStatus.warning":
		"Möchten Sie diesen Rechnungsstatus sicher? Diese Aktion speichert auch den Workflow und kann nicht rückgängig gemacht werden.",
	"supplier.lockedTraining": "Gesperrt?",
	"workflow.load.error": "Fehler beim Abrufen des Workflows",
	"workflow.toolTip.addStatus": "Speichern hinzufügen.",
	"workflow.toolTip.save": "Speichern",
	"workflow.toolTip.clone": "Klon",
	"workflow.toolTip.undoCHanges": "Änderungen stornieren",
	"workflow.clone.newName": "Neuer Workflow.",
	"workflow.clone.code": "Name",
	"workflow.clone.description": "Beschreibung",
	"workflow.clone.save": "Speichern",
	"workflow.clone.success": "Workflow erfolgreich kloniert!",
	"workflow.clone.error": "Fehler beim Klonen des Workflows aufgetreten",
	searchClient: "Zoek een klant",
	"invoice.transition.reintegrate": "Wiedereingliederung der Rechnung",
	"history.oldValue": "Alter Wert",
	"history.newValue": "Neuer Wert",
	"history.showMore": "Uhr plus",
	INVOICE_NO: "Rechnungsnummer",
	SUPPLIER: "Lieferant",
	ISSUE_DATE: "Erstellungsdatum",
	DUE_DATE: "Fristdatum",
	VAT: "USt-IdNr",
	COMPANY_ID: "Mw-StNr. des Unternehmens",
	COUNTRY: "Land",
	PURCHASE_ORDER_NO: "Bestellungsnummer",
	PAYMENT_TERM: "Zahlungsweise",
	DELIVERY_SLIP: "Lieferschein",
	CURRENCY: "Währung",
	DELIVER_ADDRESS: "Lieferadresse",
	BILLING_ADDRESS: "Rechnungsadresse",
	ORDER_NO: "Bestellnummer",
	CLIENT: "Klientin",
	"history.fieldName": "Feldname",
	ADD_ATTACHMENT: "Anhang hinzugefügt",
	DELETE_ATTACHMENT: "Adjunto eliminado",
	TOTAL: "Gesamt",
	DISCOUNT: "Rabatt",
};
