import React from "react";
import { useHistory } from "react-router";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import CardActions from "@material-ui/core/CardActions";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { setIsUserModif } from "App/appSlice";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import useStyles from "../style";
import { deleteUser, fetchSuperUsers } from "../redux/actions";

const nonAgentTypes = ["SUPPLIER", "CLIENT"];

const DeleteDialog = ({ open, setOpen, userId, setSelected }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleCloseConfirmationPopup = () => {
		setOpen(!open);
	};
	const onClick = () => {
		dispatch(
			deleteUser(userId, () => {
				dispatch(fetchSuperUsers()).then(() => setSelected([]));
			})
		);
		handleCloseConfirmationPopup();
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseConfirmationPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t("deleteUserActionMessage")}</DialogTitle>

			<DialogActions>
				<Button onClick={handleCloseConfirmationPopup} color="secondary" autoFocus>
					{t("cancel")}
				</Button>
				<Button onClick={onClick} type="submit" color="secondary">
					{t("confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
function TableSuperUsers({
	row,
	index,
	selected,
	setSelected,
	withActions = true,
	typeUser,
	module,
	unclickeable,
	setIdUserToModify,
}) {
	const classes = useStyles();
	const [file] = React.useState();
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const filterUsers = useSelector(({ filterUsers: filterUsersParam }) => filterUsersParam);
	const [openDeletePopup, setopenDeletePopup] = React.useState(false);

	const onClickRow = (id) => {
		if (!unclickeable) history.push(`/administration/${module}/view/${id}`);
	};

	return (
		<Card key={index} className={classes.root} elevation={1}>
			<CardContent className={classes.content}>
				<ButtonBase focusRipple onClick={() => onClickRow(row.id)}>
					<Grid container justify="left" alignItems="center">
						<Grid
							container
							item
							xs={3}
							justify="center"
							alignItems="center"
							direction="row-reverse"
							spacing={2}
						>
							<Grid item>
								<Typography variant="subtitle2" align="left" color="textSecondary">
									{typeUser === "SUPPLIER"
										? get(row, "supplier.label")
										: typeUser === "CLIENT"
										? get(row, "client.label")
										: get(row, "lastName")}
								</Typography>
							</Grid>
							<Grid item>
								<Avatar alt="user" src={file} className={classes.large} />
							</Grid>
						</Grid>
						<Grid item xs={3} align="left">
							<Grid item>
								<Typography variant="subtitle2" align="center" color="textSecondary">
									{nonAgentTypes.find((e) => e === typeUser)
										? get(row, "email")
										: get(row, "firstName")}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={3} align="left">
							<Grid item>
								<Typography variant="subtitle2" align="center" color="textSecondary">
									{t(get(row, "jobTitle.label"))}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</ButtonBase>
			</CardContent>

			<CardActions>
				<Grid container xs={12} justify="flex-end" style={{ minWidth: "60px" }}>
					&nbsp;
				</Grid>
			</CardActions>
			{withActions && (
				<CardActions>
					<Grid container xs={12} justify="flex-end">
						<Grid item>
							<Tooltip placement="top" title={t("tootlip.action.edit")}>
								<IconButton
									aria-label="edit"
									onClick={() => {
										setIdUserToModify(row.id);
										dispatch(setIsUserModif());
										// history.push(`/`);
										history.push(`/administration/${module}/edit/${row.id}`);
									}}
								>
									<EditIcon />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip placement="top" title={t("tooltip.action.delete")}>
								<IconButton
									aria-label="delete"
									onClick={() => {
										setopenDeletePopup(true);
									}}
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
							<DeleteDialog
								userId={row.id}
								typeUser={typeUser}
								filterUsers={filterUsers}
								setSelected={setSelected}
								open={openDeletePopup}
								setOpen={setopenDeletePopup}
							/>
						</Grid>
						<Grid item>
							<Checkbox
								value={parseInt(row.id, 10)}
								checked={selected.includes(parseInt(row.id, 10))}
								onChange={(e) => {
									const list = [...selected];
									// eslint-disable-next-line
									e.target.checked
										? list.push(parseInt(e.target.value, 10))
										: list.splice(list.indexOf(parseInt(e.target.value, 10)), 1);
									setSelected(list);
								}}
							/>
						</Grid>
					</Grid>
				</CardActions>
			)}
		</Card>
	);
}

export default TableSuperUsers;
