import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { isActionAllowed } from "components/HasPermission";
import { toast } from "utils";
import TableCardItem from "../../components/TableCardItem";
import CircularLoader from "../../components/Loaders/CircularLoader";
import Filter from "../../pages/Clients/Filter";
import { fetchClients } from "../../redux/actions/clients";
import { deleteTrainingForClient, lockClientTraining } from "../redux/actions";
import { updateFilter, initFilter } from "../../redux/clientFilterReducer";
import ConfirmDialog from "../../components/ConfirmDialog";

import useStyles from "./style";

const ConfirmationDeleteTrainingDialog = ({ open, setOpen, selectedId, setSelectedId }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleCloseDeletePopup = () => {
		setOpen(!open);
		setSelectedId(0);
	};
	const deleteTraining = (clientId) => {
		setSelectedId(clientId);
		dispatch(
			deleteTrainingForClient(
				clientId,
				(msg) => {
					setSelectedId(0);
					setOpen(false);
					toast.error(t(msg));
				},
				(response) => {
					setSelectedId(0);
					setOpen(false);
					toast.error(t(response.data.detail));
				}
			)
		);
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleCloseDeletePopup}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t("deleteTrainingMessage")}</DialogTitle>

				<DialogActions>
					<Button onClick={handleCloseDeletePopup} color="secondary" autoFocus>
						{t("cancel")}
					</Button>
					<Button onClick={() => deleteTraining(selectedId)} type="submit" color="secondary">
						{t("confirm")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

const ListClientsSetting = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [selected, setSelected] = React.useState([]);
	const clients = useSelector(({ requests }) => get(requests, "queries.FETCH_CLIENTS.data"));
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = React.useMemo(() => get(currentUser, "permissions"), [
		currentUser,
	]);
	const isToggleTrainingAllowed = isActionAllowed(
		currentUserPermissions,
		"Clients training",
		"Toggle training"
	);
	const isDeleteTrainingAllowed = isActionAllowed(
		currentUserPermissions,
		"Clients training",
		"Delete training"
	);
	const idClients = "";

	const [selectedId, setSelectedId] = React.useState(0);
	const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		isLoading: false,
	});
	const [clientId, setClientId] = React.useState(null);

	const filterUsers = useSelector(({ filterClients: i }) => i);

	const handleSelectedAll = (checked) => {
		setSelected(checked ? idClients : []);
	};

	const handleChangePage = (event, newPage) => {
		dispatch(updateFilter({ ...filterUsers, pageNo: newPage }));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(
			updateFilter({
				...filterUsers,
				pageSize: +event.target.value,
				pageNo: 0,
			})
		);
	};

	useEffect(() => {
		dispatch(fetchClients(filterUsers));
	}, [filterUsers, dispatch]);

	useEffect(() => {
		dispatch(initFilter());
	}, [dispatch]);

	const onConfirm = () => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		dispatch(
			deleteTrainingForClient(
				clientId,
				(msg) => {
					setSelectedId(0);
					setConfirmObject({ message: "", openConfirm: false, isLoading: false });
					toast.success(t(msg));
				},
				(response) => {
					setSelectedId(0);
					setConfirmObject({ message: "", openConfirm: false, isLoading: false });
					toast.error(t(response.data.detail));
				}
			)
		);
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false, isLoading: false });
	};

	const handleLockTraining = (id) => {
		dispatch(lockClientTraining(id)).then((res) => {
			if (res.status === 200) dispatch(fetchClients(filterUsers));
		});
	};

	const getTraningButton = (id, isLocked) => {
		if (isLocked) {
			return (
				<Button
					variant="contained"
					type="submit"
					color="primary"
					onClick={() => {
						handleLockTraining(id);
					}}
					className={classes.buttonDelete}
					disabled={!isToggleTrainingAllowed}
				>
					{t("unlockTraining")}
				</Button>
			);
		}
		return (
			<Button
				variant="contained"
				type="submit"
				onClick={() => {
					handleLockTraining(id);
				}}
				className={classes.lockButton}
				disabled={!isToggleTrainingAllowed}
			>
				{t("lockTraining")}
			</Button>
		);
	};

	const getContent = () => {
		const res = [];
		Object.values(get(clients, "content")).forEach((row, i) => {
			const items = (
				<Grid container xs alignItems="center" justify="flex-end">
					<Grid container direction="row" xs={4} justify="flex-end" style={{ marginRight: 10 }}>
						<Grid item alignItems="center">
							{getTraningButton(row.id, row.lockedTraining)}
						</Grid>
					</Grid>
					<Grid container xs={4} justify="flex-end">
						<Button
							key={i}
							variant="contained"
							type="submit"
							color="secondary"
							disabled={row.id === selectedId || !isDeleteTrainingAllowed}
							onClick={() => {
								setClientId(row.id);
								setConfirmObject({
									...confirmObject,
									message: t("deleteTrainingMessage"),
									openConfirm: true,
								});
							}}
							className={classes.buttonDelete}
						>
							{t("deleteTraining")}
						</Button>
					</Grid>
				</Grid>
			);
			const content = (
				<TableCardItem
					admin
					index={i}
					t={t}
					row={row}
					contactDetails
					withLogo
					isClient
					withActions
					isClientTraining
					supplierTrainingItem={items}
				/>
			);
			res.push(content);
		});
		return res;
	};

	return (
		<>
			<Grid spacing={2} direction="column" xs={12} container className={classes.firstGrid}>
				<Grid item container>
					<Filter
						selected={selected}
						setSelected={setSelected}
						handleSelectedAll={handleSelectedAll}
					/>
				</Grid>
				<Grid item className={classes.list}>
					{get(clients, "content") ? getContent() : <CircularLoader />}
					<TablePagination
						rowsPerPageOptions={[10, 25, 50]}
						component="div"
						count={get(clients, "totalElements") ? get(clients, "totalElements") : 0}
						rowsPerPage={filterUsers.pageSize}
						page={filterUsers.pageNo}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
						labelRowsPerPage={t("pagination.rows.per.page")}
						labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("pagination.of")} ${count}`}
					/>
				</Grid>
				<ConfirmationDeleteTrainingDialog
					open={openDeletePopup}
					setOpen={setOpenDeletePopup}
					selectedId={selectedId}
					setSelectedId={setSelectedId}
				/>
				<ConfirmDialog
					message={confirmObject.message}
					open={confirmObject.openConfirm}
					isLoading={confirmObject.isLoading}
					onConfirm={onConfirm}
					onCancel={onCancel}
				/>
			</Grid>
		</>
	);
};
export default ListClientsSetting;
