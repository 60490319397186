export const EXTENTIONS_INVOICES = ["image/*", "application/pdf", "application/xml", ".xml"];
export const EXTENTIONS_ATTACHMENTS = [
	"image/*",
	"application/pdf",
	"application/xml",
	"text/plain",
	"text/html",
	".xml",
	".csv",
	".doc",
	".docx",
	".xlsx",
];
export const INVOICE = "INVOICE";
export const ORDER = "ORDER";
