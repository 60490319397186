import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import axios from "axios";
import { handleRequests } from "@redux-requests/core";
import { createDriver } from "@redux-requests/axios";
import get from "lodash/get";
import rootReducer from "./reducers/rootReducer";

export const getApi = () => {
	let locationHref = window.location.origin;
	const splitOrigin = locationHref.split(".");
	let uri;
	if (process.env.REACT_APP_DOMAIN === undefined) {
		locationHref =
			splitOrigin.length > 1
				? locationHref.replace(splitOrigin[0], `${splitOrigin[0]}-api`)
				: locationHref;
		uri = `${locationHref}/api`;
	} else {
		uri = process.env.REACT_APP_DOMAIN;
	}

	return uri;
};
export const getApiGateway = () => {
	let locationHref = window.location.origin;
	const splitOrigin = locationHref.split(".");
	let uri;
	if (process.env.REACT_APP_GATEWAY === undefined) {
		locationHref =
			splitOrigin.length > 1
				? locationHref.replace(splitOrigin[0], `${splitOrigin[0]}-api`)
				: locationHref;
		uri = `${locationHref}`;
	} else {
		uri = process.env.REACT_APP_GATEWAY;
	}

	return uri;
};
export const configureStore = () => {
	const uri = getApi();
	const instance = axios.create({
		baseURL: uri,
	});
	instance.defaults.withCredentials = true;
	instance.interceptors.request.use((config) => {
		config.headers.common["X-Requested-With"] = "XMLHttpRequest";
		config.headers.common["Accept-Language"] = localStorage.getItem("lgn")
			? localStorage.getItem("lgn")
			: "fr";
		return config;
	});

	instance.interceptors.response.use(
		(response) =>
			/** In dev, intercepts request and logs it into console for dev */
			response,
		(error) => {
			if (get(error, "response.status") === 401) {
				const currectUrl = window.location.href;
				window.location.href = `${getApiGateway()}/login/sso?redirect_url=${encodeURI(currectUrl)}`;
			}
			return Promise.reject(error);
		}
	);

	const { requestsReducer, requestsMiddleware } = handleRequests({
		driver: createDriver(instance),
	});

	const reducers = combineReducers({
		...rootReducer,
		requests: requestsReducer,
	});

	const composeEnhancers =
		(typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

	const store = createStore(reducers, composeEnhancers(applyMiddleware(...requestsMiddleware)));

	return store;
};

export default configureStore;
