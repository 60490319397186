import { createSlice } from "@reduxjs/toolkit";

const initialFilter = {
	userName: "",
	status: [],
	pageNo: 0,
	pageSize: 10,
	sortBy: "",
	typeUser: null,
};
const filterListUsers = createSlice({
	name: "filterUsers",
	initialState: initialFilter,
	reducers: {
		updateFilter: (state, action) => (state = action.payload), // eslint-disable-line
		initFilter: (state) => (state = initialFilter), // eslint-disable-line
	},
});

export const { updateFilter, initFilter } = filterListUsers.actions;

export default filterListUsers.reducer;
