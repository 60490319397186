import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@material-ui/core/Chip";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { initialFilter, updateFilter } from "../../../../../redux/filterReducer";
import chipData from "./data";

import useStyles from "./style";

import { duplicated, digitalInvoice } from "../../../utils";

export default () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const filterInvoices = useSelector(({ filterInvoices: i }) => i);
	const tempFilter = filterInvoices || initialFilter;
	const { t } = useTranslation();
	const [selectedChip, setSelectedChip] = React.useState([]);
	const filters = [duplicated, digitalInvoice];

	useEffect(() => {
		const newFilter = { ...tempFilter, pageNo: 0 };
		newFilter.extractionLvl = selectedChip.filter((i) => !filters.includes(i));
		newFilter.duplicated = !!selectedChip.find((i) => i === duplicated);
		newFilter.digitalInvoice = !!selectedChip.find((i) => i === digitalInvoice);
		// localStorage.setItem("invoice_filter", JSON.stringify(newFilter));
		dispatch(updateFilter(newFilter));
	}, [selectedChip]);
	useEffect(() => {
		const newFilter = { ...filterInvoices };
		if (newFilter.extractionLvl) {
			setSelectedChip(newFilter.extractionLvl);
		}
		if (newFilter.duplicated) {
			let selected = newFilter.extractionLvl;
			selected = [...selected, duplicated];
			setSelectedChip(selected);
		}
		if (newFilter.digitalInvoice) {
			let selected = newFilter.extractionLvl;
			selected = [...selected, digitalInvoice];
			setSelectedChip(selected);
		}
	}, []);

	const handleDelete = (id) => {
		setSelectedChip(selectedChip.filter((i) => i !== id));
	};

	const handleClick = (id) => {
		setSelectedChip([...selectedChip, id]);
	};

	return (
		<div className={classes.chipLayout}>
			{chipData.map(({ labelId, icon, id }) => {
				const isSelected = selectedChip.includes(id);
				return (
					<Chip
						key={id}
						icon={icon}
						label={t(labelId)}
						onClick={() => handleClick(id)}
						onDelete={isSelected ? () => handleDelete(id) : null}
						classes={{
							root: clsx(classes.chip, classes[isSelected ? `${id}Selected` : id], {
								[classes.chipSelected]: isSelected,
							}),
						}}
					/>
				);
			})}
		</div>
	);
};
