import React from "react";
import {
	Grid,
	Card,
	CardContent,
	Typography,
	CardActions,
	Button,
	TablePagination,
} from "@material-ui/core";
import { get } from "lodash";
import CircularLoader from "components/Loaders/CircularLoader";

const CommercialOrganizationSuppliers = ({
	t,
	classes,
	selectedSuppliers,
	setSelectedSuppliers,
	handleChangePage,
	suppliers,
	handleChangeRowsPerPage,
	filterUsers,
}) => {
	const addSupplier = (id) => {
		if (selectedSuppliers?.includes(id)) {
			setSelectedSuppliers(selectedSuppliers?.filter((val) => val !== id));
		} else {
			setSelectedSuppliers([...selectedSuppliers, id]);
		}
	};

	const renderSuppliersList = () =>
		suppliers &&
		suppliers?.content?.map((supplier, index) => (
			<Card
				key={`commercial-organization-supplier-${index}`}
				className={classes.root}
				elevation={1}
			>
				<CardContent className={classes.content}>
					<Grid container item xs={12} direction="row" justify="space-between">
						<Grid container item xs={11} justify="flex-start">
							<Typography>{get(supplier, "label")}</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className={classes.actionButton}>
					<Grid container xs={12} justify="flex-end" alignContent="flex-end" alignItems="flex-end">
						<Button
							className={
								selectedSuppliers?.includes(get(supplier, "id"))
									? classes.notSelected
									: classes.selectedSupplier
							}
							variant="contained"
							fullWidth
							onClick={() => addSupplier(get(supplier, "id"))}
						>
							{selectedSuppliers?.includes(get(supplier, "id"))
								? t("admin.commercialOrganization.remove")
								: t("admin.commercialOrganization.add")}
						</Button>
					</Grid>
				</CardActions>
			</Card>
		));
	return (
		<Grid container direction="row" item xs={12}>
			{get(suppliers, "content") ? renderSuppliersList() : <CircularLoader />}
			<Grid item className={classes.list}>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={get(suppliers, "totalElements") ? get(suppliers, "totalElements") : 0}
					rowsPerPage={filterUsers.pageSize}
					page={filterUsers.pageNo}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
					labelRowsPerPage={t("pagination.rows.per.page")}
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} ${t("pagination.of")} ${count}`
					}
				/>
			</Grid>
		</Grid>
	);
};

export default CommercialOrganizationSuppliers;
