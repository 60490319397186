import React from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import useStyles from "../style";

export const DetailsFinancialInformations = ({ supplier }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<Grid container xs={12} spacing={3}>
			<Grid container item xs={12} spacing={3}>
				<Grid item xs={6}>
					<Typography variant="h6" align="center">
						{t("bank")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "bank") ? get(supplier, "bank") : "-"}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" align="center">
						{t("iban")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "iban") ? get(supplier, "iban") : "-"}
						</Typography>
					</Typography>
				</Grid>
			</Grid>

			<Grid container item xs={12} spacing={3}>
				<Grid item xs={6}>
					<Typography variant="h6" align="center">
						{t("bic")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "bic") ? get(supplier, "bic") : "-"}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" align="center">
						{t("rib")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "rib") ? get(supplier, "rib") : "-"}
						</Typography>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} spacing={3}>
				<Grid item xs={6}>
					<Typography variant="h6" align="center">
						{t("account_journal_auxiliary")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "accountJournalAuxiliary")
								? get(supplier, "accountJournalAuxiliary")
								: "-"}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" align="center">
						{t("account_journal_number")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "accountJournalNumber") ? get(supplier, "accountJournalNumber") : "-"}
						</Typography>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DetailsFinancialInformations;
