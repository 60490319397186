import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function MultiLines({ rows }) {
  return (
    <Grid container direction="column">
      {rows &&
        rows.map((row) => (
          <Grid container item>
            <Grid item xs={8}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="left"
              >
                {row.label}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary" align="left">
                {row.value}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}

export default MultiLines;
