import {
	FETCH_ALL_COMMERCIAL_ORGANIZATIONS,
	FETCH_COMMERCIAL_ORGANIZATION,
	FETCH_COMMERCIAL_ORGANIZATIONS,
} from "../../constants";
import { URL_FETCH_COMMERCIAL_ORGANIZATION } from "../../constants/endpoint";

export const fetchAllCommercialOrganizations = ({ pageNumber = 0, pageSize = 50, userName }) => ({
	type: FETCH_ALL_COMMERCIAL_ORGANIZATIONS,
	request: {
		url: `${URL_FETCH_COMMERCIAL_ORGANIZATION}/list?pageNumber=${pageNumber}&pageSize=${pageSize}&name=${
			userName || ""
		}`,
	},
});

export const fetchGroup = (id) => ({
	type: FETCH_COMMERCIAL_ORGANIZATION,
	request: {
		url: `${URL_FETCH_COMMERCIAL_ORGANIZATION}/${id}`,
	},
});

export const fetchCommercialOrganizations = () => ({
	type: FETCH_COMMERCIAL_ORGANIZATIONS,
	request: {
		url: `${URL_FETCH_COMMERCIAL_ORGANIZATION}/all`,
	},
});

export const fetchCommercialOrganization = (id, module) => ({
	type: FETCH_COMMERCIAL_ORGANIZATION,
	request: {
		url: `${URL_FETCH_COMMERCIAL_ORGANIZATION}/${id}?env=${module}`,
	},
});

export const deleteCommercialOrganization = (id, env) => ({
	type: FETCH_COMMERCIAL_ORGANIZATION,
	request: {
		url: `${URL_FETCH_COMMERCIAL_ORGANIZATION}/${id}?env=${env}`,
		method: "delete",
	},
});

export const saveNewSuppliersCommercialOrganization = (data) => ({
	type: "SAVE_COMMERCIAL_ORGANIZATION",
	request: {
		url: `${URL_FETCH_COMMERCIAL_ORGANIZATION}/update?env=INVOICE`,
		data: { ...data },
		method: "put",
	},
});

export const saveNewClientsCommercialOrganization = (data) => ({
	type: "SAVE_COMMERCIAL_ORGANIZATION",
	request: {
		url: `${URL_FETCH_COMMERCIAL_ORGANIZATION}/update?env=ORDER`,
		data: { ...data },
		method: "put",
	},
});
