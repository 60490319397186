import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
	root: {},
	inputRoot: {
		// This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
		'&[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
			// Default left padding is 6px
			paddingTop: 3,
			paddingBottom: 3,
		},
	},
}));

export default function GlAccountsBox({ glAccounts, selectedAccount, index, onChangeAccount }) {
	const classes = useStyles();

	const [value, setValue] = React.useState(
		glAccounts.find((gl) => gl.accountNumber === selectedAccount)
	);
	return (
		<Autocomplete
			id="country-select-demo"
			options={glAccounts ?? []}
			autoHighlight
			fullWidth
			getOptionLabel={(option) => option.accountNumber}
			value={value}
			classes={classes}
			onChange={(event, newValue) => {
				onChangeAccount(index, "glAccount", newValue?.accountNumber ?? "");
				setValue(newValue);
			}}
			renderOption={(props, option) => (
				<Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
					{option.accountNumber} - {option.accountName} - {option.accountType}
				</Box>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					label=""
					variant="standard"
					inputProps={{
						...params.inputProps,
						autoComplete: "new-password", // disable autocomplete and autofill
					}}
				/>
			)}
		/>
	);
}
