import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
	Grid,
	Typography,
	Tooltip,
	IconButton,
	Divider,
	OutlinedInput,
	Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "utils";
import { updateSettings } from "administration/redux/actions";
import { fetchSettingsByModuleName } from "redux/actions/users";
import CircularLoader from "components/Loaders/CircularLoader";
import useStyles from "../style";
import ConfirmDialog from "../../../components/ConfirmDialog";

// TODO: refactor this mess.

const InvoiceSettings = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { errors, handleSubmit, register } = useForm();

	const [confirmObject, setConfirmObject] = useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});
	const [edit, setEdit] = useState(false);
	const [settingsToSave, setSettingsToSave] = useState(null);
	const [settings, setSettings] = useState(null);
	const [loaded, setLoaded] = useState(false);

	const [autoExport, setAutoExport] = useState({ loaded: false, changed: false });
	const [exportJE, setExportJE] = useState({ loaded: false, changed: false });
	const [exportPDF, setExportPDF] = useState({ loaded: false, changed: false });
	const [exportXML, setExportXML] = useState({ loaded: false, changed: false });
	const [exportEXCEL, setExportEXCEL] = useState({ loaded: false, changed: false });
	const [showExportPDF, setShowExportPDF] = useState({ loaded: false, changed: false });
	const [showExportXML, setShowExportXML] = useState({ loaded: false, changed: false });
	const [showExportEXCEL, setShowExportEXCEL] = useState({ loaded: false, changed: false });
	const [deleteAction, setDeleteAction] = useState({ loaded: false, changed: false });
	const [showExportEdi, setShowExportEdi] = useState({ loaded: false, changed: false });
	const [exportEdi, setExportEdi] = useState({ loaded: false, changed: false });
	const [showExportIdoc, setShowExportIdoc] = useState({ loaded: false, changed: false });
	const [exportIdoc, setExportIdoc] = useState({ loaded: false, changed: false });

	const [showExportUBL, setShowExportUBL] = useState({ loaded: false, changed: false });
	const [showExportCII, setShowExportCII] = useState({ loaded: false, changed: false });
	const [showExportFacturX, setShowExportFacturX] = useState({ loaded: false, changed: false });

	const [showExportExcelAccounting, setShowExportExcelAccounting] = useState({
		loaded: false,
		changed: false,
	});
	const [deleteAllAction, setDeleteAllAction] = useState({ loaded: false, changed: false });
	const [relaunchExtraction, setRelaunchExtraction] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorMyTasks, setIndicatorMyTasks] = useState({ loaded: false, changed: false });
	const [indicatorInvoicesExported, setIndicatorInvoicesExported] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorInvoicesNotExported, setIndicatorInvoicesNotExported] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorLastActions, setIndicatorLastActions] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorLoadInvoices, setIndicatorLoadInvoices] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorLast5Invoices, setIndicatorLast5Invoices] = useState({
		loaded: false,
		changed: false,
	});

	const getDataFromSettings = (data, code) => data.find((e) => e.code === code);

	const intSettings = (data) => {
		setSettings(data);
		if (getDataFromSettings(data, "AUTO_EXPORT"))
			setAutoExport({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "AUTO_EXPORT").value === "1",
			});

		if (getDataFromSettings(data, "EXPORT_EC"))
			setExportJE({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_EC").value === "1",
			});

		if (getDataFromSettings(data, "EXPORT_PDF"))
			setExportPDF({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_PDF").value === "1",
			});
		if (getDataFromSettings(data, "EXPORT_XML"))
			setExportXML({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_XML").value === "1",
			});

		if (getDataFromSettings(data, "EXPORT_EXCEL"))
			setExportEXCEL({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_EXCEL").value === "1",
			});

		if (getDataFromSettings(data, "INVOICE_EXPORT_PDF"))
			setShowExportPDF({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_PDF").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_XML"))
			setShowExportXML({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_XML").value === "1",
			});

		if (getDataFromSettings(data, "INVOICE_EXPORT_EXCEL"))
			setShowExportEXCEL({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_EXCEL").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_RELAUNCH_EXTRACTION"))
			setRelaunchExtraction({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_RELAUNCH_EXTRACTION").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_DELETE_ACTION"))
			setDeleteAction({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_DELETE_ACTION").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_DELETE_All_ACTION"))
			setDeleteAllAction({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_DELETE_All_ACTION").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_MY_TASKS"))
			setIndicatorMyTasks({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_MY_TASKS").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_INVOICES_EXPORTED"))
			setIndicatorInvoicesExported({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_INVOICES_EXPORTED").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_INVOICES_NOT_EXPORTED"))
			setIndicatorInvoicesNotExported({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_INVOICES_NOT_EXPORTED").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_LAST_ACTIONS"))
			setIndicatorLastActions({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_LAST_ACTIONS").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_LOAD_INVOICES"))
			setIndicatorLoadInvoices({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_LOAD_INVOICES").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_LAST_5_INVOICES"))
			setIndicatorLast5Invoices({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_LAST_5_INVOICES").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY"))
			setShowExportExcelAccounting({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY").value === "1",
			});
		if (getDataFromSettings(data, "EXPORT_EDI"))
			setExportEdi({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_EDI").value === "1",
			});
		if (getDataFromSettings(data, "EXPORT_IDOC"))
			setExportIdoc({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_IDOC").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_EDI"))
			setShowExportEdi({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_EDI").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_IDOC"))
			setShowExportIdoc({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_IDOC").value === "1",
			});

		if (getDataFromSettings(data, "INVOICE_EXPORT_UBL"))
			setShowExportUBL({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_UBL").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_CII"))
			setShowExportCII({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_CII").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_FACTURX"))
			setShowExportFacturX({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_FACTURX").value === "1",
			});
	};

	const formatBooleanValue = (value) => (value ? "1" : "0");

	const onSubmit = (data) => {
		const _settings = JSON.parse(JSON.stringify(settings));
		_settings.find((e) => e.code === "AUTO_EXPORT").value = formatBooleanValue(autoExport.value);
		_settings.find((e) => e.code === "AUTO_EXPORT").changed = autoExport.changed;
		_settings.find((e) => e.code === "AMOUNT_TOLERANCE").value = data.AMOUNT_TOLERANCE;
		_settings.find((e) => e.code === "AMOUNT_TOLERANCE").changed = true;
		_settings.find((e) => e.code === "INVOICE_MAXIMUM_SIZE").value = data.MAXIMUM_SIZE;
		_settings.find((e) => e.code === "INVOICE_MAXIMUM_SIZE").changed = true;
		_settings.find((e) => e.code === "TOTAL_AMOUNT_GAP_THRESHOLD").value = data.TOTAL_AMOUNT_GAP_THRESHOLD;
		_settings.find((e) => e.code === "TOTAL_AMOUNT_GAP_THRESHOLD").changed = true;
		_settings.find((e) => e.code === "EXPORT_EC").value = formatBooleanValue(exportJE.value);
		_settings.find((e) => e.code === "EXPORT_EC").changed = exportJE.changed;
		_settings.find((e) => e.code === "EXPORT_PDF").value = formatBooleanValue(exportPDF.value);
		_settings.find((e) => e.code === "EXPORT_PDF").changed = exportPDF.changed;
		_settings.find((e) => e.code === "EXPORT_EXCEL").value = formatBooleanValue(exportEXCEL.value);
		_settings.find((e) => e.code === "EXPORT_EXCEL").changed = exportEXCEL.changed;
		_settings.find((e) => e.code === "EXPORT_XML").value = formatBooleanValue(exportXML.value);
		_settings.find((e) => e.code === "EXPORT_XML").changed = exportXML.changed;
		_settings.find((e) => e.code === "INVOICE_EXPORT_PDF").value = formatBooleanValue(
			showExportPDF.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_PDF").changed = showExportPDF.changed;
		_settings.find((e) => e.code === "INVOICE_EXPORT_EXCEL").value = formatBooleanValue(
			showExportEXCEL.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_EXCEL").changed = showExportEXCEL.changed;
		_settings.find((e) => e.code === "INVOICE_EXPORT_XML").value = formatBooleanValue(
			showExportXML.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_XML").changed = showExportXML.changed;
		_settings.find((e) => e.code === "INVOICE_RELAUNCH_EXTRACTION").value = formatBooleanValue(
			relaunchExtraction.value
		);
		_settings.find((e) => e.code === "INVOICE_RELAUNCH_EXTRACTION").changed =
			relaunchExtraction.changed;
		_settings.find((e) => e.code === "INVOICE_DELETE_ACTION").value = formatBooleanValue(
			deleteAction.value
		);
		_settings.find((e) => e.code === "INVOICE_DELETE_ACTION").changed = deleteAction.changed;
		_settings.find((e) => e.code === "INVOICE_DELETE_All_ACTION").value = formatBooleanValue(
			deleteAllAction.value
		);
		_settings.find((e) => e.code === "INVOICE_DELETE_All_ACTION").changed = deleteAllAction.changed;
		_settings.find(
			(e) => e.code === "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY"
		).value = formatBooleanValue(showExportExcelAccounting.value);
		_settings.find((e) => e.code === "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY").changed =
			showExportExcelAccounting.changed;
		_settings.find((e) => e.code === "INDICATOR_MY_TASKS").value = formatBooleanValue(
			indicatorMyTasks.value
		);
		_settings.find((e) => e.code === "INDICATOR_MY_TASKS").changed = indicatorMyTasks.changed;
		_settings.find((e) => e.code === "INDICATOR_INVOICES_EXPORTED").value = formatBooleanValue(
			indicatorInvoicesExported.value
		);
		_settings.find((e) => e.code === "INDICATOR_INVOICES_EXPORTED").changed =
			indicatorInvoicesExported.changed;
		_settings.find((e) => e.code === "INDICATOR_INVOICES_NOT_EXPORTED").value = formatBooleanValue(
			indicatorInvoicesNotExported.value
		);
		_settings.find((e) => e.code === "INDICATOR_INVOICES_NOT_EXPORTED").changed =
			indicatorInvoicesNotExported.changed;
		_settings.find((e) => e.code === "INDICATOR_LAST_ACTIONS").value = formatBooleanValue(
			indicatorLastActions.value
		);
		_settings.find((e) => e.code === "INDICATOR_LAST_ACTIONS").changed =
			indicatorLastActions.changed;
		_settings.find((e) => e.code === "INDICATOR_LOAD_INVOICES").value = formatBooleanValue(
			indicatorLoadInvoices.value
		);
		_settings.find((e) => e.code === "INDICATOR_LOAD_INVOICES").changed =
			indicatorLoadInvoices.changed;
		_settings.find((e) => e.code === "INDICATOR_LAST_5_INVOICES").value = formatBooleanValue(
			indicatorLast5Invoices.value
		);
		_settings.find((e) => e.code === "INDICATOR_LAST_5_INVOICES").changed =
			indicatorLast5Invoices.changed;

		_settings.find((e) => e.code === "INVOICE_EXPORT_EDI").value = formatBooleanValue(
			showExportEdi.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_EDI").changed = showExportEdi.changed;

		_settings.find((e) => e.code === "EXPORT_EDI").value = formatBooleanValue(exportEdi.value);
		_settings.find((e) => e.code === "EXPORT_EDI").changed = exportEdi.changed;

		_settings.find((e) => e.code === "INVOICE_EXPORT_IDOC").value = formatBooleanValue(
			showExportIdoc.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_IDOC").changed = showExportIdoc.changed;

		_settings.find((e) => e.code === "EXPORT_IDOC").value = formatBooleanValue(exportIdoc.value);
		_settings.find((e) => e.code === "EXPORT_IDOC").changed = exportIdoc.changed;

		_settings.find((e) => e.code === "INVOICE_EXPORT_UBL").value = formatBooleanValue(
			showExportUBL.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_UBL").changed = showExportUBL.changed;

		_settings.find((e) => e.code === "INVOICE_EXPORT_CII").value = formatBooleanValue(
			showExportCII.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_CII").changed = showExportCII.changed;

		_settings.find((e) => e.code === "INVOICE_EXPORT_FACTURX").value = formatBooleanValue(
			showExportFacturX.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_FACTURX").changed = showExportFacturX.changed;

		const settingsUpdate = _settings.filter((setting) => setting.changed === true);

		setSettingsToSave(settingsUpdate);

		setConfirmObject({ message: t("admin.setting.message.confirm"), openConfirm: true });
	};

	const refreshSettings = () => {
		dispatch(fetchSettingsByModuleName("invoice")).then((res) => {
			if (res.data) intSettings(res.data);
			setLoaded(true);
		});
	};

	const onConfirm = () => {
		if (!confirmObject.cancel) {
			setConfirmObject({ ...confirmObject, isLoading: true });
			dispatch(
				updateSettings(
					settingsToSave,
					"invoice",
					() => {
						setConfirmObject({ message: "", openConfirm: false, isLoading: false });
						toast.success(t("admin.config.update-settings"));
						setEdit(false);
					},
					(error) => {
						setConfirmObject({ message: "", openConfirm: false, isLoading: false });
						toast.error(error);
					}
				)
			).then(() => refreshSettings());
		} else {
			setConfirmObject({ message: "", openConfirm: false, cancel: false });
			setEdit(false);
		}
	};

	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
		if (confirmObject.cancel) setEdit(true);
	};

	useEffect(() => {
		refreshSettings();
	}, [dispatch]);

	if (!loaded) return <CircularLoader />;
	return (
		<>
			<Grid container item xs={12} />
			<Grid
				container
				item
				xs={12}
				className={classes.settingsContainer}
				justify="center"
				alignItems="center"
				alignContent="center"
			>
				{settings && (
					<form onSubmit={handleSubmit(onSubmit)} style={{ width: "60%" }}>
						<Grid container item xs={12} className={classes.loginContainer} spacing={6}>
							<Grid item container xs={12}>
								<Grid container item xs={6}>
									<Typography variant="h6" color="primary">
										{t("admin.settings.invoices")}
									</Typography>
								</Grid>
								<Grid item container xs={6} justify="flex-end">
									<Tooltip placement="top" title={t("tootlip.action.edit")}>
										<IconButton aria-label="edit">
											<EditIcon id="settings-invoice-edit-btn" onClick={() => setEdit(!edit)} />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={6}>
									<Grid item xs={6} alignItems="center">
										<Typography variant="subtitle2" align="left" color="primary">
											{t("admin.AMOUNT_TOLERANCE")}
											<p>{errors.email?.message}</p>
										</Typography>
										<Typography variant="body2" color="primary">
											{t("admin.invoice.AMOUNT_TOLERANCE-description")}
										</Typography>
									</Grid>
									<Grid item xs={6} align="center" justify="center">
										<OutlinedInput
											id="settings-invoice-amntTolerance"
											inputRef={register({
												required: true,
												validate: (value) => !!value.trim(),
											})}
											name="AMOUNT_TOLERANCE"
											type="text"
											disabled={!edit}
											defaultValue={
												settings &&
												settings.find((e) => e.code === "AMOUNT_TOLERANCE") &&
												settings.find((e) => e.code === "AMOUNT_TOLERANCE").value
											}
											className={classes.amountTolerance}
										/>
										{errors.AMOUNT_TOLERANCE && (
											<Typography color="error">
												{t("admin.setting.AMOUNT_TOLERANCE.required")}
											</Typography>
										)}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={6}>
									<Grid item xs={6} alignItems="center">
										<Typography variant="subtitle2" align="left" color="primary">
											{t("admin.MAXIMUM_SIZE")}
											<p>{errors.email?.message}</p>
										</Typography>
										<Typography variant="body2" color="primary">
											{t("admin.MAXIMUM_SIZE-description")}
										</Typography>
									</Grid>
									<Grid item xs={6} align="center" justify="center">
										<OutlinedInput
											id="settings-invoice-invoice-min-size"
											inputRef={register({
												required: true,
												validate: (value) => !!value.trim(),
											})}
											name="MAXIMUM_SIZE"
											type="text"
											disabled={!edit}
											defaultValue={
												settings &&
												settings.find((e) => e.code === "INVOICE_MAXIMUM_SIZE") &&
												settings.find((e) => e.code === "INVOICE_MAXIMUM_SIZE").value
											}
											className={classes.amountTolerance}
										/>
										{errors.AMOUNT_TOLERANCE && (
											<Typography color="error">{t("admin.MAXIMUM_SIZE.required")}</Typography>
										)}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={6}>
									<Grid item xs={6} alignItems="center">
										<Typography variant="subtitle2" align="left" color="primary">
											{t("admin.TOTAL_AMOUNT_GAP_THRESHOLD")}
											<p>{errors.email?.message}</p>
										</Typography>
										<Typography variant="body2" color="primary">
											{t("admin.TOTAL_AMOUNT_GAP_THRESHOLD-description")}
										</Typography>
									</Grid>
									<Grid item xs={6} align="center" justify="center">
										<OutlinedInput
											id="settings-invoice-invoice-min-size"
											inputRef={register({
												required: true,
												validate: (value) => !!value.trim(),
											})}
											name="TOTAL_AMOUNT_GAP_THRESHOLD"
											type="text"
											disabled={!edit}
											defaultValue={settings?.find((e) => e.code === "TOTAL_AMOUNT_GAP_THRESHOLD")?.value}
											className={classes.amountTolerance}
										/>
										{errors.AMOUNT_TOLERANCE && (
											<Typography color="error">{t("admin.TOTAL_AMOUNT_GAP_THRESHOLD.required")}</Typography>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={12}
							spacing={2}
							className={classes.actions}
							justify="center"
							alignItems="center"
							alignContent="center"
						>
							<Grid item>
								<Button
									id="settings-invoice-cancel-btn"
									color="primary"
									autoFocus
									onClick={() => {
										setConfirmObject({
											message: t("CancelActionMessage"),
											openConfirm: true,
											cancel: true,
										});
									}}
									disabled={!edit}
								>
									{t("cancel")}
								</Button>
							</Grid>
							<Grid item>
								<Button
									id="settings-invoice-confirm-btn"
									variant="contained"
									type="submit"
									color="secondary"
									disabled={!edit}
								>
									{t("confirm")}
								</Button>
							</Grid>
						</Grid>

						<ConfirmDialog
							message={confirmObject.message}
							open={confirmObject.openConfirm}
							isLoading={confirmObject.isLoading}
							onConfirm={onConfirm}
							onCancel={onCancel}
						/>
					</form>
				)}
			</Grid>
		</>
	);
};

export default InvoiceSettings;
