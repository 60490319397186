import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useStyles from "./style";
export default function SiretSelect({ value, onChange, name, inputRef, siretList, placeholder }) {
	const classes = useStyles();
	const [stateValue, setStateValue] = React.useState({});
	const [inputValue, setInputValue] = React.useState("");

	useEffect(() => {
		if (siretList.length === 1) {
			if (siretList[0] === value || value === undefined) {
				onChange(siretList[0]);
				setStateValue(siretList[0]);
			}
		}
		if (siretList && value) {
			if (siretList.includes(value)) {
				setStateValue(siretList.find((siret) => siret === value));
			} else {
				setStateValue(value);
			}
		}
	}, [siretList, value]);

	return (
		<Autocomplete
			id="siret-select-demo"
			fullWidth
			options={siretList ?? []}
			classes={{
				option: classes.option,
			}}
			name={name}
			autoHighlight
			getOptionLabel={(option) => option}
			value={stateValue}
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			onChange={(event, newValue) => {
				onChange(newValue);
				setStateValue(event.target.value);
			}}
			freeSolo={siretList.length === 1 || siretList.length === 0}
			renderOption={(option) => <>{option}</>}
			renderInput={(params) => (
				<TextField
					placeholder={placeholder}
					{...params}
					inputProps={{
						...params.inputProps,
						autoComplete: "new-password", // disable autocomplete and autofill
					}}
					inputRef={inputRef}
					fullWidth
					onChange={(event) => {
						setStateValue(event.target.value);
						onChange(event.target.value);
					}}
				/>
			)}
		/>
	);
}
