export default {
	lastName: "Nom",
	firstName: "Prénom",
	email: "E-mail",
	Username: "Le nom d'utilisateur",
	userFunction: "Fonction",
	supplier_name: "Fournisseur",
	client_name: "Nom du client",
	job: "Intitulé de poste",
	manageUsers: " Gestion des utilisateurs",
	manageSuppliers: "Espace Fournisseurs",
	manageClients: "Espace Clients",
	manageGroups: "Gestion des groupes",
	manageRoles: "Gestion des rôles",
	manageWorkflow: "Gestion du workflow",
	manageLicenseAddOn: "Gestion licences & Add-ons",
	status: "Statut",
	ACTIVE: "Actif",
	WAITING: "En attente",
	DISABLE: "Inactif",
	add_user: "Ajouter utilisateur",
	add_user_button: "AJOUTER UTILISATEUR ",
	add_group_button: "AJOUTER UNE ORGANISATION COMMERCIALE",
	add_supplier_button: "AJOUTER FOURNISSEUR",
	add_client_button: "AJOUTER CLIENT",
	edit_client: "Modifier client",
	sendEmailCreationUserMessage:
		"Un mail avec l'identifiant et le mot de passe a été envoyé à l’utilisateur pour pouvoir se connecter",
	CancelActionMessage: "Êtes-vous sûr de vouloir annuler cette action ?",
	searchUser: "Recherche",
	userCivility: "Civilité",
	administrator: "Administration",
	user: "Agent",
	Mr: "Monsieur",
	Ms: "Mademoiselle",
	Mrs: "Madame",
	enableDisableActionMessage: "Êtes-vous sûr de vouloir activer / désactiver cet utilisateur?",
	edit_user: "Modifier Utilisateur",
	add_supplier: "Ajouter fournisseur",
	edit_supplier: "Modifier fournisseur",
	add_client: "Ajouter un client",
	// validation messages
	lastName_required: "Nom obligatoire",
	lastName_notValid: "Nom invalide",
	supplierName_notValid: "Le nom du fournisseur n'est pas valide",
	phone_notValid: "Le téléphone n'est pas valide",
	supplier_required: "Le nom du fournisseur est obligatoire",
	firstName_required: "Prènom obligatoire",
	firstName_notValid: "Le prènom n'est pas valide",
	email_required: "L'e-mail est obligatoire",
	email_notValid: "L'e-mail n'est pas valide",
	siren_notValid: "Le code SIREN n'est pas valide",
	siret_notValid: "Numéro SIRET invalide",
	url_notValid: "L'URL n'est pas valide",
	name_notValid: "Le nom n'est pas valide",
	supplier_created:
		"Fournisseur ajouté avec succès. Si le fournisseur n’est pas affiché sur votre liste des fournisseurs veuillez contacter votre administrateur",
	supplier_updated: "Fournisseur mis à jour avec succès !",
	supplier_error: "Une erreur s'est produite lors de l'enregistrement du fournisseur",
	supplier_update_error: "Échec de la mise à jour des détails du fournisseur !",
	job_required: "Intitulé du poste obligatoire",
	civility_required: "Civilité obligatoire",
	doctype_required: "Le type de document est requis",
	deleteUserActionMessage: "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
	deleteUsersActionMessage: "Êtes-vous sûr de vouloir supprimer ces utilisateurs ?",
	"admin.settings": "Paramètres",
	"admin.commercialOrganization": "Organisation commerciale",
	"user.commercialOrganization.exists.message": "L'organisation commerciale existe déjà.",
	"admin.config.update-settings": "Les paramètres ont été modifiés avec succès",
	"admin.AUTO_EXPORT": "Export Automatique",
	"admin.email-config": "Configuration d'e-mail",
	"admin.ftp-config": "Configuration FTP",
	"admin.import-config": "Configuration d'import",
	"admin.folder-config": "Configuration des dossiers",
	"admin.config.update-message": "La configuration d'e-mail a été mise à jour avec succès",
	"admin.AMOUNT_TOLERANCE": "Tolérance des Totaux",
	"admin.MAXIMUM_SIZE": "Taille maximum d’un fichier chargé",
	field_required: "Obligatoire",
	Host: "Hôte",
	Port: "Port",
	Email: "E-mail",
	Password: "Mot de passe",
	"Read folder": "Dossier de lecture",
	"Processing folder": "Dossier de traitement",
	"Success folder": "Dossier de réussite",
	"Exception folder": "Dossier d'exceptions",
	supplierTraining: "Apprentissage fournisseur",
	clientTraining: "Apprentissage client",
	deleteTraining: "Supprimer apprentissage",
	deleteTrainingMessage: "Êtes-vous sûr de vouloir supprimer le training de ce fournisseur?",
	successdeleteTrainingMessage: "L'apprentissage a été supprimé avec succès",
	"admin.download-buttons": "Actions sur les factures",
	"admin.download-buttons-description":
		"Choisissez les actions que vous voudriez permettre sur vos factures dans le portail.",
	"admin.download-button.excel": "Télécharger au Format Excel",
	"admin.download-button.journal-entry": "Télécharger Excel écriture comptable",
	"admin.download-button.xml": "Télecharger au Format XML",
	"admin.download-button.pdf": "Télécharger au Format PDF",
	"admin.download-button.edi": "Télécharger au Format EDI",
	"admin.invoice.AUTO_EXPORT-description":
		"Ce paramètre active l'export automatique des factures qui sont totalement extraites et qui n'ont aucun message d'erreur.",
	"admin.invoice.AMOUNT_TOLERANCE-description":
		"Ce champs vous permet de paramétrer une tolérance dans les calculs des totaux d’une facture. C’est sur la base de cette tolérance que les messages d’erreur seront affichés.",
	"admin.MAXIMUM_SIZE-description": "Taille maximale d'un document à télécharger en Mo",
	"admin.display-indicators": "Affichage des indicateurs dans le tableau de bord",
	"admin.display-indicators-description":
		"Choisissez les indicateurs vous voulez visualiser dans votre Dashboard NovyPOM.",
	"admin.setting.my_tasks": "Mes tâches",
	"My orders": "Mes commandes",
	"admin.setting.invoices-exported": "Factures exportées",
	"admin.setting.invoices-not-exported": "Factures non exportées",
	"admin.setting.last-actions": "Dernières actions",
	"admin.setting.load-invoices": "Charger des factures",
	"admin.setting.last-5-invoices": "Dernières 5 Factures",
	"admin.setting.download-pdf": "Télécharger le PDF",
	"admin.setting.delete": "Supprimer",
	"admin.setting.delete-all": "Supprimer toutes les factures",
	"admin.setting.AMOUNT_TOLERANCE.required": "Tolérance des totaux requise",
	"admin.setting.relaunch-extraction": "Relancer l'extraction",
	"admin.setting.export_entry_account_excel": "Exporter Excel écriture comptable",
	"admin.export-buttons": "Boutons de téléchargement à l’export",
	"admin.invoice.export-buttons-description":
		"Paramétrez l’affichage des raccourcis de téléchargements possibles après l’export d’une facture. Les boutons seront visibles après l’export dans le détail des factures.",
	"admin.extraction-level-settings": "L'importance des champs extraits (Niveau d'extraction)",
	"admin.extraction-level-settings-desciption":
		"Paramétrer l'importance des champs qui vous semblent les plus importants et des champs, qui vous sont indispensables. Cela sera pris en compte dans le calcul du niveau d'extraction.",
	"admin.level-settings": "Paramètres de Niveau d'extraction",
	"admin.extraction-level.champs": "Champs",
	"admin.extraction-level.status": "Statut",
	"admin.extraction-level.mandatory": "Indispensable",
	"admin.extraction-level.ignored": "Ignoré",
	"admin.extraction-level.important": "Important",
	"admin.setting.message.confirm": "Voulez-vous vraiment modifier ces paramètres ?",
	"admin.user.confirm.message.edit": "Voulez-vous vraiment modifier cet utilisateur ?",
	"admin.user.confirm.message.add": "Voulez-vous vraiment ajouter cet utilisateur ?",
	"admin.supplier.confirm.message.add": "Voulez-vous vraiment ajouter cet utilisateur ?",
	"admin.client.confirm.message.add": "Voulez-vous vraiment ajouter cet utilisateur ?",
	"admin.supplier.confirm.message.edit": "Voulez-vous vraiment modifier cet utilisateur ?",
	"admin.config.message.confirm":
		"Voulez-vous vraiment modifier cette configuration de messagerie ?",
	"admin.extraction.message.confirm":
		"Voulez-vous vraiment mettre à jour ces niveaux d'extraction ?",
	"invoice.user.updated.success": "L'utilisateur a été modifié  avec succès",
	"admin.config.ftp.message.confirm":
		"Voulez-vous vraiment modifier cette configuration du serveur FTP ?",
	"admin.config.ftp.update-message":
		"La configuration du serveur FTP a été mise à jour avec succès",
	agent_accounting: "Agent comptable",
	agent_purchase: "Agent d'achat",
	"admin.settings.invoice": "Facture",
	"admin.settings.order": "Commande",
	"admin.settings.invoices": "Paramètres des factures",
	"admin.settings.orders": "Paramètres des commandes",
	"admin.order.download-buttons": "Actions sur les commandes",
	"admin.order.download-buttons-description":
		"Choisissez les actions que vous souhaitez autoriser sur vos commandes dans le portail.",
	"admin.order.AUTO_EXPORT-description":
		"Ce paramètre active l'exportation automatique des commandes entièrement extraites et sans message d'erreur.",
	"admin.order.AMOUNT_TOLERANCE-description":
		"Ce champ vous permet de définir une tolérance dans le calcul des totaux d'une commande. C'est sur la base de cette tolérance que les messages d'erreur seront affichés.",
	"admin.setting.last-5-orders": "5 dernières commandes",
	"admin.order.setting.delete-all": "Supprimer toutes les commandes",
	"admin.order.export-buttons-description":
		"Configurer l'affichage des raccourcis de téléchargement possibles après l'exportation d'une commande. Les boutons seront visibles après l'export dans les détails de la commande.",
	"admin.settings.order.upload": "Télécharger les commandes",
	"order.exported": "Commandes exportées",
	"order.unexported": "Commandes non exportées",
	"pagination.rows.per.page": "Lignes par page:",
	"pagination.of": "sur",
	fix_errors: "Impossible de continuer, veuillez vérifier les erreurs suivantes",
	automatic_transition: "Autoriser la transition automatique",
	"rule.orderMissing": "Le numéro de commande n'existe pas",
	"rule.orderNumberAbsent": "Le numéro de commande n'est pas disponible",
	"rule.quantityInvalid": "La quantité facturée est supérieure à la quantité de la commande",
	"rule.unitPriceLineInvalid":
		"Le prix unitaire facturé est différent du prix unitaire de la commande",
	"rule.orderLineMissing": "La ligne de commande n'existe pas",
	"rule.orderLineMissings": "Les lignes de commande n'existent pas",
	"rule.totalAmountInvalid":
		"Le montant total de la facture est supérieur au montant total de la commande",
	"rule.cumulativeOrderInvalid":
		"Le montant total sera supérieur au total de la commande après l'exportation",
	"rule.accumulatedQuantityLineInvalid":
		"La quantité calculée sera supérieure à la quantité de la commande après exportation",
	"rule.incoherenceAmounts": "Le montant total TTC est différent du montant hors taxes plus TVA",
	"rule.totalEmpty": "Le montant total de la facture est vide",
	"rule.DeliveryMissing": "Le bon de livraison n'existe pas",
	"rule.DeliveredQuantityInvalid":
		"La quantité facturée est supérieure à la quantité livrée telle que mentionnée dans le bon de livraison",
	"rule.sumTotalHtInvalid":
		"Le montant total hors taxe est différent de la somme des lignes libres d'impôt",
	"rule.duplicatedInvoice": "La facture existe déjà",
	"rule.nettopayInvalid": "Le net à payer n'est pas cohérent",
	"rule.PrepayAmountInvalid": "Le montant prépayé n'est pas cohérent",
	"rule.nettopayExist": "Le net à payer n'existe pas",
	transition: "Transition",
	transitionbuttonlabel: "Étiquette du bouton",
	addstatus: "Ajouter un statut",
	saveworkflow: "Enregistrer le flux de travail",
	asksaveworkflow: "Êtes-vous sûr de vouloir enregistrer le workflow ?",
	update: "mettre à jour",
	deletestatus: "Supprimer le statut",
	deletetransition: "Supprimer la transition",
	updatestatus: "Mettre à jour Status",
	updatetransition: "Mettre à jour Transition",
	blockedby: "Bloqué par",
	appliedrules: "Règles appliquées",
	errorautotransition: "Vous ne pouvez pas avoir plus d'une transition automatique",
	AbleCorrect: "Eligible à la correction manuelle",
	workflow: "Flux de travail",
	"workflow.delete.error":
		"Cet état a {{count}} transition(s), veuillez supprimer la/les transition(s) avant de supprimer le statut.",
	"workflow.status.success": "Flux de travail mis à jour avec succès!",
	"workflow.property.isLitige": "Status de litige",
	"workflow.source.status.litige.message":
		"Les statuts en litige ne doivent pas avoir des transitions sortantes",
	manager: "Manager",
	super_user: "Super-utilisateur",
	"user.super_user.add": "Ajouter Super utilisateur",
	"user.super_user.edit": "Modifier super utilisateur",
	managerSuperusers: "Gestion des Super utilisateurs ",
	"admin.download-button.idoc": "Télécharger au Format IDOC",
	"admin.setting.download-idoc": "Télécharger le IDOC",
	"admin.setting.download-edi": "Télécharger le EDI",
	"admin.workflow": "Flux de travail",
	"admin.setting.download-ubl": "Exporter en UBL",
	"admin.setting.download-cii": "Exporter en CII16B",
	"admin.setting.download-xcbl": "Exporter en XCBL",
	"admin.setting.download-facturx": "Exporter en Factur-X",
	"user.field.commercialOrganization": "Organisation Commerciale",
	"admin.commercialOrganization.name": "Nom",
	"admin.commercialOrganization.description": "Description",
	"admin.commercialOrganization.suppliers": "Fournisseurs",
	"admin.commercialOrganization.clients": "Clients",
	"admin.commercialOrganization.add": "Affecter",
	"admin.commercialOrganization.remove": "Désaffecter",
	"admin.commercialOrganization.edit":
		"Êtes-vous sûr de vouloir modifier cette organisation commerciale?",
	"admin.commercialOrganization.save":
		"Êtes-vous sûr de vouloir enregistrer cette organisation commerciale?",
	"admin.commercialOrganization.addRecord": "Ajouter une organisation commerciale",
	"admin.commercialOrganization.editRecord": "Modifier l'organisation commerciale",
	"admin.commercialOrganization.success": "Organisation commerciale ajoutée avec succès",
	"admin.commercialOrganizationEdit.success": "Organisation commerciale modifiée avec succès",
	"admin.commercialOrganization.deleteSuccess": "L'organisation commerciale supprimée avec succès",
	"admin.commercialOrganization.deleteMsg":
		"Êtes-vous sûr de vouloir supprimer cette organisation commerciale?",
	"user.commercialOrganization.users.exists.message":
		"Impossible de supprimer cette organisation commerciale. Certains utilisateurs sont toujours assignés.",
	"user.commercial_organization_showAll": "Affecter tout",
	"user.commercial_organization_showAll.description":
		"En choisissant cette option, le manager/administrateur aura permission de voir tous les données reliées aux factures et commandes",
	"permissions.admin.commercialOrganization": "Organisation commerciale",
	"permissions.admin.commercialOrganization.add": "Ajouter",
	"permissions.admin.commercialOrganization.edit": "Modifier",
	"permissions.admin.commercialOrganization.delete": "Supprimer",
	"permissions.admin.manageUsers": "Gestion des utilisateurs",
	"permissions.admin.manageUsers.add": "Ajouter",
	"permissions.admin.manageUsers.edit": "Modifier",
	"permissions.admin.manageUsers.delete": "Supprimer",
	"permissions.admin.manageUsers.permissions": "Permissions",
	"permissions.admin.manageSuppliers": "Gestions des fournisseur",
	"permissions.admin.manageSuppliers.add": "Ajouter",
	"permissions.admin.manageSuppliers.edit": "Modifier",
	"permissions.admin.manageSuppliers.delete": "Supprimer",
	"permissions.admin.manageClients": "Gestion des clients",
	"permissions.admin.manageClients.add": "Ajouter",
	"permissions.admin.manageClients.edit": "Modifier",
	"permissions.admin.manageClients.delete": "Supprimer",
	"permissions.admin.supplierTraining": "Apprentissage fournisseur",
	"permissions.admin.supplierTraining.toggle": "Activer / Désactiver",
	"permissions.admin.supplierTraining.delete": "Supprimer",
	"permissions.admin.clientTraining": "Apprentissage client",
	"permissions.admin.clientTraining.toggle": "Activer / Désactiver",
	"permissions.admin.clientTraining.delete": "Supprimer",
	"permissions.admin.InvoiceSettings": "Paramètres de facturation",
	"permissions.admin.orderSetting": "Paramètres des commandes",
	"permissions.admin.invoiceExtractionLevel": "Paramètres de niveau d'extraction de facture",
	"permissions.admin.orderExtractionLevel": "Paramètres de niveau d'extraction de commande",
	"permissions.admin.invoiceImportConfig": "Configuration de l'importation de facture",
	"permissions.admin.orderImportConfig": "Commande Configuration de l'importation",
	"permissions.admin.invoiceWorkflow": "Flux de travail de la facture",
	"invoice.dashboard": "Tableau de bord",
	"invoice.list": "Factures",
	"invoice.disputed": "Factures litigieuses",
	"invoice.disputed.reintegrate": "Réintégrer la facture",
	"invoice.disputed.reject": "Rejeter la facture",
	"invoice.disputed.delete": "Supprimer la facture",
	"invoice.add": "Ajouter",
	"invoice.delete": "Supprimer",
	"invoice.deleteAdd": "Supprimer tout",
	"invoice.download": "Exporter PDF",
	"invoice.relaunchExtraction": "Relancer l'extraction",
	"invoice.exportXml": "Exporter XML",
	"invoice.exportExcel": "Exporter excel",
	"invoice.exportJE": "Exporter des entrées de comptabilité Excel",
	"invoice.exportEDI": "Exporter EDI",
	"invoice.exportIDOC": "Exporter IDOC",
	"invoice.exportUBL": "Exporter UBL",
	"invoice.exportCII": "Exporter CII16B",
	"invoice.exportFactureX": "Exporter Factur-X",
	"invoice.supplier": "Fournisseurs",
	"invoice.supplier.add": "Ajouter",
	"invoice.supplier.edit": "Modifier",
	"invoice.supplier.delete": "Supprimer",
	"invoice.supplier.import": "Importer des fournisseurs",
	"invoice.supplier.export": "Exporter la liste des fournisseurs",
	"order.dashboard": "Tableau de bord",
	"permissions.admin.orders": "Commandes",
	"order.client": "Clients",
	"order.list": "Factures",
	"order.add": "Ajouter",
	"order.delete": "Supprimer",
	"order.deleteAdd": "Supprimer tout",
	"order.download": "Exporter PDF",
	"order.relaunchExtraction": "Relancer l'extraction",
	"order.exportXml": "Exporter XML",
	"order.exportExcel": "Exporter excel",
	"order.exportJE": "Exporter des entrées de comptabilité Excel",
	"order.exportEDI": "Exporter EDI",
	"order.exportIDOC": "Exporter IDOC",
	"order.client.add": "Ajouter",
	"order.client.edit": "Modifier",
	"order.client.delete": "Supprimer",
	"order.client.import": "Importer des clients",
	"order.client.export": "Exporter la liste des clients",
	"user.permission.save": "Sauvegarder",
	"user.permission.confirmSave":
		"Voulez-vous vraiment mettre à jour les permissions pour cet utilisateur?",
	"tooltip.action.permissions": "Permissions",
	ORDER: "COMMANDES",
	INVOICE: "FACTURES",
	"admin.emailConfig.addNewEmail": "Ajouter",
	"admin.user.toggleActive": "Activer/Désactiver",
	"admin.client.confirm.message.edit": "Voulez-vous vraiment modifier cet utilisateur?",

	"rule.DateInvoiceNotExist": "La date d'emission de la facture est absente",
	"rule.DateInvoiceGreaterThanToday":
		"La date d'emission de la facture est postérieure à la date d'aujourd'hui",
	"rule.SupplierNotExist": " Le fournisseur absent ou non reconnu",
	"rule.NbrInvoiceNotExist": "Le numéro de facture n'est pas disponible",
	"rule.AddressValid": "L'adresse mentionnée dans la facture est incorrecte",
	"rule.AmountInvoiceOrderValid": "Incohérence entre les montants de la facture et de la commande",
	"rule.OrderCharged": "la commande est déjà facturée",
	"rule.IncoherenceCurrency": "la devise de la facture est différente à la devise de la commande",
	"rule.IncoherenceSupplier": "le fournisseur est différent de celui sur la commande",
};
