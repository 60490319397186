import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function InfoItem({ label, value }) {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default InfoItem;
