import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import CountrySelect from "components/CountryPicker";
import { useDispatch } from "react-redux";
import countries from "i18n-iso-countries";
import { fetchClients } from "redux/actions/clients";
import SelectAutocomplete from "components/Identification/SelectAutocomplete";
import { REGEX } from "../../../utils";
import { fetchDataFromApiSiren, fetchSuppliers } from "../../../redux/actions/suppliers";
import { ImageProfil } from "../../Suppliers/Component/ImageProfil";
import useStyles from "../style";
const GeneralInformation = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const [list, setList] = useState([]);
	const [listStreet, setListStreet] = useState([]);
	const [listCity, setListCity] = useState([]);
	const [listZip, setListZip] = useState([]);

	useEffect(() => {
		if (props.isClient) dispatch(fetchClients());
		else dispatch(fetchSuppliers());
		if (
			props.supplier?.companySiren &&
			props.supplier?.companySiren.trim().length > 0 &&
			props.supplier?.companySiret &&
			props.supplier?.companySiret.trim().length > 0
		) {
			dispatch(fetchDataFromApiSiren(props.supplier?.companySiren)).then(({ data }) => {
				if (data) {
				const Listsiret = data.map((e) => e.siret).filter((x, i, a) => a.indexOf(x) === i);
				if (Listsiret.includes(props.supplier?.companySiret)) {
					dispatch(
						fetchDataFromApiSiren(props.supplier?.companySiren, props.supplier?.companySiret)
					).then((e) => {
						
						let List = e.data.map((c) => c.nafCode).filter((x, i, a) => a.indexOf(x) === i);
						setList(List.length === 1 && List[0] === null ? [] : List);

						List = e.data.map((c) => c.city).filter((x, i, a) => a.indexOf(x) === i);
						setListCity(List.length === 1 && List[0] === null ? [] : List);

						List = e.data.map((c) => c.street).filter((x, i, a) => a.indexOf(x) === i);
						setListStreet(List.length === 1 && List[0] === null ? [] : List);

						List = e.data.map((c) => c.zipCode).filter((x, i, a) => a.indexOf(x) === i);
						setListZip(List.length === 1 && List[0] === null ? [] : List);
					});
					}
					}
			});
		}
		if (
			props.supplier?.companySiren &&
			(!props.supplier?.companySiret || props.supplier?.companySiret.trim().length === 0)
		) {
			dispatch(fetchDataFromApiSiren(props.supplier?.companySiren)).then(({ data }) => {
				if (data) {	
				let List = data.map((c) => c.nafCode).filter((x, i, a) => a.indexOf(x) === i);
				setList(List.length === 1 && List[0] === null ? [] : List);

				List = data.map((c) => c.city).filter((x, i, a) => a.indexOf(x) === i);
				setListCity(List.length === 1 && List[0] === null ? [] : List);

				List = data.map((c) => c.street).filter((x, i, a) => a.indexOf(x) === i);
				setListStreet(List.length === 1 && List[0] === null ? [] : List);

				List = data.map((c) => c.zipCode).filter((x, i, a) => a.indexOf(x) === i);
				setListZip(List.length === 1 && List[0] === null ? [] : List);
			}
				});
		}
	}, []);

	const lang = localStorage.getItem("lgn") ? localStorage.getItem("lgn") : "fr";
	const temp = countries.getNames(lang, { select: "official" });
	const countryList = [];
	Object.keys(temp).forEach((ctr) => countryList.push({ isoCode: ctr, displayName: temp[ctr] }));

	return (
		<Grid container xs={12} justify="center">
			<Grid container xs={2} alignItems="center" justify="flex-end">
				<Typography variant="subtitle2" align="left" color="primary">
					{t("supplier.add.logo")}
					<Grid justify="center" className={classes.ImageProfil}>
						<ImageProfil
							src={props.supplier?.logo}
							changeValue={props.setLogo}
							isClient={props.isClient}
						/>
					</Grid>
				</Typography>
			</Grid>
			<Grid container xs={10} justify="flex-end">
				<Grid container xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.country")}
							<CountrySelect
								onChange={(newValue) => {
									props.updateSupplier({
										target: {
											name: "countryCode",
											value: newValue,
										},
									});
								}}
								value={props.supplier?.countryCode}
								name="countryCode"
								countryList={countryList}
								placeholder={t("supplier.add.country")}
							/>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.naf")}
							<SelectAutocomplete
								onChange={(newValue) => {
									props.updateSupplier({
										target: {
											name: "codeNaf",
											value: newValue,
										},
									});
								}}
								value={props.supplier?.codeNaf}
								name="codeNaf"
								List={list}
								placeholder={t("supplier.add.naf")}
							/>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.street")}
							<br />
							<SelectAutocomplete
								onChange={(newValue) => {
									props.updateSupplier({
										target: {
											name: "street",
											value: newValue,
										},
									});
								}}
								value={props.supplier?.street}
								name="street"
								List={listStreet}
								placeholder={`102 ${t("supplier.add.street")} XXX`}
							/>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.city")}
							<br />
							<SelectAutocomplete
								onChange={(newValue) => {
									props.updateSupplier({
										target: {
											name: "city",
											value: newValue,
										},
									});
								}}
								value={props.supplier?.city}
								name="city"
								List={listCity}
								placeholder="Paris"
							/>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.zipCode")}
							<br />
							<SelectAutocomplete
								onChange={(newValue) => {
									props.updateSupplier({
										target: {
											name: "zipCode",
											value: newValue,
										},
									});
								}}
								value={props.supplier?.zipCode}
								name="zipCode"
								List={listZip}
								placeholder={t("supplier.add.zipCode")}
							/>
							<p className={classes.validation_error}>{props.errors.zipCode?.message}</p>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.phone")}
							<Input
								value={props.supplier?.phone}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									pattern: { value: REGEX.TEL, message: t("phone_notValid") },
								})}
								name="phone"
								placeholder="+33 123 456 7890"
								fullWidth
							/>
							<p className={classes.validation_error}>{props.errors.phone?.message}</p>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.fax")}
							<Input
								value={props.supplier?.fax}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									pattern: { value: REGEX.TEL, message: t("phone_notValid") },
								})}
								name="fax"
								placeholder="+33 123 456 7890"
								fullWidth
							/>
							<p className={classes.validation_error}>{props.errors.fax?.message}</p>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.email")}
							<br />
							<Input
								value={props.supplier?.email}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									pattern: { value: REGEX.EMAIL, message: t("email_notValid") },
								})}
								placeholder="supplychain@novelis.io"
								name="email"
								type="email"
								fullWidth
							/>
							<p className={classes.validation_error}>{props.errors.email?.message}</p>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="primary">
							{t("supplier.add.linkedin")}
							<Input
								value={props.supplier?.linkedIn}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									pattern: { value: REGEX.LINKEDIN, message: t("url_notValid") },
								})}
								placeholder="https://www.linkedin.com/example"
								name="linkedIn"
								fullWidth
							/>
							<p className={classes.validation_error}>{props.errors.linkedIn?.message}</p>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default GeneralInformation;
