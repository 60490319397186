export default {
	orders: "Commandes",
	clients: "Clients",
	client: "Client",
	"client.delete.msg": "Êtes-vous sûr de vouloir supprimer ce client?",
	"client.delete.success": "Client supprimé avec succès",
	"client.delete.error": "Une erreur s'est produite lors de la suppression du client",
	clientCount: "Nombre total de clients",
	searchClient: "Trouvez un client",
	countOrders: "Nombre total de commandes",
	"Total amount of orders": "Total des commandes",
	searchOrders: "Rechercher une commande ...",
	"Add an order": "Ajouter une commande",
	"order.duplicated": "Doublon",
	"extraction.label.not_order": "Autre que la commande",
	"Order Details": "Détails de la commande",
	"order Number": "Numéro de commande",
	"Order overview": "Aperçu de la commandes",
	"order.detail.reject.success": "La commande a été rejetée avec succès",
	"order.detail.reject.failed": "La commande n'a pas été rejetée",
	"order.status.Processed": "",
	"Total VAT amount": "",
	"order.status.UnderProcess": "",
	"upload.orders": "Charger les commandes",
	"order.status.integration": "Intégration en cours",
	ClientCannotBeDeleted: "",
	"order.status.underProcess": "En cours de traitement",
	"order.status.processed": "Traitée",
	clientEmptyMessageError: "Le client de la commande n'est pas rempli",
	"Orders to verify": "À vérifier",
	"Orders to be checked": "À contrôler",
	"order.exported": "Commandes exportées",
	"order.status.exported": "Exporté",
	"Add your Order": "Ajoutez votre commande ou ",
	"order.unexported": "Commandes non exportées",
	"order.upload": "Importer les commandes",
	"My Orders": "Mes commandes",
	"Total order of expenses": "Total des commandes",
	"order.total_invoice": "Nombre  des commandes",
	"New clients": "Nouveaux clients",
	"order.status.toverify": "A vérifier",
	"order.status.tocontrol": "A contrôler",
	"order.status.topay": "A payer",
	"order.status.payed": "Payé",
	"order.status.rejected": "Rejeté",
	"order.status.canceled": "Annulé",
	"order.status.exception": "Exception",
	duplicated_order: "Commande dupliquée",
	"order.selected.title0": "Toutes les commandes sont sélectionnées",
	"order.selected.title1": "Les ",
	"order.selected.title2": " les commandes de cette page sont toutes sélectionnées.",
	"order.selected.title3": "Sélectionnez toutes les commandes",
	OrdersNotCreatedMessage: "Les commandes suivantes ne peuvent pas être créées :",
	"order.total_amoutn_to_verify": "Montant total des commandes à vérifier",
	"client.add.button": "Ajouter un client",
	"client.import.button": "Importer des clients",
	"clients.export.excel": "Exporter la liste des clients",
	"client.add2.button": "Ajouter un client",
	"client.add.step1": "Numéros d’identification",
	"client.add.step2": "Informations générales",
	"client.add.step3": "Informations financières",
	"client.add.step4": "Premier contact",
	"client.add.next": "Suivant",
	"client.add.previous": "Précédent",
	"client.add.finish": "Finir",
	"client.add.confirm": "Voulez-vous vraiment enregistrer les modifications?",
	"clients.empty.field.name.row.excel": "Il y a une ligne sans nom !!",
	"client.name.exist.already": "Le client existe déjà sur le système",
	"clients.names.exist.already": "Le(s) client(s) {{clients}} existe déjà sur le système!",
	"clients.error.sample": "Le fichier envoyé n'est pas conforme avec le fichier modèle.",
	"clients.add.multiple": "Ajouter vos client ou ",
	"upload.client": "Charger les clients",
	"upload.client.instructions1": "Remplissez la feuille Excel avec les informations des clients",
	"upload.client.instructions2":
		"Remplissez la colonne «Logo» avec le nom du fichier du logo. (Exemple: logofilename.jpg)",
	"upload.client.instructions3": "Assurez-vous que tous les champs sont de type TEXT",
	"upload.client.instructions4":
		"Placez la feuille Excel remplie et les logos des clients dans le même dossier",
	"upload.client.instructions5": "Compressez ce dossier:",
	"upload.client.instructions51":
		'Windows: Faites un clic droit sur le dossier puis choisissez "Envoyer vers " puis cliquez sur "Dossier compressé "',
	"upload.client.instructions52":
		"MAC: cliquez dessus tout en maintenant la touche Contrôle enfoncée ou appuyez dessus avec deux doigts, puis choisissez Compresser dans le menu contextuel.",
	"upload.client.instructions6":
		"Téléchargez le dossier compressé sur le portail ou faites-le glisser et déposez-le dans la zone dédiée",
	"upload.client.instructions7": 'Cliquez sur "Télécharger les clients"',
	"add.client.name": "Nom du client",
	"client.created": "Client créé avec succès !",
	"client.updated": "Client mis à jour avec succès !",
	"client.error": "Une erreur s'est produite lors de l'enregistrement du client",
	"client.delete.confirm": "Voulez-vous vraiment supprimer le client?",
	"client.modify": " Modifier client",
	"order.detail.company_siret": "Identifiant Entreprise",
	"Total of order expenses": "Total des commandes",
	orderRejectMessage: "Voulez-vous vraiment rejeter cette commande?",
	orderControlMessage: "Voulez-vous vraiment mettre cette commande en contrôle?",
	"order.detail.message.rectify": "Êtes-vous sûr de vouloir sauvegarder ces changements?",
	"order.file.not.created.message": "Erreur lors de la création du fichier",
	order_uploaded_success:
		"La commande a été ajoutée avec succès. Elle apparaîtra dans la liste si vous êtes l'émetteur de cette commande.",
};
