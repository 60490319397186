/* eslint-disable */
import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import "fabric-webpack";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import isEmpty from "lodash/isEmpty";
import { fetchOcrData, fetchOcrImage } from "../../../../../redux/actions/invoices";
import { updateFormulaire, initFormulaire } from "../../../../../redux/formulaireReducer";
import { adaptData, highlightColor, rectColor, reducedData } from "../../../utils";
import { getApi } from "redux/store";
import { Box, Button } from "@material-ui/core";

const { fabric } = window;
class DesignCanvas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canvas: null,
			dimensions: {},
			updated: false,
			imageLoading: true,
			emptyPagesData: false,
			disablePan: true,
			LoadPreviewFile: false,
			imgHeight: null,
			imgWidth: null,
			currentImageId: null,
			initState: false,
		};
	}

	static propTypes = {
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
	};

	static defaultProps = {
		width: 720,
		height: 867,
	};

	componentWillMount() {
		const { invoiceId } = this.props;
		this.props.fetchOcrData(invoiceId, (data) => {
			if (isEmpty(data.pagesDataDto)) {
				this.setState({ emptyPagesData: true });
			} else this.initItem(data.invoiceDto);
			this.setState({ initState: true });
		});
	}

	componentDidMount() {
		document.addEventListener("keydown", this.handleKeyDown);
		document.addEventListener("keyup", this.handleKeyUp);
	}

	componentWillUnmount() {
		document.removeEventListener("keyup", this.handleKeyDown);
		document.removeEventListener("keyup", this.handleKeyUp);
		this.props.initFormulaire();
		// window.location.reload();
	}

	initItem = (value) => {
		const formulaire = JSON.parse(JSON.stringify(this.props.formulaire));
		formulaire.invoiceDto = value;
		this.props.updateFormulaire(formulaire);
	};

	componentWillReceiveProps(nextProps) {
		const that = this;
		const { initState } = this.state;
		if (nextProps.formulaire) {
			this.setState({ formulaire: nextProps.formulaire });
		}
		if (nextProps.ocrData) {
			const pagesDataDto = nextProps.ocrData.pagesDataDto[0];
			if (pagesDataDto && !this.state.updated && initState) {
				this.setState({ currentImageId: pagesDataDto.pageId });
				const img = new Image();
				img.onload = function () {
					that.setState({ imageLoading: false });
					that.createCanvas(pagesDataDto, img);
				};
				img.src = `${getApi()}/invoice/image/${pagesDataDto.pageImageId}`;

				this.setState({ updated: true });
			}
		}
		if (nextProps.location && nextProps.isSelectedField) {
			this.findItems(nextProps.location);
		}

		if (nextProps.previewFile) {
			this.setState({ LoadPreviewFile: true });
		}
	}

	updateCanvas = (page, withLocation) => {
		const that = this;
		const img = new Image();
		img.onload = function () {
			that.createCanvas(page, img, withLocation);
		};
		img.src = `${getApi()}/invoice/image/${page.pageImageId}`;
		this.setState({ updated: true });
	};

	findSelectedPage = (location) => {
		const { ocrData } = this.props;
		if (location && location.pageId) {
			const selectedPage = ocrData.pagesDataDto.find((page) => page.pageId === location.pageId);
			this.setState({ currentImageId: selectedPage.pageId }, () => {
				this.updateCanvas(selectedPage, location);
			});
		}
	};

	updateItem = (value, item) => {
		const formulaire = JSON.parse(JSON.stringify(this.state.formulaire));
		const _item = item || formulaire.selectedItem;
		if (!_item) {
		} else if (_item === "invoiceDto") {
			formulaire[_item] = value;
		} else if (_item.startsWith("order.withLabel")) {
			const selectedOrder = _item.split(".")[2];
			formulaire.invoiceDto.order[selectedOrder].label.value = value.value;
			formulaire.invoiceDto.order[selectedOrder].label.location = value.location;
		} else if (_item.startsWith("order")) {
			const selectedOrder = _item.split(".")[1];
			formulaire.invoiceDto.order[selectedOrder].value = value.value;
			formulaire.invoiceDto.order[selectedOrder].location = value.location;
		} else if (_item.startsWith("withLabel")) {
			const selectedOrder = _item.split(".")[1];
			formulaire.invoiceDto[selectedOrder].label.value = value.value;
			formulaire.invoiceDto[selectedOrder].label.location = value.location;
		} else if (_item.startsWith("paymentTerm")) {
			const selectedOrder = _item.split(".")[1];
			formulaire.invoiceDto.paymentTerm[selectedOrder].value = value.value;
			formulaire.invoiceDto.paymentTerm[selectedOrder].location = value.location;
			formulaire.invoiceDto.paymentTerm.code.value = value.value;
			formulaire.invoiceDto.paymentTerm.code.location = value.location;
		} else if (_item.startsWith("additionalAmountsht")) {
			const index = _item.split(".")[1];
			formulaire.invoiceDto.additionalAmountsHT[index].amount.value = value.value;
			formulaire.invoiceDto.additionalAmountsHT[index].amount.location = value.location;
		} else if (_item.startsWith("additionalAmountstva")) {
			const index = _item.split(".")[1];
			if (_item.split(".").length > 3) {
				if (formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails === null)
					formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails = {
						percentage: {},
						base: {},
					};

				if (_item.split(".")[3] === "base") {
					formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails.base.value =
						value.value;
					formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails.base.location =
						value.location;
				} else if (_item.split(".")[3] === "percentage") {
					formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails.percentage.value =
						value.value;
					formulaire.invoiceDto.additionalAmountsTVA[index].amountsTvaDetails.percentage.location =
						value.location;
				}
			} else {
				formulaire.invoiceDto.additionalAmountsTVA[index].amount.value = value.value;
				formulaire.invoiceDto.additionalAmountsTVA[index].amount.location = value.location;
			}
		} else if (_item.startsWith("additionalAmountscharge")) {
			const index = _item.split(".")[1];
			formulaire.invoiceDto.additionalAmountsCharge[index].amount.value = value.value;
			formulaire.invoiceDto.additionalAmountsCharge[index].amount.location = value.location;
		} else if (_item.startsWith("table")) {
			const index = _item.split(".")[1];
			const selectedOrder = _item.split(".")[2];
			formulaire.invoiceDto.billingLinesDtos[index][selectedOrder].value = value.value;
			formulaire.invoiceDto.billingLinesDtos[index][selectedOrder].location = value.location;
			// temp work
		} else if (_item === "vatNumber") {
			formulaire.invoiceDto.supplier.vatNumber.value = value.value;
			formulaire.invoiceDto.supplier.vatNumber.location = value.location;
		} else if (_item === "companySiret") {
			formulaire.invoiceDto.supplier.companySiret.value = value.value;
			formulaire.invoiceDto.supplier.companySiret.location = value.location;
		} else if (_item === "prepayAmount") {
			formulaire.invoiceDto.prepayAmount.value = value.value;
			formulaire.invoiceDto.prepayAmount.location = value.location;
		} else if (_item === "netToPay") {
			formulaire.invoiceDto.netToPay.value = value.value;
			formulaire.invoiceDto.netToPay.location = value.location;
		} else if (_item.startsWith("invoiceRibs")) {
			const index = _item.split(".")[1];
			formulaire.invoiceDto.invoiceRibs[index].rib.value = value.value;
			formulaire.invoiceDto.invoiceRibs[index].location = value.location;
		} else {
			formulaire.invoiceDto[_item].value = value.value;
			formulaire.invoiceDto[_item].location = value.location;
		}
		Object.preventExtensions(formulaire);
		this.props.updateFormulaire(formulaire);
	};

	createCanvas = (pagesDataDto, _image, withLocation) => {
		const oldCanvas = this.state.canvas;
		const canvas = oldCanvas || new fabric.Canvas(this.c);
		const texts = new fabric.Canvas();
		const that = this;
		const objects = texts.getObjects();
		fabric.Image.fromURL(_image.src, (img) => {
			const initZoom = this.props.width / img.width;
			this.setState({
				imgHeight: img.height,
				imgWidth: img.width,
				initZoom,
			});

			img.setOptions({
				hasControls: false,
				lockMovementX: true,
				lockMovementY: true,
				lockRotation: true,
				lockScalingY: false,
				lockScalingX: false,
				lockSkewingX: true,
				lockSkewingY: true,
				hasRotatingPoint: false,
				hoverCursor: "auto",
			});
			canvas.setBackgroundImage(img);
			canvas.setWidth(this.props.width);
			canvas.setHeight(img.height * initZoom);
			canvas.setZoom(initZoom);
			const _data = reducedData(1, 1, pagesDataDto);
			objects.forEach((o) => {
				o.setOptions({
					hasControls: false,
					lockMovementX: true,
					lockMovementY: true,
					lockRotation: true,
					lockScalingY: true,
					lockScalingX: true,
					lockSkewingX: true,
					lockSkewingY: true,
					hasRotatingPoint: false,
					setControlsVisibility: {
						bl: false,
						br: false,
						mb: false,
						ml: false,
						mr: false,
						mt: false,
					},
				});
			});

			texts.loadFromJSON(JSON.stringify(adaptData(_data)), () => {
				texts.renderAll();
			});
			canvas.clear();

			canvas.add(...objects);
			if (withLocation) {
				objects
					.filter((o) => {
						const { location } = o.text;
						return (
							location.top >= withLocation.top &&
							location.bottom <= withLocation.bottom &&
							location.left >= withLocation.left &&
							location.right <= withLocation.right &&
							location.pageId === withLocation.pageId &&
							withLocation.top !== 0 &&
							withLocation.left !== 0
						);
					})
					.forEach((o) => {
						o.fill = highlightColor;
					});
				canvas.renderAll();
			}
		});

		canvas.on("mouse:over", (options) => {
			if (options.target && !(options.target.selectable === false)) {
				options.target.set("fill", highlightColor);
				canvas.renderAll();
			}
		});

		canvas.on("mouse:out", (options) => {
			options.target && !options.target.active && options.target.set("fill", rectColor);
			canvas.renderAll();
		});

		canvas.on("object:selected", (options) => {
			const objs = get(options, "target._objects", []).filter((e) => e.type === "rect");
			if (objs.length) {
				const item = {
					value: "",
					location: {},
				};
				options.target._objects.map((e) => {
					e.set("fill", highlightColor);
					item.value += ` ${e.text.value}`;
					item.location.top =
						item.location.top <= e.text.location.top ? item.location.top : e.text.location.top;
					item.location.left =
						item.location.left <= e.text.location.left ? item.location.left : e.text.location.left;
					item.location.right =
						item.location.right >= e.text.location.right
							? item.location.right
							: e.text.location.right;
					item.location.bottom =
						item.location.bottom >= e.text.location.bottom
							? item.location.bottom
							: e.text.location.bottom;
					item.location.pageId = e.text.location.pageId;
				});
				item.value.trim();
				that.updateItem(item);
			} else {
				options.target.set("fill", highlightColor);
				that.updateItem(options.target.text);
			}
		});

		canvas.on("selection:cleared", (options) => {
			canvas.getObjects().map((o) => {
				o.fill = rectColor;
			});
			canvas.renderAll();
			that.setState({ items: null });
		});
		this.setState({ canvas });
		canvas.renderAll();
	};

	findElementsInCanvas = (location, canvas) => {
		const { currentImageId } = this.state;
		if (location.pageId !== currentImageId) {
			this.findSelectedPage(location);
		} else {
			canvas.getObjects().map((o) => {
				if (
					o.text.location.top >= location.top &&
					o.text.location.bottom <= location.bottom &&
					o.text.location.left >= location.left &&
					o.text.location.right <= location.right &&
					o.text.location.pageId === location.pageId &&
					// for incorect location
					location.top !== 0 &&
					location.left !== 0
				) {
					o.fill = highlightColor;
				} else {
					o.fill = rectColor;
				}
			});
			canvas.renderAll();
		}
	};

	findItems = (location) => {
		const { canvas } = this.state;
		if (canvas) {
			this.findElementsInCanvas(location, canvas);
		}
	};

	handleKeyDown = (e) => {
		const { disablePan } = this.state;
		if (disablePan && e.key === "Alt") {
			this.setState({ disablePan: false });
		}
	};

	handleKeyUp = (e) => {
		if (e.key === "Alt") {
			this.setState({ disablePan: true });
		}
	};

	zoomIn = () => {
		const { canvas, imgHeight, imgWidth } = this.state;
		const zoom = canvas.getZoom() + 0.1;
		canvas.setHeight(imgHeight * zoom);
		canvas.setWidth(imgWidth * zoom);
		canvas.setZoom(zoom);
		this.setState({ canvas });
	};

	zoomOut = () => {
		const { canvas, imgHeight, imgWidth } = this.state;
		const zoom = canvas.getZoom() - 0.1 > 0 ? canvas.getZoom() - 0.1 : canvas.getZoom();
		canvas.setHeight(imgHeight * zoom);
		canvas.setWidth(imgWidth * zoom);
		canvas.setZoom(zoom);
		this.setState({ canvas });
	};

	resetZoom = () => {
		const { initZoom, canvas } = this.state;
		const { width, height } = this.props;
		canvas.setHeight(height);
		canvas.setWidth(width);
		canvas.setZoom(initZoom);
		this.setState({ canvas });
	};

	render() {
		const { width, height, ocrData, previewFile, isSelectedField, t } = this.props;
		const { imageLoading, emptyPagesData, disablePan, LoadPreviewFile } = this.state;
		return (
			<>
				{imageLoading && !emptyPagesData && LoadPreviewFile ? (
					<div
						style={{
							height: 800,
							width: 800,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CircularProgress />
					</div>
				) : (
					<>
						{emptyPagesData ? (
							<iframe
								src={previewFile}
								type="application/pdf"
								width="100%"
								height="776px"
								title="invoice"
							/>
						) : (
							<Box style={{ marginTop: "-45px" }}>
								<div className="tools">
									<IconButton onClick={this.zoomIn}>
										<ZoomInIcon />
									</IconButton>
									<IconButton onClick={this.zoomOut}>
										<ZoomOutIcon />
									</IconButton>
									<IconButton onClick={this.resetZoom}>
										<RotateLeftIcon />
									</IconButton>

									<FormControlLabel
										control={
											<Checkbox
												checked={isSelectedField}
												onChange={(e) => {
													this.props.selectedField(e.target.checked);
												}}
												name="checkedA"
											/>
										}
										label={this.props.t("invoice.detail.marked.selectedField")}
									/>
								</div>
								<Box mt={2}>
									{ocrData?.pagesDataDto.map((page, index) => (
										<Button
											onClick={() => {
												this.setState({ currentImageId: page.pageId });
												this.updateCanvas(page);
											}}
											disableElevation
											style={{
												textTransform: "none",
												marginRight: "4px",
												borderBottomLeftRadius: 0,
												borderBottomRightRadius: 0,
											}}
											variant={this.state.currentImageId === page.pageId ? "contained" : "outlined"}
											color="primary"
										>
											Page {index + 1}/{ocrData.pagesDataDto.length}
										</Button>
									))}
								</Box>
								<div style={{ width, height, overflow: "auto" }}>
									<canvas
										ref={(c) => (this.c = c)}
										width={width}
										height={height}
										style={{ border: "2px solid black" }}
									/>
								</div>
							</Box>
						)}
					</>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	const { formulaire } = state;
	const { requests } = state;
	return {
		formulaire,
		selectedItem: formulaire.selectedItem,
		ocrData: get(requests, "queries.FETCH_OCR_DATA.data"),
		ocrImage: get(requests, "queries.FETCH_OCR_IMAGE.data"),
	};
};

const mapDispatchToProps = {
	initFormulaire,
	fetchOcrData,
	fetchOcrImage,
	updateFormulaire,
};
export default connect(mapStateToProps, mapDispatchToProps)(DesignCanvas);
