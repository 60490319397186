import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	firstGrid: {
		paddingTop: "1%",
		width: "calc(100% + 48px)",
	},
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	input: {
		Width: "224px",
	},
	space: {
		marginTop: "10px",
	},
	tab: {
		fontWeight: "300",
		height: "20px",
	},
	supplierDetails: {
		backgroundColor: theme.palette.background.paper,
	},
	supplierItem: {
		margin: "25px 0",
	},

	large: {
		width: theme.spacing(15),
		height: theme.spacing(15),
	},

	customBadge: {
		backgroundColor: theme.palette.background.paper,
		height: "24px",
	},
	invoicesTable: {
		marginTop: "25px",
	},
	rootContainer: {
		padding: theme.spacing(2, 0),
	},
	appBar: { backgroundColor: "transparent", boxShadow: "none" },
	invoicesDetails: {
		marginTop: theme.spacing(4),
	},
	selectedTab: {
		color: "#0da8ad",
	},
	fullwidth: {
		width: "100%",
	},
	list: {
		minHeight: "1200px",
		margin: "30px 0px",
		paddingBottom: "50px",
	},
	lockButton: {
		backgroundColor: "#d63031",
		color: "white",
	},
	// buttonDelete:{
	//   marginRight:theme.spacing(5),
	// }
}));
