import { FETCH_DELIVERY_ITEMS_BY_SUPPLIER } from "../../constants";
import { URL_BASE_INVOICE } from "../../constants/endpoint";

export const fetchDeliveryItemsBySupplier = (supplierId) => ({
    type: FETCH_DELIVERY_ITEMS_BY_SUPPLIER,
    request: {
      url: `${URL_BASE_INVOICE}/delivery/delivery-items/${supplierId}`,
    },
  });

  export default fetchDeliveryItemsBySupplier;