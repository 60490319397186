export default {
	orders: "Pedidos",
	clients: "Clientes",
	client: "Cliente",
	"client.delete.msg": "¿Está seguro de que desea eliminar este cliente?",
	"client.delete.success": "Cliente eliminado correctamente",
	"client.delete.error": "Se produjo un error al eliminar el cliente",
	clientCount: "Número total de clientes",
	searchClient: "Encontrar un cliente",
	countOrders: "Número total de pedidos",
	"Total amount of orders": "Cantidad total de pedidos",
	searchOrders: "Buscar un pedido ...",
	"Add an order": "Agregar pedido",
	"order.duplicated": "Duplicado",
	"extraction.label.not_order": "Aparte del pedido",
	"Order Details": "Detalles del pedido",
	"order Number": "Número de pedido",
	"Order overview": "Resumen de pedidos",
	"order.detail.reject.success": "El pedido se rechazó correctamente",
	"order.detail.reject.failed": "El pedido no fue rechazado",
	"order.status.underProcess": "En proceso",
	"order.status.processed": "Procesado",
	clientEmptyMessageError: "El cliente del pedido no está satisfecho",
	"Orders to verify": "Para verificar",
	"Orders to be checked": "Para controlar",
	"order.exported": "Pedidos exportados",
	"order.status.exported": "Exportado",
	"Add your Order": "Agregue su pedido o ",
	"order.unexported": "Pedidos no exportados",
	"order.upload": "Subir pedidos",
	"My Orders": "Mis ordenes",
	"Total order of expenses": "Orden total",
	"order.total_invoice": "Número de pedidos",
	"New clients": "Nuevos clientes",
	"order.status.Processed": "",
	"Total VAT amount": "",
	"order.status.UnderProcess": "",
	"upload.orders": "Subir pedidos",
	"order.status.integration": "Integración en curso",
	ClientCannotBeDeleted: "",
	"order.status.toverify": "Para verificar",
	"order.status.tocontrol": "Para controlar",
	"order.status.topay": "Para pagar",
	"order.status.payed": "Pagado",
	"order.status.rejected": "Rechazado",
	"order.status.canceled": "Cancelado",
	"order.status.exception": "Excepción",
	duplicated_order: "Pedido duplicado",
	"order.selected.title0": "Todos los pedidos están seleccionados",
	"order.selected.title1": "los ",
	"order.selected.title2": " Los pedidos de esta página están todos seleccionados.",
	"order.selected.title3": "Seleccionar todos los pedidos",
	OrdersNotCreatedMessage: "No se pueden crear los siguientes pedidos :",
	"order.total_amoutn_to_verify": "Cantidad total de pedidos a verificar",
	"client.add.button": "Agregar un cliente",
	"client.import.button": "Importar clientes",
	"clients.export.excel": "Exportar la lista de clientes",
	"client.add2.button": "Agregar un cliente",
	"client.add.step1": "Números de identificación",
	"client.add.step2": "Información general",
	"client.add.step4": "Contacto primario",
	"client.add.step3": "Informaciones financieras",
	"client.add.next": "Próxima",
	"client.add.previous": "Previa",
	"client.add.finish": "Finalizar",
	"client.add.confirm": "¿Estás segura de que quieres guardar los cambios?",
	"clients.empty.field.name.row.excel": "Hay una línea con nombre vacío !!",
	"client.name.exist.already": "El cliente no es único",
	"clients.names.exist.already": "losClientes {{clients}} YaExistenEnElSistema!",
	"clients.error.sample": "El archivo enviado no cumple con el archivo ejemplar",
	"clients.add.multiple": "Agregar clientes o ",
	"upload.client": "Subir clientes",
	"upload.client.instructions1": "Complete la hoja de Excel con la información del clientes",
	"upload.client.instructions2":
		'Complete la columna "Logotipo" con el nombre del archivo del logotipo. (Ejemplo: logofilename.jpg)',
	"upload.instructions3": "Asegúrese de que todos los campos sean de tipo TEXTO",
	"upload.client.instructions4":
		"Coloque la hoja de Excel completa y los logotipos de clientes en la misma carpeta",
	"upload.client.instructions5": "Comprime esta carpeta:",
	"upload.client.instructions51":
		'Windows: haga clic con el botón derecho en la carpeta, luego elija "Enviar a " y luego haga clic en "Carpeta comprimida "',
	"upload.client.instructions52":
		"MAC: haz Control-clic en él o tócalo con dos dedos, luego elige Comprimir en el menú emergente.",
	"upload.client.instructions6":
		"Cargue la carpeta comprimida en el portal o arrástrela y suéltela en el área dedicada",
	"upload.client.instructions7": 'Haga clic en "Descargar clientes"',
	"add.client.name": "Nombre del cliente",
	"client.created": "cliente creado con éxito!",
	"client.updated": "cliente actualizado correctamente!",
	"client.error": "Ocurrió un error al guardar el cliente",
	"client.delete.confirm": "¿Está seguro de que desea eliminar el cliente?",
	"client.modify": " Modificar cliente",
	"order.detail.company_siret": "Identificación de la empresa",
	"Total of order expenses": "Total de pedidos",
	orderRejectMessage: "¿Estás seguro de que deseas rechazar este pedido?",
	orderControlMessage: "¿Estás seguro de que quieres controlar este comando?",
	"order.detail.message.rectify": "¿Estás seguro de que quieres guardar estos cambios?",
	"order.file.not.created.message": "Error al crear el archivo",
	order_uploaded_success:
		"El pedido se ha agregado con éxito.Aparecerá en la lista si usted es el emisor de este pedido.",
};
