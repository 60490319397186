import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import FlagIcon from "@material-ui/icons/Flag";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Divider from "@material-ui/core/Divider";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { useForm } from "react-hook-form";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import { ExpandMore, Remove } from "@material-ui/icons";
import Description from "@material-ui/icons/Description";
import clsx from "clsx";
import { Accordion, AccordionDetails, AccordionSummary, Tooltip } from "@material-ui/core";
import get from "lodash/get";
import { useHistory } from "react-router";
import { toast, isSupplierOnly, currencyFormat } from "utils";
import { fetchSettingsByModuleName } from "redux/actions/users";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash";
import ErrorIcon from "@material-ui/icons/Error";
import History from "./Components/History";
import AmountsComponent from "./Components/AmountsComponent";
import DesignCanvas from "./Components/DesignCanvas";
import Erreur from "../../../Assets/img/erreur.png";
import {
	fetchInvoice,
	fetchGlAccounts,
	fetchCostCenters,
	fetchPaymentMethods,
	fetchDocumentInvoice,
	fetchPaymentTerms,
	updateInvoice,
	createInvoiceRejection,
	verifyInvoice,
	fetchBillingLines,
	fetchDeliveryMethods,
	rectifyInvoice,
	topayInvoice,
	createInvoiceApproval,
	exportXMLFileToServer,
	fetchCountSuppliers,
	fetchCountInvoices,
	downloadXMLInvoice,
	downloadInvoiceExcel,
	downloadInvoice,
	exportSingleAccountingEntryExcel,
	downloadEdifactInvoice,
	downloadIdocInvoice,
	workflowProceed,
	workflowForceProceed,
	fetchRejectionMotifs,
} from "../../../redux/actions/invoices";

import { fetchSuppliers } from "../../../redux/actions/suppliers";
import { updateFormulaire } from "../../../redux/formulaireReducer";
import { INTEGRATION, TO_VERIFY_LIST, TREATED, REJECTED } from "../../../redux/constants";
import Attachements from "./Components/Attachements";
import DeliveryItemsSection from "./Components/DeliveryItemsSection";
import PDFViewer from "./Components/PDFViewer";
import PDFJSBackend from "./Components/pdfjs";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import useStyles from "./style";
import ConfirmDialog from "../../../components/ConfirmDialog";
import AccountingLines from "./Components/AccountingLines";
import DigitalSummary from "./Components/DigitalSummary";
import { isInternal } from "../utils";
import SelectPayment from "./Components/SelectPayment/SelectPayment";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other} // eslint-disable-line
		>
			{value === index && (
				<Grid container xs={12} style={{ margin: 0 }}>
					{children}
				</Grid>
			)}
		</div>
	);
}
TabPanel.propTypes = {
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
	};
}

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />; // eslint-disable-line
}

const DialogReject = ({ open, setOpen, ...props }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = React.useState(false);
	const [rejectionMotifs, setRejectionMotifs] = React.useState(props.rejectionMotifs);

	useEffect(() => {
		if (!rejectionMotifs) {
			setRejectionMotifs(props.rejectionMotifs);
		}
	});

	const { errors, handleSubmit, register } = useForm();

	const handleCloseRejectPopup = () => {
		setOpen(!open);
		setIsLoading(false);
	};

	const onSubmit = (data) => {
		setIsLoading(true);
		const listMotifs = rejectionMotifs && rejectionMotifs.invoiceRejectionMotifList;
		const motif = listMotifs.find((motifParam) => t(motifParam.label) === data.motif);
		const id = props.invoicePersisted && props.invoicePersisted.id;
		props.createInvoiceRejection(
			id,
			{ invoiceId: id, motif: motif.code, comment: data.comment },
			() => {
				props.onClose(false);
				handleCloseRejectPopup();
			},
			() => {
				props.onClose(true);
				handleCloseRejectPopup();
			}
		);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseRejectPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t("rejectMessage")}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<Autocomplete
						options={rejectionMotifs && rejectionMotifs.invoiceRejectionMotifList}
						getOptionLabel={({ label = "" }) => t(label)}
						id="rejectionReason"
						renderInput={(params) => (
							<div>
								<TextField
									{...params} // eslint-disable-line
									inputRef={register({ required: true })}
									variant="standard"
									label={t("rejectionReason")}
									margin="normal"
									name="motif"
								/>
								{errors.motif && (
									<Typography variant="subtitle2" align="left" color="error">
										{t("this field is mandatory")}
									</Typography>
								)}
							</div>
						)}
					/>

					<TextField
						inputRef={register}
						name="comment"
						id="outlined-multiline-static"
						label={t("rejectionDetails")}
						multiline
						fullWidth
						rows={4}
						defaultValue=""
						variant="outlined"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseRejectPopup} color="secondary" disabled={isLoading} autoFocus>
						{t("cancel")}
					</Button>
					<Button type="submit" color="secondary" disabled={isLoading}>
						{isLoading && <CircularProgress size={14} />}&nbsp; {t("confirm")}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

const DigitalChip = () => {
	const { t } = useTranslation();

	return (
		<Tooltip title={t("invoice.digital.extract")}>
			<Chip
				icon={<Description style={{ width: "12px", height: "12px" }} />}
				color="primary"
				style={{
					width: "15px",
					height: "15px",
					paddingLeft: "8px",
				}}
				size="small"
			/>
		</Tooltip>
	);
};

function Detail(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [previewFile, setPreviewFile] = React.useState(null);
	const [mimeType, setMimeType] = React.useState(null);
	const filterContext =
		history.location && history.location.state && history.location.state.filterContext;

	const [value, setValue] = React.useState(0);
	const [scValue, setScValue] = React.useState(0);

	const [openRejectPopup, setOpenRejectPopup] = React.useState(false);

	const [openAlertesDialog, setOpenAlertesDialog] = React.useState(false);
	const [warningsToFix, setWarningsToFix] = React.useState([]);

	const [isEditFacture] = React.useState(false);
	const [rectify, setRectify] = React.useState(false);
	const [billingLines, setBillingLines] = React.useState([]);
	const [expandedPanel, setExpandedPanel] = React.useState(false);
	const [expandedDefaultPanel, setExpandedDefaultPanel] = React.useState(true);
	const [expandedPanelOrder, setExpandedPanelorder] = React.useState(true);
	const [expandedDefaultPanelOrder, setExpandedDefaultPanelOrder] = React.useState(true);
	const [openTva, setOpenTva] = React.useState(false);
	const [openCharge, setOpenCharge] = React.useState(false);
	const handleAccordionOrderChange = (panel) => (event, isExpanded) => {
		setExpandedDefaultPanelOrder(false);
		setExpandedPanelorder(isExpanded ? panel : false);
	};
	const handleAccordionChange = (panel) => (event, isExpanded) => {
		setExpandedDefaultPanel(false);
		setExpandedPanel(isExpanded ? panel : false);
	};
	const [invoice, setInvoice] = React.useState({});
	const [order, setOrder] = React.useState(props.invoice ? props.invoice.order : {});
	const [supplier, setSupplier] = React.useState(null);
	const [suppliers, setSuppliers] = React.useState([]);
	const [location, setLocation] = React.useState(null);
	// TODO: remove this, it's used but never set.
	const [dataErrors] = React.useState([]);
	const [settings, setSettings] = React.useState({ loaded: false, data: [] });
	const [isSelectedField, setSelectedFied] = React.useState(true);

	const [dataFromXml, setDataFromXml] = React.useState({});
	const [fromDigitalInvoice, setFromDigitalInvoice] = React.useState({});
	const [isDigitalInvoice, setDigitalInvoice] = React.useState(false);
	const [isDocumentInternal, setIsDocumentInternal] = React.useState(false);
	const [nextStatusLoading, setNextStatusLoading] = React.useState(false);
	const [openExportSatus, setOpenExportSatus] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState({
		excel: false,
		xml: false,
		pdf: false,
		edi: false,
		idoc: false,
		ec: false,
	});

	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
		rectify: false,
		control: false,
		export: false,
		training: false,
		isLoading: false,
	});

	// eslint-disable-next-line no-shadow
	const formulaire = useSelector(({ formulaire }) => formulaire);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const getDataFromSettings = (code) =>
		get(
			settings.data.find((e) => e.code === code),
			"value"
		) === "1";

	const isEmptyOrNull = (v) => v === null || v === "";

	const isSupplier = isSupplierOnly(get(currentUser, "roles", []));

	useEffect(() => {
		dispatch(fetchCountSuppliers());
		dispatch(fetchCountInvoices());
		dispatch(fetchDeliveryMethods());
		if (!isSupplier)
			dispatch(fetchSettingsByModuleName("invoice")).then((res) => {
				if (res.data) setSettings({ loaded: true, data: res.data });
			});
		fetchInvoice(props.match.params.idInvoice ? parseFloat(props.match.params.idInvoice) : 0);
		setInvoice(props.invoicePersisted);
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		props.updateFormulaire({
			..._formulaire,
			invoiceAccounting: props.invoicePersisted?.invoiceAccounting,
		});
		if (props.invoicePersisted) setOrder(props.invoicePersisted.order);
		if (props.invoicePersisted) setBillingLines(props.invoicePersisted.billingLinesDtos);
		if (props.invoicePersisted)
			setDataFromXml(JSON.parse(get(props.invoicePersisted, "dataFromXml")));
		if (props.invoicePersisted)
			setFromDigitalInvoice(JSON.parse(get(props.invoicePersisted, "fromDigitalInvoice")));
		if (props.invoicePersisted) setDigitalInvoice(get(props.invoicePersisted, "digitalInvoice"));
		if (props.invoicePersisted)
			setIsDocumentInternal(isInternal(get(props.invoicePersisted, "source")));
		setSupplier(props.invoicePersisted ? props.invoicePersisted.supplier : null);
		// TODO: why fetch billing lines?? They are already included in the invoice.
		dispatch(
			fetchBillingLines(props.match.params.idInvoice ? parseFloat(props.match.params.idInvoice) : 0)
		);

		if (props.invoicePersisted) {
			// get rules of current clicked transition
			if (props.invoicePersisted.status) {
				// get alertes
				const alerts = props.invoicePersisted.alertes?.alertes;

				if (typeof alerts === "object") {
					setWarningsToFix(alerts);
				}
			}
		}
	}, [props.invoicePersisted]);

	const handleSupplierInputChange = debounce((q) => {
		if (q && props.invoicePersisted) {
			dispatch(
				fetchSuppliers({
					nameOrContactLastNameOrContactFirstName: q,
					pageNo: 0,
					pageSize: null,
					sortBy: "label",
				})
			).then((res) => {
				if (res.data) {
					const _suppliers = res.data.content.filter(
						(s) => s.id !== props.invoicePersisted.supplier?.id
					);
					if (props.invoicePersisted.supplier) {
						_suppliers.push(props.invoicePersisted.supplier);
					}
					setSuppliers(_suppliers);
				}
			});
		}
	}, 500);

	const getTotalTTC = (prixHt, vat) => {
		// Ensure that the arguments are numbers
		prixHt = parseFloat(prixHt);
		vat = parseFloat(vat);
		const totalTTC = prixHt * (1 + vat / 100);
		return Math.round(totalTTC * 100) / 100;
	};

	useEffect(() => {
		if (props.invoicePersisted?.supplier) {
			setSuppliers([props.invoicePersisted.supplier]);
		}
	}, [props.invoicePersisted]);

	const handleSupplierFieldChange = (e, val) => {
		setSupplier(val);
		setInvoice({
			...invoice,
			supplier: val,
		});
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		if (val) {
			_formulaire.invoiceDto.supplier.label.value = val.label;
			_formulaire.invoiceDto.supplier.id.value = val.id;
			_formulaire.invoiceDto.supplier.vatNumber.value = val.vatNumber;
			_formulaire.invoiceDto.supplier.companySiret.value = val.companySiret;
			_formulaire.invoiceDto.supplier.code.value = val.code;
		} else {
			_formulaire.invoiceDto.supplier.label.location = {
				top: null,
				right: null,
				left: null,
				bottom: null,
			};
		}
		props.updateFormulaire(_formulaire);
	};
	const handlePaymentTermFieldChange = (val) => {
		const selectedPayement = props.paymentTerms.paymentTermsList.find((s) => s.label === val);
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		_formulaire.invoiceDto.paymentTerm.label.value = val;
		if (selectedPayement) {
			_formulaire.invoiceDto.paymentTerm.code.value = selectedPayement.code;
		} else {
			_formulaire.invoiceDto.paymentTerm.code.value = val;
		}
		if (isEmptyOrNull(val)) {
			_formulaire.invoiceDto.paymentTerm.label.location = {
				top: null,
				right: null,
				left: null,
				bottom: null,
			};
		}
		props.updateFormulaire(_formulaire);
	};
	const addAccountingLine = () => {
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		if (!_formulaire?.invoiceAccounting?.accountingLines) {
			_formulaire.invoiceAccounting = { accountingLines: [] };
		}
		_formulaire.invoiceAccounting.accountingLines.push({ sign: "D" });
		props.updateFormulaire(_formulaire);
	};

	const deleteAccountingLine = (index) => {
		const _formulaire = JSON.parse(JSON.stringify(formulaire));

		const filteredItems = _formulaire.invoiceAccounting.accountingLines
			.slice(0, index)
			.concat(
				_formulaire.invoiceAccounting.accountingLines.slice(
					index + 1,
					_formulaire.invoiceAccounting.accountingLines.length
				)
			);
		_formulaire.invoiceAccounting.accountingLines = filteredItems;
		props.updateFormulaire(_formulaire);
	};

	const handleAccountingLineChange = (index, name, accountingLineValue) => {
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		if (!_formulaire?.invoiceAccounting?.accountingLines) {
			_formulaire.invoiceAccounting = { accountingLines: [] };
		}
		_formulaire.invoiceAccounting.accountingLines[index][name] = accountingLineValue;
		props.updateFormulaire(_formulaire);
	};

	const handleAccountingChange = (name, accountingValue) => {
		const _formulaire = JSON.parse(JSON.stringify(formulaire));
		if (!_formulaire?.invoiceAccounting?.accountingLines) {
			_formulaire.invoiceAccounting = { accountingLines: [] };
		}
		if (name === "costCenter") {
			_formulaire.invoiceAccounting[name] = props.costCenters.find(
				(costCenter) => costCenter.id === accountingValue
			);
		} else {
			_formulaire.invoiceAccounting[name] = props.paymentMethods.find(
				(paymentMethod) => paymentMethod.id === accountingValue
			);
		}
		props.updateFormulaire(_formulaire);
	};

	const exportEdi = () => {
		setNextStatusLoading(true);
		dispatch(
			exportXMLFileToServer(
				props.match.params.idInvoice,
				() => {
					toast.success(t("invoice.detail.export.success"));
					dispatch(fetchInvoice(props.match.params.idInvoice));
					setOpenExportSatus(false);
					setNextStatusLoading(false);
				},
				() => {
					toast.error(t("invoice.detail.export.failed"));
					setOpenExportSatus(false);
					setNextStatusLoading(false);
				}
			)
		);
	};

	useEffect(() => {
		props.fetchInvoice(props.match.params.idInvoice ? parseFloat(props.match.params.idInvoice) : 0);
		props
			.fetchDocumentInvoice(
				props.match.params.idInvoice ? parseFloat(props.match.params.idInvoice) : 0,
				"inv",
				null,
				setMimeType
			)
			.then((response) => {
				if (response.data) {
					const blob = new Blob([response.data], { type: mimeType });
					const fileURL = URL.createObjectURL(blob);
					setPreviewFile(fileURL);
				}
			});
		props.fetchPaymentTerms();
		props.fetchRejectionMotifs({ token: null });
		props.fetchGlAccounts();
		props.fetchCostCenters();
		props.fetchPaymentMethods();

		return () => {
			setPreviewFile(null);
			setWarningsToFix([]);
		};
	}, []);

	const theme = useTheme();

	const handleChangeFirstTab = (event, newValue) => {
		setValue(newValue);
	};
	const handleChangeSecondTab = (event, newScValue) => {
		setScValue(newScValue);
	};
	const onChangeBilling = (event, i, cellule) => {
		const newRows = billingLines.slice();
		newRows[i][cellule] = event.target.value;
		setBillingLines(newRows);
	};
	const minimizeValueDesignation = (v) => (v && v.length > 45 ? `${v.substr(0, 44)}...` : v);
	const minimizeValueCode = (v) => (v && v.length > 9 ? `${v.substr(0, 8)}...` : v);

	const updateSelectedItem = (v) => {
		if (isSelectedField) dispatch(updateFormulaire({ ...formulaire, selectedItem: v }));
	};

	const getPaymentTermsOptions = () => {
		if (!isEmpty(invoice.paymentTerms)) {
			return invoice.paymentTerms;
		}
		return get(props, "paymentTerms.paymentTermsList", []).filter((x, i, a) => a.indexOf(x) === i);
	};
	const status = get(props.invoicePersisted, "status");

	const statusCode = get(status, "code");

	const getInvoiceStatusLabel = (arg) => {
		// TODO: check this.
		if (isSupplierOnly(get(currentUser, "roles", []))) {
			if (TO_VERIFY_LIST.includes(arg.code)) {
				return t("invoice.status.underProcess");
			}
			if (TREATED.includes(arg.code)) {
				return t("invoice.status.processed");
			}
			if (REJECTED.includes(arg.code)) {
				return t("Rejected");
			}
		}
		return status.displayName ? t(status.displayName) : t(status.label);
	};

	const onExportError = () => {
		toast.error(t("invoice.export.error"));
	};

	const exportEXCEl = () => {
		setIsLoading({ ...isLoading, excel: true });
		dispatch(
			downloadInvoiceExcel(
				props.match.params.idInvoice,
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, excel: false });
				}
			)
		);
	};

	const exportXML = () => {
		setIsLoading({ ...isLoading, xml: true });
		dispatch(
			downloadXMLInvoice(
				props.match.params.idInvoice,
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, xml: false });
				}
			)
		);
	};

	const exportPDF = () => {
		setIsLoading({ ...isLoading, pdf: true });
		dispatch(
			downloadInvoice(
				props.match.params.idInvoice,
				"inv",
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, pdf: false });
				}
			)
		);
	};

	const exportEdifact = () => {
		setIsLoading({ ...isLoading, edi: true });
		dispatch(
			downloadEdifactInvoice(
				props.match.params.idInvoice,
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, edi: false });
				}
			)
		);
	};
	const exportIdoc = () => {
		setIsLoading({ ...isLoading, idoc: true });
		dispatch(
			downloadIdocInvoice(
				props.match.params.idInvoice,
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, idoc: false });
				}
			)
		);
	};
	const exportEC = () => {
		setIsLoading({ ...isLoading, ec: true });
		dispatch(
			exportSingleAccountingEntryExcel(
				props.match.params.idInvoice,
				() => {
					onExportError();
				},
				() => {
					setIsLoading({ ...isLoading, ec: false });
				}
			)
		);
	};

	const onCloseDialogReject = (error) => {
		if (error) {
			toast.error(t("invoice.detail.reject.failed"));
		} else {
			toast.success(t("invoice.detail.reject.success"));
		}
	};
	const initConfirmObject = () => {
		setConfirmObject({
			message: "",
			openConfirm: false,
			cancel: false,
			rectify: false,
			control: false,
			training: false,
		});
	};
	const onConfirm = () => {
		if (confirmObject.cancel) {
			initConfirmObject();
			window.location.reload(false);
		}

		if (confirmObject.rectify) {
			initConfirmObject();
			props.rectifyInvoice(props.match.params.idInvoice, formulaire, confirmObject.training);
		}
		if (confirmObject.control) {
			if (isEmpty(dataErrors)) {
				props.verifyInvoice(props.invoicePersisted && props.invoicePersisted.id);
				initConfirmObject();
			} else {
				initConfirmObject();
			}
		}
		if (confirmObject.export) {
			initConfirmObject();
			exportEdi();
		}
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	const updateLocation = (loca) => {
		setLocation(loca);
	};
	const addBillingLine = (index) => {
		if (rectify) {
			const _formulaire = JSON.parse(JSON.stringify(formulaire));
			const item = {
				id: {},
				lineNo: {},
				designation: {},
				quantity: {},
				vat: {},
				price: {},
				totalHt: {},
				deliveryDelay: {},
				codeArticle: {},
			};
			_formulaire.invoiceDto.billingLinesDtos.splice(index, 0, item);
			_formulaire.invoiceDto.billingLinesDtos[index].designation.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].lineNo.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].quantity.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].vat.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].price.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].price.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].totalHt.value = "";
			_formulaire.invoiceDto.billingLinesDtos[index].codeArticle.value = "";
			props.updateFormulaire(_formulaire);
		} else {
			const newRows = billingLines ? billingLines.slice() : [];
			newRows.push({});
			setBillingLines(newRows);
		}
	};
	const finalWarnings = warningsToFix.filter((w) => w.severity.toLowerCase().trim() === "warning");
	const finalErrors = warningsToFix.filter((w) => w.severity.toLowerCase().trim() === "error");

	const openProceed = () => {
		dispatch(fetchInvoice(props.invoicePersisted.id)).then((res) => {
			if (res.status === 200) {
				if (res.data.alertes?.alertes?.length > 0) {
					setOpenAlertesDialog(true);
				} else {
					dispatch(workflowProceed(props.invoicePersisted.id)).then((result) => {
						if (result?.response?.status === 400) {
							toast.error(result?.response?.data?.detail);
						} else dispatch(fetchInvoice(props.invoicePersisted.id));
					});
				}
			}
		});
	};

	const forceProceed = () => {
		dispatch(workflowForceProceed(props.invoicePersisted.id)).then((res) => {
			if (res.status === 200) {
				dispatch(fetchInvoice(props.invoicePersisted.id));
				setOpenAlertesDialog(false);
			} else if (res?.response?.status === 400) {
				toast.error(res?.response?.data?.detail);
				setOpenAlertesDialog(false);
			}
		});
	};

	const onConfirmExportStatus = () => {
		exportEdi();
	};
	const onCancelExportStatus = () => {
		setOpenExportSatus(false);
	};
	const totalTva = invoice?.additionalAmountsTVA?.reduce((a, v) => a + v.amount, 0);
	const totalCharge = (
		Math.round(
			(invoice?.additionalAmountsCharge?.reduce((a, v) => a + v.amount, 0) + Number.EPSILON) * 100
		) / 100
	).toFixed(2);

	return (
		<Grid container xs={12} style={{ padding: "10px 0" }}>
			<Grid container item xs={12} justify="space-evenly">
				<Grid container item xs={5}>
					<Grid
						container
						item
						xs
						direction="row"
						alignItems="flex-start"
						className={classes.detailHeader}
					>
						<Grid container item xs={3}>
							<Grid container item xs={12} justify="flex-start">
								<Button
									variant="contained"
									fullWidth
									color="secondary"
									className={classes.backBtn}
									onClick={() => history.push("/invoices", { filterContext, fromDetails: true })}
								>
									{t("return")}
								</Button>
							</Grid>
						</Grid>
						<Grid container item xs={9}>
							<Grid container xs={12} item alignItems="center" justify="flex-end">
								<Grid item>
									{props.invoicePersisted && props.invoicePersisted.status ? (
										<Chip
											align="left"
											className={isDigitalInvoice && classes.alignStatus}
											label={
												props.invoicePersisted &&
												props.invoicePersisted.status &&
												getInvoiceStatusLabel(props.invoicePersisted.status)
											}
											color="secondary"
										/>
									) : (
										<CircularLoader />
									)}
								</Grid>
								<Grid item>
									{isDigitalInvoice && !isEmptyOrNull(dataFromXml) ? (
										<DigitalSummary dataFromXml={dataFromXml} />
									) : null}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{(!isSupplier ||
					!isEmpty(get(invoice, "purchaseOrder.deliveryItems", [])) ||
					TREATED.includes(statusCode)) && (
					<Grid container item xs={rectify ? 6 : 5}>
						&nbsp;
					</Grid>
				)}
			</Grid>
			<Grid container item xs={12} className={classes.detailsContent} justify="space-evenly">
				{(!isSupplier ||
					!isEmpty(get(invoice, "purchaseOrder.deliveryItems", [])) ||
					// TODO: check this
					TREATED.includes(statusCode)) && (
					<Grid container item xs={5} className={classes.invoiceOrderTabs}>
						<Grid item xs={12}>
							<AppBar position="static" color="white" elevation={2} className={classes.rootAppbar}>
								<Tabs
									value={value}
									onChange={handleChangeFirstTab}
									indicatorColor="secondary"
									textColor="secondary"
									variant="fullWidth"
									aria-label="tab facture"
								>
									{(!isSupplier || TREATED.includes(statusCode)) && (
										<Tab label={t("Invoice Datas")} {...a11yProps(0)} />
									)}
									{!isSupplier && <Tab label={t("Order Datas")} {...a11yProps(1)} />}
									{!isEmpty(get(invoice, "purchaseOrder.deliveryItems", [])) &&
										get(props.invoicePersisted, "status.code") !== INTEGRATION && (
											// eslint-disable-next-line
											<Tab label={t("Delivery Details")} {...a11yProps(2)} />
										)}
									{(!isSupplier || TREATED.includes(statusCode)) && (
										<Tab label={t("Accounting Lines")} {...a11yProps(2)} />
									)}
								</Tabs>
							</AppBar>
							<TabPanel section={1} value={value} index={2} dir={theme.direction}>
								<AccountingLines
									rectify={rectify}
									formulaire={formulaire}
									invoice={invoice}
									addAccountingLine={addAccountingLine}
									handleAccountingLineChange={handleAccountingLineChange}
									deleteAccountingLine={deleteAccountingLine}
									glAccounts={props.glAccounts}
									costCenters={props.costCenters}
									paymentMethods={props.paymentMethods}
									handleAccountingChange={handleAccountingChange}
								/>
							</TabPanel>
							{/* TODO: Check this */}
							{(!isSupplier || TREATED.includes(statusCode)) && (
								<TabPanel
									section={1}
									value={value}
									index={0}
									dir={theme.direction}
									className={classes.tabInvoiceContainer}
								>
									{invoice ? (
										<Grid container item xs={12}>
											<Accordion
												style={{ margin: 10 }}
												className={classes.accordion}
												expanded={expandedDefaultPanel || expandedPanel === "panel3"}
												onChange={handleAccordionChange("panel3")}
											>
												<AccordionSummary
													expandIcon={<ExpandMore />}
													aria-controls="panel1a-content"
													id="panel1a-header"
													className={
														(expandedPanel && classes.accordionopen) ||
														(expandedDefaultPanel && classes.accordionDefault)
													}
												>
													<Grid
														container
														item
														alignItems="center"
														xs={10}
														className={classes.headerBillingCard}
													>
														<Grid item alignContent="center">
															<Typography variant="subtitle2" align="left" color="primary" xs={10}>
																{t("Invoice Details")}
																{"	"}
																{invoice && isDigitalInvoice && fromDigitalInvoice?.billingLines ? (
																	<DigitalChip />
																) : null}
															</Typography>
														</Grid>
													</Grid>
												</AccordionSummary>
												<AccordionDetails>
													<Grid container item xs={12} className={classes.tabInvoice} spacing={2}>
														<Grid
															container
															item
															xs={12}
															justify="center"
															alignItems="flex-end"
															spacing={1}
														>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("supplier")}
																	{"	"}
																	{invoice &&
																	isDigitalInvoice &&
																	fromDigitalInvoice?.supplierLabel ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	<>
																		{rectify ? (
																			<Autocomplete
																				id="suppliers-select"
																				value={supplier}
																				options={suppliers}
																				getOptionLabel={(sup) => sup.label}
																				onChange={handleSupplierFieldChange}
																				disableClearable
																				renderInput={(params) => (
																					<TextField
																						{...params}
																						onChange={(e) => {
																							handleSupplierInputChange(e.target.value);
																						}}
																					/>
																				)}
																			/>
																		) : (
																			<Typography
																				variant="body2"
																				align="left"
																				className={classes.blackColor}
																			>
																				{get(invoice, "supplier.label")}
																				<Divider />
																			</Typography>
																		)}
																	</>
																</Typography>
															</Grid>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("invoice Number")}
																	{"	"}
																	{invoice && isDigitalInvoice && fromDigitalInvoice?.invoiceNo ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	{isEditFacture || rectify ? (
																		<TextField
																			fullWidth
																			id="invoiceNo"
																			onClick={() => {
																				updateSelectedItem("invoiceNo");
																				updateLocation(formulaire.invoiceDto.invoiceNo.location);
																			}}
																			onChange={(e) => {
																				const _formulaire = JSON.parse(JSON.stringify(formulaire));
																				_formulaire.invoiceDto.invoiceNo.value = e.target.value;
																				if (isEmptyOrNull(e.target.value)) {
																					_formulaire.invoiceDto.invoiceNo.location = {
																						top: null,
																						right: null,
																						left: null,
																						bottom: null,
																					};
																				}
																				props.updateFormulaire(_formulaire);
																			}}
																			disabled={
																				invoice && isDigitalInvoice && fromDigitalInvoice?.invoiceNo
																			}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.invoiceNo
																					? get(invoice, "invoiceNo")
																					: formulaire.invoiceDto.invoiceNo.value
																			}
																			className={clsx(
																				"classes.resize",
																				rectify &&
																					isEmptyOrNull(formulaire.invoiceDto.invoiceNo.value) &&
																					classes.emptyInput
																			)}
																			InputProps={{
																				classes: {
																					input: classes.resize,
																				},
																			}}
																			InputLabelProps={{
																				shrink: true,
																			}}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{props.invoicePersisted && props.invoicePersisted.invoiceNo}

																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>
														</Grid>

														<Grid
															container
															item
															xs={12}
															justify="center"
															alignItems="flex-end"
															spacing={2}
														>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("VAT Number")}
																	{"	"}
																	{invoice &&
																	isDigitalInvoice &&
																	fromDigitalInvoice?.supplierVatNumber ? (
																		<DigitalChip />
																	) : null}
																	<>
																		{rectify ? (
																			<Input
																				className={classes.resize}
																				fullWidth
																				disabled={
																					invoice &&
																					isDigitalInvoice &&
																					(fromDigitalInvoice?.supplierVatNumber ||
																						fromDigitalInvoice?.supplierCompanySiret)
																				}
																				value={
																					isDigitalInvoice &&
																					(fromDigitalInvoice?.supplierVatNumber ||
																						fromDigitalInvoice?.supplierCompanySiret)
																						? get(invoice, "supplier.vatNumber")
																						: get(formulaire.invoiceDto, "supplier.vatNumber.value")
																				}
																			/>
																		) : (
																			<Typography
																				variant="body2"
																				align="left"
																				className={classes.blackColor}
																			>
																				{get(invoice, "supplier.vatNumber") ? (
																					get(invoice, "supplier.vatNumber")
																				) : (
																					<br />
																				)}
																				<Divider />
																			</Typography>
																		)}
																	</>
																</Typography>
															</Grid>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("invoice.detail.company_siret")}
																	{"	"}
																	{invoice &&
																	isDigitalInvoice &&
																	fromDigitalInvoice?.supplierCompanySiret ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	{isEditFacture || rectify ? (
																		<TextField
																			fullWidth
																			id="companySiret"
																			// type="date"
																			onClick={() => {
																				updateSelectedItem("companySiret");
																				updateLocation(
																					formulaire.invoiceDto.supplier.companySiret.location
																				);
																			}}
																			onChange={(e) => {
																				if (rectify) {
																					const _formulaire = JSON.parse(
																						JSON.stringify(formulaire)
																					);
																					_formulaire.invoiceDto.supplier.companySiret.value =
																						e.target.value;
																					if (isEmptyOrNull(e.target.value)) {
																						_formulaire.invoiceDto.supplier.companySiret.location = {
																							top: null,
																							right: null,
																							left: null,
																							bottom: null,
																						};
																					}
																					props.updateFormulaire(_formulaire);
																				} else {
																					setInvoice({
																						...invoice,
																						dueDate: e.target.value,
																					});
																				}
																			}}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.supplierCompanySiret
																					? get(invoice, "supplier.companySiret")
																					: get(
																							formulaire.invoiceDto,
																							"supplier.companySiret.value"
																					  )
																			}
																			className={classes.textField}
																			disabled={
																				invoice &&
																				isDigitalInvoice &&
																				fromDigitalInvoice?.supplierCompanySiret
																			}
																			InputProps={{
																				classes: {
																					input: classes.resize,
																				},
																			}}
																			InputLabelProps={{
																				shrink: true,
																			}}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{get(invoice, "supplier.companySiret") ? (
																				get(invoice, "supplier.companySiret")
																			) : (
																				<br />
																			)}
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>
														</Grid>
														<Grid container item xs={12} alignItems="flex-end" spacing={1}>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("client")}
																	{"	"}
																	{invoice &&
																	isDigitalInvoice &&
																	fromDigitalInvoice?.busnissesUnitName ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	{isEditFacture || rectify ? (
																		<Input
																			className={clsx(
																				"classes.resize",
																				rectify &&
																					isEmptyOrNull(
																						get(formulaire, "invoiceDto.busnissesUnitName.value")
																					) &&
																					classes.emptyInput
																			)}
																			fullWidth
																			onChange={(e) => {
																				if (rectify) {
																					const _formulaire = JSON.parse(
																						JSON.stringify(formulaire)
																					);
																					_formulaire.invoiceDto.busnissesUnitName.value =
																						e.target.value;
																					if (isEmptyOrNull(e.target.value)) {
																						_formulaire.invoiceDto.busnissesUnitName.location = {
																							top: null,
																							right: null,
																							left: null,
																							bottom: null,
																						};
																					}
																					props.updateFormulaire(_formulaire);
																				} else {
																					setInvoice({
																						...invoice,
																						busnissesUnitName: e.target.value,
																					});
																				}
																			}}
																			onClick={() => {
																				updateSelectedItem("busnissesUnitName");
																				updateLocation(
																					get(formulaire, "invoiceDto.busnissesUnitName.location")
																				);
																			}}
																			disabled={
																				invoice &&
																				isDigitalInvoice &&
																				fromDigitalInvoice?.busnissesUnitName
																			}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.busnissesUnitName
																					? get(invoice, "busnissesUnitName")
																					: rectify
																					? get(formulaire, "invoiceDto.busnissesUnitName.value")
																					: get(invoice, "busnissesUnitName")
																			}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{get(invoice, "busnissesUnitName") ? (
																				get(invoice, "busnissesUnitName")
																			) : (
																				<br />
																			)}
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("country")}
																	{"	"}
																	{invoice && isDigitalInvoice && fromDigitalInvoice?.country ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	{isEditFacture || rectify ? (
																		<Input
																			className={clsx(
																				"classes.resize",
																				rectify &&
																					isEmptyOrNull(
																						get(formulaire, "invoiceDto.country.value")
																					) &&
																					classes.emptyInput
																			)}
																			fullWidth
																			onChange={(e) => {
																				if (rectify) {
																					const _formulaire = JSON.parse(
																						JSON.stringify(formulaire)
																					);
																					_formulaire.invoiceDto.country.value = e.target.value;
																					if (isEmptyOrNull(e.target.value)) {
																						_formulaire.invoiceDto.country.location = {
																							top: null,
																							right: null,
																							left: null,
																							bottom: null,
																						};
																					}
																					props.updateFormulaire(_formulaire);
																				} else {
																					setInvoice({
																						...invoice,
																						country: e.target.value,
																					});
																				}
																			}}
																			onClick={() => {
																				updateSelectedItem("country");
																				updateLocation(
																					get(formulaire, "invoiceDto.country.location")
																				);
																			}}
																			disabled={
																				invoice && isDigitalInvoice && fromDigitalInvoice?.country
																			}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.country
																					? get(invoice, "country")
																					: rectify
																					? get(formulaire, "invoiceDto.country.value")
																					: get(invoice, "country")
																			}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{get(invoice, "country") ? get(invoice, "country") : <br />}
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>
														</Grid>
														<Grid
															container
															item
															xs={12}
															justify="center"
															alignItems="flex-end"
															spacing={1}
														>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("issueDate")}
																	{"	"}
																	{invoice && isDigitalInvoice && fromDigitalInvoice?.issueDate ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	{isEditFacture || rectify ? (
																		<TextField
																			fullWidth
																			id="issueDate"
																			// type="date"
																			onClick={() => {
																				updateSelectedItem("issueDate");
																				updateLocation(formulaire.invoiceDto.issueDate.location);
																			}}
																			onChange={(e) => {
																				if (rectify) {
																					const _formulaire = JSON.parse(
																						JSON.stringify(formulaire)
																					);
																					_formulaire.invoiceDto.issueDate.value = e.target.value;
																					if (isEmptyOrNull(e.target.value)) {
																						_formulaire.invoiceDto.issueDate.location = {
																							top: null,
																							right: null,
																							left: null,
																							bottom: null,
																						};
																					}
																					props.updateFormulaire(_formulaire);
																				} else {
																					setInvoice({
																						...invoice,
																						issueDate: e.target.value,
																					});
																				}
																			}}
																			disabled={
																				invoice && isDigitalInvoice && fromDigitalInvoice?.issueDate
																			}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.issueDate
																					? get(invoice, "issueDate")
																					: rectify
																					? formulaire.invoiceDto.issueDate.value
																					: get(invoice, "issueDate")
																			}
																			className={classes.textField}
																			InputProps={{
																				classes: {
																					input: clsx(
																						"classes.resize",
																						rectify &&
																							isEmptyOrNull(
																								formulaire.invoiceDto.issueDate.value
																							) &&
																							classes.emptyInput
																					),
																				},
																			}}
																			InputLabelProps={{
																				shrink: true,
																			}}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{get(invoice, "issueDate") ? (
																				get(invoice, "issueDate")
																			) : (
																				<br />
																			)}
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>

															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("dueDate")}
																	{"	"}
																	{invoice && isDigitalInvoice && fromDigitalInvoice?.dueDate ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	{isEditFacture || rectify ? (
																		<TextField
																			fullWidth
																			id="dueDate"
																			// type="date"
																			onClick={() => {
																				updateSelectedItem("dueDate");
																				updateLocation(formulaire.invoiceDto.dueDate.location);
																			}}
																			onChange={(e) => {
																				if (rectify) {
																					const _formulaire = JSON.parse(
																						JSON.stringify(formulaire)
																					);
																					_formulaire.invoiceDto.dueDate.value = e.target.value;
																					if (isEmptyOrNull(e.target.value)) {
																						_formulaire.invoiceDto.dueDate.location = {
																							top: null,
																							right: null,
																							left: null,
																							bottom: null,
																						};
																					}
																					props.updateFormulaire(_formulaire);
																				} else {
																					setInvoice({
																						...invoice,
																						dueDate: e.target.value,
																					});
																				}
																			}}
																			disabled={
																				invoice && isDigitalInvoice && fromDigitalInvoice?.dueDate
																			}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.dueDate
																					? get(invoice, "dueDate")
																					: rectify
																					? formulaire.invoiceDto.dueDate.value
																					: get(invoice, "dueDate")
																			}
																			className={classes.textField}
																			InputProps={{
																				classes: {
																					input: clsx(
																						"classes.resize",
																						rectify &&
																							isEmptyOrNull(formulaire.invoiceDto.dueDate.value) &&
																							classes.emptyInput
																					),
																				},
																			}}
																			InputLabelProps={{
																				shrink: true,
																			}}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{get(invoice, "dueDate") ? get(invoice, "dueDate") : <br />}
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>
														</Grid>

														<Grid container item xs={12} alignItems="flex-end" spacing={1}>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("orderNo")}
																	{"	"}
																	{invoice &&
																	isDigitalInvoice &&
																	fromDigitalInvoice?.purchaseOrderNo ? (
																		<DigitalChip />
																	) : null}
																	{isEditFacture || rectify ? (
																		<Input
																			className={clsx(
																				"classes.resize",
																				rectify &&
																					isEmptyOrNull(
																						formulaire.invoiceDto.purchaseOrderNo.value
																					) &&
																					classes.emptyInput
																			)}
																			fullWidth
																			onClick={() => {
																				updateSelectedItem("purchaseOrderNo");
																				updateLocation(
																					formulaire.invoiceDto.purchaseOrderNo.location
																				);
																			}}
																			disabled={
																				invoice &&
																				isDigitalInvoice &&
																				fromDigitalInvoice?.purchaseOrderNo
																			}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.purchaseOrderNo
																					? get(invoice, "purchaseOrderNo")
																					: rectify
																					? formulaire.invoiceDto.purchaseOrderNo.value
																					: get(invoice, "purchaseOrderNo")
																			}
																			onChange={(e) => {
																				if (rectify) {
																					const _formulaire = JSON.parse(
																						JSON.stringify(formulaire)
																					);
																					_formulaire.invoiceDto.purchaseOrderNo.value =
																						e.target.value;
																					if (isEmptyOrNull(e.target.value)) {
																						_formulaire.invoiceDto.purchaseOrderNo.location = {
																							top: null,
																							right: null,
																							left: null,
																							bottom: null,
																						};
																					}
																					props.updateFormulaire(_formulaire);
																				} else {
																					setOrder({ ...order, orderNo: e.target.value });
																				}
																			}}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{get(invoice, "purchaseOrderNo") ? (
																				get(invoice, "purchaseOrderNo")
																			) : (
																				<br />
																			)}
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("PaymentTerm")}
																	{"	"}
																	{invoice &&
																	isDigitalInvoice &&
																	fromDigitalInvoice?.paymentTermLabel ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	{isEditFacture || rectify ? (
																		<>
																			<SelectPayment
																				value={formulaire.invoiceDto.paymentTerm.label.value}
																				List={getPaymentTermsOptions()}
																				onChange={(paymentTermValue) => {
																					if (rectify) {
																						handlePaymentTermFieldChange(paymentTermValue);
																					} else {
																						setInvoice({
																							...invoice,
																							paymentTerm: paymentTermValue,
																						});
																					}
																				}}
																				onClick={() => {
																					updateSelectedItem("paymentTerm.label");
																					updateLocation(
																						formulaire.invoiceDto.paymentTerm.label.location
																					);
																				}}
																				disabled={
																					invoice &&
																					isDigitalInvoice &&
																					fromDigitalInvoice?.paymentTerm
																				}
																				className={clsx(
																					"classes.resize",
																					rectify &&
																						isEmptyOrNull(
																							formulaire.invoiceDto.paymentTerm.label.value
																						) &&
																						classes.emptyInput
																				)}
																			/>
																		</>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{get(invoice, "paymentTerm.label") ? (
																				get(invoice, "paymentTerm.label")
																			) : (
																				<br />
																			)}
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>
														</Grid>
														<Grid container item xs={12} alignItems="flex-end" spacing={1}>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("DeliverySlip")}
																	{"	"}
																	{invoice &&
																	isDigitalInvoice &&
																	fromDigitalInvoice?.deliverySlip ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	{isEditFacture || rectify ? (
																		<Input
																			className={clsx(
																				"classes.resize",
																				rectify &&
																					isEmptyOrNull(formulaire.invoiceDto.deliverySlip.value) &&
																					classes.emptyInput
																			)}
																			fullWidth
																			onChange={(e) => {
																				if (rectify) {
																					const _formulaire = JSON.parse(
																						JSON.stringify(formulaire)
																					);
																					_formulaire.invoiceDto.deliverySlip.value =
																						e.target.value;
																					if (isEmptyOrNull(e.target.value)) {
																						_formulaire.invoiceDto.deliverySlip.location = {
																							top: null,
																							right: null,
																							left: null,
																							bottom: null,
																						};
																					}
																					props.updateFormulaire(_formulaire);
																				} else {
																					setInvoice({
																						...invoice,
																						deliverySlip: e.target.value,
																					});
																				}
																			}}
																			onClick={() => {
																				updateSelectedItem("deliverySlip");
																				updateLocation(formulaire.invoiceDto.deliverySlip.location);
																			}}
																			disabled={
																				invoice &&
																				isDigitalInvoice &&
																				fromDigitalInvoice?.deliverySlip
																			}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.deliverySlip
																					? get(invoice, "deliverySlip")
																					: rectify
																					? formulaire.invoiceDto.deliverySlip.value
																					: get(invoice, "deliverySlip")
																			}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{get(invoice, "deliverySlip") ? (
																				get(invoice, "deliverySlip")
																			) : (
																				<br />
																			)}
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>

															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("currency")}
																	{"	"}
																	{invoice && isDigitalInvoice && fromDigitalInvoice?.currency ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	{isEditFacture || rectify ? (
																		<Input
																			className={clsx(
																				"classes.resize",
																				rectify &&
																					isEmptyOrNull(formulaire.invoiceDto.currency.value) &&
																					classes.emptyInput
																			)}
																			fullWidth
																			onChange={(e) => {
																				if (rectify) {
																					const _formulaire = JSON.parse(
																						JSON.stringify(formulaire)
																					);
																					_formulaire.invoiceDto.currency.value = e.target.value;
																					if (isEmptyOrNull(e.target.value)) {
																						_formulaire.invoiceDto.currency.location = {
																							top: null,
																							right: null,
																							left: null,
																							bottom: null,
																						};
																					}
																					props.updateFormulaire(_formulaire);
																				} else {
																					setInvoice({
																						...invoice,
																						currency: e.target.value,
																					});
																				}
																			}}
																			onClick={() => {
																				updateSelectedItem("currency");
																				updateLocation(formulaire.invoiceDto.currency.location);
																			}}
																			disabled={
																				invoice && isDigitalInvoice && fromDigitalInvoice?.currency
																			}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.currency
																					? get(invoice, "currency")
																					: rectify
																					? formulaire.invoiceDto.currency.value
																					: get(invoice, "currency")
																			}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{get(invoice, "currency") ? get(invoice, "currency") : <br />}
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>
														</Grid>
														<Grid
															container
															item
															xs={12}
															justify="center"
															alignItems="flex-end"
															spacing={1}
														>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("Delivery address")}
																	{"	"}
																	{invoice &&
																	isDigitalInvoice &&
																	fromDigitalInvoice?.deliveryAddress ? (
																		<DigitalChip />
																	) : null}
																	<br />
																	{isEditFacture || rectify ? (
																		<Input
																			className={clsx(
																				"classes.resize",
																				rectify &&
																					isEmptyOrNull(
																						formulaire.invoiceDto.deliveryAddress.value
																					) &&
																					classes.emptyInput
																			)}
																			fullWidth
																			rows={3}
																			multiline
																			onChange={(e) => {
																				if (rectify) {
																					const _formulaire = JSON.parse(
																						JSON.stringify(formulaire)
																					);
																					_formulaire.invoiceDto.deliveryAddress.value =
																						e.target.value;
																					if (isEmptyOrNull(e.target.value)) {
																						_formulaire.invoiceDto.deliveryAddress.location = {
																							top: null,
																							right: null,
																							left: null,
																							bottom: null,
																						};
																					}
																					props.updateFormulaire(_formulaire);
																				} else {
																					setInvoice({
																						...invoice,
																						deliveryAddress: e.target.value,
																					});
																				}
																			}}
																			onClick={() => {
																				updateSelectedItem("deliveryAddress");
																				updateLocation(
																					formulaire.invoiceDto.deliveryAddress.location
																				);
																			}}
																			disabled={
																				invoice &&
																				isDigitalInvoice &&
																				fromDigitalInvoice?.deliveryAddress
																			}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.deliveryAddress
																					? get(invoice, "deliveryAddress")
																					: rectify
																					? formulaire.invoiceDto.deliveryAddress.value
																					: get(invoice, "deliveryAddress")
																			}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{get(invoice, "deliveryAddress") ? (
																				get(invoice, "deliveryAddress")
																			) : (
																				<br />
																			)}
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>
															<Grid item xs={6}>
																<Typography variant="subtitle2" align="left" color="primary">
																	{t("Billing address")}
																	{"	"}
																	{invoice &&
																	isDigitalInvoice &&
																	fromDigitalInvoice?.billingAddress ? (
																		<DigitalChip />
																	) : null}
																	{isEditFacture || rectify ? (
																		<Input
																			className={clsx(
																				"classes.resize",
																				rectify &&
																					isEmptyOrNull(
																						formulaire.invoiceDto.billingAddress.value
																					) &&
																					classes.emptyInput
																			)}
																			fullWidth
																			rows={3}
																			multiline
																			onClick={() => {
																				updateSelectedItem("billingAddress");
																				updateLocation(
																					formulaire.invoiceDto.billingAddress.location
																				);
																			}}
																			onChange={(e) => {
																				if (rectify) {
																					const _formulaire = JSON.parse(
																						JSON.stringify(formulaire)
																					);
																					_formulaire.invoiceDto.billingAddress.value =
																						e.target.value;
																					if (isEmptyOrNull(e.target.value)) {
																						_formulaire.invoiceDto.billingAddress.location = {
																							top: null,
																							right: null,
																							left: null,
																							bottom: null,
																						};
																					}
																					props.updateFormulaire(_formulaire);
																				} else {
																					setInvoice({
																						...invoice,
																						billingAddress: e.target.value,
																					});
																				}
																			}}
																			disabled={
																				invoice &&
																				isDigitalInvoice &&
																				fromDigitalInvoice?.billingAddress
																			}
																			value={
																				isDigitalInvoice && fromDigitalInvoice?.billingAddress
																					? get(invoice, "billingAddress")
																					: rectify
																					? formulaire.invoiceDto.billingAddress.value
																					: get(invoice, "billingAddress")
																			}
																		/>
																	) : (
																		<Typography
																			variant="body2"
																			align="left"
																			className={classes.blackColor}
																		>
																			{rectify
																				? formulaire.invoiceDto.billingAddress.value
																				: invoice && invoice.billingAddress}
																			<br />
																			<Divider />
																		</Typography>
																	)}
																</Typography>
															</Grid>

															{invoice?.invoiceRibs &&
																invoice?.invoiceRibs.map((invoiceRib, index) => (
																	<Grid item xs={6}>
																		<Typography variant="subtitle2" align="left" color="primary">
																			{get(invoiceRib, "type")}

																			{isEditFacture || rectify ? (
																				<>
																					<Input
																						fullWidth
																						multiline
																						// id="invoiceRib"
																						onClick={() => {
																							updateSelectedItem(`invoiceRibs.${index}`);
																							updateLocation(
																								formulaire.invoiceDto?.invoiceRibs[index]?.rib
																									?.location
																							);
																						}}
																						onChange={(e) => {
																							const _formulaire = JSON.parse(
																								JSON.stringify(formulaire)
																							);
																							_formulaire.invoiceDto.invoiceRibs[index].rib.value =
																								e.target.value;

																							if (isEmptyOrNull(e.target.value)) {
																								_formulaire.invoiceDto.invoiceRibs[
																									index
																								].rib.location = {
																									top: null,
																									right: null,
																									left: null,
																									bottom: null,
																								};
																							}
																							props.updateFormulaire(_formulaire);
																						}}
																						value={
																							formulaire.invoiceDto?.invoiceRibs[index]?.rib?.value
																						}
																						className={clsx(
																							"classes.resize",
																							rectify &&
																								isEmptyOrNull(
																									formulaire.invoiceDto?.invoiceRibs[index]?.rib
																										?.value
																								) &&
																								classes.emptyInput
																						)}
																						InputProps={{
																							classes: {
																								input: classes.resize,
																							},
																						}}
																						InputLabelProps={{
																							shrink: true,
																						}}
																					/>
																				</>
																			) : (
																				<Typography
																					variant="body2"
																					align="left"
																					className={classes.blackColor}
																				>
																					{rectify
																						? formulaire.invoiceDto?.invoiceRibs[index]?.rib?.value
																						: get(invoiceRib, "rib")}
																					<br />
																					<Divider />
																				</Typography>
																			)}
																		</Typography>
																	</Grid>
																))}
														</Grid>
													</Grid>
												</AccordionDetails>
											</Accordion>
											{/* TODO: Check this */}
											{(!isSupplier || TREATED.includes(statusCode)) && (
												<>
													<Grid
														id="billing-line-container"
														container
														xs={12}
														direction="row"
														justify="space-between"
													>
														<Accordion
															style={{ margin: 10, minWidth: 480 }}
															className={classes.accordion}
															expanded={expandedPanel === "panel1"}
															onChange={handleAccordionChange("panel1")}
														>
															<AccordionSummary
																expandIcon={<ExpandMore />}
																aria-controls="panel1a-content"
																id="panel1a-header"
																className={expandedPanel && classes.accordionopen}
															>
																<Grid
																	container
																	item
																	alignItems="center"
																	xs={12}
																	className={classes.headerBillingCard}
																>
																	<Grid item alignContent="center">
																		<Typography
																			variant="subtitle2"
																			align="left"
																			color="primary"
																			xs={10}
																		>
																			{t("Commands")}
																			{"	"}
																			{invoice &&
																			isDigitalInvoice &&
																			fromDigitalInvoice?.billingLines ? (
																				<DigitalChip />
																			) : null}
																		</Typography>
																	</Grid>
																</Grid>
															</AccordionSummary>
															<AccordionDetails>
																<Grid container item alignItems="center" justify="center" xs={12}>
																	<TableContainer
																		component={Paper}
																		elevation={0}
																		className={classes.tableContainer}
																	>
																		<Table aria-label="spanning table">
																			<TableHead>
																				<TableRow>
																					<TableCell>
																						<Typography
																							className={classes.tableCell5}
																							variant="subtitle2"
																							align="left"
																						>
																							{t("itemNLineBC")}
																						</Typography>
																					</TableCell>
																					<TableCell>
																						<Typography
																							className={classes.tableCell3}
																							variant="subtitle2"
																							align="left"
																						>
																							{t("designation")}
																						</Typography>
																					</TableCell>
																					<TableCell>
																						<Typography
																							className={classes.tableCell2}
																							variant="subtitle2"
																							align="left"
																						>
																							{t("quantity")}
																						</Typography>
																					</TableCell>
																					<TableCell>
																						<Typography
																							className={classes.tableCell1}
																							variant="subtitle2"
																							align="left"
																						>
																							{t("unitPrice")}
																						</Typography>
																					</TableCell>
																					<TableCell>
																						<Typography
																							className={classes.tableCell2}
																							variant="subtitle2"
																							align="left"
																						>
																							{t("vat")}
																						</Typography>
																					</TableCell>
																					<TableCell>
																						<Typography
																							className={classes.tableCell4}
																							variant="subtitle2"
																							align="left"
																						>
																							{t("totalHt")}
																						</Typography>
																					</TableCell>
																					<TableCell>
																						{rectify &&
																							formulaire.invoiceDto.billingLinesDtos &&
																							isEmpty(formulaire.invoiceDto.billingLinesDtos) && (
																								<IconButton
																									aria-label="add"
																									onClick={() => addBillingLine(0)}
																									disabled={
																										isDigitalInvoice &&
																										fromDigitalInvoice?.billingLines
																									}
																								>
																									<AddIcon />
																								</IconButton>
																							)}
																					</TableCell>
																				</TableRow>
																			</TableHead>
																			<TableBody>
																				{rectify ? (
																					<>
																						{formulaire.invoiceDto.billingLinesDtos &&
																							!isEmpty(formulaire.invoiceDto.billingLinesDtos) &&
																							formulaire.invoiceDto.billingLinesDtos.map(
																								(row, i) => (
																									<TableRow key={row.id}>
																										<>
																											<TableCell>
																												<Input
																													className={clsx(
																														"classes.tableCell1",
																														isEmptyOrNull(
																															formulaire.invoiceDto
																																.billingLinesDtos[i].lineNo?.value
																														) && classes.emptyInput
																													)}
																													fullWidth
																													value={row.lineNo?.value}
																													onClick={() => {
																														updateSelectedItem(`table.${i}.lineNo`);
																														updateLocation(
																															formulaire.invoiceDto
																																.billingLinesDtos[i].lineNo
																																?.location
																														);
																													}}
																													onChange={(event) => {
																														const _formulaire = JSON.parse(
																															JSON.stringify(formulaire)
																														);

																														if (
																															!get(
																																_formulaire.invoiceDto
																																	.billingLinesDtos[i],
																																"lineNo"
																															)
																														)
																															_formulaire.invoiceDto.billingLinesDtos[
																																i
																															] = {
																																..._formulaire.invoiceDto
																																	.billingLinesDtos[i],
																																lineNo: {
																																	value: null,
																																	fieldFullName: null,
																																	updatable: true,
																																	location: {
																																		top: null,
																																		right: null,
																																		bottom: null,
																																		left: null,
																																		pageId: null,
																																	},
																																},
																															};
																														_formulaire.invoiceDto.billingLinesDtos[
																															i
																														].lineNo.value = event.target.value;

																														props.updateFormulaire(_formulaire);
																													}}
																													disabled={
																														fromDigitalInvoice?.billingLines[i]
																															?.lineNo
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												<Input
																													className={clsx(
																														"classes.tableCell3",
																														rectify &&
																															isEmptyOrNull(
																																formulaire.invoiceDto
																																	.billingLinesDtos[i].designation
																																	.value
																															) &&
																															classes.emptyInput
																													)}
																													fullWidth
																													multiline
																													value={row.designation.value}
																													onClick={() => {
																														updateSelectedItem(
																															`table.${i}.designation`
																														);
																														updateLocation(
																															formulaire.invoiceDto
																																.billingLinesDtos[i].designation
																																.location
																														);
																													}}
																													onChange={(event) => {
																														const _formulaire = JSON.parse(
																															JSON.stringify(formulaire)
																														);
																														_formulaire.invoiceDto.billingLinesDtos[
																															i
																														].designation.value =
																															event.target.value;

																														props.updateFormulaire(_formulaire);
																													}}
																													disabled={
																														fromDigitalInvoice?.billingLines[i]
																															?.designation
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												{" "}
																												<Input
																													className={clsx(
																														"classes.tableCell2",
																														isEmptyOrNull(
																															formulaire.invoiceDto
																																.billingLinesDtos[i].quantity.value
																														) && classes.emptyInput
																													)}
																													fullWidth
																													value={row.quantity.value}
																													onClick={() => {
																														updateSelectedItem(
																															`table.${i}.quantity`
																														);
																														updateLocation(
																															formulaire.invoiceDto
																																.billingLinesDtos[i].quantity
																																.location
																														);
																													}}
																													onChange={(event) => {
																														const _formulaire = JSON.parse(
																															JSON.stringify(formulaire)
																														);
																														_formulaire.invoiceDto.billingLinesDtos[
																															i
																														].quantity.value = event.target.value;

																														props.updateFormulaire(_formulaire);
																													}}
																													disabled={
																														fromDigitalInvoice?.billingLines[i]
																															?.quantity
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												<Input
																													className={clsx(
																														"classes.tableCell1",
																														isEmptyOrNull(
																															formulaire.invoiceDto
																																.billingLinesDtos[i].price.value
																														) && classes.emptyInput
																													)}
																													fullWidth
																													value={row.price.value}
																													onClick={() => {
																														updateSelectedItem(`table.${i}.price`);
																														updateLocation(
																															formulaire.invoiceDto
																																.billingLinesDtos[i].price.location
																														);
																													}}
																													onChange={(event) => {
																														const _formulaire = JSON.parse(
																															JSON.stringify(formulaire)
																														);
																														_formulaire.invoiceDto.billingLinesDtos[
																															i
																														].price.value = event.target.value;

																														props.updateFormulaire(_formulaire);
																													}}
																													disabled={
																														fromDigitalInvoice?.billingLines[i]
																															?.price
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												{" "}
																												<Input
																													className={clsx(
																														"classes.tableCell2",
																														isEmptyOrNull(
																															formulaire.invoiceDto
																																.billingLinesDtos[i].vat.value
																														) && classes.emptyInput
																													)}
																													fullWidth
																													value={row.vat.value}
																													onClick={() => {
																														updateSelectedItem(`table.${i}.vat`);
																														updateLocation(
																															formulaire.invoiceDto
																																.billingLinesDtos[i].vat.location
																														);
																													}}
																													onChange={(event) => {
																														const _formulaire = JSON.parse(
																															JSON.stringify(formulaire)
																														);
																														_formulaire.invoiceDto.billingLinesDtos[
																															i
																														].vat.value = event.target.value;

																														props.updateFormulaire(_formulaire);
																													}}
																													disabled={
																														fromDigitalInvoice?.billingLines[i]?.vat
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												{" "}
																												<Input
																													className={clsx(
																														"classes.tableCell4",
																														rectify &&
																															isEmptyOrNull(
																																formulaire.invoiceDto
																																	.billingLinesDtos[i].totalHt.value
																															) &&
																															classes.emptyInput
																													)}
																													fullWidth
																													value={row.totalHt.value}
																													onClick={() => {
																														updateSelectedItem(
																															`table.${i}.totalHt`
																														);
																														updateLocation(
																															formulaire.invoiceDto
																																.billingLinesDtos[i].totalHt
																																.location
																														);
																													}}
																													onChange={(event) => {
																														const _formulaire = JSON.parse(
																															JSON.stringify(formulaire)
																														);
																														_formulaire.invoiceDto.billingLinesDtos[
																															i
																														].totalHt.value = event.target.value;

																														props.updateFormulaire(_formulaire);
																													}}
																													disabled={
																														fromDigitalInvoice?.billingLines[i]
																															?.totalHt
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												<IconButton
																													aria-label="add"
																													onClick={() => {
																														const _formulaire = JSON.parse(
																															JSON.stringify(formulaire)
																														);
																														_formulaire.invoiceDto.billingLinesDtos.splice(
																															i,
																															1
																														);
																														props.updateFormulaire(_formulaire);
																													}}
																													disabled={isDigitalInvoice}
																												>
																													<DeleteOutline />
																												</IconButton>
																											</TableCell>
																											<TableCell>
																												<IconButton
																													aria-label="add"
																													onClick={() => addBillingLine(i + 1)}
																													disabled={isDigitalInvoice}
																												>
																													<AddIcon />
																												</IconButton>
																											</TableCell>
																										</>
																									</TableRow>
																								)
																							)}
																					</>
																				) : (
																					<>
																						{billingLines &&
																							!isEmpty(billingLines) &&
																							billingLines.map((row, i) => (
																								<TableRow key={row.id}>
																									{isEditFacture ? (
																										<>
																											<TableCell>
																												{" "}
																												<Input
																													className={classes.tableCell1}
																													fullWidth
																													value={row.lineNo}
																													onChange={(event) =>
																														onChangeBilling(event, i, "lineNo")
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												{" "}
																												<Input
																													className={classes.tableCell3}
																													fullWidth
																													value={row.designation}
																													onChange={(event) =>
																														onChangeBilling(event, i, "designation")
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												{" "}
																												<Input
																													className={classes.tableCell2}
																													fullWidth
																													value={row.quantity}
																													onChange={(event) =>
																														onChangeBilling(event, i, "quantity")
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												{" "}
																												<Input
																													className={classes.tableCell1}
																													fullWidth
																													value={row.price}
																													onChange={(event) =>
																														onChangeBilling(event, i, "price")
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												{" "}
																												<Input
																													className={classes.tableCell2}
																													fullWidth
																													value={row.vat}
																													onChange={(event) =>
																														onChangeBilling(event, i, "vat")
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												{" "}
																												<Input
																													className={classes.tableCell1}
																													fullWidth
																													value={row.totalHt}
																													onChange={(event) =>
																														onChangeBilling(event, i, "totalHt")
																													}
																												/>
																											</TableCell>
																											<TableCell>
																												<IconButton
																													aria-label="add"
																													onClick={() => {
																														const newRows = billingLines.slice();
																														newRows.splice(i, 1);
																														setBillingLines(newRows);
																													}}
																												>
																													<DeleteOutline />
																												</IconButton>
																											</TableCell>
																										</>
																									) : (
																										<>
																											<Tooltip title={row.lineNo} arrow>
																												<TableCell>
																													<Typography
																														className={classes.tableCell1}
																														variant="subtitle"
																														align="left"
																													>
																														{minimizeValueCode(row.lineNo)}
																													</Typography>
																												</TableCell>
																											</Tooltip>
																											<Tooltip title={row.designation} arrow>
																												<TableCell>
																													<Typography
																														className={classes.tableCell3}
																														variant="subtitle"
																														align="left"
																													>
																														{minimizeValueDesignation(
																															row.designation
																														)}
																													</Typography>
																												</TableCell>
																											</Tooltip>
																											<TableCell>
																												<Typography
																													className={classes.tableCell2}
																													variant="subtitle"
																													align="left"
																												>
																													{row.quantity}
																												</Typography>
																											</TableCell>
																											<TableCell>
																												<Typography
																													className={classes.tableCell1}
																													variant="subtitle"
																													align="left"
																												>
																													{row.price}
																												</Typography>
																											</TableCell>
																											<TableCell>
																												<Typography
																													className={classes.tableCell2}
																													variant="subtitle"
																													align="left"
																												>
																													{row.vat}
																												</Typography>
																											</TableCell>
																											<TableCell>
																												<Typography
																													className={classes.tableCell1}
																													variant="subtitle"
																													align="left"
																												>
																													{row.totalHt}
																												</Typography>
																											</TableCell>
																										</>
																									)}
																								</TableRow>
																							))}
																					</>
																				)}
																			</TableBody>
																		</Table>
																	</TableContainer>
																</Grid>
															</AccordionDetails>
														</Accordion>
													</Grid>
													<Grid
														id="billing-line-container"
														container
														xs={12}
														direction="row"
														justify="space-between"
													>
														<Accordion
															style={{ margin: 10 }}
															className={classes.accordion}
															expanded={expandedPanel === "panel2"}
															onChange={handleAccordionChange("panel2")}
														>
															<AccordionSummary
																expandIcon={<ExpandMore />}
																aria-controls="panel1a-content"
																id="panel1a-header"
																className={expandedPanel && classes.accordionopen}
															>
																<Grid
																	container
																	item
																	alignItems="center"
																	xs={10}
																	className={classes.headerBillingCard}
																>
																	<Grid item alignContent="center">
																		<Typography
																			variant="subtitle2"
																			align="left"
																			color="primary"
																			xs={9}
																		>
																			{t("Total Amounts")}
																			{"	"}
																			{invoice &&
																			isDigitalInvoice &&
																			fromDigitalInvoice?.billingLines ? (
																				<DigitalChip />
																			) : null}
																		</Typography>
																	</Grid>
																</Grid>
															</AccordionSummary>
															<AccordionDetails>
																<Grid xs={12}>
																	{rectify ? (
																		<AmountsComponent
																			formulaire={formulaire}
																			rectify={rectify}
																			location={location}
																			setLocation={updateLocation}
																			invoice={invoice}
																			fromDigitalInvoice={fromDigitalInvoice}
																			isSelectedField={isSelectedField}
																		/>
																	) : (
																		<>
																			<TableContainer elevation={2} component={Paper}>
																				<Table className={classes.MontantTab}>
																					<TableBody>
																						{invoice &&
																						invoice.additionalAmountsHT &&
																						invoice.additionalAmountsHT.length ? (
																							invoice.additionalAmountsHT.map((row, index) => (
																								<TableRow>
																									<>
																										<TableCell align="left" colSpan={2}>
																											<Typography
																												variant="subtitle2"
																												align="left"
																												color="primary"
																											>
																												{t("totalHt")}{" "}
																												{index !== 0 ? index + 1 : ""}
																											</Typography>
																										</TableCell>
																										<TableCell align="right" colSpan={2}>
																											<Typography
																												variant="body2"
																												align="right"
																												className={classes.blackColor}
																											>
																												{get(row, "amount")}{" "}
																												{get(invoice, "currencySymbol")}
																											</Typography>
																										</TableCell>
																									</>
																								</TableRow>
																							))
																						) : (
																							<TableRow>
																								<>
																									<TableCell align="left" colSpan={2}>
																										<Typography
																											variant="subtitle2"
																											align="left"
																											color="primary"
																										>
																											{t("totalHt")}{" "}
																										</Typography>
																									</TableCell>
																									<TableCell align="right" colSpan={2}>
																										<Typography
																											variant="body2"
																											align="right"
																											className={classes.blackColor}
																										>
																											{0} {get(invoice, "currencySymbol")}
																										</Typography>
																									</TableCell>
																								</>
																							</TableRow>
																						)}

																						<TableRow>
																							<TableCell align="left" colSpan={2}>
																								<Typography
																									variant="subtitle2"
																									align="left"
																									color="primary"
																								>
																									{t("VAT amount")}
																								</Typography>
																							</TableCell>

																							<TableCell align="right" colSpan={2}>
																								<Typography
																									variant="body2"
																									align="right"
																									className={classes.blackColor}
																								>
																									{invoice &&
																										invoice.additionalAmountsTVA &&
																										currencyFormat(totalTva)}{" "}
																									{get(invoice, "currencySymbol")}
																								</Typography>
																							</TableCell>
																							<IconButton
																								onClick={() => {
																									setOpenTva(!openTva);
																								}}
																							>
																								{!openTva ? <AddIcon /> : <Remove />}
																							</IconButton>
																						</TableRow>
																						{openTva && (
																							<TableRow>
																								<TableCell
																									colSpan={4}
																									style={{ borderBottom: "none", marginLeft: 0 }}
																									align="left"
																								>
																									<Table aria-label="customized table">
																										<TableHead>
																											<TableRow>
																												<TableCell align="left">
																													{t("Base ")}
																												</TableCell>
																												<TableCell align="center">
																													{t("Associated VAT amount")}
																												</TableCell>
																												<TableCell align="right">
																													{t("% applied")}
																												</TableCell>
																											</TableRow>
																										</TableHead>
																										<TableBody>
																											{invoice &&
																												invoice.additionalAmountsTVA &&
																												invoice.additionalAmountsTVA.length &&
																												invoice.additionalAmountsTVA.map((row) => (
																													<TableRow>
																														<TableCell
																															className={classes.tableCell1}
																															variant="subtitle"
																															align="left"
																														>
																															{get(row, "tvaDetailsDto.base")}
																														</TableCell>
																														<TableCell
																															align="center"
																															className={classes.tableCell2}
																															variant="subtitle"
																														>
																															{get(row, "amount")}
																															{get(invoice, "currencySymbol")}
																														</TableCell>
																														<TableCell
																															align="right"
																															className={classes.tableCell2}
																															variant="subtitle"
																														>
																															{get(row, "tvaDetailsDto.percentage")}
																															%
																														</TableCell>
																													</TableRow>
																												))}
																										</TableBody>
																									</Table>
																								</TableCell>
																							</TableRow>
																						)}
																						<TableRow>
																							<TableCell align="left" colSpan={2}>
																								<Typography
																									variant="subtitle2"
																									align="left"
																									color="primary"
																								>
																									{t("TTC amount")}
																								</Typography>
																							</TableCell>
																							<TableCell align="right" colSpan={2}>
																								<Typography
																									variant="body2"
																									align="right"
																									className={classes.blackColor}
																								>
																									{get(invoice, "total")}{" "}
																									{get(invoice, "currencySymbol")}{" "}
																								</Typography>
																							</TableCell>
																						</TableRow>
																						<TableRow>
																							<TableCell align="left" colSpan={2}>
																								<Typography
																									variant="subtitle2"
																									align="left"
																									color="primary"
																								>
																									{t("Prepay amount")}
																								</Typography>
																							</TableCell>
																							<TableCell align="right" colSpan={2}>
																								<Typography
																									variant="body2"
																									align="right"
																									className={classes.blackColor}
																								>
																									{get(invoice, "prepayAmount")}{" "}
																									{get(invoice, "currencySymbol")}{" "}
																								</Typography>
																							</TableCell>
																						</TableRow>
																						<TableRow>
																							<TableCell align="left" colSpan={2}>
																								<Typography
																									variant="subtitle2"
																									align="left"
																									color="primary"
																								>
																									{t("invoice_charge")}
																								</Typography>
																							</TableCell>
																							<TableCell align="right" colSpan={2}>
																								<Typography
																									variant="body2"
																									align="right"
																									className={classes.blackColor}
																								>
																									{invoice &&
																										invoice.additionalAmountsCharge &&
																										totalCharge}{" "}
																									{get(invoice, "currencySymbol")}
																								</Typography>
																							</TableCell>
																							<IconButton
																								onClick={() => {
																									setOpenCharge(!openCharge);
																								}}
																							>
																								{!openCharge ? <AddIcon /> : <Remove />}
																							</IconButton>
																						</TableRow>
																						{openCharge &&
																							invoice &&
																							invoice.additionalAmountsCharge &&
																							invoice.additionalAmountsCharge.map((row, index) => (
																								<TableRow style={{ marginLeft: 100 }}>
																									<TableCell colSpan={2}>
																										<Typography
																											variant="body1"
																											align="center"
																											color="primary"
																										>
																											{t("invoice_charge")}{" "}
																											{index !== 0 ? index + 1 : ""}
																										</Typography>
																									</TableCell>
																									<TableCell colSpan={2}>
																										{get(row, "amount")}{" "}
																										{get(invoice, "currencySymbol")}
																									</TableCell>
																								</TableRow>
																							))}
																						<TableRow>
																							<TableCell align="left" colSpan={2}>
																								<Typography
																									variant="subtitle2"
																									align="left"
																									color="primary"
																								>
																									{t("Discount")}
																								</Typography>
																							</TableCell>
																							<TableCell align="right" colSpan={2}>
																								<Typography
																									variant="body2"
																									align="right"
																									className={classes.blackColor}
																								>
																									{get(invoice, "discount")}{" "}
																									{get(invoice, "currencySymbol")}{" "}
																								</Typography>
																							</TableCell>
																						</TableRow>
																						<TableRow>
																							<TableCell align="left" colSpan={2}>
																								<Typography
																									variant="subtitle2"
																									align="left"
																									color="primary"
																								>
																									{t("Net payable")}
																								</Typography>
																							</TableCell>
																							<TableCell align="right" colSpan={2}>
																								<Typography
																									variant="body2"
																									align="right"
																									className={classes.blackColor}
																								>
																									{get(invoice, "netToPay")
																										? get(invoice, "netToPay")
																										: get(invoice, "total")}{" "}
																									{invoice && invoice.currencySymbol}
																								</Typography>
																							</TableCell>
																						</TableRow>
																					</TableBody>
																				</Table>
																				<Divider style={{ margin: 20 }} />
																				<>
																					{dataErrors &&
																						!isEmpty(dataErrors) &&
																						get(props.invoicePersisted, "status.code") !==
																							INTEGRATION &&
																						dataErrors.map((row) => (
																							<Grid item className={classes.alert}>
																								<Alert severity="error" fullWidth>
																									{row.withParam
																										? t(row.message, { VatNumber: row.param })
																										: t(row)}
																								</Alert>
																							</Grid>
																						))}
																				</>
																			</TableContainer>
																		</>
																	)}
																</Grid>
															</AccordionDetails>
														</Accordion>
													</Grid>
												</>
											)}
										</Grid>
									) : (
										<CircularLoader />
									)}
								</TabPanel>
							)}
							<TabPanel section={1} value={value} index={1} dir={theme.direction}>
								<Grid container item xs={12} className={classes.orderTab} spacing={4}>
									<Accordion
										style={{ margin: 10 }}
										className={classes.accordion}
										expanded={expandedDefaultPanelOrder || expandedPanelOrder === "panel4"}
										onChange={handleAccordionOrderChange("panel4")}
									>
										<Grid item alignContent="center">
											<AccordionSummary
												expandIcon={<ExpandMore />}
												aria-controls="panel1a-content"
												id="panel1a-header"
												className={
													(expandedPanelOrder && classes.accordionopen) ||
													(expandedDefaultPanelOrder && classes.accordionDefault)
												}
											>
												<Grid
													container
													item
													alignItems="center"
													xs={12}
													className={classes.headerBillingCard}
												>
													<Grid item alignContent="center">
														<Typography variant="subtitle2" align="center" color="primary" xs={10}>
															{t("Order Details")}
															{"	"}
															{invoice && isDigitalInvoice && fromDigitalInvoice?.billingLines ? (
																<DigitalChip />
															) : null}
														</Typography>
													</Grid>
												</Grid>
											</AccordionSummary>
										</Grid>
										<AccordionDetails>
											<Grid
												container
												item
												xs={12}
												justify="center"
												alignItems="flex-end"
												spacing={4}
											>
												<Grid item xs={6}>
													<Typography variant="subtitle2" align="left" color="primary">
														{t("orderNo")}
														{"	"}
														{invoice && isDigitalInvoice && fromDigitalInvoice?.purchaseOrderNo ? (
															<DigitalChip />
														) : null}
														{isEditFacture || rectify ? (
															<Input
																className={clsx(
																	"classes.resize",
																	rectify &&
																		isEmptyOrNull(formulaire.invoiceDto.order.orderNo.value) &&
																		classes.emptyInput
																)}
																fullWidth
																onClick={() => {
																	updateSelectedItem("order.orderNo");
																	updateLocation(formulaire.invoiceDto.order.orderNo.location);
																}}
																disabled={
																	invoice && isDigitalInvoice && fromDigitalInvoice?.purchaseOrderNo
																}
																value={
																	isDigitalInvoice && fromDigitalInvoice?.purchaseOrderNo
																		? get(invoice, "purchaseOrderNo")
																		: rectify
																		? formulaire.invoiceDto.order.orderNo.value
																		: get(invoice, "purchaseOrderNo")
																}
																onChange={(e) => {
																	if (rectify) {
																		const _formulaire = JSON.parse(JSON.stringify(formulaire));
																		_formulaire.invoiceDto.order.orderNo.value = e.target.value;
																		if (isEmptyOrNull(e.target.value)) {
																			_formulaire.invoiceDto.order.orderNo.location = {
																				top: null,
																				right: null,
																				left: null,
																				bottom: null,
																			};
																		}
																		props.updateFormulaire(_formulaire);
																	} else {
																		setOrder({ ...order, orderNo: e.target.value });
																	}
																}}
															/>
														) : (
															<Typography
																variant="body2"
																align="left"
																className={classes.blackColor}
															>
																{get(invoice, "purchaseOrderNo") ? (
																	get(invoice, "purchaseOrderNo")
																) : (
																	<br />
																)}
																<Divider />
															</Typography>
														)}
													</Typography>
												</Grid>

												<Grid item xs={6}>
													<Typography variant="subtitle2" align="left" color="primary">
														{t("issueDate")}
														<br />
														<Typography variant="body2" align="left" className={classes.blackColor}>
															{get(invoice, "purchaseOrder.issueDate") ? (
																get(invoice, "purchaseOrder.issueDate")
															) : (
																<br />
															)}

															<Divider />
														</Typography>
													</Typography>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>
									<Accordion
										style={{ margin: 10 }}
										className={classes.accordion}
										expanded={expandedPanelOrder === "panel5"}
										onChange={handleAccordionOrderChange("panel5")}
									>
										<AccordionSummary
											expandIcon={<ExpandMore />}
											aria-controls="panel1a-content"
											id="panel1a-header"
											className={expandedPanelOrder && classes.accordionopen}
										>
											<Grid
												container
												item
												alignItems="center"
												xs={12}
												className={classes.headerBillingCard}
											>
												<Grid item alignContent="center">
													<Typography variant="subtitle2" align="left" color="primary">
														{t("order Lines")}
													</Typography>
												</Grid>
											</Grid>
										</AccordionSummary>
										<AccordionDetails>
											<Grid container item alignItems="center" justify="center" xs={12}>
												<TableContainer
													component={Paper}
													elevation={0}
													className={classes.tableContainer}
												>
													<Table aria-label="spanning table">
														<TableHead>
															<TableRow>
																<TableCell>
																	<Typography
																		className={classes.tableCell5}
																		variant="subtitle2"
																		align="left"
																	>
																		{t("itemNLineBC")}
																	</Typography>
																</TableCell>
																<TableCell>
																	<Typography
																		className={classes.tableCell2}
																		variant="subtitle2"
																		align="left"
																	>
																		{t("designation")}
																	</Typography>
																</TableCell>

																<TableCell>
																	<Typography
																		className={classes.tableCell2}
																		variant="subtitle2"
																		align="left"
																	>
																		{t("quantity")}
																	</Typography>
																</TableCell>

																<TableCell>
																	<Typography
																		className={classes.tableCell1}
																		variant="subtitle2"
																		align="left"
																	>
																		{t("unitePriceHT")}
																	</Typography>
																</TableCell>

																<TableCell>
																	<Typography
																		className={classes.tableCell2}
																		variant="subtitle2"
																		align="left"
																	>
																		{t("vat")}
																	</Typography>
																</TableCell>

																<TableCell>
																	<Typography
																		className={classes.tableCell1}
																		variant="subtitle2"
																		align="left"
																	>
																		{t("price TTC")}
																	</Typography>
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															<>
																{get(invoice, "purchaseOrder.purchaseOrderLines") &&
																	!isEmpty(get(invoice, "purchaseOrder.purchaseOrderLines")) &&
																	invoice.purchaseOrder.purchaseOrderLines.map((row, i) => (
																		<TableRow key={i}>
																			<>
																				<Tooltip title={row.lineNo} arrow>
																					<TableCell>
																						<Typography
																							className={classes.tableCell1}
																							variant="subtitle"
																							align="left"
																						>
																							{minimizeValueCode(row.lineNo)}
																						</Typography>
																					</TableCell>
																				</Tooltip>
																				<Tooltip title={row.article} arrow>
																					<TableCell>
																						<Typography
																							className={classes.tableCell3}
																							variant="subtitle"
																							align="left"
																						>
																							{minimizeValueDesignation(row.article)}
																						</Typography>
																					</TableCell>
																				</Tooltip>
																				<TableCell>
																					<Typography
																						className={classes.tableCell2}
																						variant="subtitle"
																						align="left"
																					>
																						{row.quantity}
																					</Typography>
																				</TableCell>
																				<TableCell>
																					<Typography
																						className={classes.tableCell2}
																						variant="subtitle"
																						align="left"
																					>
																						{row.price}
																					</Typography>
																				</TableCell>
																				<TableCell>
																					<Typography
																						className={classes.tableCell1}
																						variant="subtitle"
																						align="left"
																					>
																						{row.vat}
																					</Typography>
																				</TableCell>
																				<TableCell>
																					<Typography
																						className={classes.tableCell1}
																						variant="subtitle"
																						align="left"
																					>
																						{row.vat !== null
																							? getTotalTTC(row.totalHt, row.vat)
																							: row.totalHt}
																					</Typography>
																				</TableCell>
																			</>
																		</TableRow>
																	))}
															</>
															<TableRow>
																<TableCell rowSpan={6} />
																<TableCell rowSpan={6} />
																<TableCell rowSpan={6} />
																<TableCell rowSpan={6} />
																<TableCell
																	align="right"
																	className={clsx(rectify && classes.rectifyHover)}
																>
																	{t("Total HT")}
																</TableCell>
																<TableCell align="right">
																	{get(invoice, "purchaseOrder.amountHT")}{" "}
																	{get(invoice, "purchaseOrder.currency")}
																</TableCell>
															</TableRow>
															<TableRow>
																<TableCell
																	align="right"
																	className={clsx(rectify && classes.rectifyHover)}
																>
																	{t("VAT amount")}
																</TableCell>
																<TableCell align="right">
																	{get(invoice, "purchaseOrder.amountTVA")}{" "}
																	{get(invoice, "purchaseOrder.currency")}
																</TableCell>
															</TableRow>
															<TableRow>
																<TableCell
																	align="right"
																	className={clsx(rectify && classes.rectifyHover)}
																>
																	{t("Total amount")}
																</TableCell>
																<TableCell align="right">
																	{get(invoice, "purchaseOrder.totalAmount")}{" "}
																	{get(invoice, "purchaseOrder.currency")}
																</TableCell>
															</TableRow>
														</TableBody>
													</Table>
												</TableContainer>
											</Grid>
										</AccordionDetails>
									</Accordion>
								</Grid>
							</TabPanel>
							{!isEmpty(get(invoice, "purchaseOrder.deliveryItems", [])) &&
								get(props.invoicePersisted, "status.code") !== INTEGRATION && (
									<TabPanel section={2} value={value} index={2} dir={theme.direction}>
										<DeliveryItemsSection
											deliveryItems={get(invoice, "purchaseOrder.deliveryItems")}
											orderNo={get(invoice, "purchaseOrder.orderNo")}
										/>
									</TabPanel>
								)}
						</Grid>
						<Grid item xs={12} id="error-warning-container">
							{warningsToFix.map((alert) => (
								<Grid item xs={12} className={classes.alert}>
									<Alert
										severity={alert.severity.toLowerCase()}
										variant="filled"
										fullWidth
										elevation={0}
									>
										{alert.message}
									</Alert>
								</Grid>
							))}
						</Grid>
					</Grid>
				)}

				<Grid container item xs={rectify ? 6 : 5}>
					<Grid item xs={12}>
						{!isDocumentInternal && rectify ? (
							<DesignCanvas
								selectedField={(e) => {
									setSelectedFied(e);
								}}
								invoiceId={props.match.params.idInvoice}
								location={location}
								previewFile={previewFile}
								isSelectedField={isSelectedField}
								t={t}
							/>
						) : (
							<>
								<AppBar
									position="relative"
									color="default"
									elevation={0}
									className={classes.rootAppbar}
								>
									<Tabs
										className={classes.root}
										value={scValue}
										onChange={handleChangeSecondTab}
										indicatorColor="secondary"
										textColor="secondary"
										variant="fullWidth"
										aria-label="full width tabs example2"
									>
										{/* eslint-disable-next-line */}
										<Tab label={t("Invoice overview")} {...a11yProps(0)} />
										{/* eslint-disable-next-line */}
										{!isSupplier && <Tab label={t("History")} {...a11yProps(1)} />}
										<Tab label={t("Invoice attachements")} {...a11yProps(isSupplier ? 1 : 2)} />
										{invoice && invoice.multiAttachement && (
											<Tooltip title={t("check attachements")} arrow>
												<Grid container xs={1} alignItems="center" align="right">
													<ErrorOutlineIcon style={{ color: theme.custom.color.warning }} />
												</Grid>
											</Tooltip>
										)}
									</Tabs>
								</AppBar>
								<TabPanel
									style={{
										minHeight: "800px",
									}}
									section={2}
									value={scValue}
									index={0}
									dir={theme.direction}
								>
									{previewFile ? (
										mimeType === "application/pdf" && previewFile ? (
											<>
												<PDFViewer
													backend={PDFJSBackend}
													src={previewFile}
													style={{
														width: "100%",
														height: "800px",
													}}
												/>
											</>
										) : (
											<img src={previewFile} className={classes.previewImg} alt="preview" />
										)
									) : (
										<CircularLoader />
									)}
								</TabPanel>
								{!isSupplier && (
									<TabPanel section={2} value={scValue} index={1} dir={theme.direction}>
										<History
											idInvoice={props.match.params.idInvoice}
											emailSubject={invoice && invoice.emailSubject}
											emailDate={invoice && invoice.emailDeliveredDate}
											{...props} //eslint-disable-line
										/>
									</TabPanel>
								)}
								<TabPanel
									section={3}
									value={scValue}
									index={isSupplier ? 1 : 2}
									dir={theme.direction}
									style={{
										minHeight: "800px",
									}}
								>
									<Attachements />
								</TabPanel>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid container item xs={12} className={classes.detailsContent} justify="space-evenly">
				{!rectify ? (
					<>
						<Grid container item xs={5} justify="space-evenly">
							{get(props, "invoicePersisted.status.code") === "EXPORTED" && settings.loaded && (
								<Grid container item xs={12} spacing={1} style={{ marginTop: "20px" }}>
									{getDataFromSettings("EXPORT_EXCEL") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportEXCEl();
												}}
												disabled={isLoading?.excel}
											>
												{isLoading?.excel && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_EXCEL")}
											</Button>
										</Grid>
									)}

									{getDataFromSettings("EXPORT_XML") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportXML();
												}}
												disabled={isLoading?.xml}
											>
												{isLoading?.xml && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_XML")}
											</Button>
										</Grid>
									)}
									{getDataFromSettings("EXPORT_PDF") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportPDF();
												}}
												disabled={isLoading?.pdf}
											>
												{isLoading?.pdf && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_PDF")}
											</Button>
										</Grid>
									)}
									{getDataFromSettings("EXPORT_EDI") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportEdifact();
												}}
												disabled={isLoading?.edi}
											>
												{isLoading?.edi && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_EDI")}
											</Button>
										</Grid>
									)}
									{getDataFromSettings("EXPORT_IDOC") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportIdoc();
												}}
												disabled={isLoading?.idoc}
											>
												{isLoading?.idoc && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_IDOC")}
											</Button>
										</Grid>
									)}

									{getDataFromSettings("EXPORT_EC") && (
										<Grid item xs={4}>
											<Button
												variant="contained"
												fullWidth
												color="secondary"
												onClick={() => {
													exportEC();
												}}
												disabled={isLoading?.ec}
											>
												{isLoading?.ec && <CircularProgress size={14} />}&nbsp;
												{t("EXPORT_EC")}
											</Button>
										</Grid>
									)}
								</Grid>
							)}
							{!isSupplier && (
								<Grid
									container
									item
									xs={12}
									direction="row"
									justify="center"
									style={{ marginTop: "20px" }}
								>
									{props.invoicePersisted?.allowedActions.rectify && (
										<Grid item xs={4} style={{ padding: "2px" }}>
											<Button
												variant="outlined"
												fullWidth
												className={classes.button}
												color="secondary"
												onClick={() => setRectify(!rectify)}
											>
												{t("Correct Extraction")}
											</Button>
										</Grid>
									)}
									{props.invoicePersisted?.allowedActions.proceed && (
										<Grid item xs={4} style={{ padding: "2px" }}>
											<Button
												variant="contained"
												fullWidth
												className={classes.button}
												color="secondary"
												onClick={openProceed}
											>
												{t("invoice.action.proceed")}
											</Button>
										</Grid>
									)}
									{props.invoicePersisted?.allowedActions.reject && (
										<Grid item xs={4} style={{ padding: "2px" }}>
											<Button
												variant="contained"
												fullWidth
												className={classes.button}
												color="secondary"
												onClick={() => setOpenRejectPopup(true)}
											>
												{t("invoice.action.reject")}
											</Button>
											<DialogReject
												open={openRejectPopup}
												setOpen={setOpenRejectPopup}
												onClose={onCloseDialogReject}
												{...props}
											/>
										</Grid>
									)}
								</Grid>
							)}
							<Grid container item xs={5} className={classes.header} spacing={1}>
								<Grid item xs={4} style={{ padding: "0 4px 0 0" }}>
									{" "}
								</Grid>
							</Grid>
						</Grid>
						<Grid container item xs={5} justify="space-evenly">
							&nbsp;
						</Grid>
					</>
				) : (
					<>
						<Grid container item xs={5} spacing={1}>
							<div style={{ position: "absolute", marginTop: 20, width: "39%" }}>
								<Grid
									container
									item
									xs={12}
									className={classes.rectifyHeader}
									direction="row-reverse"
								>
									<Grid item xs={4} style={{ paddingRight: 10 }}>
										<Button
											variant="outlined"
											fullWidth
											color="secondary"
											disabled={
												(props.invoicePersisted?.supplier &&
													props.invoicePersisted?.supplier?.lockedTraining) ||
												isDocumentInternal
											}
											onClick={() => {
												setConfirmObject({
													message: t("invoice.detail.message.rectify"),
													openConfirm: true,
													rectify: true,
													training: true,
												});
											}}
										>
											{t("invoice.detail.apply.validate")}
										</Button>
									</Grid>
									<Grid item xs={4} style={{ paddingRight: 10 }}>
										<Button
											variant="outlined"
											fullWidth
											color="secondary"
											onClick={() => {
												setConfirmObject({
													message: t("invoice.detail.message.rectify"),
													openConfirm: true,
													rectify: true,
													training: false,
												});
											}}
										>
											{t("Apply Changes")}
										</Button>
									</Grid>

									<Grid item xs={4} style={{ paddingRight: 10 }}>
										<Button
											variant="outlined"
											fullWidth
											className={classes.rejectButton}
											onClick={() => {
												setConfirmObject({
													message: t("CancelActionMessage"),
													openConfirm: true,
													cancel: true,
												});
											}}
										>
											{t("cancel")}
										</Button>
									</Grid>
								</Grid>
							</div>
						</Grid>
						<Grid container item xs={6} justify="space-evenly">
							&nbsp;
						</Grid>
					</>
				)}
			</Grid>

			<Dialog
				open={openAlertesDialog}
				onClose={() => setOpenAlertesDialog(!openAlertesDialog)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xs"
				fullWidth
			>
				<DialogTitle style={{ color: "#0da8ad" }} id="alert-dialog-title">
					<Typography variant="h6">{t("completeerrors")}</Typography>
					<Typography variant="h6">{t("completeerrorsphrase")}</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid direction="row" justify="space-between" container>
						<Grid>
							{warningsToFix.length > 0 && (
								<>
									<Grid container alignItems="center">
										<ErrorIcon htmlColor="red" />
										&nbsp;
										<b>
											{t("errors")}: {finalErrors.length}
										</b>
									</Grid>
									<Grid container alignItems="center">
										<FlagIcon htmlColor="orange" />
										&nbsp;
										<b>
											{t("warnings")}: {finalWarnings.length}
										</b>
									</Grid>
								</>
							)}
						</Grid>

						<img src={Erreur} style={{ width: 200, height: 180 }} alt="error icon" />
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container xs={12} direction="row" justify="space-evenly">
						<Button
							onClick={() => {
								setOpenAlertesDialog(!openAlertesDialog);
								document.getElementById("error-warning-container").scrollIntoView();
							}}
							type="submit"
							color="secondary"
							variant="contained"
						>
							{t("checkerrors")}
						</Button>
						{props.invoicePersisted?.allowedActions.forceProceed && (
							<Button onClick={forceProceed} type="submit" color="secondary" variant="outlined">
								{t("completetask")}
							</Button>
						)}
					</Grid>
				</DialogActions>
			</Dialog>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
			<ConfirmDialog
				message={t("confirmation.ExportStatus")}
				open={openExportSatus}
				onConfirm={onConfirmExportStatus}
				onCancel={onCancelExportStatus}
				isLoading={nextStatusLoading}
			/>

			<Grid container item xs={12} direction="column" className={classes.headerAction} />
		</Grid>
	);
}

const mapStateToProps = (state) => {
	const dataQueris = state.requests.queries;
	const dataMutations = state && state.mutations;
	const { formulaire } = state;
	return {
		statusList:
			dataQueris.LIST_FETCH_STATUS &&
			dataQueris.LIST_FETCH_STATUS.data &&
			dataQueris.LIST_FETCH_STATUS.data.invoiceStatusList,
		supplier: dataQueris.FETCH_SUPPLIER && dataQueris.FETCH_SUPPLIER.data,
		status: dataQueris.FETCH_STATUS && dataQueris.FETCH_STATUS.data,
		paymentTerm: dataQueris.FETCH_PAYMENT_TERM && dataQueris.FETCH_PAYMENT_TERM.data,
		paymentTerms: dataQueris.FETCH_PAYMENT_TERMS && dataQueris.FETCH_PAYMENT_TERMS.data,
		invoicePersisted: dataQueris.FETCH_INVOICE && dataQueris.FETCH_INVOICE.data,
		response: dataQueris.RESPONSE && dataQueris.RESPONSE.data,
		rejectionMotifs: dataQueris.FETCH_REJECTION_MOTIFS && dataQueris.FETCH_REJECTION_MOTIFS.data,
		rejectionError:
			dataMutations &&
			dataMutations.CREATE_INVOICE_REJECTION &&
			dataMutations.CREATE_INVOICE_REJECTION.error,
		selectedItem: formulaire.selectedItem,
		glAccounts: dataQueris.LIST_FETCH_GLACCOUNTS && dataQueris.LIST_FETCH_GLACCOUNTS.data,
		costCenters: dataQueris.LIST_FETCH_COSTCENTERS && dataQueris.LIST_FETCH_COSTCENTERS.data,
		paymentMethods:
			dataQueris.LIST_FETCH_PAYMENTMETHODS && dataQueris.LIST_FETCH_PAYMENTMETHODS.data,
	};
};

const mapDispatchToProps = {
	fetchInvoice,
	fetchDocumentInvoice,
	updateInvoice,
	fetchRejectionMotifs,
	createInvoiceRejection,
	verifyInvoice,
	fetchPaymentTerms,
	fetchBillingLines,
	rectifyInvoice,
	topayInvoice,
	updateFormulaire,
	createInvoiceApproval,
	fetchGlAccounts,
	fetchCostCenters,
	fetchPaymentMethods,
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
