export default {
	lastName: "الاسم",
	firstName: "الاسم الاول",
	email: "البريد الإلكتروني",
	Username: "اسم المستخدم",
	userFunction: "وظيفة",
	job: "وظيفة",
	manageUsers: "إدارةالمستخدم",
	manageSuppliers: "منطقة الموردين",
	manageClients: "منطقة العملاء",
	supplier_name: "المورد",
	client_name: "اسم العميل",
	manageGroups: "إدارة المجموعات",
	manageRoles: "إدارة الأدوار",
	manageWorkflow: "إدارة سير العمل",
	manageLicenseAddOn: "إدارة التراخيص والوظائف الإضافية",
	status: "الحالة",
	ACTIVE: "نشط",
	WAITING: "في الانتظار",
	DISABLE: "تعطيل",
	add_user: "إضافة مستخدم",
	add_user_button: "إضافة مستخدم",
	add_group_button: "أضف منظمة تجارية",
	add_supplier_button: "إضافة مورد",
	add_client_button: "إضافة الزبون",
	add_supplier: "إضافة مورد",
	edit_supplier: "تعديل المورد",
	add_client: "إضافة عميل",
	sendEmailCreationUserMessage:
		"تم إرسال بريد إلكتروني به اسم المستخدم وكلمة المرور إلى المستخدم ليتمكن من الاتصال",
	CancelActionMessage: "هل أنت متأكد أنك تريد إلغاء هذا الإجراء؟",
	searchUser: "بحث",
	userCivility: "الكياسة",
	administrator: "المسؤول",
	user: "وكيل",
	Mr: "سيدي",
	Ms: "الانسة",
	Mrs: "سيدة",
	enableDisableActionMessage: "هل أنت متأكد أنك تريد تمكين / تعطيل هذا المستخدم",
	edit_user: "تعديل المستخدم",
	// validation messages
	lastName_required: "مطلوب اسم",
	lastName_notValid: "الاسم غير صالح",
	supplierName_notValid: "اسم المورد غير صالح",
	phone_notValid: "الهاتف غير صالح",
	firstName_required: "الاسم الأول مطلوب",
	firstName_notValid: "الاسم الأول غير صالح",
	email_required: "البريد الالكتروني مطلوب",
	supplier_required: "اسم المورد مطلوب",
	email_notValid: "البريد الإلكتروني غير صالح",
	siren_notValid: "رمز SIREN غير صالح",
	siret_notValid: "شركة SIRET غير صالحة",
	url_notValid: "رابط غير صالح",
	name_notValid: "الاسم غير صالح",
	supplier_error: "حدث خطأ أثناء تسجيل الموفر",
	supplier_created:
		"المورد أضاف بنجاح.إذا لم يتم عرض المورد على قائمة البائع الخاصة بك، فيرجى الاتصال بالمسؤول",
	supplier_updated: "تم تحديث المورد بنجاح!",
	supplier_update_error: "فشل تحديث تفاصيل المورد!",
	job_required: "الكياسة إلزامية",
	civility_required: "الكياسة إلزامية",
	doctype_required: "نوع المستند مطلوب",
	deleteUserActionMessage: "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
	deleteUsersActionMessage: "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
	"admin.settings": "إعدادات",
	"admin.commercialOrganization": "التنظيم التجاري",
	"admin.email-config": "تكوين البريد الإلكتروني",
	"admin.ftp-config": "تكوين بروتوكول نقل الملفات",
	"admin.import-config": "تكوين الاستيراد",
	Host: "مضيف",
	Port: "منفذ",
	Email: "البريد الإلكتروني",
	Password: "كلمه السر",
	"Read Folder": "مجلد القراءة",
	"Processing folder": "مجلد المعالجة",
	"Success folder": "مجلد النجاح",
	"Exception folder": "مجلد الاستثناءات",
	"admin.config.update-settings": "تم تحديث المعلمات بنجاح",
	"admin.config.update-message": "تم تحديث تكوين البريد الإلكتروني بنجاح",
	"admin.invoice.AUTO_EXPORT": "تصدير تلقائي",
	"admin.AMOUNT_TOLERANCE": "هامش الخطأ",
	"admin.MAXIMUM_SIZE": "الحد الأقصى لحجم الملف تحميل",
	supplierTraining: "تعلم المورد",
	clientTraining: "تعلم العملاء",
	deleteTraining: "حذف التدريب",
	deleteTrainingMessage: "هل أنت متأكد أنك تريد حذف التدريب من هذا المزود",
	successdeleteTrainingMessage: "تم حذف التدريب بنجاح",
	"admin.download-buttons": "الإجراءات على الفواتير",
	"admin.download-buttons-description":
		"اختر الإجراءات التي تريد السماح بها في فواتيرك في البوابة.",
	"admin.download-button.excel": "Excel تنزيل بتنسيق",
	"admin.download-button.xml": " XML تنزيل بتنسيق",
	"admin.download-button.pdf": "PDF تنزيل بتنسيق",
	"admin.download-button.edi": "EDI تنزيل بتنسيق",
	"admin.invoice.AUTO_EXPORT-description":
		"تعمل هذه المعلمة على تنشيط التصدير التلقائي للفواتير المستخرجة بالكامل والتي لا تحتوي على رسالة خطأ.",
	"admin.invoice.AMOUNT_TOLERANCE-description":
		"يتيح لك هذا الحقل تكوين تفاوت في حسابات مجاميع الفاتورة. سيتم عرض رسائل الخطأ على أساس هذا التفاوت.",
	"admin.MAXIMUM_SIZE-description": "الحد الأقصى لحجم المستند المراد تنزيله بالميغابايت",
	"admin.display-indicators": "عرض المؤشرات في لوحة القيادة",
	"admin.display-indicators-description": "اختر المؤشرات التي تريد تصورها في NovyPOM Dashboard.",
	"admin.setting.my_tasks": "مهامي",
	"My orders": "طلباتي",
	"admin.setting.invoices-exported": "الفواتير  المصدرة",
	"admin.setting.invoices-not-exported": "الفواتير غير المصدرة",
	"admin.setting.last-actions": "آخر الإجراءات",
	"admin.setting.load-invoices": "تحميل الفواتير",
	"admin.setting.last-5-invoices": "آخر 5 فواتير",
	"admin.setting.download-pdf": "تحميل PDF",
	"admin.setting.delete": "حذف",
	"admin.setting.delete-all": "حذف جميع الفواتير",
	"admin.setting.AMOUNT_TOLERANCE.required": "مطلوب هامش الخطأ",
	"admin.setting.relaunch-extraction": "أعد تشغيل الاستخراج",
	"admin.export-buttons": "أزرار التنزيل",
	"admin.invoice.export-buttons-description":
		"قم بإعداد عرض اختصارات التنزيلات الممكنة بعد تصدير فاتورة. ستظهر الأزرار بعد التصدير في تفاصيل الفاتورة.",
	"admin.setting.export_entry_account_excel": "تصدير إدخال محاسبة Excel",
	"admin.extraction-level-settings": "أهمية الحقول المستخرجة (مستوى الاستخراج)",
	"admin.extraction-level-settings-desciption":
		"حدد أهمية المجالات التي تبدو أكثر أهمية بالنسبة لك والمجالات التي تعتبرها ضرورية. سيؤخذ ذلك في الاعتبار عند حساب مستوى الاستخراج",
	"admin.level-settings": "إعدادات مستوى الاستخراج",
	"admin.extraction-level.champs": "مجالات",
	"admin.extraction-level.status": "حالة",
	"admin.extraction-level.mandatory": "أساسي",
	"admin.extraction-level.ignored": "مهمل",
	"admin.extraction-level.important": "مهم",
	"admin.setting.message.confirm": "?هل أنت متأكد أنك تريد تحديث هذه الإعدادات",
	"admin.user.confirm.message.edit": "هل أنت متأكد أنك تريد تحديث هذا المستخدم?",
	"admin.user.confirm.message.add": "هل أنت متأكد أنك تريد إضافة هذا المستخدم?",
	"admin.supplier.confirm.message.add": "?هل أنت متأكد أنك تريد إضافة هذا المستخدم",
	"admin.client.confirm.message.add": "?هل أنت متأكد أنك تريد إضافة هذا المستخدم",
	"admin.supplier.confirm.message.edit": "هل أنت متأكد أنك تريد تحديث هذا المستخدم?",
	"admin.config.message.confirm": "هل أنت متأكد أنك تريد تحديث إعدادات البريد الإلكتروني ؟",
	"admin.extraction.message.confirm": " هل أنت متأكد أنك تريد تحديث مستويات الاستخراج هذه ؟",
	"invoice.user.updated.success": "تم تحديث المستخدم بنجاح",
	"admin.config.ftp.message.confirm": "هل أنت متأكد أنك تريد تعديل هذا التكوين لخادم FTP؟",
	"admin.config.ftp.update-message": "تم تحديث تكوين خادم FTP بنجاح",
	agent_accounting: "وكيل محاسبة",
	agent_purchase: "وكيل شراء",
	"admin.settings.invoice": "فاتورة",
	"admin.settings.order": "أمر",
	"admin.settings.invoices": "إعدادات الفواتير",
	"admin.settings.orders": "إعدادات الطلبات",
	"admin.order.download-buttons": "الإجراءات على الطلبات",
	"admin.order.download-buttons-description":
		"اختر الإجراءات التي تريد السماح بها لأوامرك في البوابة.",
	"admin.order.AUTO_EXPORT-description":
		"تعمل هذه المعلمة على تنشيط التصدير التلقائي للأمر المستخرج بالكامل وليس به رسائل خطأ.",
	"admin.order.AMOUNT_TOLERANCE-description":
		"يسمح لك هذا الحقل بتعيين تفاوت في حساب مجاميع الطلب. سيتم عرض رسائل الخطأ على أساس هذا التفاوت.",
	"admin.setting.last-5-orders": "آخر 5 طلبات",
	"admin.order.setting.delete-all": "احذف جميع الطلبات",
	"admin.order.export-buttons-description":
		"قم بإعداد عرض اختصارات التنزيلات الممكنة بعد تصدير الطلب. ستظهر الأزرار بعد التصدير في تفاصيل الطلب.",
	"admin.settings.order.upload": "تحميل الطلبات",
	"order.exported": "أوامر التصدير",
	"order.unexported": "الطلبات غير المصدرة",
	"admin.AUTO_EXPORT": "تصدير تلقائي",
	"admin.download-button.journal-entry": "قم بتنزيل Excel Journal Entry",
	"pagination.rows.per.page": "عدد الصفوف في الصفحة:",
	"pagination.of": "من",
	fix_errors: "لا يمكن المتابعة ، يرجى التحقق من الأخطاء التالية",
	automatic_transition: "السماح بالانتقال التلقائي",
	"rule.orderMissing": "رقم الطلب غير موجود",
	"rule.orderNumberAbsent": "رقم الطلب غير متوفر",
	"rule.quantityInvalid": "الكمية المفوترة أعلى من الكمية في الأمر",
	"rule.unitPriceLineInvalid": "يختلف سعر الوحدة المفوتر في الفاتورة عن سعر الوحدة للأمر",
	"rule.orderLineMissing": "سطر الطلب غير موجود",
	"rule.orderLineMissings": "أسطر الطلب غير موجودة",
	"rule.totalAmountInvalid": "المبلغ الإجمالي للفاتورة أكبر من المبلغ الإجمالي للأمر",
	"rule.cumulativeOrderInvalid": "ستكون الكمية المحسوبة أعلى من كمية الأمر بعد التصدير",
	"rule.accumulatedQuantityLineInvalid": "ستكون الكمية المحسوبة أعلى من كمية الأمر بعد التصدير",
	"rule.incoherenceAmounts":
		"يختلف إجمالي المبلغ المدرج عن المبلغ المعفى من الضرائب بالإضافة إلى ضريبة القيمة المضافة",
	"rule.totalEmpty": "إجمالي مبلغ الفاتورة فارغ",
	"rule.DeliveryMissing": "إيصال التسليم غير موجود",
	"rule.DeliveredQuantityInvalid":
		"الكمية المفوترة أعلى من الكمية المسلمة كما هو مذكور في إيصال التسليم",
	"rule.sumTotalHtInvalid":
		"يختلف إجمالي المبلغ المعفى من الضرائب عن مجموع الخطوط المعفاة من الضرائب",
	"rule.duplicatedInvoice": "الفاتورة موجودة بالفعل",
	"rule.nettopayInvalid": "صافي المبلغ الواجب دفعه ليس متسقا",
	"rule.PrepayAmountInvalid": "المبلغ المدفوع مسبقا غير متسق",
	"rule.nettopayExist": "الصافي الواجب دفعه غير موجود",
	transition: "الانتقال",
	transitionbuttonlabel: "تسمية الزر",
	addstatus: "أضف الحالة",
	saveworkflow: "حفظ سير العمل",
	asksaveworkflow: "هل أنت متأكد أنك تريد حفظ سير العمل؟",
	update: "تحديث",
	deletestatus: "حذف الحالة",
	deletetransition: "حذف الانتقال",
	updatestatus: "تحديث الحالة",
	updatetransition: "تحديث الانتقال",
	blockedby: "حظر من قبل",
	appliedrules: "القواعد المطبقة",
	errorautotransition: "لا يمكنك الحصول على أكثر من انتقال تلقائي واحد",
	AbleCorrect: "مؤهل للتصحيح اليدوي",
	workflow: "سير العمل",
	"workflow.delete.error": "هذه الحالة لها انتقال {{count}} حذف الانتقال قبل إزالة الحالة.",
	"workflow.status.success": "تحديث سير العمل بنجاح!",
	"workflow.source.status.litige.message": "يجب ألا يكون الوضع في التقاضي الانتقال الصادر",
	"workflow.property.isLitige": "حالة التقاضي",
	manager: "مدير",
	"admin.client.confirm.message.edit": "هل تريد حقا تغيير هذا المستخدم؟",
	super_user: "سوبر المستخدم",
	"user.super_user.add": "أضف سوبر للمستخدمين",
	"user.super_user.edit": "تحرير المستخدمين سوبر",
	managerSuperusers: "إدارة المستخدمين السوبر",
	"admin.download-button.idoc": "IDOC تنزيل بتنسيق",
	"admin.setting.download-idoc": "تحميل IDOC",
	"admin.setting.download-edi": "تحميل EDI",
	"admin.workflow": "سير العمل",
	"admin.setting.download-ubl": "تحميل UBL",
	"admin.setting.download-cii": "تحميل CII16B",
	"admin.setting.download-xcbl": "تحميل XCBL",
	"admin.setting.download-facturx": "تحميل Factur-X",
	"user.field.commercialOrganization": "التنظيم التجاري",
	"admin.commercialOrganization.name": "اسم",
	"admin.commercialOrganization.description": "وصف",
	"admin.commercialOrganization.suppliers": "الموردون",
	"admin.commercialOrganization.clients": "عملاء",
	"admin.commercialOrganization.add": "يؤثر",
	"admin.commercialOrganization.remove": "يلجأ",
	"admin.commercialOrganization.edit": "هل أنت متأكد أنك تريد تحرير هذه المؤسسة التجارية؟",
	"admin.commercialOrganization.save": "هل أنت متأكد أنك تريد حفظ هذه المؤسسة التجارية؟",
	"admin.commercialOrganization.editRecord": "أضف منظمة تجارية",
	"admin.commercialOrganization.addRecord": "تحرير التنظيم التجاري",
	"admin.commercialOrganization.success": "وأضافت المنظمة التجارية بنجاح",
	"admin.commercialOrganizationEdit.success": "تغيرت المنظمة التجارية بنجاح",
	"admin.commercialOrganization.deleteSuccess": "منظمة تجارية حذف بنجاح",
	"admin.commercialOrganization.deleteMsg": "هل أنت متأكد أنك تريد حذف هذه المنظمة التجارية؟",
	"user.commercialOrganization.exists.message": "المؤسسة التجارية موجودة بالفعل.",
	"user.commercialOrganization.users.exists.message":
		"غير قادر على حذف المنظمة التجارية.لا يزال بعض المستخدمين مخصصين.",
	"user.commercial_organization_showAll": "تؤثر الكل",
	"user.commercial_organization_showAll.description":
		"باختيار هذا الخيار، سيكون للمدير/للمسؤول إذن لرؤية جميع البيانات المتعلقة بالفواتير والأوامر",
	"permissions.admin.commercialOrganization": "التنظيم التجاري",
	"permissions.admin.commercialOrganization.add": "يضيف",
	"permissions.admin.commercialOrganization.edit": "يحرر",
	"permissions.admin.commercialOrganization.delete": "حذف",
	"permissions.admin.manageUsers": "مدير المستخدمين",
	"permissions.admin.manageUsers.add": "يضيف",
	"permissions.admin.manageUsers.edit": "يحرر",
	"permissions.admin.manageUsers.delete": "حذف",
	"permissions.admin.manageUsers.permissions": "الأذونات",
	"permissions.admin.manageSuppliers": "مدير الموردين",
	"permissions.admin.manageSuppliers.add": "يضيف",
	"permissions.admin.manageSuppliers.edit": "يحرر",
	"permissions.admin.manageSuppliers.delete": "حذف",
	"permissions.admin.manageClients": "عملاء المدير",
	"permissions.admin.manageClients.add": "يضيف",
	"permissions.admin.manageClients.edit": "يحرر",
	"permissions.admin.manageClients.delete": "حذف",
	"permissions.admin.supplierTraining": "تدريب الموردين",
	"permissions.admin.supplierTraining.toggle": "مفعل وغير مفعل",
	"permissions.admin.supplierTraining.delete": "حذف",
	"permissions.admin.clientTraining": "تدريب العملاء",
	"permissions.admin.clientTraining.toggle": "مفعل وغير مفعل",
	"permissions.admin.clientTraining.delete": "حذف",
	"permissions.admin.InvoiceSettings": "إعدادات الفاتورة",
	"permissions.admin.orderSetting": "إعدادات الطلب",
	"permissions.admin.invoiceExtractionLevel": "إعدادات مستوى استخراج الفاتورة",
	"permissions.admin.orderExtractionLevel": "إعدادات مستوى استخراج الطلب",
	"permissions.admin.invoiceImportConfig": "رسوم استيراد الفاتورة",
	"permissions.admin.orderImportConfig": "طلب تكوين الاستيراد",
	"permissions.admin.invoiceWorkflow": "اتفاع سير العمل",
	"invoice.dashboard": "لوحة القيادة",
	"invoice.list": "الفواتير",
	"invoice.disputed": "الفواتير المتنازع عليها",
	"invoice.disputed.reintegrate": "إعادة دمج الفاتورة",
	"invoice.disputed.reject": "رفض الفاتورة",
	"invoice.disputed.delete": "حذف الفاتورة",
	"invoice.add": "يضيف",
	"invoice.delete": "حذف",
	"invoice.deleteAdd": "حذف الكل",
	"invoice.download": "تصدير PDF.",
	"invoice.relaunchExtraction": "إعادة تشغيل الاستخراج",
	"invoice.exportXml": "تصدير XML",
	"invoice.exportExcel": "تصدير Excel.",
	"invoice.exportJE": "إدخالات محاسبة Excel Excel",
	"invoice.exportEDI": "تصدير إيدي",
	"invoice.exportIDOC": "تصدير IDOC.",
	"invoice.exportUBL": "تصدير Ubl",
	"invoice.exportCII": "تصدير CII16B.",
	"invoice.exportFactureX": "تصدير الحقيقة-X",
	"invoice.supplier": "الموردون",
	"invoice.supplier.add": "يضيف",
	"invoice.supplier.edit": "يحرر",
	"invoice.supplier.delete": "حذف",
	"invoice.supplier.import": "استيراد المورد",
	"invoice.supplier.export": "مورد التصدير",
	"order.dashboard": "لوحة القيادة",
	"permissions.admin.orders": "الطلبات",
	"order.client": "قائمة العملاء",
	"order.list": "الفواتير",
	"order.add": "يضيف",
	"order.delete": "حذف",
	"order.deleteAdd": "حذف الكل",
	"order.download": "تصدير PDF.",
	"order.relaunchExtraction": "إعادة تشغيل الاستخراج",
	"order.exportXml": "تصدير XML",
	"order.exportExcel": "تصدير Excel.",
	"order.exportJE": "إدخالات محاسبة Excel Excel",
	"order.exportEDI": "تصدير EDI",
	"order.exportIDOC": "تصدير IDOC.",
	"order.client.add": "يضيف",
	"order.client.edit": "يحرر",
	"order.client.delete": "حذف",
	"order.client.import": "استيراد العميل",
	"order.client.export": "تصدير العميل",
	"user.permission.save": "يحفظ",
	"user.permission.confirmSave": "هل تريد حقا تحديث الأذونات لهذا المستخدم؟",
	"tooltip.action.permissions": "الأذونات",
	ORDER: "الطلبات",
	INVOICE: "الفواتير",
	"admin.emailConfig.addNewEmail": "يضيف",
	"admin.user.toggleActive": "ممكن / إبطال",
	edit_client: "تحرير العميل",
};
