import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";
import { Doughnut } from "react-chartjs-2";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import PlaylistAddCheck from "@material-ui/icons/PlaylistAddCheckOutlined";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isMenuAllowed } from "components/HasPermission";
import { get } from "lodash";
import {
	fetchInvoiceIndicators,
	getFilterInvoiceStatus,
} from "../../../../redux/actions/invoices/index";

import DashboardCard from "../../../../components/DashboardCard/index";

import useStyles from "./style";
import { updateFilter } from "../../../../redux/filterReducer";
import CircularLoader from "../../../../components/Loaders/CircularLoader";

const dispalyModes = ["STATUS", "EXTRACTION_LEVEL"];

// const statusIndicators = [
// 	[{ code: "TO_VERIFY", label: "To verify" }],
// 	[{ code: "TO_CONTROL", label: "To control" }],
// 	[{ code: "EXPORTED", label: "invoice.status.exported" }],
// ];

const extractionLevelIndicators = ["N1", "N2", "N3"];

function MyTasks(props) {
	const classes = useStyles();
	const theme = useTheme();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = React.useMemo(() => get(currentUser, "permissions"), [
		currentUser,
	]);

	const [display] = React.useState(dispalyModes[0]);
	const rowsPerPage = localStorage.getItem("invoices_pageSize_default")
		? localStorage.getItem("invoices_pageSize_default")
		: process.env.REACT_APP_DEFAULT_PAGE_SIZE_LIST_INVOICE;

	const statusIndicators = useSelector(
		({ requests }) =>
			requests.queries.FETCH_FILTER_STATUS_INVOICE &&
			requests.queries.FETCH_FILTER_STATUS_INVOICE.data
	);
	const indicators = useSelector(
		({ requests }) =>
			requests.queries.FETCH_COUNT_INVOICE_INDICATOR &&
			requests.queries.FETCH_COUNT_INVOICE_INDICATOR.data
	);

	useEffect(() => {
		dispatch(fetchInvoiceIndicators());
		dispatch(getFilterInvoiceStatus());
	}, []);
	const onClickShowAll = (status, extractionLvl) => {
		dispatch(
			updateFilter({
				searchBy: "",
				status: [status],
				startIssueDate: null,
				startSaveDate: null,
				startCreatedDate: null,
				endIssueDate: null,
				endSaveDate: null,
				endCreatedDate: null,
				pageNo: 0,
				pageSize: rowsPerPage,
				extractionLvl,
			})
		);
		history.push("/invoices", { fromDashboard: true });
	};

	const getData = () => ({
		labels:
			display === "STATUS"
				? [t("Invoices to verify"), t("Invoices to be checked"), t("invoice.exported")]
				: [t("total_extract"), t("partial_extract"), t("not_extract")],
		datasets: [
			{
				data:
					display === "STATUS"
						? [
								indicators ? indicators.totalInvoicesToCheck : 0,
								indicators ? indicators.totalInvoicesToControl : 0,
								indicators ? indicators.totalInvoicesTopay : 0,
						  ]
						: [
								indicators ? indicators.totalInvoicesELN1 : 0,
								indicators ? indicators.totalInvoicesELN2 : 0,
								indicators ? indicators.totalInvoicesELN3 : 0,
						  ],
				backgroundColor: [
					theme.custom.color.color1,
					theme.custom.color.color2,
					theme.custom.color.color3,
				],
			},
		],
	});
	const data = getData(theme, t);

	return (
		<DashboardCard
			title={t("My tasks")}
			icon={PlaylistAddCheck}
			className={classes.card}
			{...props} // eslint-disable-line
		>
			{data ? (
				<div>
					<Doughnut data={data} width={500} options={{ legend: { display: false } }} />
					<List>
						{data.labels.map((label, index) => (
							<ListItem key={index} className={clsx(!(index % 2) && classes.bgLight)}>
								<ListItemText
									primary={data.datasets[0].data[index]}
									secondary={label}
									secondaryTypographyProps={{
										variant: "subtitle1",
									}}
									style={{ color: data.datasets[0].backgroundColor[index] }}
								/>
								<ListItemSecondaryAction>
									{isMenuAllowed(currentUserPermissions, "Invoices list") && (
										<IconButton
											edge="end"
											aria-label="view"
											onClick={() => {
												if (display === dispalyModes[0])
													onClickShowAll(statusIndicators[index], null);
												else onClickShowAll(null, extractionLevelIndicators[index]);
											}}
										>
											<VisibilityOutlined />
										</IconButton>
									)}
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				</div>
			) : (
				<CircularLoader />
			)}
		</DashboardCard>
	);
}

export default MyTasks;
