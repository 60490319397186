import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import UserFilter from "./UserFilter";
import StatusLegend from "../component/StatusLegend";
import TableUsersList from "../component/Table";
import { fetchUsers } from "../redux/actions";

import { setIsUserModif } from "../../App/appSlice";
import { initFilter } from "../redux/reducers/UserFilterReducer";

const columns = ["status", "lastName", "firstName", "userFunction", "email"];
const sortByList = ["firstName", "lastName", "job"];

export const List = () => {
	const dispatch = useDispatch();
	const [addUserMode, setAddUserMode] = React.useState(false); // eslint-disable-line
	const [updateUserMode, setUpdateUserMode] = React.useState(false); // eslint-disable-line
	const [idUserToModify, setIdUserToModify] = React.useState(null); // eslint-disable-line
	const [typeUser] = React.useState("USER");
	const filterUsers = useSelector(({ filterUsers: i }) => i);
	const [selected, setSelected] = React.useState([]);
	const idUsers = useSelector(
		({ requests }) =>
			requests.queries.FETCH_USERS &&
			requests.queries.FETCH_USERS.data &&
			requests.queries.FETCH_USERS.data.content.map((user) => user.id)
	);
	const handleSelectedAll = (checked) => {
		setSelected(checked ? idUsers : []);
	};
	const setUserToModify = (id) => {
		setIdUserToModify(id);
		dispatch(setIsUserModif());
	};

	useEffect(() => {
		dispatch(initFilter());
		dispatch(fetchUsers({ ...filterUsers, typeUser }));
	}, []);

	return (
		<>
			<Grid container item xs={12}>
				<StatusLegend />
			</Grid>
			<Grid container item xs={12}>
				<UserFilter typeUser="USER" sortByList={sortByList} module="users" />
			</Grid>
			<Grid container item xs={12}>
				<TableUsersList
					selected={selected}
					columns={columns}
					typeUser={typeUser}
					setSelected={setSelected}
					handleSelectedAll={handleSelectedAll}
					setUpdateUserMode={setUpdateUserMode}
					setIdUserToModify={setUserToModify}
					module="users"
				/>
			</Grid>
		</>
	);
};

export default List;
