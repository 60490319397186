import { createSlice } from "@reduxjs/toolkit";

const defaultPageSize = localStorage.getItem("orders_pageSize_default")
	? localStorage.getItem("orders_pageSize_default")
	: process.env.REACT_APP_DEFAULT_PAGE_SIZE_LIST_INVOICE;

export const initialFilterOrder = {
	orderNoOrClientLabel: "",
	status: [],
	startDate: null,
	endDate: null,
	pageNo: 0,
	pageSize: defaultPageSize,
	selectAll: false,
	sortBy: "",
	period: "",
};
const filterListOrders = createSlice({
	name: "filter",
	initialState: initialFilterOrder,
	reducers: {
		updateFilterOrder: (state, action) => action.payload,
		initFilterOrder: () => initialFilterOrder,
	},
});

export const { updateFilterOrder, initFilterOrder } = filterListOrders.actions;

export default filterListOrders.reducer;
