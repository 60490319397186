import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import DropzoneArea from "components/DropZoneArea";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { isActionAllowed } from "components/HasPermission";
import { toast, currencyFormat } from "utils";
import { EXTENTIONS_INVOICES } from "utils/constants";
// import { initialFilter } from "redux/filterReducer";
import Filter from "./Components/Filter";
import InvoicesTable from "../../../components/InvoicesToValidate";
import {
	fetchInvoices,
	createInvoice,
	invoicesTotalAmounts,
	createInvoiceCutting,
	// fetchCountInvoices,
	fetchInvoiceCuttings,
} from "../../../redux/actions/invoices";
import ExtractionFilter from "./Components/ExtractionFilter";
import { fetchSettingsByModuleName } from "../../../redux/actions/users";

import useStyles from "./style";

function List() {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const fromDashboard =
		history.location && history.location.state && history.location.state.fromDashboard;

	const [expanded, setExpanded] = React.useState(false);
	const [selected, setSelected] = React.useState([]);
	const [files, setFiles] = React.useState([]);
	const [withCutter, setWithCutter] = React.useState(false);

	// const filterInvoices = localStorage.getItem("invoice_filter")
	// 	? localStorage.getItem("invoice_filter")
	// 	: initialFilter;

	const filterInvoices = useSelector(({ filterInvoices: i }) => i);

	const idInvoices = useSelector(
		({ requests }) =>
			requests.queries.LIST_FETCH_INVOICE &&
			requests.queries.LIST_FETCH_INVOICE.data &&
			requests.queries.LIST_FETCH_INVOICE.data.content.map((invoice) => invoice.id)
	);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = useMemo(() => get(currentUser, "permissions"), [currentUser]);
	const onloadFiles = (filesParam) => {
		setFiles(filesParam);
	};
	const onDeleteFile = (file) => {
		files.splice(files.indexOf(file), 1); // TODO il faut utiliser setFiles
		toast.info(t("fileRemovedMsg", { filename: file.name }), { autoClose: 3000 });
	};
	const onDrop = () => {
		// toast.success(t("fileAddedMsg"), { autoClose: 3000 });
	};

	// TODO: Les deux actions fetchInvoices et invoicesTotalAmounts doivent être appellé une seule fois
	// TODO: split this to handle uploading invoice cutting separately
	const onclickUploadInvoices = () => {
		const tmpFiles = [];
		files.forEach((element, i) => {
			const action = withCutter ? createInvoiceCutting : createInvoice;
			dispatch(action(element)).then((response) => {
				if (
					response.status === 201 &&
					currentUser &&
					get(currentUser, "commercialOrganizationId")
				) {
					toast.success(t("invoice_uploaded_success"));
				}
				if (withCutter) {
					dispatch(fetchInvoiceCuttings());
				} else {
					dispatch(fetchInvoices(filterInvoices));
					dispatch(invoicesTotalAmounts([]));
				}
				if (response != null) {
					if (response.status !== 201) {
						tmpFiles.push(element.name);
					}
					if (i === files.length - 1) {
						if (tmpFiles.length === files.length) {
							toast.error(`${t("InvoicesNotCreatedMessage")} : ${tmpFiles.join(", ")}`, {
								autoClose: 3000,
							});
						}
					}
				}
			});
		});
		// dispatch(fetchCountInvoices());
		setExpanded(!expanded);
	};
	const formatSettings = (data) =>
		data && data.reduce((acc, { code, value }) => ({ ...acc, [code]: parseInt(value, 10) }), {});

	const settings = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_SETTINGS_MODULE.data")
	);
	const handleSelectedAll = (checked) => {
		setSelected(checked ? idInvoices : []);
	};

	const totalInvoices = useSelector(({ requests }) => get(requests, "queries.TOTAL_INVOICE.data"));
	const totalAmounts = useSelector(({ requests }) =>
		get(requests, "queries.INVOICE_TOTAL_AMOUNT.data")
	);
	useEffect(() => {
		dispatch(fetchSettingsByModuleName("invoice"));
		// if (fromDashboard) {
		// 	dispatch(fetchInvoices({ filterInvoices }));
		// } else {
		// 	dispatch(initFilter());
		// 	dispatch(fetchInvoices({ ...initialFilter }));
		// }

		if (
			history &&
			history.location &&
			history.location.state &&
			!isEmpty(history.location.state.files)
		) {
			toast.error(
				`${t("InvoicesNotCreatedMessage")} : ${history?.location?.state?.files?.join(", ")}`
			);
			const state = { ...history.location.state };
			delete state.files;
			history.replace({ ...history.location, state });
		}
	}, []);

	const getDataFromSettings = (data, code) => data.find((e) => e.code === code);
	return (
		<>
			<Grid spacing={2} xs={12} container className={classes.firstGrid}>
				<Grid
					spacing={2}
					container
					item
					xs={12}
					className={classes.headerZone}
					justify="center"
					alignItems="stretch"
				>
					<Grid item xs={6}>
						{settings && getDataFromSettings(settings, "INVOICE_MAXIMUM_SIZE") && (
							<DropzoneArea
								fileLimit={30}
								expanded={expanded}
								showFileNames
								useChipsForPreview
								dropzoneClass={classes.dropzone}
								dropzoneParagraphClass={classes.text}
								onChange={onloadFiles}
								onDelete={onDeleteFile}
								onDrop={onDrop}
								zoneMessage="Add your Invoice"
								acceptedFiles={EXTENTIONS_INVOICES}
							/>
						)}
					</Grid>
					<Grid container item xs={2}>
						{expanded && (
							<>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									className={classes.expand}
									style={{ height: "100px" }}
									onClick={(e) => {
										onclickUploadInvoices(e, withCutter);
										setExpanded(!expanded);
									}}
								>
									{t("Upload")}
								</Button>
								<Grid container style={{ height: "50px" }}>
									<Grid container item xs="10" justify="center" alignItems="center">
										<Typography variant="body2" color="secondary">
											{t("use.doc.cutter")}
										</Typography>
									</Grid>

									<Grid container item xs="2" justify="center" alignItems="center">
										<Switch
											defaultChecked={withCutter}
											onChange={(e) => setWithCutter(e.target.checked)}
											name="AUTO_EXPORT"
											color="secondary"
										/>
									</Grid>
								</Grid>
							</>
						)}
					</Grid>
				</Grid>
				<Grid container item xs={12} direction="row-reverse">
					<Grid container item xs={2} justify="flex-end">
						{isActionAllowed(currentUserPermissions, "Invoices list", "Add") && !expanded && (
							<Button
								fullWidth
								variant="contained"
								color="primary"
								className={classes.expand}
								onClick={() => {
									setExpanded(!expanded);
								}}
							>
								{t("Add an invoice")}
							</Button>
						)}
					</Grid>
					<Grid container alignItems="center" justify="flex-end" item xs={6}>
						<ExtractionFilter />
					</Grid>
					<Grid container item direction="column" xs={4}>
						<Grid item>
							<Typography variant="h6" color="secondary">
								{t("Total amount of invoices")} :
								<span> {totalAmounts ? currencyFormat(totalAmounts) : 0}</span>€
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2" color="textSecondary">
								{t("countInvoices")} :<span> {totalInvoices}</span>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				{/* <InvoiceCutting /> */}
				<Grid item container xs={12}>
					<Filter
						settings={settings}
						selected={selected}
						setSelected={setSelected}
						handleSelectedAll={handleSelectedAll}
						fromDashboard={fromDashboard}
					/>
				</Grid>
				<Grid item xs={12}>
					<InvoicesTable
						settings={formatSettings(settings)}
						selected={selected}
						setSelected={setSelected}
						fromDashboard={fromDashboard}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default List;
