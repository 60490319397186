import { makeStyles } from "@material-ui/core/styles";

import chipData from "./data";

const generateClassByChipId = ({ id, color }) => ({
	[id]: {
		color,
		borderColor: color,
  },
  [`${id}Selected`]:{
    backgroundColor: color,
    "&:focus": {
			backgroundColor: color,
		},
  }
});

export default makeStyles((theme) => ({
	chipLayout: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
		marginRight: theme.spacing(2),
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
	chip: {
		border: "2px solid",
		backgroundColor: "#ffffff",
		"& .MuiChip-icon": {
			color: "inherit",
    },
    "&:focus": {
			backgroundColor: "ffffff",
		},
  },
  chipSelected: {
		color: "#ffffff",
		borderColor: "#ffffff",
		"& .MuiChip-deleteIcon": {
			color: "#ffff",
		}
  },
  ...chipData.reduce((acc, curr) => ({...acc, ...generateClassByChipId(curr)}), {}),
}));
