import React from "react";
import { DialogActions, DialogTitle, Dialog, Button, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function ConfirmDialog({ message, open, onConfirm, onCancel, isLoading = false }) {
	const { t } = useTranslation();
	return (
		<Dialog
			disableBackdropClick
			open={open}
			onClose={onCancel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{message}</DialogTitle>
			<DialogActions>
				<Button onClick={onCancel} color="secondary" disabled={isLoading}>
					{t("suplier.action.disagree")}
				</Button>
				<Button
					onClick={onConfirm}
					disabled={isLoading}
					variant="contained"
					color="secondary"
					autoFocus
				>
					{isLoading && <CircularProgress size={14} />}&nbsp;
					{t("suplier.action.agree")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmDialog;
