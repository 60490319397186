import React from "react";
import { useTranslation } from "react-i18next";

const Instructions = ({ isClient }) => {
	const { t } = useTranslation();
	return (
		<>
			<ol>
				<li>{t(`upload.${isClient ? "client." : ""}instructions1`)}</li>
				<li>{t(`upload.${isClient ? "client." : ""}instructions2`)}</li>
				<li>{t(`upload.${isClient ? "client." : ""}instructions3`)}</li>
				<li>{t(`upload.${isClient ? "client." : ""}instructions4`)}</li>
				<li>
					{t(`upload.${isClient ? "client." : ""}instructions5`)}
					<ol>
						<li>{t(`upload.${isClient ? "client." : ""}instructions51`)}</li>
						<li>{t(`upload.${isClient ? "client." : ""}instructions52`)}</li>
					</ol>
				</li>
				<li>{t(`upload.${isClient ? "client." : ""}instructions6`)}</li>
				<li>{t(`upload.${isClient ? "client." : ""}instructions7`)}</li>
			</ol>
		</>
	);
};

export default Instructions;
