import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	dropzone: {
		background: "transparent",
		maxWidth: "100%",
		minHeight: "80%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& .MuiDropzoneArea-icon": {
			color: theme.palette.primary.main,
			opacity: 0.8,
		},
	},
}));
