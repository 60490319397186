import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import NativeSelect from "@material-ui/core/NativeSelect";
import { useDispatch, useSelector } from "react-redux";
import { fetchCivilities } from "../../../administration/redux/actions";
import { REGEX } from "../../../utils";
import useStyles from "../style";

const Contact = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();

	useEffect(() => {
		dispatch(fetchCivilities());
	}, []);

	const civilities = useSelector(
		({ requests }) =>
			requests.queries.FETCH_USERS_CIVILITIES && requests.queries.FETCH_USERS_CIVILITIES.data
	);

	return (
		<Grid container item xs={12} justify="center">
			<Grid container item xs={8} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.civility")}
						<br />
						<NativeSelect
							value={props.contact.civility}
							onChange={(event) => props.updateSupplierContact(event)}
							name="civility"
							fullWidth
						>
							{/* eslint-disable-next-line */}
							<option value="" />
							{civilities &&
								civilities.map((elt, i) => (
									<option key={i} value={elt.code}>
										{t(elt.label)}
									</option>
								))}
						</NativeSelect>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.function")}
						<Input
							value={props.contact.function}
							onChange={(event) => props.updateSupplierContact(event)}
							name="function"
							fullWidth
						/>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={8} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.lastName")}
						<Input
							value={props.contact.lastName}
							onChange={(event) => props.updateSupplierContact(event)}
							inputRef={props.register({
								pattern: { value: REGEX.FORM_NAMES, message: t("lastName_notValid") },
							})}
							name="lastName"
							fullWidth
						/>
						<p className={classes.validation_error}>{props.errors.lastName?.message}</p>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.firstName")}
						<Input
							value={props.contact.firstName}
							onChange={(event) => props.updateSupplierContact(event)}
							inputRef={props.register({
								pattern: { value: REGEX.FORM_NAMES, message: t("firstName_notValid") },
							})}
							name="firstName"
							fullWidth
						/>
						<p className={classes.validation_error}>{props.errors.firstName?.message}</p>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={8} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.phone")}
						<Input
							value={props.contact.phone}
							onChange={(event) => props.updateSupplierContact(event)}
							inputRef={props.register({
								pattern: { value: REGEX.TEL, message: t("phone_notValid") },
							})}
							name="phone"
							placeholder="+33 123 456 7890"
							fullWidth
						/>
						<p className={classes.validation_error}>{props.errors.phone?.message}</p>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("email")}
						<Input
							value={props.contact.email}
							onChange={(event) => props.updateSupplierContact(event)}
							inputRef={props.register({
								pattern: { value: REGEX.EMAIL, message: t("email_notValid") },
							})}
							name="email"
							placeholder="supplychain@novelis.io"
							fullWidth
						/>
						<p className={classes.validation_error}>{props.errors.email?.message}</p>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Contact;
