import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { Typography } from "@material-ui/core";

import useStyles from "../style";
export const DetailsLegalInformation = ({ supplier }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<Grid container xs={12} spacing={4}>
			<Grid container item xs={12} spacing={4} alignItems="flex-end">
				<Grid item xs={4} spacing={2}>
					<Typography variant="h6" align="center">
						{t("Company Siret")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "companySiret") ? get(supplier, "companySiret") : "-"}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={4}>
					<Typography variant="h6" align="center">
						{t("Company Siren")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "companySiren") ? get(supplier, "companySiren") : "-"}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={4}>
					<Typography variant="h6" align="center">
						{t("vatNumber")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "vatNumber") ? get(supplier, "vatNumber") : "-"}
						</Typography>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DetailsLegalInformation;
