import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { get } from "lodash";
import {
	Card,
	CardContent,
	ButtonBase,
	Grid,
	Typography,
	CardActions,
	IconButton,
	Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { HasPermission } from "components/HasPermission";

const CommercialOrganizationRow = ({
	data,
	index,
	classes,
	handleDeleteCommercialOrganization,
	setSelectedId,
	t,
}) => {
	const history = useHistory();
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);
	const module = isOrderEnv ? "order" : "invoice";

	const handleEditCommercialOrganization = (id) => {
		history.push(`/administration/${module}/commercial-organization/edit/${id}`);
	};

	return (
		<>
			<Card key={`commercial-organization-row-${index}`} className={classes.root} elevation={1}>
				<CardContent className={classes.content}>
					<ButtonBase focusRipple>
						<Grid container justify="left" alignItems="center">
							<Grid item xs={3} align="left">
								<Grid item>
									<Typography variant="subtitle2" align="center" color="textSecondary">
										{get(data, "name", "")}
									</Typography>
								</Grid>
							</Grid>
							<Grid container item xs={6} align="left">
								<Grid container item xs={12} justify="center">
									<Typography
										variant="subtitle2"
										align="center"
										color="textSecondary"
										className={classes.rowDescription}
									>
										{get(data, "description")}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</ButtonBase>
				</CardContent>
				<CardActions className={classes.actionButton}>
					<Grid container xs={12} justify="center" alignContent="flex-end" alignItems="flex-end">
						<HasPermission menuName="Commercial Organization" actionName="Edit">
							<Tooltip placement="top" title={t("tootlip.action.edit")}>
								<IconButton onClick={() => handleEditCommercialOrganization(get(data, "id"))}>
									<EditIcon />
								</IconButton>
							</Tooltip>
						</HasPermission>
						<HasPermission menuName="Commercial Organization" actionName="Delete">
							<Tooltip placement="top" title={t("tooltip.action.delete")}>
								<IconButton
									onClick={() => {
										setSelectedId(get(data, "id"));
										handleDeleteCommercialOrganization();
									}}
								>
									<DeleteIcon color="red" />
								</IconButton>
							</Tooltip>
						</HasPermission>
					</Grid>
				</CardActions>
			</Card>
		</>
	);
};

export default CommercialOrganizationRow;
