import React, { useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";

import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
// import { isMenuAllowed } from "components/HasPermission";
import { isSupplierOnly } from "utils";
import { HasPermission, isActionAllowed, isMenuAllowed } from "components/HasPermission";
import InvoicesTable from "../../components/InvoicesToValidate";
import MyTasks from "./components/MyTasks";
import PaidInvoice from "./components/PaidInvoice";
import UnpaidInvoice from "./components/UnpaidInvoice";
import LastActions from "./components/LastActions";
import AddInvoice from "./components/AddInvoice";
import { fetchSettingsByModuleName } from "../../redux/actions/users";

import useStyles from "./style";

const formatSettings = (data) =>
	data.reduce((acc, { code, value }) => ({ ...acc, [code]: parseInt(value, 10) }), {});

function Home() {
	const classes = useStyles();
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = useMemo(() => get(currentUser, "permissions"), [currentUser]);
	const dispatch = useDispatch();
	const [settings, setSettings] = React.useState({});

	useEffect(() => {
		if (currentUser && !isSupplierOnly(get(currentUser, "roles", [])))
			dispatch(fetchSettingsByModuleName("invoice")).then((res) => {
				if (get(res, "data")) {
					setSettings(formatSettings(res.data));
				}
			});
	}, [dispatch, currentUser]);

	const getPermissionsValue = (menu, action) => {
		if (isActionAllowed(currentUserPermissions, menu, action)) {
			return 1;
		}
		return 0;
	};

	const col1 = getPermissionsValue("Dashboard invoices", "My invoices") > 0;
	const col2 =
		getPermissionsValue("Dashboard invoices", "Exported invoices") +
			getPermissionsValue("Invoices list", "Add") >
		0;
	const col3 =
		getPermissionsValue("Dashboard invoices", "Last actions") +
			getPermissionsValue("Dashboard invoices", "Non exported invoices") >
		0;

	const col = 12 / (col1 + col2 + col3);

	return (
		get(currentUser, "roles") &&
		(currentUser.roles.includes("invoice_user") || currentUser.roles.includes("manager")) && (
			<>
				<Grid container alignItems="stretch" className={classes.firstGrid}>
					<Grid item xs={12} sm={col}>
						<HasPermission menuName="Dashboard invoices" actionName="My invoices">
							<MyTasks elevation={26} square />
						</HasPermission>
					</Grid>
					<Grid container item xs={12} sm={col}>
						<HasPermission menuName="Dashboard invoices" actionName="Exported invoices">
							<Grid item xs={12}>
								<PaidInvoice elevation={26} square />
							</Grid>
						</HasPermission>
						<Grid item xs={12}>
							<AddInvoice settings={settings} elevation={26} square />
						</Grid>
					</Grid>
					<Grid container item xs={12} sm={col}>
						<HasPermission menuName="Dashboard invoices" actionName="Non exported invoices">
							<Grid item xs={col === 12 ? 6 : 12}>
								<UnpaidInvoice elevation={26} square style={{ background: "#f8f9fa" }} />
							</Grid>
						</HasPermission>
						<HasPermission menuName="Dashboard invoices" actionName="Last actions">
							{isMenuAllowed(currentUserPermissions, "Invoices list") && (
								<Grid item xs={col === 12 ? 6 : 12}>
									<LastActions elevation={26} square />
								</Grid>
							)}
						</HasPermission>
					</Grid>
				</Grid>
				<Grid container spacing={4}>
					<HasPermission menuName="Dashboard invoices" actionName="Last invoices">
						<Grid container spacing={4} item xs={12}>
							<Grid item xs={12} sm={12}>
								{isMenuAllowed(currentUserPermissions, "Invoices list") && (
									<InvoicesTable settings={settings} isDashboard />
								)}
							</Grid>
						</Grid>
					</HasPermission>
				</Grid>
			</>
		)
	);
}

export default Home;
