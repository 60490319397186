import React, { useEffect } from "react";
import { Avatar, Badge, IconButton } from "@material-ui/core";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import { getApi } from "redux/store";
import useStyles from "./style";


export const ImageProfil = (props) => {
	const classes = useStyles();
	const [file, setFile] = React.useState();
	const getImageURL = (path, module) => `${getApi()}/${module}${path}`;
	const onChange = () => {
		const tmpFile =
			document.getElementById("imageUpload") && document.getElementById("imageUpload").files[0];
		const reader = new FileReader();
		reader.readAsDataURL(tmpFile);
		reader.onload = () => {
			setFile(reader.result);
			if (props.changeValue) props.changeValue(tmpFile);
		};
	};

	useEffect(() => {
		if (props?.src) setFile(getImageURL(props.src, props.isClient ? "order" : "invoice"));
	}, [props.src]);

	return (
		<Badge
			overlap="circle"
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			classes={{ badge: classes.customBadge }}
			badgeContent={
				<IconButton component="label" onChange={onChange}>
					<input id="imageUpload" accept="image/png, image/jpg, image/jpeg" type="file" hidden />
					<CameraAltOutlinedIcon color="primary" />
				</IconButton>
			}
		>
			<Avatar alt="supplier" src={file} className={classes.large} />
		</Badge>
	);
};

export default ImageProfil;
