import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  historyContainer: {
    margin: 0,
    maxWidth: "100%",
    minWidth: "100%",
    minHeight: "800px",
    maxHeight: "800px",
    overflow: "auto",
  },
  spaceTop: {
    marginTop: "10px",
  }
}));
