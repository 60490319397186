import React from "react";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@material-ui/core";
import get from "lodash/get";
import useStyles from "../../style";

function DeliveryItemsSection({ deliveryItems, orderNo }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const minimizeValueCode = (value) => value && value.length > 9 ? `${value.substr(0, 8)  }...` : value;

  return (
    <Grid container item xs={12} className={classes.cardBilling}>
      <Grid
        container
        item
        alignItems="center"
        xs={12}
        className={classes.headerBillingCard}
      >
        <Grid item alignContent="center">
          <Typography variant="subtitle2" align="left" color="primary">
            {t("Delivery Lines")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item alignItems="center" justify="center" xs={12}>
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes.tableContainer}
        >
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    className={classes.tableCell1}
                    variant="subtitle2"
                    align="left"
                  >
                    {t("deliveryNo")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.tableCell2}
                    variant="subtitle2"
                    align="left"
                  >
                    {t("deliveryItemDate")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.tableCell2}
                    variant="subtitle2"
                    align="left"
                  >
                    {t("orderNo")}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography
                    className={classes.tableCell2}
                    variant="subtitle2"
                    align="left"
                  >
                    {t("codeArticle")}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography
                    className={classes.tableCell2}
                    variant="subtitle2"
                    align="left"
                  >
                    {t("deliveredQuantity")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {deliveryItems &&
                  deliveryItems.map((delivery, i) => (
                      <TableRow key={i}>
                        <>
                          <Tooltip
                            title={get(delivery, "delivery.deliveryNumber")}
                            arrow
                          >
                            <TableCell>
                              <Typography
                                className={classes.tableCell1}
                                variant="subtitle"
                                align="left"
                              >
                                {minimizeValueCode(
                                  get(delivery, "delivery.deliveryNumber")
                                )}
                              </Typography>
                            </TableCell>
                          </Tooltip>
                          <TableCell>
                            <Typography
                              className={classes.tableCell2}
                              variant="subtitle"
                              align="left"
                            >
                              {get(delivery, "delivery.deliveryDate")}
                            </Typography>
                          </TableCell>
                          <Tooltip title={orderNo} arrow>
                            <TableCell>
                              <Typography
                                className={classes.tableCell3}
                                variant="subtitle"
                                align="left"
                              >
                                {orderNo || ""}
                              </Typography>
                            </TableCell>
                          </Tooltip>
                          <TableCell>
                            <Typography
                              className={classes.tableCell1}
                              variant="subtitle"
                              align="left"
                            >
                              {get(delivery, "codeArticle")}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              className={classes.tableCell2}
                              variant="subtitle"
                              align="left"
                            >
                              {get(delivery, "deliveredQuantity")}
                            </Typography>
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default DeliveryItemsSection;
