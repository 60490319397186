import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import Icon from "@material-ui/core/Icon";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import useStyles from "../style";
import NotFound from "../icons/NotFound.jpg";
export const DetailsSupplierContact = ({ contact }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<Grid container xs={12} direction="column" spacing={4}>
			{contact ? (
				<>
					<Grid container item xs={12} spacing={4} alignItems="flex-end">
						<Grid item xs={6} alignItems="flex-end">
							<Typography variant="h6" align="center">
								{t("name")}
								<Typography className={classes.typography} align="center">
									{"".concat(
										t(get(contact, "civility")),
										" ",
										get(contact, "firstName") ?? "",
										" ",
										get(contact, "lastName") ?? ""
									)}
								</Typography>
							</Typography>
						</Grid>
						<Grid item xs={6} spacing={4}>
							<Typography variant="h6" align="center">
								{t("function")}
								<Typography className={classes.typography} align="center">
									{get(contact, "function") ? get(contact, "function") : "-"}
								</Typography>
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={4} alignItems="flex-end">
						<Grid item xs={6} spacing={4}>
							<Typography variant="h6" align="center">
								{t("email")}
								<Typography className={classes.typography} align="center">
									{get(contact, "email") ? get(contact, "email") : "-"}
								</Typography>
							</Typography>
						</Grid>
						<Grid item xs={6} spacing={4}>
							<Typography variant="h6" align="center">
								{t("phone")}
								<Typography className={classes.typography} align="center">
									{get(contact, "phone") ? get(contact, "phone") : "-"}
								</Typography>
							</Typography>
						</Grid>
					</Grid>
				</>
			) : (
				<Grid
					container
					item
					xs={12}
					spacing={4}
					className={classes.gridContact}
					justify="center"
					alignItems="center"
				>
					<Grid item align="center" xs={12}>
						<span>
							<img src={NotFound} alt="logo" className={classes.icon2} />
						</span>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="subtitle2" style={{ color: "gray" }} align="center">
							{t("noPrincipalContact")}
						</Typography>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

export default DetailsSupplierContact;
