import supllierEs from "../pages/Suppliers/i18n/es";
import clientEs from "../pages/Clients/i18n/es";
import administrationEs from "../administration/i18n/es";

export default {
	...supllierEs,
	...administrationEs,
	...clientEs,
	Copyright: "Copyright",
	Dashboard: "Tablero de mandos",
	Orders: "Órdenes de compra",
	Invoices: "Facturas",
	Regulations: "Pagos",
	Suppliers: "Proveedores",
	Rapports: "Reporte",
	Search: "Buscar",
	"Add your Invoice": "Agregar tu factura o ",
	browse: "Navegar",
	fileAddedMsg: "Archivo (s) agregado con éxito!",
	fileRemovedMsg: "Archivo {{filename}} eliminado.",
	"Show all": "Mostrar todo",
	id: "Identificador",
	supplier: "Proveedor",
	invoiceNo: "N° de factura",
	issueDate: "Fecha de emisión",
	dueDate: "Fecha de vencimiento",
	orderDeliveryDate: "Fecha de entrega",
	"Price WT": "Importe sin IVA",
	"Taxe Rate": "IVA",
	Price: "Importe con IVA",
	"Purchase order": "Orden de compra",
	"Last actions": "Ultimas acciones",
	Today: "Hoy",
	Yesterday: "Ayer",
	"Last week": "Semana pasada",
	Status: "Estado",
	Date: "Fecha",
	"My tasks": "Mis facturas",
	"Invoices to verify": "A verificar",
	"Invoices to be checked": "A validar",
	"Invoices to Payers": "Lista para pago",
	"Paid invoices": "Pagadas",
	"Unpaid invoices": "No pagadas",
	"Total amount of invoices": "Monto total de facturas",
	"Total amount of orders": "Monto total de órdenes",
	"Total VAT amount": "Total IVA",
	"Statistical overview of purchases": "Historial de facturación",
	"Total Purchases": "Total de compras",
	"Total of expenses": "Facturas de gastos",
	Late: "Con retraso",
	Administrator: "Administración",
	"New suppliers": "Nuevos proveedores",
	FOUNDED_RESULT: "Resultado encontrado",
	EndDate: "Fecha final",
	StartDate: "Fecha de inicio",
	"Add an invoice": "Agregar una factura",
	Upload: "Cargar",
	Download: "Descargar",
	Show: "Mostrar",
	Delete: "Eliminar",
	All: "Todas",
	SearchInvoice: "Buscar una facturas",
	"Delivery address": "Dirección de entrega",
	"Billing address": "Dirección de facturación",
	"Payment terms": "Términos de pago",
	"Order Details": "Detalles del pedido",
	"Invoice Details": "Detalles de la orden",
	"Invoice Datas": "Datos de facturas",
	"Order Datas": "Datos del pedido",
	"Total Amounts": "Importes totales",
	"Net payable": "Neto por pagar",
	"Prepay amount": "Importe del prepago",
	"Base ": "Base ",
	"Associated VAT amount": "Importe de IVA asociado",
	"% applied": "% aplicado",
	"Reject Invoice": "Rechazar factura",
	"Reject Order": "Rechazar orden",
	"Correct Extraction": "Corregir",
	Control: "Control",
	ToPay: "A pagar",
	"Total HT": "HT Total",
	Discount: "Descuento",
	"order Lines": "Líneas de pedido",
	"VAT amount": "Importe del IVA",
	"Total amount": "Cantidad total",
	"Invoice overview": "Resumen de la factura",
	History: "Historial",
	verificationDate: "Fecha de verificación",
	rejectionDate: "Fecha de rechazo",
	controlDate: "Fecha de control",
	dateOfReceipt: "Fecha de recepción",
	rejectMessage: "¿Está seguro que desea rechazar esta factura?",
	controlMessage: "¿Está seguro que desea controlar esta factura?",
	ErrorMessageToControl: "No puede controlar esta factura:",
	deleteMessage: "¿Está seguro que desea eliminar esta(s) factura(s)?",
	individualDeleteMessage: "¿Está seguro que desea eliminar esta factura?",
	orderDeleteMessage: "¿Estás seguro de que quieres eliminar esta orden?",
	rejectionReason: "Motivo de rechazo",
	rejectionDetails: "Detalles del rechazo",
	cancel: "Anular",
	confirm: "Confirmar",
	editForm: "Modificar",
	countInvoices: "Número total de facturas ",
	countOrders: "Número total de órdenes ",
	tobeprocessed: "A procesar ",
	None: "------------------",
	save: "Guardar",
	edit: "Editar",
	Actions: "Acciones",
	itemCode: "Código",
	itemNLineBC: "Nº de línea BC",
	designation: "Artículo",
	quantity: "Ctd",
	unitPrice: "Precio",
	Commands: "Líneas de facturación",
	"order.lines": "LÍNEAS DE COMANDO",
	Total: "Total",
	VAT: "Tipo de IVA",
	"Pre-Tax-Amount": "Monto antes de impuestos",
	totalHt: "HT Total",
	vat: "IVA",
	sortBy: "Ordenar por",
	createdAt: "Creado en",
	updatedAt: "Actualizado en",
	InvoicesCannotBeDeleted: "Algunas facturas no pueden ser reclamadas",
	OrdersCannotBeDeleted: "Algunos comandos no pueden ser eliminados ",
	InvoicesCannotBeRelaunched: "Algunas facturas no se pueden eliminar",
	"Integration in progress": "Integración en curso",
	"To verify": "Para verificar",
	"To control": "A controlar",
	"To pay": "A pagar",
	Payed: "Pagado",
	Rejected: "Rechazado",
	Canceled: "Cancelado",
	Exception: "Excepción",
	codeArticle: "Código del artículo",
	deliveryDelay: "Retraso en la entrega",
	orderNo: "N º de orden",
	PaymentTerm: "Forma de pago",
	numberOfArticle: "Número de artículo",
	deliveryMethod: "Método de entrega",
	InvoicesNotCreatedMessage: "No se pueden crear las siguientes facturas",
	invoice: "Factura",
	order: "Orden",
	training: "Entrenando...",
	trained: "Entrenada",
	"Company Siret": "Siret",
	"VAT Number": "Número de IVA",
	"invoice Number": "N° de factura",
	"Apply Changes": "Aplicar cambios",
	"Invoice was already paid": "La factura ya ha sido pagada",
	"Order has been already delivered": "El pedido ya ha sido entregado",
	"Goods or Services were never received": "Bienes o servicios que nunca se recibieron",
	"Supporting documents were not received with the invoice":
		"No se recibieron los documentos de respaldo con la factura",
	"Goods or services are not available at the moment":
		"Los bienes o servicios no están disponibles en este momento",
	"The purchase order number is missing, is incorrect or has been canceled":
		"Falta el número de orden de compra, es incorrecto o ha sido cancelado",
	"The order number is missing, is incorrect or has been canceled":
		"Falta el número de pedido, es incorrecto o ha sido cancelado",
	"The amount being billed does not match the agreed upon amount on the contract":
		"El monto facturado no coincide con el monto acordado en el contrato",
	"Supporting documents were not received with the order":
		"No se recibieron los documentos de respaldo con el pedido",
	Other: "Otro",
	"price TTC": "Precio IVA incluido",
	"TTC amount": "Importe del IVA incluido",
	CREATE_ACTION: "Creación de la factura",
	CREATE_FROM_CUTTING_ACTION: "Creación de la factura (a través de un desglose)",
	EDIT_ACTION: "Modificación de la factura",
	VERIFY_ACTION: "Comprobando la factura",
	REJECT_ACTION: "Rechazo de la factura",
	CONTROL_ACTION: "Control de la factura",
	END_TRAINING_ACTION: "Aprendizaje completado",
	CORRECT_ACTION: "Lanzamiento de aprendizaje",
	TO_PAY_ACTION: "Pago de la factura",
	DELET_ACTION: "Eliminación de la factura",
	RELAUNCH_ACTION: "Extracción liberada",
	EXPORT_ACTION: "Exportación de la factura",
	ORDER_CREATE_ACTION: "Orden creada",
	ORDER_VERIFY_ACTION: "Orden bajo verificación",
	ORDER_REJECT_ACTION: "Orden rechazada",
	ORDER_CONTROL_ACTION: "Orden enviada para controlar",
	ORDER_TO_VALIDATE_EXTERNAL_ACTION: "Solicitud de validación",
	ORDER_END_TRAINING_ACTION: "Aprendizaje completado",
	ORDER_CORRECT_ACTION: "Lanzamiento de aprendizaje",
	ORDER_TO_PAY_ACTION: "Pago de la orden",
	ORDER_DELETE_ACTION: "Eliminación de la orden",
	ORDER_EDIT_ACTION: "Modificación del orden",
	ORDER_RELAUNCH_ACTION: "Extracción liberada",
	ORDER_EXPORT_ACTION: "Exportación de la orden",
	"Apply for approval": "Aplicar aprobación",
	INVOICE_CHANGE_STATUS: "La factura pasó al estado. {{status}}",
	INVOICE_CHANGE_STATUS_AUTOMATIC: "La factura pasó automáticamente al estado {{status}}",
	comment: "Comentario",
	ApplyForApproval: "Solicitud de aprobación",
	action: "Acción",
	details: "Detalles",
	totalEmptyMessageError: "El monto total de la factura está vacio",
	supplierEmptyMessageError: "El proveedor de la factura está vacio",
	incohenrenceAmountsMessageError:
		"El cálculo de las cantidades es incorrecto; El monto del IVA y el monto sin IVA es diferente del monto total",
	invoice_charge: "Fresco",
	order_charge: "Fresco",
	total_extract: "Facturas totalmente extraídas",
	partial_extract: "Facturas parcialmente extraídas",
	not_extract: "Facturas no extraídas",
	"this field is mandatory": "Este campo es obligatorio",
	country: "País",
	duplicated_invoice: "Factura duplicada",
	currency: "Moneda",
	reason: "Razón",
	"invoice.status.underProcess": "En procesamiento",
	"invoice.status.processed": "Procesados",
	"invoice.status.InoviceProcessed": "Facturas procesadas",
	"invoice.status.InoviceUnderProcess": "Facturas en proceso",
	"invoice.duplicated": "Duplicado",
	"invoice.status.integration": "Integración",
	"invoice.status.toVerify": "A verificar",
	"invoice.status.tocontrol": "A validar",
	"invoice.status.topay": "A pagar",
	"invoice.status.payed": "Pagado",
	"invoice.status.rejected": "Rechazado",
	"invoice.status.canceled": "Cancelado",
	"invoice.status.exception": "Excepción",
	"invoice.status.open": "Abierto",
	"invoice.status.reintegrated": "Reintegrada",
	"invoice.status.external_validation": "Aprobación pendiente",
	"invoice.status.acompte": "Depósito",
	"invoice.status.waiting_receipt": "Esperando recibo",
	"invoice.status.keywordsForbidden": "No conformidad",
	"order.status.external_validation": "Aprobación pendiente",
	"invoice.status.exported": "Exportado",
	"Invoice available": "Factura disponible",
	"extraction.label.fully_extracted": "Totalmente extraído",
	"extraction.label.partially_extracted": "Extraído parcialmente",
	"extraction.label.not_extracted": "No se ha extraído",
	"extraction.label.not_invoice": "Otros que la factura",
	"tootlip.action.edit": "Editar",
	"tooltip.action.delete": "Eliminar",
	"tooltip.action.edit": "Modificadora",
	"tooltip.action.relaunch": "Relanzar",
	"tooltip.action.download": "Descargar",
	"tooltip.action.relaunch_extraction": "Reintentar la extracción",
	"tooltip.action.export_excel": "Exportar como excel",
	"tooltip.action.export_entry_account_excel": "Entrada contable de exportación de Excel",
	"tooltip.action.export_xml": "Exportar como xml",
	"supplier.add.button": "Importar proveedores",
	"supplier.export.suppliers.excel": "Exportar la lista de proveedores",
	"supplier.add2.button": "Agregar un proveedor",
	"supplier.name.exist.already": "Proveedor existe en el sistema.",
	"suppliers.names.exist.already": "Los proveedores {{fournisseurs}} ya existen en el sistema!",
	"suppliers.error.sample": "El archivo enviado no cumple con el archivo ejemplar",
	"suppliers.empty.field.name.row.excel": "¡Hay una línea sin nombre!",
	"suppliers.add.multiple": "Añadir proveedores o ",
	"upload.supplier": "Cargar proveedores",
	"downloqd.template": "Descargar plantilla",
	"invoice.exported": "Facturas exportadas",
	"invoice.unexported": "Facturas no exportadas",
	"invoice.total_amoutn_to_verify": "Importe total de las facturas a verificar",
	"invoice.total_invoice": "Total de facturas",
	Administration: "Administración",
	Logout: "Cerrar sesión",
	Profile: "Perfil",
	"invoice.detail.company_siret": "Identificación de la empresa",
	DeliverySlip: "Hoja de entrega",
	"invoice.selected.title0": "Todas las facturas están seleccionadas",
	"invoice.selected.title1": "los ",
	"invoice.selected.title2": " Las facturas de esta página están todas seleccionadas.",
	"invoice.selected.title3": "Seleccionar todas las facturas",
	"supplier.add.step1": "Números de identificación",
	"supplier.add.step2": "Información general",
	"supplier.add.step3": "Informaciones financieras",
	"supplier.add.step4": "Contacto primario",
	"supplier.add.next": "Próxima",
	"supplier.add.previous": "Anterior",
	"supplier.add.finish": "Finalizar",
	"supplier.add.country": "País",
	"supplier.add.suppliername": "Nombre del proveedor",
	"supplier.add.logo": "Logo",
	"supplier.add.capital": "Capital",
	"supplier.add.siret": "Empresa SIRET",
	"supplier.add.siren": "Empresa SIREN",
	"supplier.add.linkedin": "LinkedIn",
	"supplier.add.email": "Correo electrónico",
	"supplier.add.fax": "Fax",
	"supplier.add.civility": "Civilidad",
	"supplier.add.phone": "Teléfono",
	"supplier.add.vat": "Número de IVA intracomunitario",
	"supplier.add.naf": "Código NAF",
	"supplier.add.bank": "Banco",
	"supplier.add.bic": "BIC",
	"supplier.add.iban": "IBAN",
	"supplier.add.rib": "RIB",
	"supplier.add.acc": "Cuenta contable",
	"supplier.add.curr": "Moneda predeterminada",
	"supplier.add.function": "Función",
	"supplier.add.firstName": "Nombre de pila",
	"supplier.add.lastName": "Apellido",
	"supplier.add.city": "Ciudad",
	"supplier.add.street": "Calle",
	"supplier.add.zipCode": "Código postal",
	"supplier.add.confirm": "¿Estás segura de que quieres guardar los cambios?",
	"supplier.delete.confirm": "¿Está seguro que desea eliminar el proveedor?",
	"supplier.delete.success": "Proveedor eliminado correctamente",
	"supplier.have.attached.user.accounts.or.invoices.message":
		"No se puede eliminar el proveedor(es). Hay una factura/cuenta adjunta.",
	"client.has.attached.user.accounts.message":
		"No se puede eliminar el (los) cliente (s).¡Se adjunta una orden / cuenta!",
	"suplier.action.agree": "Aceptar",
	"suplier.action.disagree": "Rechazar",
	"supplier.add.aux": "Cuenta auxiliar",
	TO_VALIDATE_EXTERNAL_ACTION: "Solicitud de aprobación",
	EXPORT_EXCEL: "Exportar como excel",
	EXPORT_EDI: "Exportar como edi",
	EXPORT_XML: "Exportar como xml",
	EXPORT_PDF: "Exportar como pdf",
	EXPORT_EC: "Exporter como EC",
	"order.absent": "Orden ausente",
	"amount.greate.thang.goods": "Monto de la factura mayor que el pedido",
	"delivery.absent": "Entrega ausente",
	delivery: "Entrega",
	"user.roles.user": "Usuario",
	"profile.update-password.current-password-required": "Se requiere contraseña actual",
	"profile.update-password.new-password-required": "Se requiere nueva contraseña",
	"profile.update-password.password-required": "Se requiere contraseña",
	"profile.update-password.password-not-matched": "Las contraseñas no coinciden",
	"profile.update-password.current-password": "Contraseña actual",
	"profile.update-password.new-password": "Nueva contraseña",
	"profile.update-password.confirm-password": "Confirmar contraseña",
	"profile.update-password.reset-password": "Restablecer la contraseña",
	"profile.update-password.password-reset": "Restablecimiento de contraseña",
	"profile.update-password.password-reset-success": "Su contraseña fue cambiada exitosamente",
	"profile.update-password.password-reset-done": "Hecho",
	"profile.update-password.update-password": "Cambiar la contraseña",
	"profile.update-password.email": "Tu correo electrónico actual",
	"profile.update-password.tokenError": "Token no válido, la contraseña ya está actualizada.",
	"profile.update-password.password-email-send":
		"Un correo electronico ha sido enviado. por favor revise su correo.",
	"Zip Code": "Código postal",
	Street: "Calle",
	City: "Ciudad",
	"supplier.delete.training.error.message": "No existe formación para este proveedor.",
	"invoice.detail.reject.success": "La factura fue rechazada con éxito",
	"invoice.detail.reject.failed": "La factura no fue rechazada",
	"invoice.detail.approved.success": "La factura fue aprobada con éxito",
	"invoice.detail.marked.selectedField": "Marcó el campo seleccionado",
	"invoice.detail.apply.validate": "Aplicar y validar",
	"invoice.detail.approved.failed": "La factura no fue aprobada",
	"invoice.detail.export.success": "La factura se exportó correctamente",
	"order.detail.export.success": "La orden se exportó correctamente",
	"invoice.detail.export.failed": "La factura no se exportó",
	"order.detail.export.failed": "La orden no se exportó",
	"invoice.detail.message.rectify": "¿Está seguro que desea guardar estos cambios?",
	"invoice.detail.message.confirmExport": "¿Está seguro que desea exportar esta factura?",
	"order.detail.approved.success": "El pedido ha sido aprobado con éxito.",
	"order.detail.message.confirmExport": "¿Está seguro que desea exportar este pedido?",
	"order.detail.approved.failed": "El pedido no ha sido aprobado",
	Approve: "Aprobar",
	"token.validation.noteExist": "Solicitud de cambio de contraseña enviada",
	"client.delete.msg": "¿Está seguro que desea eliminar este cliente?",
	year: "Este año",
	month: "Este mes",
	week: "Esta semana",
	day: "Hoy",
	Exported: "Exportada",
	Integrated: "Integradas",
	"switch.orders": "ÓRDENES",
	"switch.invoices": "FACTURAS",
	"Invoice attachements": "Archivos adjuntos",
	"invoice.attachment.contract": "Contrato",
	"invoice.attachment.order": "Orden",
	"invoice.attachment.receipt": "Recibo",
	"invoice.attachment.oth": "Otro",
	"order.attachment.contract": "Contrato",
	"order.attachment.order": "Orden",
	"order.attachment.receipt": "Recibo",
	"order.attachment.oth": "Otro",
	"attachment.name": "Nombre",
	"attachment.upload": "Subir",
	"attachment.cancel": "Cancelar",
	completeerrors: "Errores completos",
	completeerrorsphrase: "La tarea aún contiene errores",
	warnings: "Advertencias",
	ruleerrors: "Errores de reglas",
	checkerrors: "Comprobar errores",
	completetask: "Tarea completa",
	correcterrors: "Corrige los errores",
	orderDeleteMessageMultiple: "¿Está seguro que desea eliminar los orden (s) seleccionados?",
	"messenger.addComment": "Añadir comentario",
	"messenger.postComment": "Publicar comentario",
	"tooltip.action.export_edifact": "Exportar como Edifact",
	"tooltip.action.export_idoc": "Exportar como Idoc",
	EXPORT_IDOC: "Exportar como idoc",
	lockTraining: "Desactivar el aprendizaje",
	unlockTraining: "Activar el aprendizaje",
	"accountingLines.costCenter": "Centro de costes",
	"accountingLines.paymentMethod": "Método de pago",
	"accountingLines.glAccounts": "Cuenta Gl",
	"accountingLines.description": "Descripción",
	"accountingLines.sign": "Operación",
	"accountingLines.amount": "Cantidad",
	"accountingLines.addButton": "Añadir línea contable",
	"accountingLines.summary": "Las líneas contables no están equilibradas",
	"Accounting Lines": "Cuenta de contabilidad",
	"invoice.digital.extract": "Extraído de la factura electrónica",
	"invoice.digital": "Factura electrónica",
	"invoice.digital.preview": "Previa no disponible: esta factura electrónica no contiene un PDF",
	"tooltip.action.export": "Exportar a",
	"invoice.digital.about": "Sobre la factura",
	"invoice.digital.type": "Tipo de factura",
	"invoice.digital.signature.validity": "Validez de la firma",
	"invoice.digital.format.compliance": "Cumplimiento del formato",
	"invoice.digital.en16931.compliance": "Cumplimiento de la norma EN 16931",
	"invoice.digital.en16931.rules.violated": "Número de normas EN 16931 infringidas",
	"invoice.digital.chorus.pro.compliance": "Cumplimiento de las normas empresariales de Chorus Pro",
	"invoice.digital.chorus.pro.rules.violated": "Número de normas Chorus Pro infringidas",
	"invoice.digital.readmore": "Más información sobre la factura",
	"invoice.go.to": "Ver factura",
	"invoiceNo.must.be.present": "El número de factura debe estar presente",
	"supplier.must.be.present": "El proveedor debe estar presente",
	"issueDate.must.be.present": "La fecha de emisión debe estar presente",
	"total.must.be.present": "El importe total debe estar presente",
	"currency.must.be.present": "La moneda debe estar presente",
	"supplier.name.must.be.present": "El nombre del proveedor debe estar presente",
	"supplier.siret.must.be present":
		"La identificación de la empresa del proveedor debe estar presente",
	"supplier.vatNumber.must.be.present": "El número de IVA del proveedor debe estar presente",
	"supplier.country.must.be.present": "El país del proveedor debe estar presente",
	"designation.must.be.present": "Una línea de factura debe incluir la denominación del producto",
	"quantity.must.be.present": "Una línea de factura debe incluir la cantidad facturada",
	"vatRate.must.be.present": "Una línea de factura debe incluir el tipo de IVA",
	"price.must.be.present": "Una línea de factura debe incluir el precio unitario sin IVA",
	"Disputed Invoices": "Facturas Disputadas",
	"invoice.export.entry_account_excel": "Entrada contable en Excel",
	"invoice.export.error": "Error al exportar la factura",
	"order.export.error": "Error durante la exportación del pedido.",
	CREATE_ACTION_LITIGE: "Creación de una factura en disputa ",
	REINTEGRATE_ACTION_LITIGE: "Reintegración de la factura impugnada",
	REINTEGRATE_ACTION: "Restablecimiento de factura",
	REJECT_ACTION_LITIGE: "Rechazo de factura",

	"invoice.litige.already.in.open.status": "esta factura ya está en disputa",
	"confirmation.reintegrate": "realmente desea restablecer esta factura ",
	"confirmation.nextStatus": "¿Está seguro de que desea cambiar el estado de la factura a ",
	"confirmation.relance": "¿Está seguro de que desea reiniciar la extracción?",
	"confirmation.ExportStatus":
		' ¿Está seguro de que desea cambiar el estado de la factura a " EXPORTAR " ? ',
	"file.scan.failed": "Análisis antivirus fallido",
	"file.infected": "Archivo infectado",
	logoScanFailed: "El análisis antivirus del logotipo falló",
	logoInfected: "Logotipo infectado",

	close: "Cerrar",
	loading: "Cargando",
	"invoiceCutting.title": "Desglose de facturas",
	"invoiceCutting.activate-magnifier": "Activar la lupa",
	"invoiceCutting.validate": "Validar",
	"invoiceCutting.show-original-document": "Ver el documento original",
	"invoiceCutting.show-cutting": "Ver el desglose",
	"invoiceCutting.cutting-in-progress": "Corte en curso",
	"invoiceCutting.integration-in-progress": "Integración en curso",
	"invoiceCutting.pending-validation": "Pendiente de validación",

	"msg.date.email": "Esta factura se crea a partir de un correo electrónico enviado el ",
	"msg.subject.email": " con el siguiente tema : ",

	from: "De",
	to: "TIENE",
};
