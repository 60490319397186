import { Grid } from "@material-ui/core";
import React from "react";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../CustomDatePicker";

function DateRangePicker({ start, end, onStartChange, onEndChange }) {
	const { t } = useTranslation();

	return (
		<Grid container spacing={2}>
			<Grid item xs={5}>
				<CustomDatePicker selectedDate={start} onChange={onStartChange} label={t("start")} />
			</Grid>
			<Grid item xs={1} style={{ marginLeft: "5px" }}>
				<ArrowRightAltIcon />
			</Grid>
			<Grid item xs={5}>
				<CustomDatePicker selectedDate={end} onChange={onEndChange} label={t("end")} />
			</Grid>
		</Grid>
	);
}

export default DateRangePicker;
