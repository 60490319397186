import React from "react";
import { FormControl, IconButton, InputAdornment, Input, InputLabel } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchOutlined";
import { useLocation, useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

export const SearchFilter = ({ callback, placeHolder, style, value }) => {
	const history = useHistory();
	const location = useLocation();

	const handleUrlParamChange = (e) => {
		const param = e.target.value;
		if (!isEmpty(param)) {
			history.push(`${location.pathname}?q=${param}`);
		} else {
			history.push(location.pathname);
		}
		callback(param);
	};

	return (
		<FormControl fullWidth>
			<InputLabel id="search" />
			<Input
				id="standard-adornment-search"
				type="text"
				value={value}
				onChange={(event) => handleUrlParamChange(event)}
				placeholder={placeHolder}
				startAdornment={
					<InputAdornment position="start">
						<IconButton color="primary" aria-label="Recherche" className={style}>
							<SearchIcon />
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	);
};

export const useQuery = () => new URLSearchParams(useLocation().search);
