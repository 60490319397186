import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	addButton: {
		width: "200px",
		height: "50px",
		margin: "20px 0 20px 0",
	},
	uploadButton: {
		width: "200px",
		height: "50px",
		margin: "20px 20px 20px 0",
	},
	dropzone: {
		background: "transparent",
		maxWidth: "80%",
		height: "20%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		margin: "10px",
		"& .MuiDropzoneArea-icon": {
			color: theme.palette.primary.main,
			opacity: 0.8,
		},
	},
	dropzoneError: {
		borderColor: "#ff0539",
	},
	errorMessage: {
		color: "#ff0539",
	},
}));
