import React from "react";
import { Box } from "@material-ui/core";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; // the locale you want
import useStyles from "./style";

registerLocale("fr", fr);

function CustomDatePicker({ selectedDate, onChange, label }) {
	const classes = useStyles();

	return (
		<Box>
			<DatePicker
				className={classes.dateInput}
				calendarClassName={classes.datePickerCalendar}
				onChange={onChange}
				selected={selectedDate}
				showIcon
				isClearable
				dateFormat="dd/MM/yyyy"
				label={label}
				locale="fr"
			/>
		</Box>
	);
}

export default CustomDatePicker;
