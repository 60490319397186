import React from "react";
import { useTranslation } from "react-i18next";
import { Collapse, Typography } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import clsx from "clsx";
import useStyles from "./style";

const DropZoneArea = ({
	expanded,
	onChange,
	onDelete,
	onDrop,
	fileLimit = 30,
	zoneMessage,
	acceptedFiles,
	maxFileSize,
	dropzoneStyle,
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Collapse direction="row" in={expanded} timeout="auto" unmountOnExit>
			<DropzoneArea
				maxFileSize={maxFileSize}
				filesLimit={fileLimit}
				className={!dropzoneStyle && classes.dropzone}
				showFileNames
				useChipsForPreview
				dropzoneText={
					<>
						<Typography variant="subtitle1" color="textSecondary">
							{t(zoneMessage)}
							<u>{` ${t("browse")}`}</u>
						</Typography>
					</>
				}
				dropzoneClass={clsx(dropzoneStyle || classes.dropzone)}
				dropzoneParagraphClass={classes.text}
				onChange={onChange}
				onDelete={onDelete}
				acceptedFiles={acceptedFiles}
				onDrop={onDrop}
				showAlerts={false}
			/>
		</Collapse>
	);
};

export default DropZoneArea;
