import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import get from "lodash/get";
import InsertDriveFileRounded from "@material-ui/icons/InsertDriveFileOutlined";

import { useTranslation } from "react-i18next";
import DashboardCard from "../../../../components/DashboardCard/index";
import { currencyFormat, isSupplierOnly } from "../../../../utils/index";

function UnpaidInvoice(props) {
	const { t } = useTranslation();
	const label = isSupplierOnly(get(props.currentUser, "roles", []))
		? t("invoice.status.InoviceUnderProcess")
		: t("invoice.unexported");

	return (
		<DashboardCard
			title={label}
			icon={InsertDriveFileRounded}
			{...props} // eslint-disable-line
		>
			<Grid container spacing={2} justify="center">
				<Grid item xs={6}>
					<Typography variant="h5" color="primary" gutterBottom align="center">
						{`${currencyFormat(props.unpaidIncoicesAmount)} €`}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" gutterBottom align="center">
						{t("Total amount of invoices")}
					</Typography>
				</Grid>
				<Grid item xs={6} className="p-relative">
					<Divider orientation="vertical" absolute />
					<Typography variant="h5" color="error" gutterBottom align="center">
						{`${currencyFormat(props.overdue)} €`}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" gutterBottom align="center">
						{t("Late")}
					</Typography>
				</Grid>
			</Grid>
		</DashboardCard>
	);
}

export default UnpaidInvoice;
