import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import get from "lodash/get";
import { toast } from "utils";
import ImportExportIcon from "@material-ui/icons/ImportExport";

import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { isActionAllowed } from "components/HasPermission";

import {
	downloadInvoiceExcel,
	exportSingleAccountingEntryExcel,
	downloadXMLInvoice,
} from "../../../redux/actions/invoices";

export default function ExportMenu({ row, settings, index }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = useMemo(() => get(currentUser, "permissions"), [currentUser]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [settingsLst, setSettingsLst] = React.useState([]);
	const showMenu = Boolean(anchorEl);
	const showMenuItem =
		settingsLst &&
		settingsLst?.length > 0 &&
		settingsLst?.filter((s) => s > 0 || s === true).length > 0;

	const [loading, setLoading] = React.useState(false);

	const openMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorEl(null);
	};

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const onError = () => {
		toast.error(t("invoice.detail.export.failed"));
	};

	React.useEffect(() => {
		setSettingsLst([
			settings?.INVOICE_EXPORT_EXCEL &&
				isActionAllowed(currentUserPermissions, "Invoices list", "Export excel"),
			settings?.INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY &&
				isActionAllowed(currentUserPermissions, "Invoices list", "Export Excel Journal Entry"),
			settings?.INVOICE_EXPORT_XML &&
				isActionAllowed(currentUserPermissions, "Invoices list", "Export xml"),
		]);
	}, [settings]);

	return (
		<>
			<Tooltip placement="top" title={t("tooltip.action.export")}>
				<div>
					{loading ? (
						<Box sx={{ display: "flex" }}>
							<CircularProgress size={14} />
						</Box>
					) : (
						<>
							{showMenuItem && (
								<IconButton
									id={`invoice-export-dropdown-${index}`}
									onClick={(e) => {
										openMenu(e);
									}}
								>
									<ImportExportIcon style={{ color: "#707070" }} />
								</IconButton>
							)}
						</>
					)}
					<Menu
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={showMenu}
						onClose={closeMenu}
						style={{ display: "flex" }}
						id={`invoice-export-dropdown-container-${index}`}
					>
						{settingsLst[0] ? (
							<MenuItem
								id={`invoice-export-excel-${index}`}
								disabled={get(row, "status.code") === "INTEGRATION"}
								onClick={() => {
									enableLoading();
									dispatch(downloadInvoiceExcel(row.id, onError, disableLoading));
									closeMenu();
								}}
							>
								Excel
							</MenuItem>
						) : null}

						{settingsLst[1] ? (
							<MenuItem
								id={`invoice-export-acc-${index}`}
								disabled={get(row, "status.code") === "INTEGRATION"}
								onClick={() => {
									enableLoading();
									dispatch(exportSingleAccountingEntryExcel(row.id, onError, disableLoading));
									closeMenu();
								}}
							>
								{t("invoice.export.entry_account_excel")}
							</MenuItem>
						) : null}

						{settingsLst[2] ? (
							<MenuItem
								id={`invoice-export-xml-${index}`}
								onClick={() => {
									enableLoading();
									dispatch(downloadXMLInvoice(row.id, onError, disableLoading));
									closeMenu();
								}}
							>
								XML
							</MenuItem>
						) : null}
					</Menu>
				</div>
			</Tooltip>
		</>
	);
}
