import {
	Grid,
	Typography,
	TextField,
	Select,
	Input,
	MenuItem,
	ListItemText,
	Divider,
	Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import React, { useState } from "react";
import GlAccountsBox from "./GlAccountsBox";
import useStyles from "../style";

const AccountingLines = ({
	rectify,
	formulaire,
	invoice,
	addAccountingLine,
	deleteAccountingLine,
	handleAccountingLineChange,
	handleAccountingChange,
	glAccounts,
	costCenters,
	paymentMethods,
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [costCenterValue, setCostCenterValue] = useState("");
	const [paymentMethodValue, setPaymentMethodValue] = useState("");

	const accountingLines =
		(rectify
			? formulaire?.invoiceDto?.invoiceAccounting?.accountingLines
			: invoice?.invoiceAccounting?.accountingLines) ?? [];

	const add = (accumulator, a) =>
		accumulator + (a.amount ? (a.sign === "D" ? Number(a.amount) : -Number(a.amount)) : 0);

	const total = accountingLines.reduce(add, 0);
	/*
	useEffect(() => {
		if (get(formulaire.invoiceDto, "invoiceAccounting.costCenter.id"))
			setCostCenterValue(get(formulaire.invoiceDto, "invoiceAccounting.costCenter.id"));
		if (get(formulaire.invoiceDto, "invoiceAccounting.paymentMethod.id"))
			setPaymentMethodValue(get(formulaire.invoiceDto, "invoiceAccounting.paymentMethod.id"));
	}, [formulaire.invoiceDto]);
	*/
	return (
		<>
			<Grid item container xs={12} className={classes.accountingLineRow}>
				<Grid
					item
					container
					xs={6}
					justify="flex-end"
					style={{
						paddingRight: 20,
					}}
				>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("accountingLines.costCenter")}
						<br />
						{rectify ? (
							<Select
								labelId="costCenter"
								id="costCenter"
								name="costCenter"
								value={costCenterValue}
								onChange={(e) => {
									setCostCenterValue(e.target.value);
									handleAccountingChange(e.target.name, e.target.value);
								}}
								className={classes.resize}
								fullWidth
								displayEmpty
								input={<Input name="costCenter" id="costCenter" />}
							>
								<MenuItem value="">
									<ListItemText primary="" />
								</MenuItem>
								{(costCenters ?? []).map((costCenter) => (
									<MenuItem value={costCenter.id}>
										<ListItemText primary={costCenter.costCenter} />
									</MenuItem>
								))}
							</Select>
						) : (
							<Typography variant="body2" align="left" className={classes.blackColor}>
								{get(invoice, "invoiceAccounting.costCenter.costCenter")}
								<Divider />
							</Typography>
						)}
					</Typography>
				</Grid>
				<Grid
					item
					container
					xs={6}
					style={{
						paddingLeft: 20,
					}}
				>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("accountingLines.paymentMethod")}
						<br />
						{rectify ? (
							<Select
								labelId="paymentMethod"
								id="paymentMethod"
								name="paymentMethod"
								className={classes.resize}
								value={paymentMethodValue}
								onChange={(e) => {
									setPaymentMethodValue(e.target.value);
									handleAccountingChange(e.target.name, e.target.value);
								}}
								fullWidth
								displayEmpty
								InputProps={{
									classes: {
										input: classes.resize,
									},
								}}
								input={<Input name="paymentMethod" id="paymentMethod" />}
							>
								<MenuItem value="">
									<ListItemText primary="" />
								</MenuItem>
								{(paymentMethods ?? []).map((paymentMethod) => (
									<MenuItem value={paymentMethod.id}>
										<ListItemText primary={paymentMethod.paymentMethod} />
									</MenuItem>
								))}
							</Select>
						) : (
							<Typography variant="body2" align="left" className={classes.blackColor}>
								{get(invoice, "invoiceAccounting.paymentMethod.paymentMethod")}
								<Divider />
							</Typography>
						)}
					</Typography>
				</Grid>
			</Grid>
			{accountingLines.map((line, index) => (
				<Grid item container xs={12} key={index} className={classes.accountingLineRow}>
					<Grid container item xs={3} className={classes.accountingLinesItem} alignItems="flex-end">
						<Typography
							variant="subtitle2"
							align="left"
							color="primary"
							classes={{ root: classes.lineFieldFullWidth }}
						>
							{t("accountingLines.glAccounts")}
							<br />
							{rectify ? (
								<GlAccountsBox
									selectedAccount={get(line, "glAccount")}
									index={index}
									onChangeAccount={handleAccountingLineChange}
									glAccounts={glAccounts}
								/>
							) : (
								<Typography variant="body2" align="left" className={classes.blackColor}>
									{get(line, "glAccount")}
									<Divider />
								</Typography>
							)}
						</Typography>
					</Grid>
					<Grid
						item
						container
						xs={rectify ? 4 : 5}
						className={classes.accountingLinesItem}
						alignItems="flex-end"
					>
						<Typography
							variant="subtitle2"
							align="left"
							color="primary"
							classes={{ root: classes.lineFieldFullWidth }}
						>
							{t("accountingLines.description")}
							<br />
							{rectify ? (
								<TextField
									fullWidth
									id="description"
									name="description"
									onChange={(e) => {
										handleAccountingLineChange(index, e.target.name, e.target.value);
									}}
									value={get(line, "description")}
									InputProps={{
										classes: {
											input: classes.resize,
										},
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							) : (
								<Typography
									variant="body2"
									align="left"
									style={{
										width: "100%",
									}}
									className={classes.blackColor}
								>
									{get(line, "description")}
									<Divider />
								</Typography>
							)}
						</Typography>
					</Grid>
					<Grid item xs={2} container alignItems="flex-end" className={classes.accountingLinesItem}>
						<Typography
							variant="subtitle2"
							align="left"
							color="primary"
							classes={{ root: classes.lineFieldFullWidth }}
						>
							{t("accountingLines.sign")}

							<br />
							{rectify ? (
								<Select
									labelId="operation"
									id="operation"
									name="sign"
									className={classes.resize}
									value={get(line, "sign")}
									onChange={(e) => {
										handleAccountingLineChange(index, e.target.name, e.target.value);
									}}
									fullWidth
									displayEmpty
									classes={{
										root: classes.selectRoot,
									}}
									input={<Input name="operation" id="operation" />}
								>
									<MenuItem value="">
										<ListItemText primary="" />
									</MenuItem>
									<MenuItem value="D">
										<ListItemText primary={t("Debit")} />
									</MenuItem>
									<MenuItem value="C">
										<ListItemText primary={t("Credit")} />
									</MenuItem>
								</Select>
							) : (
								<Typography variant="body2" align="left" className={classes.blackColor}>
									{get(line, "sign")}
									<Divider />
								</Typography>
							)}
						</Typography>
					</Grid>
					<Grid item container alignItems="flex-end" xs={2} className={classes.accountingLinesItem}>
						<Typography
							variant="subtitle2"
							align="left"
							color="primary"
							classes={{ root: classes.lineFieldFullWidth }}
						>
							{t("accountingLines.amount")}
							<br />
							{rectify ? (
								<TextField
									fullWidth
									id="amount"
									name="amount"
									onChange={(e) => {
										handleAccountingLineChange(index, e.target.name, e.target.value);
									}}
									value={get(line, "amount")}
									InputProps={{
										classes: {
											input: classes.resize,
										},
									}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							) : (
								<Typography variant="body2" align="left" className={classes.blackColor}>
									{get(line, "amount")}
									<Divider />
								</Typography>
							)}
						</Typography>
					</Grid>
					{rectify && (
						<IconButton
							aria-label="delete"
							onClick={() => {
								deleteAccountingLine(index);
							}}
						>
							<DeleteIcon />
						</IconButton>
					)}
				</Grid>
			))}
			{rectify && (
				<Grid item container xs={12} className={classes.accountingLineRow}>
					<Button variant="outlined" fullWidth color="secondary" onClick={addAccountingLine}>
						{t("accountingLines.addButton")}
					</Button>
				</Grid>
			)}
			{total !== 0 && (
				<Grid item container xs={12} className={classes.accountingLineRow}>
					<Typography
						variant="subtitle2"
						align="left"
						color="primary"
						style={{
							color: "red",
						}}
					>
						{t("accountingLines.summary")}: {total} €
					</Typography>
				</Grid>
			)}
		</>
	);
};

export default AccountingLines;
