import appSlice from "../../App/appSlice";
import filterListSuppliers from "../supplierFilterReducer";
import filterListClients from "../clientFilterReducer";
import filterListInvoices from "../filterReducer";
import formulaireReducer from "../formulaireReducer";
import orderFormulaireReducer from "../orderFormulaireReducer";
import filterListUsers from "../../administration/redux/reducers/UserFilterReducer";
import filterListOrders from "../orderFilterReducer";

export default {
	appState: appSlice,
	filterInvoices: filterListInvoices,
	filterSuppliers: filterListSuppliers,
	filterClients: filterListClients,
	filterOrders: filterListOrders,
	filterUsers: filterListUsers,
	formulaire: formulaireReducer,
	orderFormulaire: orderFormulaireReducer,
};
