import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  select: {
    padding: theme.spacing(1)
  },
  selectText: { color: theme.palette.secondary.main },
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
