import { makeStyles } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";

export default makeStyles((theme) => ({
	root: {
		height: theme.custom.variables.headerHeight,
	},
	rootSticky: {
		height: 0,
	},
	gridContainer: {
		padding: theme.spacing(0, 4),
		height: "100%",
	},
	logo: {
		width: 150,
	},
	title: {
		lineHeight: 1,
		color: "#FFFFFF",
	},
	purple: {
		color: theme.palette.getContrastText(indigo[500]),
		backgroundColor: indigo[500],
	},
	popupItems: {
		margin: "5px 10px",
		width: "120px",
		textAlign: "center",
	},
	toolbar: {
		paddingRight: 24,
		justifyContent: "space-between",
	},
	appBar: {
		borderTopLeftRadius: 5,
		marginLeft: theme.spacing(9),
		width: `calc(100% - ${theme.spacing(9)}px)`,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: theme.custom.variables.menuLeftWidth,
		width: `calc(100% - ${theme.custom.variables.menuLeftWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	switcher: {
		position: "absolute",
		left: "50%",
		transform: "translateX(-50%)",
	},
}));
