import i18n from "i18next";
import countries from "i18n-iso-countries";
import { initReactI18next } from "react-i18next";
import en from "./en";
import fr from "./fr";
import es from "./es";
import ar from "./ar";
import de from "./de";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: en,
	},
	fr: {
		translation: fr,
	},
	es: {
		translation: es,
	},
	ar: {
		translation: ar,
	},
	de: {
		translation: de,
	},
};
i18n.use(initReactI18next).init({
	resources,
	lng: localStorage.getItem("lgn") ? localStorage.getItem("lgn") : "fr",
	keySeparator: false,
	interpolation: {
		escapeValue: false,
	},
});

countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ar.json"));

export default i18n;
