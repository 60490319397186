/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useStyles from "./style";
export default function SuplierSelect({
	value,
	onChange,
	onBlur,
	name,
	inputRef,
	disabled,
	suplierList,
	placeholder,
}) {
	const classes = useStyles();
	const [stateValue, setStateValue] = React.useState({});
	const [inputValue, setInputValue] = React.useState("");

	useEffect(() => {
		if (suplierList.length === 1) {
			if (suplierList[0] === value || value === undefined) {
				onChange(suplierList[0]);
				setStateValue(suplierList[0]);
			}
		}
		if (suplierList && value) {
			if (suplierList.includes(value))
				setStateValue(suplierList.find((suplier) => suplier === value));
			else {
				setStateValue(value);
			}
		}
	}, [suplierList, value]);

	return (
		<Autocomplete
			id="code-select-demo"
			fullWidth
			options={suplierList ?? []}
			classes={{
				option: classes.option,
			}}
			autoHighlight
			getOptionLabel={(option) => option}
			value={stateValue}
			onBlur={(event) => {
				onBlur(event);
			}}
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			onChange={(event) => {
				setStateValue(event.target.value);
				onChange(event.target.value);
			}}
			freeSolo={suplierList.length === 1 || suplierList.length === 0}
			disabled={disabled}
			renderOption={(option) => <>{option}</>}
			renderInput={(params) => (
				<TextField
					placeholder={!suplierList.length ? placeholder : placeholder}
					{...params}
					inputProps={{
						...params.inputProps,
						autoComplete: "new-password", // disable autocomplete and autofill
					}}
					onChange={(event) => {
						setStateValue(event.target.value);
						onChange(event.target.value);
					}}
					inputRef={inputRef}
					disabled={disabled}
					name={name}
					fullWidth
				/>
			)}
		/>
	);
}
