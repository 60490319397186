import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
	option: {
		fontSize: 15,
		"& > span": {
			marginRight: 10,
			fontSize: 18,
		},
	},
}));
