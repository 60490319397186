import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import FileCopyRounded from "@material-ui/icons/FileCopyOutlined";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import DashboardCard from "../../../../components/DashboardCard/index";
import { currencyFormat } from "../../../../utils/index";
import {
	// invoicesTotalAmounts,
	invoicesTotalVATAmounts,
} from "../../../../redux/actions/invoices/index";

function PaidInvoice(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const invoicesTotalAmountsPaid = useSelector(
		({ requests }) =>
			requests.queries.INVOICE_TOTAL_AMOUNT_PAID && requests.queries.INVOICE_TOTAL_AMOUNT_PAID.data
	);
	const invoicesTotalVatAmountsPaid = useSelector(
		({ requests }) =>
			requests.queries.INVOICE_TOTAL_VAT_AMOUNT_PAID &&
			requests.queries.INVOICE_TOTAL_VAT_AMOUNT_PAID.data
	);
	useEffect(() => {
		// dispatch(invoicesTotalAmounts());
		dispatch(invoicesTotalVATAmounts(["EXPORTED"], "_PAID"));
	}, []);
	return (
		<DashboardCard
			title={t("invoice.exported")}
			icon={FileCopyRounded}
			{...props} // eslint-disable-line
		>
			<Grid container spacing={2} justify="center">
				<Grid item xs={6}>
					<Typography variant="h5" color="primary" gutterBottom align="center">
						{`${
							invoicesTotalAmountsPaid
								? currencyFormat(invoicesTotalAmountsPaid)
								: currencyFormat(0)
						} €`}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" gutterBottom align="center">
						{t("Total amount of invoices")}
					</Typography>
				</Grid>
				<Grid item xs={6} className="p-relative">
					<Divider orientation="vertical" absolute />

					<Typography variant="h5" color="primary" gutterBottom align="center">
						{`${
							invoicesTotalVatAmountsPaid
								? currencyFormat(invoicesTotalVatAmountsPaid)
								: currencyFormat(0)
						} €`}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" gutterBottom align="center">
						{t("Total VAT amount")}
					</Typography>
				</Grid>
			</Grid>
		</DashboardCard>
	);
}

export default PaidInvoice;
