import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TableSuperUsersList from "administration/component/SUTablee";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { fetchSuperUsers } from "../redux/actions";

import { setIsUserModif } from "../../App/appSlice";

const columns = ["status", "lastName", "firstName", "userFunction"];

export const ListSupperUsers = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	const [addUserMode, setAddUserMode] = React.useState(false); // eslint-disable-line
	const [updateUserMode, setUpdateUserMode] = React.useState(false); // eslint-disable-line
	const [idUserToModify, setIdUserToModify] = React.useState(null); // eslint-disable-line
	const [typeUser] = React.useState("USER");

	const [selected, setSelected] = React.useState([]);
	const idUsers = useSelector(
		({ requests }) =>
			requests.queries.FETCH_SUPER_USERS &&
			requests.queries.FETCH_SUPER_USERS.data &&
			requests.queries.FETCH_SUPER_USERS.data.map((user) => user.id)
	);
	const handleSelectedAll = (checked) => {
		setSelected(checked ? idUsers : []);
	};
	const setUserToModify = (id) => {
		setIdUserToModify(id);
		dispatch(setIsUserModif());
	};

	useEffect(() => {
		dispatch(fetchSuperUsers());
	}, []);
	return (
		<>
			<Grid container item xs={12}>
				<Grid container xs={12} direction="row" justify="flex-end" style={{ height: 50 }}>
					<Grid container xs={2}>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => history.push("/administration/su/add")}
							fullWidth
						>
							{t("add_user")}
						</Button>
					</Grid>
				</Grid>
				<TableSuperUsersList
					selected={selected}
					columns={columns}
					typeUser={typeUser}
					setSelected={setSelected}
					handleSelectedAll={handleSelectedAll}
					setUpdateUserMode={setUpdateUserMode}
					setUserToModify={setUserToModify}
					unclickeable
					module="su"
				/>

			</Grid>
		</>
	);
};

export default ListSupperUsers;
