import * as React from "react";
import { useTranslation } from "react-i18next";
import {
	Chip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	IconButton,
	Tooltip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@material-ui/core";
import Description from "@material-ui/icons/Description";
import InvalidIcon from "@material-ui/icons/Close";
import ValidIcon from "@material-ui/icons/Check";
import UndefinedIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";

function iconStyles() {
	return {
		valid: {
			color: "green",
		},
		invalid: {
			color: "red",
		},
		undefined: {
			color: "grey",
		},
		closeButton: {
			position: "absolute",
			right: "0.5rem",
			top: "0.5rem",
		},
	};
}

const DigitalSummary = ({ dataFromXml }) => {
	const { t } = useTranslation();
	const classes = makeStyles(iconStyles)();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Tooltip title={t("invoice.digital.readmore")} placement="top" arrow>
				<Chip
					align="left"
					icon={<Description style={{ paddingLeft: 3, fontSize: 25 }} />}
					label={t("invoice.digital")}
					color="primary"
					onClick={handleOpen}
				/>
			</Tooltip>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title" style={{ marginLeft: "0.5em" }}>
					{t("invoice.digital.about")}
				</DialogTitle>

				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
					<InvalidIcon />
				</IconButton>

				<DialogContent>
					<DialogContentText id="scroll-dialog-description" tabIndex={-1}>
						<TableContainer>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell align="left">{t("invoice.digital.type")}</TableCell>
										<TableCell align="center">{dataFromXml.invoiceTypeLabel}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left">{t("invoice.digital.signature.validity")}</TableCell>
										<TableCell align="center">
											{dataFromXml.validationReport.signatureStatus === "VERIFIED" ? (
												<ValidIcon className={classes.valid} />
											) : dataFromXml.validationReport.signatureStatus === "UNVERIFIED" ? (
												<InvalidIcon className={classes.invalid} />
											) : (
												<UndefinedIcon className={classes.undefined} />
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left">{t("invoice.digital.format.compliance")}</TableCell>
										<TableCell align="center">
											{dataFromXml.validationReport.formatCompliance === "VALID" ? (
												<ValidIcon className={classes.valid} />
											) : (
												<InvalidIcon className={classes.invalid} />
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left">{t("invoice.digital.en16931.compliance")}</TableCell>
										<TableCell align="center">
											{dataFromXml.validationReport.en16931Compliance === "VALID" ? (
												<ValidIcon className={classes.valid} />
											) : dataFromXml.validationReport.en16931Compliance === "INVALID" ? (
												<InvalidIcon className={classes.invalid} />
											) : (
												<UndefinedIcon className={classes.undefined} />
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left">
											{t("invoice.digital.en16931.rules.violated")}
										</TableCell>
										<TableCell align="center">
											{dataFromXml.validationReport.en16931RulesViolation.length}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left">{t("invoice.digital.chorus.pro.compliance")}</TableCell>
										<TableCell align="center">
											{dataFromXml.validationReport.chorusProCompliance === "VALID" ? (
												<ValidIcon className={classes.valid} />
											) : dataFromXml.validationReport.chorusProCompliance === "INVALID" ? (
												<InvalidIcon className={classes.invalid} />
											) : (
												<UndefinedIcon className={classes.undefined} />
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell align="left">
											{t("invoice.digital.chorus.pro.rules.violated")}
										</TableCell>
										<TableCell align="center">
											{dataFromXml.validationReport.chorusProRulesViolation.length}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default DigitalSummary;
