import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/MenuOutlined";
import ArrowDropDown from "@material-ui/icons/ArrowDropDownOutlined";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { CardActionArea, ListItemText } from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { toogleMenu } from "../../../appSlice";
import useStyles from "../style";
import i18n from "../../../../i18n/index";
import { logout } from "../../../../redux/actions/invoices";

const dataLang = [
	{
		flag: "us",
		key: "en",
		text: "English",
	},
	{
		flag: "fr",
		key: "fr",
		text: "Français",
	},
	// {
	// 	flag: "es",
	// 	key: "es",
	// 	text: "Español",
	// },
	// {
	// 	flag: "ma",
	// 	key: "ar",
	// 	text: "العربية",
	// },
	// {
	// 	flag: "de",
	// 	key: "de",
	// 	text: "Deutsche",
	// },
];

const getUserInitials = (currentUser) => {
	const firstName = get(currentUser, "firstName", "").charAt(0).toUpperCase();
	const lastName = get(currentUser, "lastName", "").charAt(0).toUpperCase();

	return firstName + lastName;
};

const changeLanguage = (language, setAnchorLangEl) => {
	setAnchorLangEl(null);
	i18n
		.changeLanguage(language)
		.then(() => {
			localStorage.setItem("lgn", language);
			window.location.reload();
		})
		.catch((error) => {
			console.error("Error changing language:", error);
		});
};

export default () => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorLangEl, setAnchorLangEl] = React.useState(null);

	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const headerExpanded = useSelector(({ appState }) => appState.headerExpanded);

	return (
		<AppBar
			color="inherit"
			position={headerExpanded ? "relative" : "fixed"}
			className={clsx(classes.appBar, menuExpanded && classes.appBarShift, classes.appBarFolded)}
			elevation={24}
		>
			<Toolbar className={classes.toolbar} variant="dense">
				<div className="align-items-center d-inline-flex">
					<IconButton
						edge="start"
						color="primary"
						aria-label="open Menu"
						className={classes.menuButton}
						onClick={() => dispatch(toogleMenu())}
					>
						<MenuIcon />
					</IconButton>
				</div>
				<div className="align-items-center d-inline-flex">
					<IconButton onClick={(e) => setAnchorLangEl(e.currentTarget)}>
						<Typography
							className={`flag-icon flag-icon-${
								dataLang.find(({ key }) => key === i18n.language)?.flag
							} flag-icon-squared`}
						/>
					</IconButton>
					{!headerExpanded && (
						<IconButton onClick={(e) => setAnchorEl(e.currentTarget)} edge="end">
							<Badge
								overlap="circle"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								badgeContent={<ArrowDropDown color="secondary" />}
							>
								<Avatar className={classes.purple}>{getUserInitials(currentUser)}</Avatar>
							</Badge>
						</IconButton>
					)}
				</div>
			</Toolbar>

			<Popover
				id="profile-popover"
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<CardActionArea onClick={() => history.push("/profile")}>
					<ListItemText
						primary={t("Profile")}
						style={{
							margin: "5px 10px",
							width: "100px",
							textAlign: "center",
						}}
					/>
				</CardActionArea>
				<CardActionArea onClick={() => dispatch(logout())}>
					<ListItemText
						primary={t("Logout")}
						style={{
							margin: "5px 10px",
							width: "100px",
							textAlign: "center",
						}}
					/>
				</CardActionArea>
			</Popover>
			<Menu
				id="langage-menu"
				anchorEl={anchorLangEl}
				keepMounted
				open={Boolean(anchorLangEl)}
				onClose={() => setAnchorLangEl(null)}
			>
				{dataLang.map(({ flag, key, text }, i) => (
					<MenuItem
						key={i}
						onClick={() => changeLanguage(key, setAnchorLangEl)}
						selected={key === i18n.language}
					>
						<ListItemIcon>
							<Typography className={`flag-icon flag-icon-${flag} flag-icon-squared`} />
						</ListItemIcon>
						{text}
					</MenuItem>
				))}
			</Menu>
		</AppBar>
	);
};
