import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Dialog,
	Paper,
	Grid,
	Tabs,
	Tab,
	IconButton,
	FormControlLabel,
	Switch,
	Checkbox,
	Divider,
	FormGroup,
	Button,
	DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { get, orderBy } from "lodash";
import SubPagePanel from "components/SubPagePanel";
import { removeSpacesAndTrim } from "utils/index";
import ConfirmDialog from "components/ConfirmDialog";
import { updateUserPermissions } from "administration/redux/actions";

const PermissionManagement = ({ isOpen, setIsOpen, row, classes, dispatch, fetchEntities }) => {
	const { t } = useTranslation();
	const initPermissions = (userPermissions) =>
		userPermissions ? JSON.parse(userPermissions) : null;
	const [permissions, setPermissions] = useState(initPermissions(get(row, "permissions")));
	const [confirmObject, setConfirmObject] = React.useState({
		isOpen: false,
		message: t("user.permission.confirmSave"),
		isLoading: false,
	});
	const [value, setValue] = React.useState(0);

	const cancelConfirm = () => {
		setConfirmObject({ ...confirmObject, isLoading: false, isOpen: false });
	};

	const handleSave = () => {
		setConfirmObject({ ...confirmObject, isOpen: true });
	};

	const savePermissions = () => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		dispatch(updateUserPermissions(get(row, "id"), permissions)).then((res) => {
			if (res.status === 200) {
				fetchEntities();
			}
			cancelConfirm();
		});
	};

	const getPermissionsTabItems = () => {
		const uniqueMenus = new Set(
			permissions
				?.map((item) => item?.menu)
				?.reduce((prev, curr) => [...prev, ...curr])
				?.map((item) => item.toUpperCase())
				?.sort()
		);
		return [...uniqueMenus];
	};

	const getMenuItems = (name) => {
		const res = permissions?.filter((menuItem) => menuItem?.menu?.includes(name));
		if (res != null) return orderBy(res, "index", "asc");
		return [];
	};

	const handleClose = () => {
		setPermissions(null);
		setIsOpen(!isOpen);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleMenuItemChange = (event, itemIndex) => {
		const permissionsCopy = [...permissions];
		permissions.forEach((item) => {
			if (item.index === itemIndex) {
				item.enabled = event.target.checked;
			}
		});
		permissionsCopy.forEach((menuItem) => {
			if (menuItem.index === itemIndex) {
				permissionsCopy[itemIndex].actions.forEach((itemAction) => {
					itemAction.enabled = event.target.checked;
				});
			}
		});
		setPermissions(permissionsCopy);
	};

	const handleActionChange = (event, itemIndex, actionIndex) => {
		const permissionsCopy = [...permissions];
		permissionsCopy.forEach((menuItem) => {
			if (menuItem.index === itemIndex) {
				permissionsCopy[itemIndex].actions.forEach((itemAction) => {
					if (itemAction.index === actionIndex) {
						itemAction.enabled = event.target.checked;
					}
				});
			}
		});
		setPermissions(permissionsCopy);
	};

	function a11yProps(index) {
		return {
			id: `permission-menu-tab-${index}`,
			"aria-controls": `permission-menu-tabpanel-${index}`,
		};
	}

	return (
		<>
			<Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
				<Paper square>
					<Grid container direction="row" xs={12}>
						<Grid container direction="row" xs={11}>
							<Tabs
								value={value}
								indicatorColor="primary"
								textColor="primary"
								onChange={handleChange}
								aria-label="switch tabs"
							>
								{permissions &&
									getPermissionsTabItems().map((menuItem, i) => (
										<Tab
											key={`permission-tab-page-${i}`}
											value={i}
											label={t(menuItem)}
											{...a11yProps(0)}
										/>
									))}
							</Tabs>
						</Grid>
						<Grid container direction="row" xs={1} justify="flex-end">
							<IconButton aria-label="close" onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Paper>
				{permissions &&
					getPermissionsTabItems()?.map((menuName, menuIndex) => (
						<SubPagePanel
							key={`permission-sub-page-panel-${menuIndex}`}
							value={value}
							index={menuIndex}
						>
							<FormGroup>
								{getMenuItems(menuName)?.map((menuItem, itemIndex) => (
									<>
										<Grid container item xs={12} style={{ padding: "10px " }}>
											<Grid item container xs={3}>
												<FormControlLabel
													key={`menu-item-${itemIndex}`}
													control={
														<Switch
															id={`menu-item-switch-${removeSpacesAndTrim(
																menuItem.subMenu || menuItem.name
															)}`}
															checked={menuItem.enabled}
															onChange={(e) => handleMenuItemChange(e, menuItem.index)}
														/>
													}
													label={t(menuItem.label)}
												/>
											</Grid>
											<Grid container item xs={9}>
												{menuItem &&
													menuItem?.actions &&
													menuItem?.actions.map((menuItemAction, actionIndex) => (
														<Grid item container xs={4}>
															<FormControlLabel
																key={`menu-item-action-${actionIndex}`}
																control={
																	<Checkbox
																		id={`menu-item-action-${menuItemAction.name}`}
																		checked={menuItemAction.enabled}
																		disabled={!menuItem.enabled}
																		onChange={(e) =>
																			handleActionChange(e, menuItem.index, menuItemAction.index)
																		}
																	/>
																}
																label={t(menuItemAction.label)}
															/>
														</Grid>
													))}
											</Grid>
										</Grid>
										<Divider className={classes.menuItemDivider} />
									</>
								))}
							</FormGroup>
						</SubPagePanel>
					))}
				<DialogActions>
					<Grid
						container
						item
						xs={12}
						direction="row"
						justify="flex-end"
						style={{ margin: "10px" }}
					>
						<Button
							id="save-user-permissions-button-btn"
							color="secondary"
							variant="contained"
							onClick={handleSave}
							style={{ marginRight: 10 }}
						>
							{t("user.permission.save")}
						</Button>
						<Button
							id="cancel-user-permission-template-btn"
							variant="outlined"
							color="secondary"
							onClick={handleClose}
							style={{ marginRight: 10 }}
						>
							{t("cancel")}
						</Button>
					</Grid>
				</DialogActions>
			</Dialog>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.isOpen}
				onCancel={cancelConfirm}
				onConfirm={savePermissions}
				isLoading={confirmObject.isLoading}
			/>
		</>
	);
};

export default PermissionManagement;
