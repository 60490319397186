import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	Grid,
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
	Dialog,
	DialogContent,
	TableContainer,
	Table,
	TableCell,
	TableBody,
	TableRow,
	TableHead,
	Paper,
	IconButton,
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import { useQuery } from "@redux-requests/react";
import { useDispatch } from "react-redux";
import useStyles from "./style";
import { fetchInvoiceHistory } from "../../../../../redux/actions/invoices";
import CircularLoader from "../../../../../components/Loaders/CircularLoader";
import { FETCH_INVOICE_HISTORY_LIST } from "../../../../../redux/constants";

const ChangeLogDialog = ({ open, rows, setIsOpen, t }) => {
	const handleCloseCancelPopup = () => {
		setIsOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseCancelPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			style={{ minWidth: 850 }}
		>
			<DialogContent>
				<TableContainer component={Paper}>
					<Table style={{ minWidth: 450 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell align="left">{t("history.fieldName")}</TableCell>
								<TableCell align="left">{t("history.oldValue")}</TableCell>
								<TableCell align="left">{t("history.newValue")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Object.keys(rows).map((field) => (
								<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell align="left">
										{t(field.split("-")[0])} {field.split("-")[1] ? ` ${field.split("-")[1]}` : ""}
									</TableCell>
									<TableCell align="left">{rows[field]?.prev}</TableCell>
									<TableCell align="left">{rows[field]?.new}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
		</Dialog>
	);
};

function History(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = React.useState(false);
	const [currentRow, setCurrentRow] = React.useState({});
	const { data, loading } = useQuery({ type: FETCH_INVOICE_HISTORY_LIST });

	const parsedData = data?.map((his) => ({ ...his, changeLog: JSON.parse(his.changeLog) }));

	useEffect(() => {
		dispatch(fetchInvoiceHistory(props.idInvoice));
	}, []);

	const handleShowMoreChangeLog = (changeLogs) => {
		setIsOpen(true);
		setCurrentRow(changeLogs);
	};

	return (
		<Grid xs={12} className={classes.historyContainer}>
			<List>
				{loading ? (
					<CircularLoader />
				) : (
					parsedData?.map((row) => (
						<ListItem>
							<ListItemText
								primary={
									<Typography>
										<strong>@{row.userFullName}</strong>
										{"  -  "}
										{row.date}
									</Typography>
								}
								secondary={
									<Grid container xs={12} spacing={2} className={classes.spaceTop}>
										<Grid container item xs={12} spacing={2}>
											{row.statusCode && (
												<Grid item container xs={12}>
													<Grid item xs={4}>
														{t("action")}
													</Grid>
													<Grid item xs={6}>
														{t(row.statusCode, { status: t(row.statusLabel) })}
													</Grid>
													<Grid item xs={2}>
														{row.changeLog && (
															<Grid item container xs={12}>
																<IconButton
																	variant="outlined"
																	onClick={() => handleShowMoreChangeLog(row.changeLog)}
																>
																	<MoreIcon />
																</IconButton>
															</Grid>
														)}
													</Grid>
												</Grid>
											)}
											{row.information && (
												<Grid item container xs={12}>
													<Grid item xs={4}>
														{t("details")}
													</Grid>
													<Grid container item xs={8}>
														<Grid item xs={12}>
															{t(row.information)}
														</Grid>
														<Grid item xs={12}>
															{row.comment}
														</Grid>
													</Grid>
												</Grid>
											)}
											{row.statusCode === "CREATE_ACTION" &&
												(props.emailSubject || props.emailDate) && (
													<Grid item container xs={12}>
														<Grid item xs={4}>
															{t("details")}
														</Grid>
														<Grid container item xs={8}>
															<Grid item xs={12}>
																{t("msg.date.email") +
																	props.emailDate +
																	t("msg.subject.email") +
																	props.emailSubject}
															</Grid>
														</Grid>
													</Grid>
												)}
										</Grid>
										<Grid item xs={12} spacing={2}>
											<Divider />
										</Grid>
									</Grid>
								}
							/>
						</ListItem>
					))
				)}
			</List>
			<ChangeLogDialog open={isOpen} setIsOpen={setIsOpen} rows={currentRow} t={t} />
		</Grid>
	);
}
export default History;
