import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import SiretSelect from "components/Identification/SiretSelect";
import SelectAutocomplete from "components/Identification/SelectAutocomplete";
import SupplierSelect from "components/Identification/SuplierSelect";

import { fetchDataFromApiSiren } from "../../../redux/actions/suppliers";
import useStyles from "../style";
import { REGEX } from "../../../utils";

const Identification = (props) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const classes = useStyles();
	const [siretList, setSiret] = useState([]);
	const [suplierList, setSuplier] = useState([]);
	const [tvaList, setTVA] = useState([]);

	const valid = (datainput) => {
		const array = Array.from(datainput, (num) => Number(num)).map((e, index) =>
			index % 2 === 1 ? e * 2 : e * 1
		);
		const arrayFinal = array.map((e) =>
			e >= 10 ? Array.from(e.toString(), (num) => Number(num)).reduce((a, b) => a + b, 0) : e
		);
		const count = arrayFinal.reduce((a, b) => a + b, 0);
		if (count % 10 === 0) {
			dispatch(fetchDataFromApiSiren(datainput)).then(({ data }) => {
				if (data) {
					let List = data.map((e) => e.siret).filter((x, i, a) => a.indexOf(x) === i);
					setSiret(List.length === 1 && List[0] === null ? [] : List);

					List = data.map((e) => e.vatNumber).filter((x, i, a) => a.indexOf(x) === i);
					setTVA(List.length === 1 && List[0] === null ? [] : List);

					List = data.map((e) => e.name).filter((x, i, a) => a.indexOf(x) === i);
					setSuplier(List.length === 1 && List[0] === null ? [] : List);
				}
			});
		} else {
			setSiret([]);
			setTVA([]);
			setSuplier([]);
		}
	};
	const validData = (datas) => {
		if (!props.errors.companySiren && datas) {
			valid(datas);
		}
	};
	useEffect(() => {
		if (props.supplier?.companySiren) validData(props.supplier?.companySiren);
	}, [props.supplier?.companySiren]);
	return (
		<Grid container xs={12} justify="center">
			<Grid container item xs={8} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{props.isClient ? t("add.client.name") : t("supplier.add.suppliername")}
						<span style={{ color: "red" }}>*</span>
						<br />
						<SupplierSelect
							value={props.supplier?.code}
							onChange={(newValue) => {
								props.updateSupplier({
									target: {
										name: "code",
										value: newValue,
									},
								});
							}}
							onBlur={(event) => {
								props.setSupplier({
									...props.supplier,
									[event.target.name]: event.target.value.trim().replace(/\s\s+/g, " "),
								});
							}}
							name="code"
							inputRef={props.register({
								required: { value: true, message: t("supplier_required") },
							})}
							placeholder={props.isClient ? t("add.client.name") : t("supplier.add.suppliername")}
							disabled={!!props.edit}
							suplierList={suplierList}
							fullWidth
						/>
						<p className={classes.validation_error}>{props.errors.code?.message}</p>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.siren")}
						<br />
						<Input
							value={props.supplier?.companySiren}
							onChange={(event) => {
								props.updateSupplier(event);
								validData(event.target.value);
							}}
							inputRef={props.register({
								pattern: { value: REGEX.SIREN, message: t("siren_notValid") },
							})}
							name="companySiren"
							fullWidth
							placeholder={t("supplier.add.siren")}
						/>
						<p className={classes.validation_error}>{props?.errors?.companySiren?.message}</p>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={8} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.siret")}
						<br />
						<SiretSelect
							onChange={(newValue) => {
								props.updateManySupplier({
									companySiret: newValue,
									zipCode: undefined,
									codeNaf: undefined,
									city: undefined,
									street: undefined,
								});
							}}
							placeholder={t("supplier.add.siret")}
							value={props.supplier?.companySiret}
							name="companySiret"
							siretList={siretList}
						/>
					</Typography>
				</Grid>

				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.vat")}
						<br />
						<SelectAutocomplete
							onChange={(newValue) => {
								props.updateSupplier({
									target: {
										name: "vatNumber",
										value: newValue,
									},
								});
							}}
							value={props.supplier?.vatNumber}
							name="vatNumber"
							List={tvaList}
							placeholder={t("supplier.add.vat")}
						/>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default Identification;
