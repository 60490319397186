import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import UserFilter from "./UserFilter";
import StatusLegend from "../component/StatusLegend";
import TableUsersList from "../component/Table";
import { fetchUsers } from "../redux/actions";

import { updateFilter } from "../redux/reducers/UserFilterReducer";
import { setIsUserModif } from "../../App/appSlice";

const columns = ["status", "name", "email", "userFunction"];
const sortByList = ["email"];

export const ListClients = () => {
	const dispatch = useDispatch();

	const [idUserToModify, setIdUserToModify] = React.useState(null); // eslint-disable-line
	const [typeUser] = React.useState("CLIENT");

	const [selected, setSelected] = React.useState([]);
	const filterUsers = useSelector(({ filterUsers: i }) => i);

	const idUsers = useSelector(
		({ requests }) =>
			requests.queries.FETCH_USERS &&
			requests.queries.FETCH_USERS.data &&
			requests.queries.FETCH_USERS.data.content.map((user) => user.id)
	);
	const handleSelectedAll = (checked) => {
		setSelected(checked ? idUsers : []);
	};

	const setUserToModify = (id) => {
		setIdUserToModify(id);
		dispatch(setIsUserModif());
	};

	useEffect(() => {
		dispatch(updateFilter({ ...filterUsers, typeUser }));
		dispatch(fetchUsers({ ...filterUsers, typeUser }));
	}, []);
	return (
		<>
			<Grid container item xs={12}>
				<StatusLegend />
			</Grid>
			<Grid container item xs={12}>
				<UserFilter typeUser={typeUser} sortByList={sortByList} module="clients" />
			</Grid>
			<Grid container item xs={12}>
				<TableUsersList
					typeUser={typeUser}
					selected={selected}
					columns={columns}
					setSelected={setSelected}
					handleSelectedAll={handleSelectedAll}
					setIdUserToModify={setUserToModify}
					module="clients"
				/>
			</Grid>
		</>
	);
};

export default ListClients;
