import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Card, Divider  } from "@material-ui/core";
import get from "lodash/get";
import useStyles from "../style";
import {ImageProfil} from "../ImageProfil";
import {StringComponent} from "../StringComponent";
import {SelectComponent} from "../selectComponent";
import { fetchLegalForms } from "../../../../redux/actions/suppliers/index";

export const LegalInformations = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const legalForms = useSelector(({ requests }) =>
        get(requests, "queries.FETCH_LEGAL_FORM.data")
    );

    const onchangeSiret = (value) => {
        props.handleSupplier({ ...props.supplier, companySiret: value })
    }

    const onchangeVatNumber = (value) => {
        props.handleSupplier({ ...props.supplier, vatNumber: value })
    }
    const onchangeCapital = (value) => {
        props.handleSupplier({ ...props.supplier, capital: value })
    }
    const onchangeCodeNaf = (value) => {
        props.handleSupplier({ ...props.supplier, codeNaf: value })
    }
    const onchangeLegalForm = (value) => {
        props.handleSupplier({ ...props.supplier, legalForm: value })
    }
    const onchangeLabel = (value) => {
        props.handleSupplier({ ...props.supplier, label: value, code: value })
    }
    useEffect(() => {
        dispatch(fetchLegalForms())
    }, []);


    return (
        <Card className={classes.root}>
            <Grid container xs={12} spacing={4} >
                <Grid container item xs={3} spacing={1} justify="center" alignItems="center">
                    <Grid item>
                        <ImageProfil changeValue={props.setLogo} />
                    </Grid>
                    <Grid item>
                        <StringComponent label="Nom fournisseur" type="name" required changeValue={onchangeLabel} />
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid container item xs={9} >
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={4}>
                            <StringComponent label="Numéro siret" type="siret" required changeValue={onchangeSiret} />
                        </Grid>
                        <Grid item xs={4}>
                            < SelectComponent label="Form juridique" items={legalForms} changeValue={onchangeLegalForm} />
                        </Grid>
                        <Grid item xs={4}>
                            <StringComponent label="Code NAF" changeValue={onchangeCodeNaf} />
                        </Grid>

                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={4}>
                            <StringComponent label="numéro TVA" required changeValue={onchangeVatNumber} />
                        </Grid>
                        <Grid item xs={4}>
                            <StringComponent label="Capital social" type="amount" changeValue={onchangeCapital} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card >
    );
};

export default LegalInformations;
