import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useStyles from "./style";
export default function SelectAutocomplete({ value, onChange, name, inputRef, List, placeholder }) {
	const classes = useStyles();
	const [stateValue, setStateValue] = React.useState({});
	const [inputValue, setInputValue] = React.useState("");

	useEffect(() => {
		if (List.length === 1) {
			if (List[0] === value || value === undefined) {
				onChange(List[0]);
				setStateValue(List[0]);
			}
		}
		if (List && value) {
			if (List.includes(value)) {
				setStateValue(List.find((siret) => siret === value));
			} else {
				setStateValue(value);
			}
		}
	}, [List, value]);
	return (
		<>
			<Autocomplete
				id="open-on-focus"
				fullWidth
				options={List ?? []}
				classes={{
					option: classes.option,
				}}
				autoHighlight
				getOptionLabel={(option) => option}
				value={stateValue}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				onChange={(event, newValue) => {
					onChange(newValue);
					setStateValue(event.target.value);
				}}
				freeSolo={List.length === 1 || List.length === 0}
				renderOption={(option) => <>{option}</>}
				renderInput={(params) => (
					<TextField
						placeholder={placeholder}
						{...params}
						inputProps={{
							...params.inputProps,
							autoComplete: "new-password", // disable autocomplete and autofill
						}}
						name={name}
						inputRef={inputRef}
						onChange={(event) => {
							setStateValue(event.target.value);
							onChange(event.target.value);
						}}
						fullWidth
					/>
				)}
			/>
		</>
	);
}
