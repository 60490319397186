import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
	table: {
		maxWidth: "100vw",
		overflow: "hidden",
	},
	download_link: {
		maxWidth: "150px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});
