import React, { useEffect, useState } from "react";
import {
	Grid,
	Button,
	Input,
	IconButton,
	Tooltip,
	Typography,
	List,
	ListItemText,
	ListItem,
	ListItemIcon,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import EditIcon from "@material-ui/icons/Edit";
import { REGEX, toast } from "utils";
import { updateMailConfig, createMailConfig, fetchMailConfig } from "../../redux/actions";
import useStyles from "../style";
import ConfirmDialog from "../../../components/ConfirmDialog";

export const EmailConfig = () => {
	const mailConfigList = useSelector(
		({ requests }) => requests.queries.FETCH_MAIL_CONFIG && requests.queries.FETCH_MAIL_CONFIG.data
	);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();
	const { errors, handleSubmit, register, reset, setValue } = useForm({
		mode: "onChange",
		defaultValues: {
			mailServerUrl: "",
			mailServerPort: "",
			mailServerLogin: "",
			mailServerPassword: "",
			folderRead: "",
			folderProcess: "",
			folderSuccess: "",
			folderException: "",
		},
	});
	const [mailConfig, setMailConfig] = useState(null);
	const [edit, setEdit] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [confirmObject, setConfirmObject] = useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});
	const [mailConfigToSave, setMailConfigToSave] = React.useState(null);
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);

	const updateFormValues = (mailConfigObj) => {
		setValue("mailServerUrl", mailConfigObj?.mailServerUrl);
		setValue("mailServerPort", mailConfigObj?.mailServerPort);
		setValue("mailServerLogin", mailConfigObj?.mailServerLogin);
		setValue("mailServerPassword", mailConfigObj?.mailServerPassword);
		setValue("folderRead", mailConfigObj?.folderRead);
		setValue("folderProcess", mailConfigObj?.folderProcess);
		setValue("folderSuccess", mailConfigObj?.folderSuccess);
		setValue("folderException", mailConfigObj?.folderException);
	};

	const handleListItemClick = (event, index) => {
		setSelectedIndex(index);
	};

	useEffect(() => {
		if (mailConfigList && mailConfigList.length > 0) {
			updateFormValues(mailConfigList[selectedIndex]);
			setMailConfig(mailConfigList[selectedIndex]);
		}
	}, [edit, mailConfig, selectedIndex]);

	useEffect(() => {
		dispatch(fetchMailConfig(isOrderEnv ? "order" : "invoice"));
	}, [isOrderEnv]);

	useEffect(() => {
		if (mailConfigList && mailConfigList.length > 0) updateFormValues(mailConfigList[0]);
	}, [mailConfigList]);

	const onSubmit = (data) => {
		setMailConfigToSave(data);
		setConfirmObject({ message: t("admin.config.message.confirm"), openConfirm: true });
	};
	const handleSaveSuccess = () => {
		dispatch(fetchMailConfig(isOrderEnv ? "order" : "invoice"));
		setConfirmObject({ message: "", openConfirm: false, isLoading: false });
		setSelectedIndex(0);
		toast.success(t("admin.config.update-message"));
		setEdit(false);
	};
	const handleSaveFail = (err) => {
		setConfirmObject({ message: "", openConfirm: false, isLoading: false });
		toast.error(err);
	};
	const onConfirm = () => {
		if (!confirmObject.cancel) {
			setConfirmObject({ ...confirmObject, isLoading: true });
			if (get(mailConfig, "id")) {
				dispatch(
					updateMailConfig(
						{ ...mailConfigToSave, id: get(mailConfig, "id"), type: get(mailConfig, "type") },
						() => {
							handleSaveSuccess();
						},
						(error) => {
							handleSaveFail(error);
						}
					)
				);
			} else {
				dispatch(
					createMailConfig(
						{ ...mailConfigToSave, type: isOrderEnv ? "order" : "invoice" },
						() => {
							handleSaveSuccess();
						},
						(error) => {
							handleSaveFail(error);
						}
					)
				);
			}
		} else {
			setSelectedIndex(0);
			setConfirmObject({ message: "", openConfirm: false, cancel: false });
			setEdit(false);
		}
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
		if (confirmObject.cancel) setEdit(true);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container item xs={12}>
				<Grid item xs={10}>
					<Grid container item xs={12} justify="center" alignItems="center" alignContent="center">
						<>
							<Grid container item xs={12} className={classes.loginContainer} spacing={6}>
								<Grid item container xs={12}>
									<Grid container item xs={6}>
										<Typography variant="h6" color="primary">
											{t("admin.email-config")}
										</Typography>
									</Grid>
									<Grid item container xs={6} justify="flex-end">
										<Tooltip placement="top" title={t("tootlip.action.edit")}>
											<IconButton aria-label="delete">
												<EditIcon onClick={() => setEdit(true)} />
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("Host")}
										<span style={{ color: "red" }}>*</span>
										<br />
										<Input
											inputRef={register({
												required: { value: true, message: t("field_required") },
												pattern: {
													value: REGEX.HOST,
													message: "admin.mailConfig.invalidHostPattern",
												},
											})}
											name="mailServerUrl"
											type="text"
											disabled={!edit}
											fullWidth
										/>
										<p>{errors.email?.message}</p>
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("Port")}
										<span style={{ color: "red" }}>*</span>
										<br />
										<Input
											inputRef={register({
												required: { value: true, message: t("field_required") },
											})}
											name="mailServerPort"
											type="number"
											disabled={!edit}
											fullWidth
										/>
										<p>{errors.email?.message}</p>
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("Email")}
										<span style={{ color: "red" }}>*</span>
										<br />
										<Input
											inputRef={register({
												required: { value: true, message: t("field_required") },
											})}
											name="mailServerLogin"
											type="email"
											disabled={!edit}
											fullWidth
										/>
										<p>{errors.email?.message}</p>
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("Password")}
										<span style={{ color: "red" }}>*</span>
										<br />
										<Input
											inputRef={register({
												required: { value: true, message: t("field_required") },
											})}
											name="mailServerPassword"
											type="password"
											disabled={!edit}
											fullWidth
										/>
										<p>{errors.email?.message}</p>
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("Read folder")}
										<span style={{ color: "red" }}>*</span>
										<br />
										<Input
											inputRef={register({
												required: { value: true, message: t("field_required") },
											})}
											name="folderRead"
											type="text"
											disabled={!edit}
											fullWidth
										/>
										<p>{errors.email?.message}</p>
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("Processing folder")}
										<span style={{ color: "red" }}>*</span>
										<br />
										<Input
											inputRef={register({
												required: { value: true, message: t("field_required") },
											})}
											name="folderProcess"
											type="text"
											disabled={!edit}
											fullWidth
										/>
										<p>{errors.email?.message}</p>
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("Success folder")}
										<span style={{ color: "red" }}>*</span>
										<br />
										<Input
											inputRef={register({
												required: { value: true, message: t("field_required") },
											})}
											name="folderSuccess"
											type="text"
											disabled={!edit}
											fullWidth
										/>
										<p>{errors.email?.message}</p>
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("Exception folder")}
										<span style={{ color: "red" }}>*</span>
										<br />
										<Input
											inputRef={register({
												required: { value: true, message: t("field_required") },
											})}
											name="folderException"
											type="text"
											disabled={!edit}
											fullWidth
										/>
										<p>{errors.email?.message}</p>
									</Typography>
								</Grid>
							</Grid>
							<Grid
								container
								item
								xs={12}
								spacing={2}
								className={classes.actions}
								justify="center"
								alignItems="center"
								alignContent="center"
							>
								<Grid item>
									<Button
										color="primary"
										autoFocus
										disabled={!edit}
										onClick={() => {
											setConfirmObject({
												message: t("CancelActionMessage"),
												openConfirm: true,
												cancel: true,
											});
										}}
									>
										{t("cancel")}
									</Button>
								</Grid>
								<Grid item>
									<Button variant="contained" type="submit" color="secondary" disabled={!edit}>
										{t("confirm")}
									</Button>
								</Grid>
							</Grid>

							<ConfirmDialog
								message={confirmObject.message}
								open={confirmObject.openConfirm}
								isLoading={confirmObject.isLoading}
								onConfirm={onConfirm}
								onCancel={onCancel}
							/>
						</>
					</Grid>
				</Grid>
				<Grid item xs={2} style={{ paddingLeft: "20px" }}>
					<List
						component="nav"
						aria-label="main mailbox folders"
						style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
					>
						{mailConfigList?.map((config, index) => (
							<ListItem
								button
								selected={selectedIndex === index}
								onClick={(event) => handleListItemClick(event, index)}
								disabled={edit}
							>
								<ListItemText primary={config?.mailServerLogin} />
							</ListItem>
						))}

						<ListItem
							button
							disabled={edit}
							onClick={() => {
								setSelectedIndex(null);
								setMailConfig({});
								reset(
									{
										mailServerUrl: "",
										mailServerPort: "",
										mailServerLogin: "",
										mailServerPassword: "",
										folderRead: "",
										folderProcess: "",
										folderSuccess: "",
										folderException: "",
									},
									{
										keepErrors: true,
										keepDirty: true,
										keepIsSubmitted: false,
										keepTouched: false,
										keepIsValid: false,
										keepSubmitCount: false,
									}
								);
								setEdit(true);
							}}
						>
							<ListItemIcon>
								<AddCircleIcon />
							</ListItemIcon>
							<ListItemText primary={t("admin.emailConfig.addNewEmail")} />
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</form>
	);
};

export default EmailConfig;
