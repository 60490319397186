import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import NativeSelect from "@material-ui/core/NativeSelect";
import useStyles from "../style";

const Financial = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Grid container xs={12} justify="center">
			<Grid container item xs={8} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.bank")}
						<br />
						<Input
							value={props.supplier.bank}
							onChange={(event) => props.updateSupplier(event)}
							name="bank"
							fullWidth
						/>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.bic")}
						<Input
							value={props.supplier.bic}
							onChange={(event) => props.updateSupplier(event)}
							name="bic"
							fullWidth
						/>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={8} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.iban")}
						<br />
						<Input
							value={props.supplier.iban}
							onChange={(event) => props.updateSupplier(event)}
							name="iban"
							fullWidth
						/>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.rib")}
						<Input
							value={props.supplier.rib}
							onChange={(event) => props.updateSupplier(event)}
							name="rib"
							fullWidth
						/>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={8} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.acc")}
						<br />
						<Input
							value={props.supplier.accountJournalNumber}
							onChange={(event) => props.updateSupplier(event)}
							name="accountJournalNumber"
							fullWidth
						/>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.curr")}
						<NativeSelect
							value={props.supplier.currency}
							onChange={(event) => props.updateSupplier(event)}
							name="currency"
							fullWidth
						>
							<option value="EUR">EURO</option>
						</NativeSelect>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={8} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="primary">
						{t("supplier.add.aux")}
						<br />
						<Input
							value={props.supplier.accountJournalAuxiliary}
							onChange={(event) => props.updateSupplier(event)}
							name="accountJournalAuxiliary"
							fullWidth
						/>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Financial;
