export default {
	admin: "root_administrator",
	offlineUser: "offline_access",
	tenantAdmin: "tenant_administrator",
	user: "invoice_user",
	purchase_user: "order_user",
	client: "order_client",
	umaAuth: "uma_authorization",
	supplier: "invoice_supplier",
	manager: "manager",
};
