import React, { useMemo } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { isMenuAllowed } from "components/HasPermission";
import get from "lodash/get";
import { Flip, ToastContainer } from "react-toastify";
import ChangePasswordRequest from "pages/profile/ChangePasswordRequest";
import FormPasswordReset from "pages/profile/FormPasswordReset";
import CircularLoader from "components/Loaders/CircularLoader";
import { fetchCurrentUser } from "redux/actions/users";
import { AddSuperAccount } from "administration/users/AddSuperUser";
import CommercialOrganizationForm from "administration/CommercialOrganization/components/AddCommercialOrganization";
import menu from "./menu";
import AppBar from "./components/AppBar";
import MenuLeft from "./components/MenuLeft";
import useStyles from "./style";
import invoiceDetails from "../pages/Invoices/Detail";

import SupplierDetails from "../pages/Suppliers/Details";
import "./polyfills";
import Login from "../pages/Login";
import Detail from "../pages/InvoiceApproval";
import CustomizedSteppers from "../pages/Suppliers/Component/Stepper";
import AddSupplier from "../pages/supplier/index";

import { isUserAdminOnly, isSupplierOnly, isPurchaseOrder } from "../utils";

import "react-toastify/dist/ReactToastify.css";
import { UsersHome } from "../administration/users";
import { Add } from "../administration/users/Add";
import { AddSuplierAccount } from "../administration/users/AddSupplierAccount";

import "./index.css";

const Copyright = () => {
	const { t } = useTranslation();

	return (
		<Box pt={4}>
			<Typography variant="body2" color="textSecondary" align="center">
				{t("Copyright")} ©
				<Link color="inherit" href="https://novelis.io" target="_blank">
					Novelis innovation
				</Link>{" "}
				{new Date().getFullYear()}
				&nbsp;{"  "}&nbsp;
				{process.env.REACT_APP_RELEASE_VERSION || "1.0.0.0"}
			</Typography>
		</Box>
	);
};

const getTransformedMenu = (currentUserPermissionsString) => {
	const transformedMenu = [];
	const currentUserPermissions = JSON.parse(currentUserPermissionsString);
	const permittedMenusNames = currentUserPermissions
		?.filter((menuItem) => menuItem?.enabled)
		?.map((menuItem) => menuItem?.name);
	menu.invoices.forEach(({ path, component, items, menuPermissionName }) => {
		if (permittedMenusNames?.includes(menuPermissionName) || !currentUserPermissions) {
			if (items) {
				items.forEach(({ path: pathChild, component: componentChild }) => {
					transformedMenu.push({ pathChild, componentChild });
				});
			} else transformedMenu.push({ path, component });
		}
	});
	return transformedMenu;
};

function App() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [currentUser, setCurrentUser] = React.useState(null);
	const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
	const headerExpanded = useSelector(({ appState }) => appState.headerExpanded);

	React.useEffect(() => {
		if (window.location.href.includes("/protected")) {
			setCurrentUser(true);
		} else {
			dispatch(fetchCurrentUser()).then((res) => {
				setCurrentUser(res.data);
			});
		}
	}, []);

	const currentUserPermissions = useMemo(() => get(currentUser, "permissions"), [currentUser]);
	const userSupplierId = useMemo(() => get(currentUser, "supplierId"), [currentUser]);

	return (
		<>
			{currentUser ? (
				<div className="rootContainer">
					<Switch>
						<Route path="/protected/invoices/details/:idInvoice" exact component={Detail} />
						<Route path="/login" exact component={Login} />
						<div>
							<CssBaseline />
							<AppBar currentUser={currentUser} />

							<div className={classes.wrapper}>
								<MenuLeft menu={menu} />
								<main
									className={clsx(
										classes.content,
										menuExpanded && classes.smallContent,
										!headerExpanded && classes.headerExpanded
									)}
								>
									<Container maxWidth={false} className={classes.container}>
										<Switch>
											<Redirect
												from="/supplier/details/invoices/details/:idInvoice"
												to="/invoices/details/:idInvoice"
											/>
											{isUserAdminOnly(get(currentUser, "roles", [])) && (
												<Redirect
													from="/"
													to="/administration/invoice/commercial-organization"
													exact
												/>
											)}
											{!isMenuAllowed(currentUserPermissions, "Invoices list") && (
												<Redirect from="/invoices" to="/" exact />
											)}
											{!isMenuAllowed(currentUserPermissions, "Suppliers") && (
												<Redirect from="/supplier" to="/" exact />
											)}
											<Route path="/invoices/details/:idInvoice" exact component={invoiceDetails} />
											<Route
												path="/supplier/details/:idSupplier"
												exact
												component={SupplierDetails}
											/>
											{isSupplierOnly(get(currentUser, "roles", [])) && (
												<Redirect from="*" to={`/supplier/details/${userSupplierId}`} exact />
											)}
											{isPurchaseOrder(get(currentUser, "roles", [])) && (
												<Redirect from="/" to="/home" exact />
											)}

											<Route path="/supplier/add" exact component={CustomizedSteppers} />
											<Route path="/addSupplier" exact component={AddSupplier} />
											<Route path="/supplier/:idSupplier" exact component={AddSupplier} />

											<Route path="/profile" exact component={ChangePasswordRequest} />
											<Route
												path="/protected/user/profile/:userId"
												exact
												component={FormPasswordReset}
											/>
											<Route path="/administration/users" exact component={UsersHome} />
											<Route path="/administration/su" exact component={UsersHome} />
											<Route path="/administration/suppliers" exact component={UsersHome} />
											<Route path="/administration/:module/settings" exact component={UsersHome} />
											<Route
												path="/administration/:module/import-config"
												exact
												component={UsersHome}
											/>
											<Route path="/administration/supplier-settings" exact component={UsersHome} />
											{isMenuAllowed(currentUserPermissions, "Commercial Organization") && (
												<Route
													path="/administration/invoice/commercial-organization"
													exact
													component={UsersHome}
												/>
											)}
											{isMenuAllowed(currentUserPermissions, "Commercial Organization") && (
												<Route
													path="/administration/order/commercial-organization"
													exact
													component={UsersHome}
												/>
											)}
											<Route
												path="/administration/:module/extraction-settings"
												exact
												component={UsersHome}
											/>
											<Route
												path="/administration/:module/extraction-settings"
												exact
												component={UsersHome}
											/>
											<Route path="/administration/workflow" exact component={UsersHome} />
											<Route path="/administration/users/add" component={Add} />
											<Route path="/administration/users/edit/:idUser" component={Add} />
											<Route path="/administration/users/view/:idUser" component={Add} />
											<Route path="/administration/suppliers/add" component={AddSuplierAccount} />
											<Route
												path="/administration/suppliers/edit/:idUser"
												component={AddSuplierAccount}
											/>
											<Route
												path="/administration/suppliers/view/:idUser"
												component={AddSuplierAccount}
											/>

											<Route path="/administration/su/add" component={AddSuperAccount} />
											<Route path="/administration/su/edit/:idUser" component={AddSuperAccount} />
											<Route path="/administration/su/view/:idUser" component={AddSuperAccount} />
											{isMenuAllowed(currentUserPermissions, "Commercial Organization") && (
												<Route
													path="/administration/invoice/commercial-organization/add"
													component={CommercialOrganizationForm}
												/>
											)}
											{isMenuAllowed(currentUserPermissions, "Commercial Organization") && (
												<Route
													path="/administration/invoice/commercial-organization/edit/:id"
													component={CommercialOrganizationForm}
												/>
											)}
											{isMenuAllowed(currentUserPermissions, "Commercial Organization") && (
												<Route
													path="/administration/invoice/commercial-organization/view/:id"
													component={CommercialOrganizationForm}
												/>
											)}
											{getTransformedMenu(currentUserPermissions).map(
												({ path, component }, index) => (
													<Route key={index} exact path={path} component={component} />
												)
											)}
										</Switch>
										<Copyright />
									</Container>
								</main>
							</div>
							<ToastContainer
								className={classes.toast}
								limit={3}
								hideProgressBar
								position="bottom-center"
								transition={Flip}
								autoClose={15000}
							/>
						</div>
					</Switch>
				</div>
			) : (
				<Grid container spacing={0} align="center" justify="center" direction="column">
					<Grid item>
						<CircularLoader />
					</Grid>
				</Grid>
			)}
		</>
	);
}

export default App;
