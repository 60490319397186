import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	firstGrid: {
		paddingTop: "1%",
		width: "calc(100% + 48px)",
	},
	tabb: {
		height: "30px",
		width: "100%",
		textAlign: "center",
	},
	tooltip: {
		"&:last-child": {
			position: "absolute",
			right: "50px",
		},
	},
	icon: {
		width: "70px",
		height: "80px",
	},
	image: {
		width: "120px",
		height: "120px",
	},
	avatar: {
		backgroundColor: "transparent",
		width: theme.spacing(15),
		height: theme.spacing(15),
	},
	gridContact: {
		height: "100%",
		paddingTop: "50px",
	},
	paper: {
		width: "100%",
		boxShadow: "none",
	},
	card: {
		width: "100%",
		boxShadow: "none",
		height: "100%",
	},
	logo: {
		color: "lightgray",
		width: theme.spacing(15),
		height: theme.spacing(15),
	},
	cardHeader: {
		backgroundImage: "linear-gradient(to Bottom, #f8f9fa 50%, white 50%)",
		marginTop: "-1%",
		color: "gray",
		paddingLeft: "45%",
	},
	label: {
		color: "black",
		textAlign: "center",
		width: "100%",
		paddingBottom: "20px",
	},
	typography: {
		fontSize: "15px",
		color: "black",
	},
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	input: {
		Width: "224px",
	},
	space: {
		marginTop: "10px",
	},
	tab: {
		fontWeight: "300",
		height: "20px",
	},
	supplierDetails: {
		backgroundColor: theme.palette.background.paper,
	},
	supplierItem: {
		margin: "70px 0",
	},

	large: {
		width: theme.spacing(15),
		height: theme.spacing(15),
	},

	customBadge: {
		backgroundColor: theme.palette.background.paper,
		height: "24px",
	},
	invoicesTable: {
		marginTop: "25px",
	},
	rootContainer: {
		padding: theme.spacing(2, 0),
	},
	appBar: { backgroundColor: "transparent", elevation: 3, height: "47px" },
	invoicesDetails: {
		marginTop: theme.spacing(4),
	},
	selectedTab: {
		color: "#0da8ad",
	},
	selectedTab2: {
		backgroundColor: "#0da8ad",
		color: "white",
	},
	fullwidth: {
		paddingLeft: theme.spacing(5),
		width: "100%",
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		float: "right",
		height: "100%",
		padding: "20px",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	dropzone: {
		background: "transparent",
		maxWidth: "100%",
		minHeight: "80%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& .MuiDropzoneArea-icon": {
			color: theme.palette.primary.main,
			opacity: 0.8,
		},
	},
}));
