export default {
	orders: "Bestellungen",
	clients: "Kunden",
	client: "Klientin",
	"client.delete.msg": "Möchten Sie diesen Client wirklich löschen?",
	"client.delete.success": "Client erfolgreich gelöscht",
	"client.delete.error": "Beim Löschen des Clients ist ein Fehler aufgetreten",
	clientCount: "Gesamtzahl der clients",
	searchClient: "Finden Sie einen Kunden",
	countOrders: "Gesamtzahl der Bestellungen",
	"Total amount of orders": "Gesamtanzahl der Bestellungen",
	searchOrders: "Bestellung finden ...",
	"Add an order": "Bestellung hinzufügen",
	"order.duplicated": "Dupliziert",
	"extraction.label.not_order": "Andere als bestellen",
	"Order Details": "Bestelldetails",
	"order Number": "Bestellnummer",
	"Order overview": "Bestellübersicht",
	"order.detail.reject.success": "Bestellung wurde erfolgreich abgelehnt",
	"order.detail.reject.failed": "Bestellung wurde nicht abgelehnt",
	"order.status.underProcess": "Wird bearbeitet",
	"order.status.processed": "Verarbeitet",
	clientEmptyMessageError: "Der Client der Bestellung ist nicht gefüllt",
	"Orders to verify": "Zu überprüfen",
	"Orders to be checked": "Zu kontrollieren",
	"order.exported": "Exportierte Bestellungen",
	"order.status.exported": "Exportiert",
	"Add your Order": "Fügen Sie Ihre Bestellung hinzu oder ",
	"order.unexported": "Nicht exportierte Bestellungen",
	"order.upload": "Bestellungen hochladen",
	"My Orders": "meine Bestellungen",
	"Total order of expenses": "Orders Total",
	"order.total_invoice": "Total orders",
	"New clients": "New clients",
	"order.status.Processed": "",
	"Total VAT amount": "",
	"order.status.UnderProcess": "",
	"upload.orders": "Bestellungen hochladen",
	"order.status.integration": "Integration in Arbeit",
	ClientCannotBeDeleted: "",
	"order.status.toverify": "Überprüfen",
	"order.status.tocontrol": "Zur Steuerung",
	"order.status.topay": "Bezahlen",
	"order.status.payed": "Bezahlt",
	"order.status.rejected": "Abgelehnt",
	"order.status.canceled": "Abgebrochen",
	"order.status.exception": "Ausnahme",
	duplicated_order: "Doppelte Bestellung",
	"order.selected.title0": "Alle Bestellungen sind ausgewählt",
	"order.selected.title1": "Die ",
	"order.selected.title2": " Bestellungen auf dieser Seite sind alle ausgewählt.",
	"order.selected.title3": "Wählen Sie alle Bestellungen aus",
	OrdersNotCreatedMessage: "Die folgenden Aufträge können nicht erstellt werden :",
	"order.total_amoutn_to_verify": "Gesamtanzahl der zu überprüfenden Bestellungen",
	"client.add.button": "Fügen Sie einen Client hinzu",
	"client.import.button": "Clients importieren",
	"clients.export.excel": "Kundenliste exportieren",
	"client.add2.button": "Klientin einfügen",
	"client.add.step1": "Identifikationsnummern",
	"client.add.step2": "Allgemeine Informationen",
	"client.add.step3": "Finanzielle Informationen",
	"client.add.step4": "Ansprechspartner",
	"client.add.next": "Nächstes",
	"client.add.previous": "Vorheriges",
	"client.add.finish": "Enden",
	"client.add.confirm": "Sind Sie sicher die Änderungen speichern zu wollen?",
	"clients.empty.field.name.row.excel": "Es gibt eine Zeile mit einem leeren Namen !!",
	"clients.names.exist.already": "Kunden {{clients}} gibt es bereits auf dem System!",
	"clients.error.sample": "Die gesendete Datei entspricht nicht der beispielhaften Datei.",
	"client.name.exist.already": "Der Kunde ist nicht eindeutig",
	"clients.add.multiple": "",
	"upload.client": "Kunden laden",
	"upload.client.instructions1": "Füllen Sie die Excel-Tabelle mit den Kundeninformation.client",
	"upload.client.instructions2":
		"Füllen Sie die «Logo»-Spalte mit dem Dateiname des Logos. (z.B.: logofilename.jpg)",
	"upload.client.instructions3": "Alle Felder müssen im TEXT-Format sein",
	"upload.client.instructions4":
		"Stellen Sie die gefüllte Excel-Tabelle und die Lieferanten-Logos im gleichen Ordner",
	"upload.client.instructions5": "Komprimieren Sie diesen Ordner:",
	"upload.client.instructions51":
		'Windows: Rechten Klick auf dem Ordner, dann wählen Sie "Schicken nach " dann klicken Sie auf "komprimierter Ordner "',
	"upload.client.instructions52":
		"MAC: Klicken Sie darauf und halten sie die Taste gedrückt oder drücken Sie einfach mit zwei Finger, dann wählen Sie Komprimieren",
	"upload.client.instructions6":
		"Laden Sie den komprimierten Ordner auf dem Portal oder verschieben und stellen Sie ihn im dafür vorgesehenen Bereich",
	"upload.client.instructions7": 'Klicken Sie auf "Lieferanten aufladen"',
	"add.client.name": "Kundenname",
	"client.created": "Client Erstellung vom Lieferant!",
	"client.updated": "Client Aktualisierung vom Lieferant!",
	"client.error": "Fehler beim Speichern des Clients ist aufgetreten",
	"client.delete.confirm": "Möchten Sie den Anbieter wirklich löschen?",
	"client.modify": " Kunden ändern",
	"order.detail.company_siret": "Mw-StNr. des Unternehmens",
	"Total of order expenses": "Bestellungen insgesamt",
	orderRejectMessage: "Möchten Sie diese Bestellung wirklich ablehnen?",
	orderControlMessage: "Sind Sie sicher, dass Sie diesen Befehl steuern möchten?",
	"order.detail.message.rectify": "Sind Sie sicher, dass Sie diese Änderungen speichern möchten?",
	"order.file.not.created.message": "Fehler beim Erstellen der Datei",
	order_uploaded_success:
		"Die Bestellung wurde erfolgreich hinzugefügt. Es wird in der Liste angezeigt, wenn Sie der Emittent dieser Bestellung sind.",
};
