import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import TableCardItem from "../../../../components/TableCardItem";

function OrderTable({ orders }) {
	const { t } = useTranslation();
	const history = useHistory();

	const onClickRow = (id) => {
		history.push(`/orders/details/${id}`);
	};
	return (
		<Grid container>
			<Grid item xs={12}>
				<>
					{orders &&
						orders.map((order, i) => {
							order.code = order.supplier.label;
							const infoItems = [
								{
									multiLines: true,
									rows: [
										{ variant: "body1", value: order.orderNo },
										{
											variant: "caption",
											color: "textSecondary",
											value: order.issueDate,
										},
										{
											status: true,
											value: order.status.code,
										},
									],
								},
								{ label: t("createdAt"), value: order.integratedAt },
								{ label: t("orderType"), value: t(order.type) },
								{
									withTitle: true,
									multiLines: true,
									rows: [
										{ label: t("Total HT"), value: `${order.amountHT} €` },
										{ label: t("VAT amount"), value: `${order.amountTVA} €` },
										{
											label: t("Total amount"),
											value: `${order.totalAmount} €`,
										},
									],
								},
							];
							return (
								<TableCardItem
									index={i}
									row={order}
									infoItems={infoItems}
									onClickRow={() => {
										onClickRow(order.orderNo);
									}}
									onDeleteItem={() => {}}
									withLogo
									isOrderList
								/>
							);
						})}
				</>
			</Grid>
		</Grid>
	);
}

export default OrderTable;
