import React from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import ExposureRoundedIcon from "@material-ui/icons/ExposureRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function LevelIcon({ level }) {
	const { t } = useTranslation();
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);

	switch (level) {
		case "N1":
			return (
				<Tooltip placement="top" title={t(`extraction.label.fully_extracted`)}>
					<CheckCircleRoundedIcon style={{ color: "#31d031" }} />
				</Tooltip>
			);

		case "N2":
			return (
				<Tooltip placement="top" title={t(`extraction.label.partially_extracted`)}>
					<ExposureRoundedIcon style={{ color: "#ff7600" }} />
				</Tooltip>
			);

		case "N3":
			return (
				<Tooltip placement="top" title={t(`extraction.label.not_extracted`)}>
					<WarningRoundedIcon style={{ color: "#ef2929" }} />
				</Tooltip>
			);

		case "N4":
			return (
				<Tooltip
					placement="top"
					title={t(isOrderEnv ? `extraction.label.not_order` : `extraction.label.not_invoice`)}
				>
					<CancelRoundedIcon style={{ color: "#000000" }} />
				</Tooltip>
			);

		default:
			return (
				<Tooltip placement="top" title={t(`extraction.label.not_extracted`)}>
					<WarningRoundedIcon style={{ color: "#ff7600" }} />
				</Tooltip>
			);
	}
}

function ExtractionLevelBadge(props) {
	const { level } = props;

	return (
		<Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
			<LevelIcon level={level} />
		</Box>
	);
}

export default ExtractionLevelBadge;
