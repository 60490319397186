export default {
	orders: "الطلب",
	clients: "عملاء",
	client: "عميل",
	"client.delete.msg": "هل أنت متأكد أنك تريد حذف هذا العميل؟",
	"client.delete.success": "تم حذف العميل بنجاح",
	"client.delete.error": "حدث خطأ أثناء حذف العميل",
	clientCount: "إجمالي عدد العملاء",
	searchClient: "ابحث عن عميل",
	countOrders: "إجمالي عدد الطلبات",
	"Total amount of orders": "إجمالي عدد الطلبات",
	searchOrders: "ابحث عن طلب ...",
	"Add an order": "إضافة طلب",
	"order.duplicated": "مكرر",
	"extraction.label.not_order": "مكرر",
	"Order Details": "تفاصيل الطلب",
	"order Number": "رقم الطلب",
	"Order overview": "نظرة عامة على الطلب",
	"order.detail.reject.success": "تم رفض الطلب بنجاح",
	"order.detail.reject.failed": "لم يتم رفض الطلب",
	"order.status.underProcess": "قيد المعالجة",
	"order.status.processed": "تمت معالجته",
	clientEmptyMessageError: "لم يتم ملء عميل الطلب",
	"Orders to verify": "للتحقق",
	"Orders to be checked": "للتحكم",
	"order.exported": "الطلبات المصدرة",
	"order.status.exported": "تم التصدير",
	"Add your Order": "أضف طلبك أو ",
	"order.unexported": "الطلبات غير المصدرة",
	"order.upload": "تحميل الطلبات",
	"My Orders": "طلباتي",
	"Total order of expenses": "إجمالي الطلب",
	"order.total_invoice": "عدد الطلبات",
	"New clients": "العملاء الجدد",
	"order.status.Processed": "",
	"Total VAT amount": "t",
	"order.status.UnderProcess": "",
	"upload.orders": "تحميل الطلبات",
	"order.status.integration": "",
	ClientCannotBeDeleted: "",
	"order.status.toverify": "للتحقق",
	"order.status.tocontrol": "للتحكم",
	"order.status.topay": "للدفع",
	"order.status.payed": "مدفوع",
	"order.status.rejected": "مرفوضة",
	"order.status.canceled": "ملغى",
	"order.status.exception": "استثناء",
	duplicated_order: "طلب مكرر",
	"order.selected.title0": "تم تحديد جميع الطلبات",
	"order.selected.title1": " تم تحديد جميع الفواتير",
	"order.selected.title2": " تم تحديد جميع الطلبات في هذه الصفحة.",
	"order.selected.title3": "حدد كل الطلبات",
	OrdersNotCreatedMessage: "لا يمكن إنشاء الطلبات التالية :",
	"order.total_amoutn_to_verify": "المبلغ الإجمالي للأوامر للتحقق",
	"clients.empty.field.name.row.excel": "هناك صف بدون اسم !!",
	"clients.names.exist.already": "العملاء {{clients}} موجود بالفعل على النظام!",
	"clients.error.sample": "الملف المرسل لا يتوافق مع الملف المثالي.",
	"client.name.exist.already": "العميل ليس فريدا",
	"client.add.button": "موردي الاستيراد",
	"client.export.suppliers.excel": "تصدير قائمة الزبناء",
	"client.import.button": "استيراد الزبناء",
	"clients.export.excel": "تصدير قائمة الزبناء",
	"client.add2.button": "إضافة زبون",
	"client.add.step1": "أرقام التعريف",
	"client.add.step2": "المعلومات العامة",
	"client.add.step3": "المعلومات المالية",
	"client.add.step4": "اتصال رئيسي",
	"client.add.next": "التالي",
	"client.add.previous": "",
	"client.add.finish": "",
	"client.add.confirm": "هل أنت متأكد أنك تريد حفظ التغييرات؟",
	"clients.add.multiple": "أضف الزبائن أو ",
	"upload.client": "Charger les clients",
	"upload.client.instructions1": "املأ ورقة Excel بمعلومات الزبناء",
	"upload.client.instructions2": 'املأ العمود "Logo" باسم ملف الشعار. (مثال: logofilename.jpg)',
	"upload.client.instructions3": "تأكد من أن جميع الحقول من النوع TEXT",
	"upload.client.instructions4": "ضع ورقة Excel المعبأة وشعارات الزبناء في نفس المجلد",
	"upload.client.instructions5": "ضغط هذا المجلد:",
	"upload.client.instructions51":
		"نظام التشغيل Windows: انقر بزر الماوس الأيمن فوق المجلد ثم اختر إرسال إلى ثم انقر فوق المجلد المضغوط",
	"upload.client.instructions52":
		"MAC: انقر عليه مع الضغط على مفتاح التحكم أو اضغط عليه بإصبعين ، ثم اختر ضغط من قائمة الاختصارات.",
	"upload.client.instructions6":
		"قم بتحميل المجلد المضغوط إلى البوابة الإلكترونية أو اسحبه وأفلته في المنطقة المخصصة",
	"upload.client.instructions7": "انقر فوق 'تحميل الزبناء'",
	"add.client.name": "اسم الزبون",
	"client.created": "تم إنشاء الزبون بنجاح!",
	"client.updated": "تم تحديث الزبون بنجاح!",
	"client.error": "حدث خطأ أثناء تسجيل الزبون",
	"client.delete.confirm": "هل أنت متأكد أنك تريد حذف الزبون",
	"client.modify": "تحديث الزبون ",
	"order.detail.company_siret": "هوية الشركة",
	"Total of order expenses": "مجموع الطلبات",
	orderRejectMessage: "هل أنت متأكد أنك تريد رفض هذا الطلب؟",
	orderControlMessage: "هل أنت متأكد أنك تريد وضع هذا الأمر تحت السيطرة؟",
	"order.detail.message.rectify": "هل أنت متأكد أنك تريد حفظ هذه التغييرات؟",
	"order.file.not.created.message": "خطأ عند إنشاء الملف",
	order_uploaded_success: "تمت إضافة الطلب بنجاح. سيظهر في القائمة إذا كنت مصدر هذا الطلب.",
};
