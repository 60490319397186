/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function getFlagEmoji(countryCode) {
	const codePoints = countryCode
		.toUpperCase()
		.split("")
		.map((char) => 127397 + char.charCodeAt());
	return String.fromCodePoint(...codePoints);
}
const useStyles = makeStyles({
	option: {
		fontSize: 15,
		"& > span": {
			marginRight: 10,
			fontSize: 18,
		},
	},
});

export default function CountrySelect({ value, onChange, name, inputRef, countryList }) {
	const classes = useStyles();

	const [stateValue, setStateValue] = React.useState({});
	const [inputValue, setInputValue] = React.useState("");

	useEffect(() => {
		if (countryList && value) {
			setStateValue(countryList.find((country) => country.isoCode === value));
		}
	}, [countryList, value]);
	return (
		<Autocomplete
			id="country-select-demo"
			fullWidth
			options={countryList ?? []}
			classes={{
				option: classes.option,
			}}
			name={name}
			autoHighlight
			getOptionLabel={(option) => option.displayName}
			value={stateValue}
			onChange={(event, newValue) => {
				setStateValue(newValue);
				onChange(newValue?.isoCode);
			}}
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderOption={(option) => (
				<>
					<span>{getFlagEmoji(option.isoCode)}</span>
					{option.displayName} ({option.isoCode})
				</>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params.inputProps,
						autoComplete: "new-password", // disable autocomplete and autofill
					}}
					inputRef={inputRef}
					fullWidth
				/>
			)}
		/>
	);
}
