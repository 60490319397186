import React from "react";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import ExposureRoundedIcon from "@material-ui/icons/ExposureRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import Description from "@material-ui/icons/Description";
import FileCopy from "@material-ui/icons/FileCopy";

export default [
	{
		id: "duplicated",
		icon: <FileCopy />,
		labelId: "invoice.duplicated",
		color: "#31bbd0",
	},
	{
		id: "digitalInvoice",
		icon: <Description />,
		labelId: "invoice.digital",
		color: "#3C4D6F",
	},
	{
		id: "N1",
		icon: <CheckCircleRoundedIcon />,
		labelId: "extraction.label.fully_extracted",
		color: "#31d031",
	},
	{
		id: "N2",
		icon: <ExposureRoundedIcon />,
		labelId: "extraction.label.partially_extracted",
		color: "#ff7600",
	},
	{
		id: "N3",
		icon: <WarningRoundedIcon />,
		labelId: "extraction.label.not_extracted",
		color: "#ef2929",
	},
	{
		id: "N4",
		icon: <CancelRoundedIcon />,
		labelId: "extraction.label.not_invoice",
		color: "#000000",
	},
];
