export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_JOBS = "FETCH_USERS_JOBS";
export const FETCH_USERS_CIVILITIES = "FETCH_USERS_CIVILITIES";
export const DELETE_USER = "DELETE_USER";
export const EDIT_USER = "EDIT_USER";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_LIST_INVOICES = "DELETE_LIST_INVOICES";
export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const UPDATE_MAIL_CONFIG = "UPDATE_MAIL_CONFIG";
export const FETCH_MAIL_CONFIG = "FETCH_MAIL_CONFIG";
export const FETCH_FOLDER_CONFIG = "FETCH_FOLDER_CONFIG";
export const UPDATE_FOLDER_CONFIG = "UPDATE_FOLDER_CONFIG";
export const DELETE_TRAINING_SUPPLIER = "DELETE_TRAINING_SUPPLIER";
export const DELETE_TRAINING_CLIENT = "DELETE_TRAINING_CLIENT";
export const FETCH_EXTRACTION_LEVEL_PARAMS = "FETCH_EXTRACTION_LEVEL_PARAMS";
export const FETCH_EXTRACTION_LEVEL_PARAMS_ORDER = "FETCH_EXTRACTION_LEVEL_PARAMS_ORDER";
export const UPDATE_EXTRACTION_LEVEL_PARAMS = "UPDATE_EXTRACTION_LEVEL_PARAMS";
export const UPDATE_EXTRACTION_LEVEL_PARAMS_ORDER = "UPDATE_EXTRACTION_LEVEL_PARAMS_ORDER";
export const FETCH_HOTFOLDER_FTP_CONFIG = "FETCH_HOTFOLDER_FTP_CONFIG";
export const UPDATE_HOTFOLDER_FTP_CONFIG = "UPDATE_HOTFOLDER_FTP_CONFIG";
export const FETCH_WORKFLOW = "FETCH_WORKFLOW";
export const PUT_WORKFLOW = "PUT_WORKFLOW";
export const FETCH_SUPER_USERS = "FETCH_SUPER_USERS";
export const FETCH_TRANSITION_ALERTS = "FETCH_TRANSITION_ALERTS";
export const LOCK_SUPPLIER_TRAINING = "LOCK_SUPPLIER_TRAINING";
export const CHECK_WORKFLOW_INVOICE_TO_DELETE = "CHECK_WORKFLOW_INVOICE_TO_DELETE";
export const LOCK_CLIENT_TRAINING = "LOCK_CLIENT_TRAINING";
export const FETCH_COMMERCIAL_ORGANIZATIONS = "FETCH_COMMERCIAL_ORGANIZATIONS";
export const FETCH_COMMERCIAL_ORGANIZATION = "FETCH_COMMERCIAL_ORGANIZATION";
