import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
	Grid,
	Typography,
	Input,
	Button,
	InputLabel,
	FormControl,
	InputAdornment,
	IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ConfirmDialog from "components/ConfirmDialog";
import {
	saveNewSuppliersCommercialOrganization,
	saveNewClientsCommercialOrganization,
	fetchCommercialOrganization,
} from "redux/actions/commercialOrganizations";
import { toast } from "utils";
import { debounce, get } from "lodash";

import { initFilter, updateFilter } from "redux/supplierFilterReducer";
import { fetchOriginalSuppliers } from "redux/actions/suppliers";
import useStyles from "./style";

import CommercialOrganizationSuppliers from "./CommercialOrganizationSuppliers";

const CommercialOrganizationForm = (props) => {
	const { errors, handleSubmit, register } = useForm();
	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const suppliers = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_SUPPLIERS_ORIGINAL.data")
	);
	const filterUsers = useSelector(({ filterSuppliers: i }) => i);

	const isAddEditMode = ["edit", "add"].some(
		(i) => new URL(window.location).pathname.indexOf(i) !== -1
	);

	const commercialOrganizationToModify = useSelector(
		({ requests }) =>
			requests.queries.FETCH_COMMERCIAL_ORGANIZATION &&
			requests.queries.FETCH_COMMERCIAL_ORGANIZATION.data
	);
	const isOrderEnv = useSelector(({ appState }) => appState.isOrderEnv);
	const module = isOrderEnv ? "order" : "invoice";

	const [selectedSuppliers, setSelectedSuppliers] = useState([]);
	const [supplierSearchValue, setSupplierSearchValue] = useState();
	const [description, setDescription] = useState("");
	const [dataToSave, setDataToSave] = useState({});
	const [confirmObject, setConfirmObject] = useState({
		message: "",
		openConfirm: false,
		cancel: false,
		isLoading: false,
	});

	useEffect(() => {
		if (props.match.params.id) {
			setDescription(commercialOrganizationToModify?.description);
			if (isOrderEnv) {
				setSelectedSuppliers(commercialOrganizationToModify?.clients?.map((supp) => supp.id) || []);
			} else {
				setSelectedSuppliers(
					commercialOrganizationToModify?.suppliers?.map((supp) => supp.id) || []
				);
			}
		}
	}, [commercialOrganizationToModify]);

	useEffect(() => {
		if (suppliers && suppliers.content) {
			setSelectedSuppliers(
				suppliers.content
					?.filter((supp) => supp.commercialOrganizationSupplier)
					.map((supp) => supp.id) || []
			);
		}
	}, [suppliers]);
	useEffect(() => {
		dispatch(fetchOriginalSuppliers(filterUsers, props.match.params.id));
	}, [filterUsers, dispatch]);

	useEffect(() => {
		dispatch(initFilter());
	}, [dispatch]);

	useEffect(() => {
		if (props.match.params.id) {
			dispatch(
				fetchCommercialOrganization(props.match.params.id, isOrderEnv ? "ORDER" : "INVOICE")
			);
		}
	}, []);

	const handleChangePage = (event, newPage) => {
		dispatch(updateFilter({ ...filterUsers, pageNo: newPage }));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(
			updateFilter({
				...filterUsers,
				pageSize: event.target.value,
				pageNo: 0,
			})
		);
	};

	const searchSupplier = (supplier) => {
		dispatch(
			updateFilter({
				...filterUsers,
				nameOrContactLastNameOrContactFirstName: supplier,
				pageNo: 0,
			})
		);
	};

	const debouncer = useCallback(
		debounce((nextValue) => searchSupplier(nextValue), 200),
		[]
	);

	const handleFilterValueChange = (event) => {
		setSupplierSearchValue(event.target.value);
		debouncer(event.target.value);
	};
	const onSubmit = (data) => {
		const supplierObjects = selectedSuppliers?.map((supplierId) => ({ id: supplierId }));
		const tempDataToSave = {
			...data,
			suppliers: isOrderEnv ? [] : supplierObjects,
			clients: isOrderEnv ? supplierObjects : [],
			id: props.match.params.id,
		};
		setDataToSave(tempDataToSave);
		setConfirmObject({
			...confirmObject,
			openConfirm: true,
			message:
				isAddEditMode && props.match.params.id
					? t("admin.commercialOrganization.edit")
					: t("admin.commercialOrganization.save"),
		});
	};

	const saveCallback = (res) => {
		if (res.status === 200) {
			if (isAddEditMode && props.match.params.id) {
				toast.success(t("admin.commercialOrganizationEdit.success"));
			} else {
				toast.success(t("admin.commercialOrganization.success"));
			}
		} else {
			toast.error(t(res.error.response.data.detail));
		}
		setConfirmObject({
			...confirmObject,
			openConfirm: false,
			message: "",
			isLoading: false,
		});
		setTimeout(() => {
			history.push(`/administration/${module}/commercial-organization`);
		}, 500);
	};

	const onConfirm = () => {
		setConfirmObject({ ...confirmObject, isLoading: true });
		if (isOrderEnv) {
			dispatch(saveNewClientsCommercialOrganization(dataToSave)).then((res) => {
				saveCallback(res);
			});
		} else {
			dispatch(saveNewSuppliersCommercialOrganization(dataToSave)).then((res) => {
				saveCallback(res);
			});
		}
	};

	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	const handleChangeDescription = (e) => {
		setDescription(e.target.value);
	};

	return (
		<Grid container item xs={12} justify="center">
			<Grid
				container
				item
				xs={12}
				className={classes.headerTitle}
				justify="center"
				alignItems="center"
				alignContent="center"
			>
				<Typography variant="h6" color="primary">
					{props.match.params.id && isAddEditMode
						? t("admin.commercialOrganization.editRecord")
						: t("admin.commercialOrganization.addRecord")}
				</Typography>
			</Grid>
			<Grid container item xs={6} className={classes.container} justify="flex-start">
				<form className={classes.pageForm} onSubmit={handleSubmit(onSubmit)}>
					<Grid container item xs={12}>
						<Grid container item xs={5}>
							<Typography
								className={classes.inputField}
								variant="subtitle2"
								align="left"
								color="primary"
							>
								{t("admin.commercialOrganization.name")}
								<span style={{ color: "red" }}>*</span>
								<br />
								<Input
									inputProps={{ maxLength: 30 }}
									inputRef={register({
										required: {
											value: true,
											message: t("lastName_required"),
										},
									})}
									value={
										props.match.params.id &&
										commercialOrganizationToModify &&
										commercialOrganizationToModify.name
									}
									name="name"
									fullWidth
									disabled={props.match.params.id}
								/>
								<Typography className={classes.errorMessage}>{errors.lastName?.message}</Typography>
							</Typography>
						</Grid>
						<Grid container item xs={9}>
							<Typography
								className={classes.inputField}
								variant="subtitle2"
								align="left"
								color="primary"
							>
								{t("admin.commercialOrganization.description")}
								<br />
								<Input
									multiline
									inputProps={{ maxLength: 500 }}
									inputRef={register({
										required: {
											value: false,
										},
									})}
									value={description}
									onChange={handleChangeDescription}
									name="description"
									fullWidth
								/>
								<Typography className={classes.errorMessage}>{errors.lastName?.message}</Typography>
							</Typography>
						</Grid>
						<Grid container item xs={12}>
							<Grid container item xs={12} className={classes.headerTitle}>
								<Typography variant="h6" color="primary">
									{isOrderEnv
										? t("admin.commercialOrganization.clients")
										: t("admin.commercialOrganization.suppliers")}
								</Typography>
							</Grid>
							<Grid container item style={{ width: "300px", marginBottom: 20 }}>
								<FormControl fullWidth>
									<InputLabel id="search" />
									<Input
										id="standard-adornment-search"
										type="text"
										value={supplierSearchValue}
										onChange={(event) => handleFilterValueChange(event)}
										placeHolder={t("searchUser")}
										startAdornment={
											<InputAdornment position="start">
												<IconButton
													color="primary"
													aria-label="Recherche"
													className={classes.searchIcon}
												>
													<SearchIcon />
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
							</Grid>
							<CommercialOrganizationSuppliers
								t={t}
								classes={classes}
								selectedSuppliers={selectedSuppliers}
								setSelectedSuppliers={setSelectedSuppliers}
								commercialOrganizationToModify={commercialOrganizationToModify}
								handleChangePage={handleChangePage}
								handleChangeRowsPerPage={handleChangeRowsPerPage}
								suppliers={suppliers}
								filterUsers={filterUsers}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						item
						xs={12}
						spacing={2}
						className={classes.actions}
						justify="flex-end"
						alignItems="center"
						alignContent="center"
					>
						<>
							<Grid item>
								<Button
									color="primary"
									autoFocus
									disabled={!isAddEditMode}
									onClick={() => {
										setConfirmObject({
											message: t("CancelActionMessage"),
											openConfirm: true,
											cancel: true,
										});
										history.push(`/administration/${module}/commercial-organization`);
									}}
								>
									{t("cancel")}
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									type="submit"
									color="secondary"
									disabled={!isAddEditMode}
								>
									{t("confirm")}
								</Button>
							</Grid>
							<ConfirmDialog
								message={confirmObject.message}
								open={confirmObject.openConfirm}
								isLoading={confirmObject.isLoading}
								onConfirm={onConfirm}
								onCancel={onCancel}
							/>
						</>
					</Grid>
				</form>
			</Grid>
		</Grid>
	);
};

export default CommercialOrganizationForm;
