import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import PermissionManagement from "administration/users/component/PermissionManagement";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import CardActions from "@material-ui/core/CardActions";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "components/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { VpnKey } from "@material-ui/icons";
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	FormControlLabel,
	Switch,
} from "@material-ui/core";
import { isActionAllowed } from "components/HasPermission";
import useStyles from "../style";
import UserStatusBadge from "./UserStatusBadge";
import {
	deleteUser,
	deleteUserClient,
	deleteUserSupplier,
	fetchUsers,
	enableOrDisableUser,
} from "../redux/actions";

const nonAgentTypes = ["SUPPLIER", "CLIENT"];

const ConfirmationDialog = ({ open, setOpen, handleChangeStatus, userId }) => {
	const { t } = useTranslation();

	const handleCloseConfirmationPopup = () => {
		setOpen(!open);
	};
	const onClick = () => {
		handleChangeStatus(userId);
		handleCloseConfirmationPopup();
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseConfirmationPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t("enableDisableActionMessage")}</DialogTitle>

			<DialogActions>
				<Button onClick={handleCloseConfirmationPopup} color="secondary" autoFocus>
					{t("cancel")}
				</Button>
				<Button onClick={onClick} type="submit" color="secondary">
					{t("confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
const DeleteDialog = ({ open, setOpen, userId, setSelected, typeUser, filterUsers }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = React.useState(false);

	const handleCloseConfirmationPopup = () => {
		setIsLoading(false);
		setOpen(!open);
	};
	const deleteService =
		typeUser === "CLIENT"
			? deleteUserClient
			: typeUser === "SUPPLIER"
			? deleteUserSupplier
			: deleteUser;
	const onClick = () => {
		setIsLoading(true);
		dispatch(
			deleteService(userId, () => {
				handleCloseConfirmationPopup();
				dispatch(fetchUsers({ ...filterUsers, typeUser })).then(() => setSelected([]));
			})
		);
	};

	return (
		<ConfirmDialog
			open={open}
			onCancel={handleCloseConfirmationPopup}
			onConfirm={onClick}
			message={t("deleteUserActionMessage")}
			isLoading={isLoading}
		/>
	);
};
function TableUsers({
	row,
	index,
	onClickUpdate,
	selected,
	setSelected,
	withActions = true,
	typeUser,
	module,
	unclickeable,
}) {
	const classes = useStyles();
	const [file] = React.useState();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);
	const currentUserPermissions = useMemo(() => get(currentUser, "permissions"), [currentUser]);

	const filterUsers = useSelector(({ filterUsers: filterUsersParam }) => filterUsersParam);
	const [active, setActive] = React.useState(row.status === "ACTIVE");
	const [openConfirmationPopup, setopenConfirmationPopup] = React.useState(false);
	const [openDeletePopup, setopenDeletePopup] = React.useState(false);
	const [isPermissionPageOpen, setIsPermissionPageOpen] = React.useState(false);

	const refreshUserList = () => {
		dispatch(fetchUsers({ ...filterUsers, typeUser }));
	};

	const handleChangeStatus = (id) => {
		setActive(!active);
		if (!active) {
			dispatch(
				enableOrDisableUser(id, "ACTIVE", () => {
					refreshUserList();
				})
			);
		} else {
			dispatch(
				enableOrDisableUser(id, "DISABLE", () => {
					refreshUserList();
				})
			);
		}
	};

	const onClickRow = (id) => {
		if (!unclickeable) history.push(`/administration/${module}/view/${id}`);
	};

	useEffect(() => {
		setActive(row.status === "ACTIVE");
	}, [row]);

	const isUser = !nonAgentTypes.includes(typeUser);

	return (
		<Card key={index} className={classes.root} elevation={1}>
			<CardContent className={classes.content}>
				<ButtonBase focusRipple onClick={() => onClickRow(row.id)}>
					<Grid container justify="left" alignItems="center">
						<Grid item xs={2} align="center">
							<UserStatusBadge level={get(row, "status")} />
						</Grid>
						<Grid
							container
							item
							xs={3}
							justify="center"
							alignItems="center"
							direction="row-reverse"
							spacing={2}
						>
							<Grid item>
								<Typography variant="subtitle2" align="left" color="textSecondary">
									{typeUser === "SUPPLIER"
										? get(row, "supplierLabel")
										: typeUser === "CLIENT"
										? get(row, "clientLabel")
										: get(row, "lastName")}
								</Typography>
							</Grid>
							<Grid item>
								<Avatar alt="user" src={file} className={classes.large} />
							</Grid>
						</Grid>
						<Grid item xs={isUser ? 2 : 3} align="left">
							<Grid item>
								<Typography variant="subtitle2" align="center" color="textSecondary">
									{nonAgentTypes.find((e) => e === typeUser)
										? get(row, "email")
										: get(row, "firstName")}
								</Typography>
							</Grid>
						</Grid>
						{isUser ? (
							<Grid item xs={isUser ? 2 : 3} align="left">
								<Grid item>
									<Typography variant="subtitle2" align="center" color="textSecondary">
										{t(get(row, "email"))}
									</Typography>
								</Grid>
							</Grid>
						) : (
							<></>
						)}
						<Grid item xs={3} align="left">
							<Grid item>
								<Typography variant="subtitle2" align="center" color="textSecondary">
									{t(get(row, "jobTitle.label"))}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</ButtonBase>
			</CardContent>
			<CardActions>
				<Grid container xs={12} justify="flex-end">
					{((typeUser === "USER" &&
						isActionAllowed(currentUserPermissions, "Manage users", "Edit")) ||
						(typeUser === "SUPPLIER" &&
							isActionAllowed(currentUserPermissions, "Manage suppliers", "Edit")) ||
						(typeUser === "CLIENT" &&
							isActionAllowed(currentUserPermissions, "Manage clients", "Edit"))) && (
						<>
							<Tooltip placement="top" title={t("admin.user.toggleActive")}>
								<FormControlLabel
									control={
										<Switch
											checked={active}
											onChange={() => {
												setopenConfirmationPopup(true);
											}}
											name="checkedB"
											color="secondary"
										/>
									}
								/>
							</Tooltip>
							<ConfirmationDialog
								userId={row.id}
								typeUser={typeUser}
								filterUsers={filterUsers}
								handleChangeStatus={handleChangeStatus}
								open={openConfirmationPopup}
								setOpen={setopenConfirmationPopup}
							/>
						</>
					)}
				</Grid>
			</CardActions>
			{withActions && (
				<CardActions style={{ width: "20%" }}>
					<Grid
						container
						xs={12}
						justify="flex-end"
						alignContent="flex-end"
						alignItems="flex-end"
						style={{ marginLeft: "48px" }}
					>
						<Grid container item xs={3}>
							{typeUser === "USER" &&
								isActionAllowed(currentUserPermissions, "Manage users", "Permissions") && (
									<Tooltip placement="top" title={t("tooltip.action.permissions")}>
										<IconButton
											id="user-admin-permission-btn"
											aria-label="edit permissions"
											onClick={() => setIsPermissionPageOpen(!isPermissionPageOpen)}
										>
											<VpnKey />
										</IconButton>
									</Tooltip>
								)}
						</Grid>
						<Grid container item xs={3}>
							{((typeUser === "USER" &&
								isActionAllowed(currentUserPermissions, "Manage users", "Edit")) ||
								(typeUser === "SUPPLIER" &&
									isActionAllowed(currentUserPermissions, "Manage suppliers", "Edit")) ||
								(typeUser === "CLIENT" &&
									isActionAllowed(currentUserPermissions, "Manage clients", "Edit"))) && (
								<Tooltip placement="top" title={t("tootlip.action.edit")}>
									<IconButton aria-label="edit" onClick={onClickUpdate}>
										<EditIcon />
									</IconButton>
								</Tooltip>
							)}
						</Grid>
						<Grid container item xs={3}>
							{((typeUser === "USER" &&
								isActionAllowed(currentUserPermissions, "Manage users", "Delete")) ||
								(typeUser === "SUPPLIER" &&
									isActionAllowed(currentUserPermissions, "Manage suppliers", "Delete")) ||
								(typeUser === "CLIENT" &&
									isActionAllowed(currentUserPermissions, "Manage clients", "Delete"))) && (
								<Tooltip placement="top" title={t("tooltip.action.delete")}>
									<IconButton
										aria-label="delete"
										onClick={() => {
											setopenDeletePopup(true);
										}}
									>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							)}
							<DeleteDialog
								userId={row.id}
								typeUser={typeUser}
								filterUsers={filterUsers}
								setSelected={setSelected}
								open={openDeletePopup}
								setOpen={setopenDeletePopup}
							/>
						</Grid>
						<Grid container item xs={3}>
							{((typeUser === "USER" &&
								isActionAllowed(currentUserPermissions, "Manage users", "Delete")) ||
								(typeUser === "SUPPLIER" &&
									isActionAllowed(currentUserPermissions, "Manage suppliers", "Delete")) ||
								(typeUser === "CLIENT" &&
									isActionAllowed(currentUserPermissions, "Manage clients", "Delete"))) && (
								<Checkbox
									value={parseInt(row.id, 10)}
									checked={selected.includes(parseInt(row.id, 10))}
									onChange={(e) => {
										const list = [...selected];
										// eslint-disable-next-line
										e.target.checked
											? list.push(parseInt(e.target.value, 10))
											: list.splice(list.indexOf(parseInt(e.target.value, 10)), 1);
										setSelected(list);
									}}
								/>
							)}
						</Grid>
					</Grid>
				</CardActions>
			)}
			{isPermissionPageOpen && (
				<PermissionManagement
					dispatch={dispatch}
					setIsOpen={setIsPermissionPageOpen}
					isOpen={isPermissionPageOpen}
					classes={classes}
					row={row}
					fetchEntities={refreshUserList}
				/>
			)}
		</Card>
	);
}

export default TableUsers;
