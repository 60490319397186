/* eslint-disable */

import { format, parse } from "date-fns";
import { getApiGateway } from "redux/store";
import {
	DELETE_LIST_INVOICE,
	FETCH_STATUS,
	FETCH_SUPPLIER,
	FETCH_SUPPLIERS,
	FETCH_PAYMENT_TERM,
	FETCH_PAYMENT_TERMS,
	FETCH_DOCUMENT_INVOICE,
	FETCH_INVOICE,
	FETCH_REJECTION_MOTIFS,
	CREATE_INVOICE_REJECTION,
	VERIFY_INVOICE,
	LIST_FETCH_INVOICE,
	DOWNLOAD_LIST_INVOICE,
	INVOICE_TOTAL_AMOUNT,
	PATCH_COMMAND_INVOICE,
	LIST_FETCH_DELIVERY_METHOD,
	FETCH_COUNT_SUPPLIERS,
	INVOICE_TOTAL_VAT_AMOUNT,
	FETCH_LAST_ACTIONS_INVOICE,
	FETCH_COUNT_INVOICE_INDICATOR,
	INVOICE_TOTAL_OVERDUE_AMOUNT,
	INVOICE_TOTAL_OVERDUE_AMOUNT_BY_SUPPLIER,
	FETCH_INVOICE_HISTORY_LIST,
	TOTAL_INVOICE,
	EXPORT_INVOICE_ENTRY_ACCOUNT_EXCEL,
	UPDATE_INVOICE_STATUS,
	FETCH_DOCUMENT_INVOICE_EDI,
	FETCH_DOCUMENT_INVOICE_IDOC,
	FETCH_INVOICE_ATTACHMENTS_DOC_TYPES,
	FETCH_INVOICE_ATTACHMENTS,
	FETCH_MESSAGES_HISTORY,
	VALIDATE_STATUS_RULES,
	LIST_FETCH_GLACCOUNTS,
	LIST_FETCH_COSTCENTERS,
	LIST_FETCH_PAYMENTMETHODS,
	LIST_FETCH_INVOICE_LITIGE,
	FETCH_INVOICE_LITIGE,
	CREATE_INVOICE_LITIGE,
	DELETE_INVOICE_LITIGE,
	FETCH_INVOICE_HISTORY_LITIGE_LIST,
	FETCH_LISTE_STATUS_LITIGE,
	CREATE_INVOICE_LITIGE_REINTEGATION,
	CREATE_INVOICE_LITIGE_REJECTION,
	FETCH_FILTER_STATUS_INVOICE,
	CREATE_INVOICE_CUTTING,
	FETCH_INVOICE_CUTTINGS,
	DOWNLOAD_INVOICE_CUTTING_DOCUMENT,
	VALIDATE_INVOICE_CUTTING,
	WORKFLOW_PROCEED,
} from "../../constants";
import {
	URL_STATUS,
	URL_SUPPLIER,
	URL_PAYMENT_TERM,
	URL_INVOICE,
	URL_CREATE_INVOICE,
	URL_MOTIF,
	URL_CREATE_INVOICE_REJECTION,
	URL_VERIFY_INVOICE,
	URL_INVOICE_TOTAL_AMOUNT,
	URL_DELIVERY_METHOD,
	URL_COUNT_SUPPLIER,
	URL_INVOICE_TOTAL_VAT_AMOUNT,
	URL_LAST_ACTION_INVOICE,
	URL_INVOICE_INDICATORS,
	URL_INVOICE_TOTAL_OVERDUE_AMOUNT,
	URL_TO_PAY_INVOICE,
	URL_INVOICE_HISTORY,
	URL_BASE_INVOICE,
	URL_RELAUNCH_EXTRACTION_INVOICE,
	URL_BASE_EXPORT,
	// URL_BASE_EXPORTS_EDIFACT,
	// URL_BASE_EXPORTS_IDOC,
	URL_FETCH_INVOICE_DOC_TYPES,
	// URL_FETCH_INVOICE_ATTACHMENTS,
	URL_CREATE_INVOICE_ATTACHMENT,
	URL_BASE_MESSENGER,
	URL_WORKFLOW,
	URL_GLACCOUNTS,
	URL_COSTCENTERS,
	URL_PAYMENTMETHODS,
	URL_EXPORT_DIGITAL_INVOICE,
	URL_EXPORT_INVOICE_EDIFACT,
	URL_EXPORT_INVOICE_IDOC,
	URL_INVOICE_CUTTING,
} from "../../constants/endpoint";

import { LIST_FETCH_STATUS_HISTORY, LIST_FETCH_BILLING_LINES } from "../../actionType/index";

export const fetchStatus = (id) => ({
	type: FETCH_STATUS,
	request: { url: `${URL_STATUS}/${id}` },
});

// export const updateInvoiceStatus = (id, transitionCode, workflowCode) => ({
// 	type: UPDATE_INVOICE_STATUS,
// 	request: {
// 		method: "put",
// 		url: `${URL_WORKFLOW}/${workflowCode}/${id}/transition/${transitionCode}`,
// 	},
// });

export const fetchListStatus = () => ({
	type: `LIST_${FETCH_STATUS}`,
	request: { url: `${URL_STATUS}` },
});

export const fetchListHistory = (id) => ({
	type: LIST_FETCH_STATUS_HISTORY,
	request: {
		url: `${URL_BASE_INVOICE}/${id}/history/old`,
	},
});

export const fetchBillingLines = (id) => ({
	type: LIST_FETCH_BILLING_LINES,
	request: {
		url: `${URL_BASE_INVOICE}/${id}/billing`,
	},
});
export const invoicesNotDeleted = (data) => ({
	type: "INVOICES_NOT_DELETED",
	payload: data,
});
export const invoicesTotalAmounts = (status, categorie) => {
	categorie = categorie || "";
	return {
		type: `${INVOICE_TOTAL_AMOUNT}${categorie}`,
		request: {
			url: URL_INVOICE_TOTAL_AMOUNT,
			params: { status: status && status.join(",") },
		},
	};
};

export const invoicesTotalVATAmounts = (status, categorie) => {
	categorie = categorie || "";
	return {
		type: `${INVOICE_TOTAL_VAT_AMOUNT}${categorie}`,
		request: {
			url: URL_INVOICE_TOTAL_VAT_AMOUNT,
			params: { status: status && status.join(",") },
		},
	};
};
export const fetchSupplier = (id) => ({
	type: FETCH_SUPPLIER,
	request: { url: `${URL_SUPPLIER}/${id}` },
});

export const fetchCountSuppliers = () => ({
	type: `${FETCH_COUNT_SUPPLIERS}`,
	request: { url: `${URL_COUNT_SUPPLIER}` },
});

export const fetchCountInvoices = () => ({
	type: TOTAL_INVOICE,
	request: { url: `${URL_BASE_INVOICE}/supplier/invoice/count` },
});

export const fetchSuppliers = () => ({
	type: `${FETCH_SUPPLIERS}`,
	request: { url: `${URL_SUPPLIER}` },
});

export const fetchPaymentTerm = (id) => ({
	type: FETCH_PAYMENT_TERM,
	request: { url: `${URL_PAYMENT_TERM}/${id}` },
});

export const fetchPaymentTerms = () => ({
	type: `${FETCH_PAYMENT_TERMS}`,
	request: { url: `${URL_PAYMENT_TERM}` },
});

export const fetchDeliveryMethods = () => ({
	type: LIST_FETCH_DELIVERY_METHOD,
	request: { url: URL_DELIVERY_METHOD },
});

export const fetchDocumentInvoice = (idInvoice, type = "inv", token, onSuccess) => ({
	type: FETCH_DOCUMENT_INVOICE,
	request: {
		url: `${URL_BASE_EXPORT}/${idInvoice}/pdf`,
		params: { token: token != null ? token : "", type },
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					const extension = filename.split(".").pop();
					if (extension === "pdf") {
						onSuccess("application/pdf");
					} else {
						onSuccess(`image/${extension}`);
					}
				}
			}
			return response;
		},
	},
});

export const fetchRejectionMotifs = ({ token }) => ({
	type: `${FETCH_REJECTION_MOTIFS}`,
	request: {
		url: `${URL_MOTIF}`,
		params: { token: token != null ? token : "" },
	},
});

export const downloadInvoice = (idInvoice, type = "inv", onError, disableLoading) => ({
	type: FETCH_DOCUMENT_INVOICE,
	request: {
		url: `${URL_BASE_EXPORT}/${idInvoice}/pdf`,
		params: { type },
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "invoice.pdf");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const downloadInvoiceExcel = (idInvoice, onError, disableLoading) => ({
	type: "FETCH_EXCEL_INVOICE",
	request: {
		url: `${URL_BASE_EXPORT}/${idInvoice}/excel`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "invoice.xlsx");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const downloadInvoices = (idInvoices, type = "inv", filterInvoice, selectAll = false) => {
	const {
		searchBy,
		status,
		startIssueDate,
		startSaveDate,
		startCreatedDate,
		endIssueDate,
		endSaveDate,
		endCreatedDate,
		extractionLvl,
		duplicated,
		digitalInvoice,
		sortBy,
		periodExported,
		periodIntegrated,
	} = filterInvoice;

	const params = {
		idInvoices: idInvoices.join(","),
		type,
		selectAll,
		searchBy,
		status: status ? status.map((s) => s?.code).join(",") : null,
		startIssueDate: startIssueDate ? format(new Date(startIssueDate), "yyyy/MM/dd") : null,
		endIssueDate: endIssueDate ? format(new Date(endIssueDate), "yyyy/MM/dd") : null,
		startSaveDate: startSaveDate ? format(new Date(startSaveDate), "yyyy/MM/dd") : null,
		endSaveDate: endSaveDate ? format(new Date(endSaveDate), "yyyy/MM/dd") : null,
		startCreatedDate: startCreatedDate ? format(new Date(startCreatedDate), "yyyy/MM/dd") : null,
		endCreatedDate: endCreatedDate ? format(new Date(endCreatedDate), "yyyy/MM/dd") : null,
		sortBy,
		extractionLvl: extractionLvl ? extractionLvl.join(",") : null,
		duplicated,
		digitalInvoice,
		periodExported,
		periodIntegrated,
	};

	return {
		type: DOWNLOAD_LIST_INVOICE,
		request: {
			url: `${URL_BASE_EXPORT}/pdf`,
			params: params,
			responseType: "blob",
		},
		meta: {
			onSuccess: (response) => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				link.setAttribute("download", "invoices.zip");
				document.body.appendChild(link);
				link.click();
				link.remove();
				return response;
			},
		},
	};
};

export const fetchInvoice = (id) => ({
	type: FETCH_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${id}`,
		params: {
			proceed: true,
		},
	},
	meta: {
		onSuccess: (response) => {
			if (response.status === 200) {
				let { data } = response;
				const issueDate = response.data.issueDate
					? parse(response.data.issueDate, "dd/MM/yyyy", new Date())
					: null;
				const dueDate = response.data.dueDate
					? parse(response.data.dueDate, "dd/MM/yyyy", new Date())
					: null;
				data = {
					...data,
					issueDate: issueDate ? format(issueDate, "yyyy-MM-dd") : null,
					dueDate: dueDate ? format(dueDate, "yyyy-MM-dd") : null,
				};
				response = { ...response, data };
			}
			return response;
		},
	},
});

export const deleteInvoice = (id, onSuccess, onError) => ({
	type: FETCH_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${id}`,
		method: "delete",
	},
	meta: {
		onSuccess: () => {
			if (onSuccess) onSuccess();
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchInvoices = (filter = {}) => {
	const {
		searchBy,
		status,
		startIssueDate,
		startSaveDate,
		startCreatedDate,
		endIssueDate,
		endSaveDate,
		endCreatedDate,
		pageNo,
		pageSize,
		extractionLvl,
		duplicated,
		digitalInvoice,
		sortBy,
		periodExported,
		periodIntegrated,
	} = filter;
	return {
		type: LIST_FETCH_INVOICE,
		request: {
			url: `${URL_INVOICE}`,
			params: {
				searchBy,
				status: status ? status.map((s) => s?.code).join(",") : null,
				startIssueDate: startIssueDate ? format(new Date(startIssueDate), "yyyy/MM/dd") : null,
				endIssueDate: endIssueDate ? format(new Date(endIssueDate), "yyyy/MM/dd") : null,
				startSaveDate: startSaveDate ? format(new Date(startSaveDate), "yyyy/MM/dd") : null,
				endSaveDate: endSaveDate ? format(new Date(endSaveDate), "yyyy/MM/dd") : null,
				startCreatedDate: startCreatedDate
					? format(new Date(startCreatedDate), "yyyy/MM/dd")
					: null,
				endCreatedDate: endCreatedDate ? format(new Date(endCreatedDate), "yyyy/MM/dd") : null,
				pageNo,
				pageSize,
				sortBy,
				extractionLvl: extractionLvl ? extractionLvl.join(",") : null,
				duplicated,
				digitalInvoice,
				periodExported,
				periodIntegrated,
			},
		},
	};
};

export const fetchGlAccounts = () => ({
	type: LIST_FETCH_GLACCOUNTS,
	request: {
		url: `${URL_GLACCOUNTS}`,
	},
});

export const fetchCostCenters = () => ({
	type: LIST_FETCH_COSTCENTERS,
	request: {
		url: `${URL_COSTCENTERS}`,
	},
});

export const fetchPaymentMethods = () => ({
	type: LIST_FETCH_PAYMENTMETHODS,
	request: {
		url: `${URL_PAYMENTMETHODS}`,
	},
});
export const deleteInvoices = (
	idInvoices,
	all,
	deleteAll,
	searchBy,
	status,
	startIssueDate,
	startSaveDate,
	startCreatedDate,
	endIssueDate,
	endSaveDate,
	endCreatedDate,
	extractionLvl,
	duplicated,
	periodExported,
	periodIntegrated,
	onSuccess
) => ({
	type: DELETE_LIST_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/`,
		method: "delete",
		params: {
			idInvoices: idInvoices.join(","),
			all,
			deleteAll,
			searchBy,
			status: status ? status.map((s) => s.code).join(",") : null,
			startIssueDate: startIssueDate ? format(new Date(startIssueDate), "yyyy/MM/dd") : null,
			endIssueDate: endIssueDate ? format(new Date(endIssueDate), "yyyy/MM/dd") : null,
			startSaveDate: startSaveDate ? format(new Date(startSaveDate), "yyyy/MM/dd") : null,
			endSaveDate: endSaveDate ? format(new Date(endSaveDate), "yyyy/MM/dd") : null,
			startCreatedDate: startCreatedDate ? format(new Date(startCreatedDate), "yyyy/MM/dd") : null,
			endCreatedDate: endCreatedDate ? format(new Date(endCreatedDate), "yyyy/MM/dd") : null,
			extractionLvl: extractionLvl ? extractionLvl.join(",") : null,
			duplicated,
			periodExported,
			periodIntegrated,
		},
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) {
				onSuccess();
			}
			store.dispatch(fetchInvoices());
			return store.dispatch(invoicesNotDeleted(response.data));
		},
	},
});

export const updateInvoice = (id, invoice) => {
	if (invoice && invoice.issueDate) {
		invoice = {
			...invoice,
			issueDate: format(new Date(invoice.issueDate), "dd/MM/yyyy"),
		};
	}
	if (invoice && invoice.dueDate) {
		invoice = {
			...invoice,
			dueDate: format(new Date(invoice.dueDate), "dd/MM/yyyy"),
		};
	}
	return {
		type: "UPDATE_INVOICE",
		request: {
			url: `${URL_BASE_INVOICE}/${id}`,
			method: "put",
			data: invoice,
		},
	};
};
export const updateCommand = (idInvoice, command) => ({
	type: PATCH_COMMAND_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${idInvoice}`,
		method: "patch",
		data: command,
	},
});

export const createInvoice = (file) => {
	const data = new FormData();
	data.append("file", file);
	return {
		type: "CREATE_INVOICE",
		request: {
			url: URL_CREATE_INVOICE,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
		meta: {
			onSuccess: (response) => response,
		},
	};
};

export const createInvoiceRejection = (id, invoiceRejection, onSuccess, onError) => ({
	type: CREATE_INVOICE_REJECTION,
	request: {
		url: `${URL_BASE_INVOICE}/${id}${URL_CREATE_INVOICE_REJECTION}`,
		method: "post",
		data: invoiceRejection,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchInvoice(id));
		},
		onError: (error, requestAction, store) => {
			if (onError) onError();
			return store.dispatch(fetchInvoice(id));
		},
	},
});

export const createInvoiceApproval = (id, invoiceApproval, onSuccess, onError) => ({
	type: CREATE_INVOICE_REJECTION,
	request: {
		url: `${URL_BASE_INVOICE}/protected/${id}/tovalidate`,
		method: "put",
		data: invoiceApproval,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchInvoice(id));
		},
		onError: (error, requestAction, store) => {
			if (onError) onError();
			return store.dispatch(fetchInvoice(id));
		},
	},
});

export const verifyInvoice = (id, onSuccess, onError) => ({
	type: VERIFY_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${id}${URL_VERIFY_INVOICE}`,
		method: "put",
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchInvoice(id));
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchOcrData = (id, onSuccess, onError) => ({
	type: "FETCH_OCR_DATA",
	request: { url: `${URL_BASE_INVOICE}/${id}/ocr/data` },
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess(response.data);
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchOcrImage = (imageId) => ({
	type: "FETCH_OCR_IMAGE",
	request: { url: `${URL_BASE_INVOICE}/${imageId}/ocr/data` },
});

export const rectifyInvoice = (id, invoiceDto, isTraining) => ({
	type: "APPLY_RECTIFY_INVOICE",
	request: {
		url: `${URL_BASE_INVOICE}/${id}/ocr/correct/apply`,
		method: "put",
		data: invoiceDto,
		params: {
			isTraining,
		},
	},
	meta: {
		onSuccess: (response) => {
			window.location.reload(false);
			return response;
		},
		onError: () => {},
	},
});

export const fetchLastThreeInvoices = () => ({
	type: FETCH_LAST_ACTIONS_INVOICE,
	request: {
		url: `${URL_LAST_ACTION_INVOICE}`,
	},
});

export const fetchInvoiceIndicators = () => ({
	type: FETCH_COUNT_INVOICE_INDICATOR,
	request: {
		url: `${URL_INVOICE_INDICATORS}`,
	},
});

export const getFilterInvoiceStatus = () => ({
	type: FETCH_FILTER_STATUS_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/invoice-status`,
	},
});

export const invoicesTotalAmountsOverDue = () => ({
	type: `${INVOICE_TOTAL_OVERDUE_AMOUNT}`,
	request: {
		url: URL_INVOICE_TOTAL_OVERDUE_AMOUNT,
	},
});

export const invoicesTotalAmountsOverDueBySupplier = (idInvoice) => ({
	type: `${INVOICE_TOTAL_OVERDUE_AMOUNT_BY_SUPPLIER}`,
	request: {
		url: `${URL_INVOICE_TOTAL_OVERDUE_AMOUNT}/${idInvoice}`,
	},
});

export const downloadXMLInvoice = (idInvoice, onError, disableLoading) => ({
	type: FETCH_DOCUMENT_INVOICE,
	request: {
		url: `${URL_BASE_EXPORT}/${idInvoice}/xmlpivot`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "export.xml");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const downloadDigitalInvoice = (type, idInvoice, onError, disableLoading) => ({
	type: FETCH_DOCUMENT_INVOICE,
	request: {
		url: `${URL_EXPORT_DIGITAL_INVOICE}/${type}/${idInvoice}`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else if (type === "facturx") {
					link.setAttribute("download", "facturx.pdf");
				} else {
					link.setAttribute("download", `${type}.xml`);
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: (error) => {
			if (
				error.request.responseType === "blob" &&
				error.response.data instanceof Blob &&
				error.response.data.type &&
				error.response.data.type.toLowerCase().indexOf("json") !== -1
			) {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => {
						error.response.data = JSON.parse(reader.result);
						onError(error);
						disableLoading();
						resolve(Promise.reject(error));
					};
					reader.onerror = () => {
						reject(error);
					};
					reader.readAsText(error.response.data);
				});
			}
			return Promise.reject(error);
		},
	},
});

export const downloadEdifactInvoice = (idInvoice, onError, disableLoading) => ({
	type: FETCH_DOCUMENT_INVOICE_EDI,
	request: {
		url: `${URL_EXPORT_INVOICE_EDIFACT}/${idInvoice}`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "export.edi");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const downloadIdocInvoice = (idInvoice, onError, disableLoading) => ({
	type: FETCH_DOCUMENT_INVOICE_IDOC,
	request: {
		url: `${URL_EXPORT_INVOICE_IDOC}/${idInvoice}`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "export.idoc");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const topayInvoice = (id, onSuccess, onError) => ({
	type: URL_TO_PAY_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${id}${URL_TO_PAY_INVOICE}`,
		method: "put",
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchInvoice(id));
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchInvoiceHistory = (id) => ({
	type: FETCH_INVOICE_HISTORY_LIST,
	request: {
		url: `${URL_BASE_INVOICE}/${id}${URL_INVOICE_HISTORY}`,
	},
});

export const exportEdiFile = (id, onSuccess, onError) => ({
	type: "EXPORT_EDI_FILE",
	request: {
		url: `${URL_BASE_EXPORT}/${id}/edi`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const exportXMLFileToServer = (id, onSuccess, onError) => ({
	type: "EXPORT_XML_FILE",
	request: {
		url: `${URL_BASE_EXPORT}/${id}/xml`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const downloadInvoicesXML = (idInvoices, filter = {}) => {
	const { selectAll = false } = filter;
	return {
		type: "DOWNLOAD_LIST_INVOICE_EXCEL",
		request: {
			url: `${URL_BASE_EXPORT}/xmlpivot`,
			params: { selectAll, idInvoices: idInvoices.join(",") },
			responseType: "blob",
		},
		meta: {
			onSuccess: (response) => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				link.setAttribute("download", "invoicesXML.zip");
				document.body.appendChild(link);
				link.click();
				link.remove();
				return response;
			},
		},
	};
};

export const relaunchInvoice = (id, onSuccess, onError) => ({
	type: "UPDATE_INVOICE",
	request: {
		url: `${URL_RELAUNCH_EXTRACTION_INVOICE}/${id}`,
		method: "put",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const relaunchInvoices = (idInvoices, onSuccess, onError) => ({
	type: "UPDATE_INVOICE",
	request: {
		url: `${URL_BASE_INVOICE}/relaunch`,
		params: { idInvoices: idInvoices.join(",") },
		method: "get",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const downloadInvoicesExcel = (idInvoices, name, filterInvoice = {}, selectAll = false) => {
	const today = new Date();
	const date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
	const {
		searchBy,
		status,
		startIssueDate,
		startSaveDate,
		startCreatedDate,
		endIssueDate,
		endSaveDate,
		endCreatedDate,
		extractionLvl,
		duplicated,
		digitalInvoice,
		sortBy,
		periodExported,
		periodIntegrated,
	} = filterInvoice;

	const params = {
		idInvoices: idInvoices.join(","),
		selectAll,
		searchBy,
		status: status ? status.map((s) => s?.code).join(",") : null,
		startIssueDate: startIssueDate ? format(new Date(startIssueDate), "yyyy/MM/dd") : null,
		endIssueDate: endIssueDate ? format(new Date(endIssueDate), "yyyy/MM/dd") : null,
		startSaveDate: startSaveDate ? format(new Date(startSaveDate), "yyyy/MM/dd") : null,
		endSaveDate: endSaveDate ? format(new Date(endSaveDate), "yyyy/MM/dd") : null,
		startCreatedDate: startCreatedDate ? format(new Date(startCreatedDate), "yyyy/MM/dd") : null,
		endCreatedDate: endCreatedDate ? format(new Date(endCreatedDate), "yyyy/MM/dd") : null,
		sortBy,
		extractionLvl: extractionLvl ? extractionLvl.join(",") : null,
		duplicated,
		digitalInvoice,
		periodExported,
		periodIntegrated,
	};

	return {
		type: "DOWNLOAD_LIST_INVOICE_EXCEL",
		request: {
			url: `${URL_BASE_EXPORT}/excel`,
			params,
			responseType: "blob",
		},
		meta: {
			onSuccess: (response) => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				if (name.length) link.setAttribute("download", `invoices_${name}_${date}.xlsx`);
				else link.setAttribute("download", `invoices.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.remove();
				return response;
			},
		},
	};
};

export const logout = () => ({
	type: "LOGOUT",
	request: {
		url: `${getApiGateway()}/logout`,
	},
	meta: {
		onSuccess: (response) => {
			const currectUrl = window.location.origin;
			window.location.href = `${getApiGateway()}/login/sso?redirect_url=${encodeURI(currectUrl)}`;
			return response;
		},
	},
});
export const exportSingleAccountingEntryExcel = (idInvoice, onError, disableLoading) => ({
	type: `${EXPORT_INVOICE_ENTRY_ACCOUNT_EXCEL}_LIST`,
	request: {
		url: `${URL_BASE_EXPORT}/${idInvoice}/excel/journal`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "écriturecomptable_multiple_invoices.xlsx");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			disableLoading();
			return response;
		},
		onError: () => {
			disableLoading();
			onError();
		},
	},
});

export const exportAccountingEntriesExcel = (idInvoices, filter = {}) => {
	const { selectAll } = filter;
	return {
		type: `${EXPORT_INVOICE_ENTRY_ACCOUNT_EXCEL}_LIST`,
		request: {
			url: `${URL_BASE_EXPORT}/excel/journal`,
			params: { idInvoices: idInvoices.join(","), selectAll },
			responseType: "blob",
		},
		meta: {
			onSuccess: (response) => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				link.setAttribute("download", "écriturecomptable_multiple_invoices.xlsx");
				document.body.appendChild(link);
				link.click();
				link.remove();
				return response;
			},
		},
	};
};

export const fetchInvoiceAttachmentsDocTypes = (onSuccess) => ({
	type: FETCH_INVOICE_ATTACHMENTS_DOC_TYPES,
	request: {
		url: `${URL_FETCH_INVOICE_DOC_TYPES}`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
	},
});
export const fetchInvoiceAttachments = (invoiceId, onSuccess, onError) => ({
	type: FETCH_INVOICE_ATTACHMENTS,
	request: {
		url: `${URL_BASE_INVOICE}/${invoiceId}/attachment`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (response) => {
			if (onError) onError();
			return response;
		},
	},
});

export const createInvoiceAttachment = (info, file) => {
	const data = new FormData();
	data.append("file", file[0]);
	data.append("info", JSON.stringify(info));
	return {
		type: "CREATE_INVOICE_ATTACHMENT",
		request: {
			url: URL_CREATE_INVOICE_ATTACHMENT,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
		meta: {
			onSuccess: (response) => response,
		},
	};
};

export const deleteInvoiceAttachment = (id, onSuccess, onError) => ({
	type: "DELETE_INVOICE_ATTACHMENT",
	request: {
		url: `${URL_BASE_INVOICE}/${id}/attachment`,
		method: "delete",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (response) => {
			if (onError) onError();
			return response;
		},
	},
});

export const downloadInvoiceAttachment = (id) => ({
	type: FETCH_DOCUMENT_INVOICE,
	request: {
		url: `${URL_BASE_EXPORT}/attachment/${id}?type=INV`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "invoice.pdf");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const fetchMessagesHistoryWithClient = (supplierId) => ({
	type: FETCH_MESSAGES_HISTORY,
	request: {
		url: `${URL_BASE_MESSENGER}/history`,
		params: { supplierId },
		method: "get",
	},
});

export const postReply = (replyText, supplierId) => ({
	type: "POST_USER_REPLY",
	request: {
		url: `${URL_BASE_MESSENGER}/reply/send`,
		method: "post",
		data: replyText,
		params: { supplierId },
		headers: { "Content-Type": "application/json" },
	},
});

// export const validateStatus = (invoiceId) => ({
// 	type: VALIDATE_STATUS_RULES,
// 	request: {
// 		url: `${URL_WORKFLOW}/status/${invoiceId}`,
// 		method: "get",
// 	},
// });

export const fetchInvoicesLitige = (filter = {}) => {
	const { searchBy, status, pageNo, pageSize, sortBy } = filter;
	return {
		type: LIST_FETCH_INVOICE_LITIGE,
		request: {
			url: `${URL_BASE_INVOICE}/litige/all`,
			method: "get",
			params: {
				searchBy,
				status: status ? status.map((s) => s.code).join(",") : null,
				pageNo,
				pageSize,
				sortBy,
			},
		},
	};
};
export const fetchInvoiceLitige = (id) => ({
	type: FETCH_INVOICE_LITIGE,
	request: { url: `${URL_BASE_INVOICE}/litige/${id}` },
});

export const createInvoicesLitige = (id) => ({
	type: CREATE_INVOICE_LITIGE,
	request: {
		url: `${URL_BASE_INVOICE}/litige/${id}`,
		method: "post",
		headers: { "Content-Type": "application/json" },
	},
});

export const deleteInvoiceLitige = (id, onSuccess) => ({
	type: DELETE_INVOICE_LITIGE,
	request: {
		url: `${URL_BASE_INVOICE}/litige/${id}`,
		method: "delete",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
	},
});

export const fetchInvoiceHistoryLitige = (id) => ({
	type: FETCH_INVOICE_HISTORY_LITIGE_LIST,
	request: {
		url: `${URL_BASE_INVOICE}/litige${URL_INVOICE_HISTORY}/${id}`,
	},
});

export const fetchListStatusLitige = () => ({
	type: FETCH_LISTE_STATUS_LITIGE,
	request: { url: `${URL_BASE_INVOICE}/litige/status` },
});

export const createInvoiceLitigeRejection = (id) => ({
	type: CREATE_INVOICE_LITIGE_REJECTION,
	request: {
		url: `${URL_BASE_INVOICE}/litige/reject`,
		method: "post",
		data: id,
		headers: { "Content-Type": "application/json" },
	},
});

export const createInvoiceReintegration = (id) => ({
	type: CREATE_INVOICE_LITIGE_REINTEGATION,
	request: {
		url: `${URL_BASE_INVOICE}/litige/reintegrate`,
		method: "post",
		data: id,
		headers: { "Content-Type": "application/json" },
	},
});

export const createInvoiceCutting = (file) => {
	const data = new FormData();
	data.append("file", file);
	return {
		type: CREATE_INVOICE_CUTTING,
		request: {
			url: `${URL_INVOICE_CUTTING}/upload`,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
		meta: {
			onSuccess: (response) => response,
		},
	};
};

export const fetchInvoiceCuttings = () => ({
	type: FETCH_INVOICE_CUTTINGS,
	request: {
		url: URL_INVOICE_CUTTING,
	},
});

export const downloadInvoiceCuttingDocument = (id, onSuccess, onError) => ({
	type: DOWNLOAD_INVOICE_CUTTING_DOCUMENT,
	request: {
		url: `${URL_INVOICE_CUTTING}/${id}/download`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const validateInvoiceCutting = (id, structure, onSuccess, onError) => {
	const data = new FormData();
	data.append("structure", structure);
	return {
		type: VALIDATE_INVOICE_CUTTING,
		request: {
			url: `${URL_INVOICE_CUTTING}/${id}/validate`,
			method: "put",
			data,
		},
		meta: {
			onSuccess: (response) => {
				if (onSuccess) onSuccess();
				return response;
			},
			onError: () => {
				if (onError) onError();
			},
		},
	};
};

export const workflowProceed = (invoiceId) => {
	return {
		type: WORKFLOW_PROCEED,
		request: {
			url: `${URL_WORKFLOW}/${invoiceId}/proceed`,
			method: "POST",
		},
		meta: {
			onSuccess: (response) => {
				return response;
			},
			onError: (error) => {
				return error;
			},
		},
	};
};

export const workflowForceProceed = (invoiceId) => {
	return {
		type: WORKFLOW_PROCEED,
		request: {
			url: `${URL_WORKFLOW}/${invoiceId}/proceed/force`,
			method: "POST",
		},
		meta: {
			onSuccess: (response) => {
				return response;
			},
			onError: (error) => {
				return error;
			},
		},
	};
};
