import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { object, string } from "yup";
import {
	Paper,
	Typography,
	FormControl,
	InputLabel,
	Input,
	FormHelperText,
	Button,
	Grid,
} from "@material-ui/core";
import get from "lodash/get";
import Spinner from "./Spinner";
import useStyles from "./style";
import { postUpdatePassword } from "../../redux/actions/users";
import AlertMessage from "./AlertMessage";

const ChangePasswordRequest = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [passChangeSuccess, setPassChangeSuccess] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState("");
	const [alertTitle, setAlertTitle] = React.useState("");

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const _handleModalClose = () => {
		setPassChangeSuccess(false);
	};

	const _renderModal = () => {
		const onClick = () => {
			setPassChangeSuccess(false);
		};

		return (
			<AlertMessage
				isOpen={passChangeSuccess}
				onClose={_handleModalClose}
				handleSubmit={onClick}
				title={t(alertTitle)}
				text={t(alertMessage)}
				submitButtonText={t("profile.update-password.password-reset-done")}
			/>
		);
	};

	const _handleSubmit = ({ setSubmitting, resetForm }) => {
		dispatch(
			postUpdatePassword(
				{
					username: get(currentUser, "userName"),
				},
				() => {
					setSubmitting(false);
					setAlertTitle("profile.update-password.update-password");
					setAlertMessage("profile.update-password.password-email-send");
					setPassChangeSuccess(true);
					resetForm();
				},
				() => {
					setSubmitting(false);
					setAlertTitle("profile.update-password.update-password");
					setAlertMessage("token.validation.noteExist");
					setPassChangeSuccess(true);
					resetForm();
				}
			)
		);
	};

	return (
		<>
			<Formik
				initialValues={{
					email: "",
				}}
				validationSchema={object().shape({
					email: string()
						.required(t("profile.update-password.current-password-required"))
						.oneOf([get(currentUser, "email")], "reeeeeeeeeeeeeeee"),
				})}
				// eslint-disable-next-line no-empty-pattern
				onSubmit={({}, { setSubmitting, resetForm }) =>
					_handleSubmit({
						setSubmitting,
						resetForm,
					})
				}
				render={(props) => {
					const {
						values,
						touched,
						errors,
						handleChange,
						handleBlur,
						handleSubmit,
						isValid,
						isSubmitting,
					} = props;
					return isSubmitting ? (
						<Spinner />
					) : (
						<Grid container item xs className={classes.container}>
							<Grid item xs={8}>
								<Paper className={classes.formWrapper} elevation={10}>
									<form className={classes.form} onSubmit={handleSubmit}>
										<Typography variant="h6" className={classes.title}>
											{t("profile.update-password.update-password")}
										</Typography>
										<FormControl
											fullWidth
											margin="normal"
											error={Boolean(touched.email && errors.email)}
										>
											<InputLabel
												htmlFor="password-new"
												error={Boolean(touched.email && errors.email)}
											>
												{t("profile.update-password.email")}
											</InputLabel>
											<Input
												id="email"
												name="email"
												type="text"
												value={values.email}
												onChange={handleChange}
												onBlur={handleBlur}
												error={Boolean(touched.email && errors.email)}
											/>
											<FormHelperText error={Boolean(touched.email && errors.email)}>
												{touched.newPass && errors.email ? errors.email : ""}
											</FormHelperText>
										</FormControl>
										<Button
											type="submit"
											variant="raised"
											color="primary"
											disabled={Boolean(!isValid || isSubmitting)}
											className={classes.resetButton}
										>
											{t("profile.update-password.reset-password")}
										</Button>
									</form>
									{_renderModal()}
								</Paper>
							</Grid>
						</Grid>
					);
				}}
			/>
		</>
	);
};

export default ChangePasswordRequest;
