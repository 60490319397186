import { format, parse } from "date-fns";
import { FETCH_INVOICE_TO_APPROVE, CREATE_INVOICE_APPROVAL_REJECTION } from "../../constants";
import { URL_BASE_INVOICE } from "../../constants/endpoint";

export const fetchInvoiceProtected = (id, token) => ({
	type: FETCH_INVOICE_TO_APPROVE,
	request: { url: `${URL_BASE_INVOICE}/protected/${id}`, params: { token } },
	meta: {
		onSuccess: (response) => {
			if (response.status === 200) {
				let { data } = response;
				const issueDate = response.data.issueDate
					? parse(response.data.issueDate, "dd/MM/yyyy", new Date())
					: null;
				const dueDate = response.data.dueDate
					? parse(response.data.dueDate, "dd/MM/yyyy", new Date())
					: null;
				data = {
					...data,
					issueDate: issueDate ? format(issueDate, "yyyy-MM-dd") : null,
					dueDate: dueDate ? format(dueDate, "yyyy-MM-dd") : null,
				};
				response = { ...response, data };
			}
			return response;
		},
		onError: (error) => {
			if ([401, 403].includes(error.response.status)) {
				window.location.replace("/login");
			}
		},
	},
});

export const createInvoiceApprovalRejection = (
	id,
	invoiceRejection,
	token,
	onSuccess,
	onError
) => ({
	type: CREATE_INVOICE_APPROVAL_REJECTION,
	request: {
		url: `${URL_BASE_INVOICE}/protected/${id}/reject`,
		params: { token },
		method: "post",
		data: invoiceRejection,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchInvoiceProtected(id, token));
		},
		onError: (error, requestAction, store) => {
			if ([401, 403].includes(error.response.status)) {
				window.location.replace("/login");
			}
			if (onError) onError();
			return store.dispatch(fetchInvoiceProtected(id, token));
		},
	},
});

export const createInvoiceApprovalConfirmation = (id, token, approvalData, onSuccess, onError) => ({
	type: CREATE_INVOICE_APPROVAL_REJECTION,
	request: {
		url: `${URL_BASE_INVOICE}/protected/${id}/approve`,
		params: { token },
		method: "put",
		data: approvalData,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchInvoiceProtected(id, token));
		},
		onError: (error, requestAction, store) => {
			if ([401, 403].includes(error.response.status)) {
				window.location.replace("/login");
			}
			if (onError) onError();
			return store.dispatch(fetchInvoiceProtected(id, token));
		},
	},
});
