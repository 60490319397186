import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";

export const isModuleAllowed = (permissions, subMenuName) => {
	if (!permissions) return true;
	permissions = JSON.parse(permissions);
	return permissions?.find((el) => el.enabled && el.menu.includes(subMenuName));
};

export const isMenuAllowed = (permissions, menuName) => {
	if (!permissions) return true;
	permissions = JSON.parse(permissions);
	return !!permissions?.find(
		(el) => el.enabled && (el?.subMenu === menuName || el?.name === menuName)
	);
};

export const isActionAllowed = (permissions, menuName, actionName) => {
	if (!permissions) return true;
	permissions = JSON.parse(permissions);
	return !!permissions
		?.find((el) => el.enabled && (el?.subMenu === menuName || el?.name === menuName))
		?.actions?.find((action) => action.name === actionName && action.enabled);
};

export const compareToTemplateAndAdapt = (arg) => {
	const permissions = [...arg];
	// TODO
	return permissions;
};

// returns a component if the user is permitted
export const HasPermission = ({ menuName, actionName, children }) => {
	const currentUserPermissions = get(
		useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data")),
		"permissions"
	);

	if (!currentUserPermissions) {
		return children;
	}

	if (isActionAllowed(currentUserPermissions, menuName, actionName)) {
		return children;
	}
	return <></>;
};
