import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
	name: "appState",
	isConnected: false,
	isUserModify: false,
	initialState: {
		menuExpanded: false,
		headerExpanded: true,
		isOrderEnv:
			new URL(window.location).pathname.startsWith("/home") ||
			new URL(window.location).pathname.includes("/client") ||
			new URL(window.location).pathname.startsWith("/orders") ||
			new URL(window.location).pathname.startsWith("/contact") ||
			new URL(window.location).pathname.includes("/client-settings") ||
			new URL(window.location).pathname.includes("/order"),
	},
	reducers: {
		toogleMenu: (state) => ({ ...state, menuExpanded: !state.menuExpanded }),
		setHeaderExpanded: (state, action) => ({
			...state,
			headerExpanded: action.payload,
		}),
		SetConnectedStatut: (state) => ({ ...state, isConnected: !state.isConnected }),
		setIsUserModif: (state) => ({ ...state, isUserModify: !state.isUserModify }),
		toggleEnv: (state) => ({ ...state, isOrderEnv: !state.isOrderEnv }),
	},
});

export const {
	toogleMenu,
	setHeaderExpanded,
	SetConnectedStatut,
	setIsUserModif,
	toggleEnv,
} = appSlice.actions;

export default appSlice.reducer;
