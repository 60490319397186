import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import InsertDriveFileRounded from "@material-ui/icons/InsertDriveFileOutlined";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DashboardCard from "../../../../components/DashboardCard/index";
import { currencyFormat } from "../../../../utils/index";
import {
	invoicesTotalAmounts,
	// invoicesTotalAmountsOverDue,
} from "../../../../redux/actions/invoices/index";

function UnpaidInvoice(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const invoicesTotalAmountsUnPaid = useSelector(
		({ requests }) =>
			requests.queries.INVOICE_TOTAL_AMOUNT_UNPAID &&
			requests.queries.INVOICE_TOTAL_AMOUNT_UNPAID.data
	);

	const invoicesTotalAmountsOverdue = useSelector(
		({ requests }) =>
			requests.queries.INVOICE_TOTAL_OVERDUE_AMOUNT &&
			requests.queries.INVOICE_TOTAL_OVERDUE_AMOUNT.data
	);

	useEffect(() => {
		// dispatch(invoicesTotalAmounts());

		dispatch(
			invoicesTotalAmounts(
				[
					"INTEGRATION",
					"TO_VERIFY",
					"TO_CONTROL",
					"TO_PAY",
					"PAYED",
					"REJECTED",
					"CANCELED",
					"EXCEPTION",
					"TO_VALIDATE_EXTERNAL",
				],
				"_UNPAID"
			)
		);
		// dispatch(invoicesTotalAmountsOverDue());
	}, []);

	return (
		<DashboardCard
			title={t("invoice.unexported")}
			icon={InsertDriveFileRounded}
			{...props} // eslint-disable-line
		>
			<Grid container spacing={2} justify="center">
				<Grid item xs={6}>
					<Typography variant="h5" color="primary" gutterBottom align="center">
						{invoicesTotalAmountsUnPaid && invoicesTotalAmountsUnPaid !== ""
							? `${currencyFormat(invoicesTotalAmountsUnPaid)} €`
							: `${currencyFormat(0)} €`}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" gutterBottom align="center">
						{t("Total amount of invoices")}
					</Typography>
				</Grid>
				<Grid item xs={6} className="p-relative">
					<Divider orientation="vertical" absolute />
					<Typography variant="h5" color="error" gutterBottom align="center">
						{invoicesTotalAmountsUnPaid && invoicesTotalAmountsOverdue !== ""
							? `${currencyFormat(invoicesTotalAmountsOverdue)} €`
							: `${currencyFormat(0)} €`}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" gutterBottom align="center">
						{t("Late")}
					</Typography>
				</Grid>
			</Grid>
		</DashboardCard>
	);
}

export default UnpaidInvoice;
