export default {
	countSuppliers: "Nombre total de fournisseurs",
	searchSupplier: "Trouver un fournisseur",
	contact: "Contact",
	principalContact: "Contact principal",
	outstandingInvoices: "Factures impayées",
	addSupplier: "Ajouter un fournisseur",
	"limited.liability.company": "SARL",
	"single.person.limited.liability": "EURL",
	"public.limited.company": "SA",
	"general.partnership": "SNC",
	"simplified.joint.stock.company": "SAS",
	"single.person.simplified.joint.stock.company": "SASU",
	other: "Autre",
	companySiret: "Siret",
	vatNumber: "Numéro TVA",
	legalForm: "Forme juridique",
	codeNaf: "Code NAF",
	email: "Email",
	address: "Adresse",
	fax: "Fax",
	socialCapital: "Capital social",
	name: "Nom",
	function: "Fontion",
	phone: "Téléphone",
	bank: "Banque",
	iban: "IBAN",
	bic: "BIC",
	rib: "RIB",
	account_journal_auxiliary: "Auxiliaire",
	account_journal_number: "Compte comptable",
	noPrincipalContact: "Aucun contact associé",
	details: "Détails",
	legalInformation: "Numéros d’identification",
	generalInformation: "Informations générales",
	financialInformation: "Informations financières",
	associatedContact: "Contacts associés",
	return: "Revenir",
	invoice_uploaded_success:
		"La facture a été ajoutée avec succès. Elle apparaîtra dans la liste si vous êtes l'émetteur de cette facture.",
	"upload.instructions": "Instructions",
	"upload.instructions1": "Remplissez la feuille Excel avec les informations des fournisseurs",
	"upload.instructions2":
		"Remplissez la colonne «Logo» avec le nom du fichier du logo. (Exemple: logofilename.jpg)",
	"upload.instructions3": "Assurez-vous que tous les champs sont de type TEXT",
	"upload.instructions4":
		"Placez la feuille Excel remplie et les logos des fournisseurs dans le même dossier",
	"upload.instructions5": "Compressez ce dossier:",
	"upload.instructions51":
		'Windows: Faites un clic droit sur le dossier puis choisissez "Envoyer vers " puis cliquez sur "Dossier compressé "',
	"upload.instructions52":
		"MAC: cliquez dessus tout en maintenant la touche Contrôle enfoncée ou appuyez dessus avec deux doigts, puis choisissez Compresser dans le menu contextuel.",
	"upload.instructions6":
		"Téléchargez le dossier compressé sur le portail ou faites-le glisser et déposez-le dans la zone dédiée",
	"upload.instructions7": 'Cliquez sur "Télécharger les fournisseurs"',
	"supplier.modify": " Modifier fournisseur",
	"invoice.attachment.label": "Libéllé",
	"invoice.attachment.extension": "Type du fichier",
	"invoice.attachment.type": "Type de document",
	"invoice.attachment.creationDate": "Date d’ajout",
	"invoice.attachment.size": "Taille",
	field_required: "Obligatoire",
	"add attach": "Ajouter une pièce jointe",
	"attachment.upload": "Ajouter le fichier",
	"attachment.upload.success": "Fichier téléchargé avec succès",
	"attachment.upload.error": "Échec du téléchargement du fichier",
	"Add your Attachment": "Ajoutez votre pièce jointe",
	"invoice.attachment.delete.confirm": "Voulez-vous vraiment supprimer cette pièce jointe?",
	"attachment.delete.success": "La pièce jointe a bien été supprimée",
	"workflow.invoice.exists": "Suppression du statut de facture",
	"workflow.invoice.exists.message":
		"Ne peut pas supprimer le statut de facturation.Une ou plusieurs factures sont dans ce statut.",
	"workflow.changeInvoicesStatus": "Changer les factures Statut",
	"workflow.updateStatus.warning":
		"Êtes-vous sûr de vouloir mettre à jour ces factures Statut? Cette action vous permettra également de sauvegarder un flux de travail et cela ne peut pas être annulé.",
	"supplier.lockedTraining": "Fermé à clé?",
	"workflow.load.error": "Erreur lors de la récupération du flux de travail",
	"workflow.toolTip.addStatus": "Ajouter le statut",
	"workflow.toolTip.save": "Sauvegarder",
	"workflow.toolTip.clone": "Cloner",
	"workflow.toolTip.undoCHanges": "Annuler les changements",
	"workflow.clone.newName": "Nouveau flux de travail",
	"workflow.clone.code": "Nom",
	"workflow.clone.description": "Description",
	"workflow.clone.save": "Sauvegarder",
	"workflow.clone.success": "Flux de travail cloné avec succès!",
	"workflow.clone.error": "Une erreur s'est produite lors du clonage du flux de travail",
	searchClient: "Trouver un client",
	"invoice.transition.reintegrate": "REINTEGRER",
	"history.oldValue": "Ancienne valeur",
	"history.newValue": "Nouvelle valeur",
	"history.showMore": "Montre plus",
	INVOICE_NO: "Numéro de facture",
	SUPPLIER: "Fournisseuse",
	ISSUE_DATE: "Date d'émission",
	DUE_DATE: "Date d'échéance",
	VAT: "Numéro de TVA",
	COMPANY_ID: "Identifiant Entreprise",
	COUNTRY: "Pays",
	PURCHASE_ORDER_NO: "N ° de commande",
	PAYMENT_TERM: "Mode de réglement",
	DELIVERY_SLIP: "Bordereau de livraison",
	CURRENCY: "Currency",
	DELIVER_ADDRESS: "Adresse de livraison",
	BILLING_ADDRESS: "Adresse de facturation",
	ORDER_NO: "Numéro de commande",
	CLIENT: "Client",
	"history.fieldName": "Champ",
	ADD_ATTACHMENT: "Attachement ajouté",
	DELETE_ATTACHMENT: "Pièce jointe supprimée",
	TOTAL: "Totale",
	DISCOUNT: "Remise",
};
