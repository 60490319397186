import React, { useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import isEmpty from "lodash/isEmpty";
import { toast } from "utils";
import debounce from "lodash/debounce";
import useStyles from "../Invoices/List/style";
import { SearchFilter, useQuery } from "../../components/Filter";
import { invoicesTotalAmounts } from "../../redux/actions/invoices";
import { fetchSuppliers, deleteSuppliers } from "../../redux/actions/suppliers";
import { initFilter, updateFilter } from "../../redux/supplierFilterReducer";

const sortByValues = ["name"];

const filterSuppliers = {
	nameOrContactLastNameOrContactFirstName: "",
	pageNo: 0,
	pageSize: 10,
	sortBy: "",
};

function Filter(props) {
	const dispatch = useDispatch();
	const query = useQuery();

	const classes = useStyles();
	const { t } = useTranslation();
	const [sortBy, setSortBy] = React.useState("");
	const [openDeletePopup, setOpenDeletePopup] = React.useState(false);

	const handleChangeSortBy = (event) => {
		setSortBy(event.target.value);
		dispatch(
			updateFilter({
				...filterSuppliers,
				pageNo: 0,
				sortBy: event.target.value,
			})
		);
		props.fetchSuppliers({
			...filterSuppliers,
			pageNo: 0,
			sortBy: event.target.value,
		});
	};

	const handleCloseDeletePopup = () => {
		setOpenDeletePopup(!openDeletePopup);
	};

	const toDelete = () => {
		dispatch(deleteSuppliers(props.selected, handleCloseDeletePopup())).then((response) => {
			if (!isEmpty(response.payload)) {
				toast.error(`${t("SuppliersCannotBeDeleted")} : ${response.payload.join(", ")}`);
			}
			dispatch(initFilter());
			dispatch(invoicesTotalAmounts([]));
			props.setSelected([]);
		});
	};

	const searchSupplier = (supplier) => {
		props.fetchSuppliers({
			...filterSuppliers,
			nameOrContactLastNameOrContactFirstName: supplier,
			pageNo: 0,
		});
	};

	const debouncer = useCallback(
		debounce((nextValue) => searchSupplier(nextValue), 200),
		[]
	);

	const handleFilterValueChange = () => {
		updateFilter({
			...filterSuppliers,
			nameOrContactLastNameOrContactFirstName: query.get("q"),
			pageNo: 0,
		});
		debouncer(query.get("q"));
	};

	useEffect(() => {
		setTimeout(() => {
			handleFilterValueChange();
		}, 0);
	}, [query.get("q")]);

	return (
		<>
			<Dialog
				open={openDeletePopup}
				onClose={handleCloseDeletePopup}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t("deleteMessage")}</DialogTitle>
				<DialogActions>
					<Button onClick={handleCloseDeletePopup} color="secondary" autoFocus>
						{t("cancel")}
					</Button>
					<Button onClick={toDelete} type="submit" color="secondary">
						{t("confirm")}
					</Button>
				</DialogActions>
			</Dialog>

			<Grid container spacing={3} xs={12}>
				<Grid item xs={4}>
					<SearchFilter
						callback={handleFilterValueChange}
						placeHolder={t("searchSupplier")}
						style={classes.searchIcon}
						value={query.get("q")}
					/>
				</Grid>

				<Grid item container xs={8} justify="flex-end">
					<Grid item xs={4}>
						<FormControl fullWidth>
							<InputLabel id="sortBy">{t("sortBy")}</InputLabel>
							<Select
								labelId="sortBy"
								id="sortById"
								value={sortBy}
								input={<Input />}
								onChange={handleChangeSortBy}
								renderValue={(selected) => t(selected)}
							>
								<MenuItem value="">
									<ListItemText primary="" />
								</MenuItem>
								{sortByValues.map((row) => (
									<MenuItem value={row}>
										<ListItemText primary={t(row)} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default connect(null, { fetchSuppliers })(Filter);
