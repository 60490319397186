import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import GavelIcon from "@material-ui/icons/Gavel";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { FinancialComponent } from "./steps/FinancialComponent";
import { PrincipalContact } from "./steps/PrincipalContact";
import { PersonnalInformations } from "./steps/PersonnalInformations";
import { createSupplier } from "../../../redux/actions/suppliers";

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: "#ccc",
		zIndex: 1,
		color: "#fff",
		width: 50,
		height: 50,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
	},
	active: {
		backgroundImage:
			"linear-gradient( 136deg, rgb(13,168,173) 0%, rgb(145,162,196) 50%, rgb(60,77,111) 100%)",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	},
	completed: {
		backgroundImage:
			"linear-gradient( 136deg, rgb(13,168,173) 0%, rgb(145,162,196) 50%, rgb(60,77,111) 100%)",
	},
	backButton: {
		backgroundColor: "#d63031",
		color: "white",
	},
});

function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	const icons = {
		1: <GavelIcon />,
		2: <GroupAddIcon />,
		3: <AccountBalanceIcon />,
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{icons[String(props.icon)]}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	backButton: {
		backgroundColor: "#d63031",
		color: "white",
	},
}));

function getSteps() {
	return ["Informations juridique", "Contacts associés", "Informations financières"];
}

function getStepContent(step, supplier, handleSupplier, setLogo) {
	switch (step) {
		case 0:
			return (
				<PersonnalInformations
					supplier={supplier}
					handleSupplier={handleSupplier}
					setLogo={setLogo}
				/>
			);
		case 1:
			return <PrincipalContact supplier={supplier} handleSupplier={handleSupplier} />;
		case 2:
			return <FinancialComponent supplier={supplier} handleSupplier={handleSupplier} />;
		default:
			return "Unknown step";
	}
}

export default function CustomizedSteppers() {
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const [logo, setLogo] = React.useState(null);
	const [supplier, setSupplier] = React.useState({
		contacts: [{}],
	});

	const steps = getSteps();
	const history = useHistory();
	const dispatch = useDispatch();

	const handleNext = () => {
		if (activeStep === steps.length - 1) {
			dispatch(createSupplier(supplier, logo));
			history.push(`/supplier`);
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleSupplier = (value) => {
		setSupplier(value);
	};

	return (
		<div>
			<Grid container xs={12} direction="column">
				<Grid item>
					<Typography variant="h6" color="primary">
						Ajouter un fournisseur
					</Typography>
				</Grid>
				<Grid item>
					<Stepper
						orientation="vertical"
						alternativeLabel
						activeStep={activeStep}
						style={{ backgroundColor: "transparent" }}
					>
						{steps.map((label, index) => (
							<Step key={label}>
								<StepLabel
									style={{ flexDirection: "row", width: "250px" }}
									StepIconComponent={ColorlibStepIcon}
								>
									<Typography align="left" variant="h6" color="secondary">
										{label}
									</Typography>
								</StepLabel>
								<StepContent style={{ paddingLeft: "250px", paddingTop: "50px" }}>
									<Grid container xs={12} spacing={4}>
										<Grid container item xs={12}>
											{getStepContent(index, supplier, handleSupplier, setLogo)}
										</Grid>
										<Grid
											container
											item
											xs={12}
											alignItems="flex-end"
											justify="flex-end"
											spacing={2}
										>
											<Grid item xs={2}>
												<Button
													fullWidth
													disabled={activeStep === 0}
													onClick={handleBack}
													className={classes.backButton}
												>
													Retour
												</Button>
											</Grid>
											<Grid item xs={2}>
												<Button
													fullWidth
													variant="contained"
													color="secondary"
													onClick={handleNext}
													className={classes.nexButton}
												>
													{activeStep === steps.length - 1 ? "Terminer" : "Suivant"}
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</StepContent>
							</Step>
						))}
					</Stepper>
				</Grid>
			</Grid>

			<div>
				{activeStep === steps.length && (
					<Paper square elevation={0} className={classes.resetContainer}>
						<Typography>All steps completed - you&apos;re finished</Typography>
						<Button onClick={handleReset} className={classes.button}>
							Initialiser
						</Button>
					</Paper>
				)}
			</div>
		</div>
	);
}
