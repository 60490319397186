import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

import countries from "i18n-iso-countries";
import get from "lodash/get";
import useStyles from "../style";

export const DetailsSupplierGeneral = ({ supplier }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const lang = localStorage.getItem("lgn") ? localStorage.getItem("lgn") : "fr";

	const country = get(supplier, "countryCode")
		? countries.getName(get(supplier, "countryCode"), lang, { select: "official" })
		: "-";
	return (
		<Grid container xs={12} spacing={3}>
			<Grid container item xs={12} spacing={3} alignItems="flex-end">
				<Grid item xs={4} spacing={3}>
					<Typography variant="h6" align="center">
						{t("socialCapital")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "capital") ? get(supplier, "capital") : "-"}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={3}>
					<Typography variant="h6" align="center">
						{t("codeNaf")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "codeNaf") ? get(supplier, "codeNaf") : "-"}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={3}>
					<Typography variant="h6" align="center">
						{t("country")}
						<Typography className={classes.typography} align="center">
							{country}
						</Typography>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} spacing={3} alignItems="flex-end">
				<Grid item xs={4} spacing={3}>
					<Typography variant="h6" align="center">
						{t("Street")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "street") ? get(supplier, "street") : "-"}
						</Typography>
					</Typography>
				</Grid>

				<Grid item xs={4} spacing={4}>
					<Typography variant="h6" align="center">
						{t("City")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "city") ? get(supplier, "city") : "-"}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={3}>
					<Typography variant="h6" align="center">
						{t("Zip Code")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "zipCode") ? get(supplier, "zipCode") : "-"}
						</Typography>
					</Typography>
				</Grid>
			</Grid>

			<Grid container item xs={12} spacing={3} alignItems="flex-end">
				<Grid item xs={4} spacing={3}>
					<Typography variant="h6" align="center">
						{t("email")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "email") ? get(supplier, "email") : "-"}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={3}>
					<Typography variant="h6" align="center">
						{t("phone")}
						<Typography className={classes.typography} align="center">
							{get(supplier, "phone") ? get(supplier, "phone") : "-"}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4} spacing={3}>
					<Typography variant="h6" align="center">
						{t("Fax")}
						<Typography className={classes.typography} align="center">
							{t(get(supplier, "fax")) ? get(supplier, "fax") : "-"}
						</Typography>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DetailsSupplierGeneral;
