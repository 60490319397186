import { URL_SUPPLIER, URL_CLIENT } from "redux/constants/endpoint";
import {
	FETCH_USERS,
	FETCH_USERS_JOBS,
	DELETE_USER,
	CREATE_USER,
	FETCH_USERS_CIVILITIES,
	EDIT_USER,
	FETCH_USER,
	UPDATE_USER,
	UPDATE_USER_STATUS,
	UPDATE_MAIL_CONFIG,
	FETCH_MAIL_CONFIG,
	FETCH_FOLDER_CONFIG,
	UPDATE_FOLDER_CONFIG,
	DELETE_TRAINING_SUPPLIER,
	FETCH_EXTRACTION_LEVEL_PARAMS,
	FETCH_EXTRACTION_LEVEL_PARAMS_ORDER,
	UPDATE_EXTRACTION_LEVEL_PARAMS,
	UPDATE_EXTRACTION_LEVEL_PARAMS_ORDER,
	FETCH_HOTFOLDER_FTP_CONFIG,
	UPDATE_HOTFOLDER_FTP_CONFIG,
	FETCH_WORKFLOW,
	PUT_WORKFLOW,
	FETCH_SUPER_USERS,
	FETCH_TRANSITION_ALERTS,
	LOCK_SUPPLIER_TRAINING,
	CHECK_WORKFLOW_INVOICE_TO_DELETE,
	DELETE_TRAINING_CLIENT,
	LOCK_CLIENT_TRAINING,
} from "../constants/index";
import {
	URL_FETCH_USERS,
	URL_FETCH_JOBS,
	URL_BASE_USERS,
	URL_FETCH_CIVILITIES,
	URL_UPDATE_USER_STATUS,
	URL_DELETE_TRAINING_SUPPLIER,
	URL_WORKFLOW,
	URL_DELETE_TRAINING_CLIENT,
	URL_USER_PERMISSIONS,
} from "../constants/endpoint";
import { DELETE_LIST_INVOICE } from "../../../redux/constants";

export const fetchUsers = (filter = {}) => {
	const { userName, status, pageNo, pageSize, sortBy, typeUser } = filter;
	return {
		type: FETCH_USERS,
		request: {
			url: `${URL_FETCH_USERS}`,
			params: {
				userName,
				pageNo,
				pageSize,
				sortBy,
				status: status ? status.map((s) => s.code).join(",") : null,
				typeUser,
			},
		},
	};
};
export const fetchJobs = () => ({
	type: FETCH_USERS_JOBS,
	request: {
		url: `${URL_FETCH_JOBS}`,
	},
});
export const fetchCivilities = () => ({
	type: FETCH_USERS_CIVILITIES,
	request: {
		url: `${URL_FETCH_CIVILITIES}`,
	},
});
export const deleteUserService = (id, onSuccess, onError) => ({
	type: DELETE_USER,
	request: {
		url: `${URL_BASE_USERS}/${id}`,
		method: "delete",
	},
	meta: {
		onSuccess: () => {
			if (onSuccess) onSuccess();
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const deleteUserSupplier = (user, onSuccess, onError) => {
	const createUserObj = deleteUserService(user, onSuccess, onError);
	createUserObj.request.url = `${createUserObj.request.url}/supplier`;
	return createUserObj;
};

export const deleteUserClient = (user, onSuccess, onError) => {
	const createUserObj = deleteUserService(user, onSuccess, onError);
	createUserObj.request.url = `${createUserObj.request.url}/client`;
	return createUserObj;
};

export const deleteUser = deleteUserService;

const createUserService = (user, onSuccess, onError) => ({
	type: CREATE_USER,
	request: {
		url: `${URL_BASE_USERS}/`,
		method: "post",
		data: user,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (error) => {
			if (onError) onError();
			if (error.response.status === 400) {
				const message = error.response.data.detail;
				onError(message);
			}
		},
	},
});

export const createUserSupplier = (user, onSuccess, onError) => {
	const createUserObj = createUserService(user, onSuccess, onError);
	createUserObj.request.url = `${createUserObj.request.url}supplier`;
	return createUserObj;
};

export const createUserClient = (user, onSuccess, onError) => {
	const createUserObj = createUserService(user, onSuccess, onError);
	createUserObj.request.url = `${createUserObj.request.url}client`;
	return createUserObj;
};

export const createUser = createUserService;

export const updateUserService = (idUser, user, onSuccess, onError) => ({
	type: UPDATE_USER,
	request: {
		url: `${URL_BASE_USERS}/${idUser}`,
		method: "put",
		data: user,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (error) => {
			if (onError) onError();
			if (error.response.status === 400) {
				const message = error.response.data.detail;
				onError(message);
			}
		},
	},
});

export const updateUserSupplier = (idUser, user, onSuccess, onError) => {
	const createUserObj = updateUserService(idUser, user, onSuccess, onError);
	createUserObj.request.url = `${createUserObj.request.url}/supplier`;
	return createUserObj;
};

export const updateUserClient = (idUser, user, onSuccess, onError) => {
	const createUserObj = updateUserService(idUser, user, onSuccess, onError);
	createUserObj.request.url = `${createUserObj.request.url}/client`;
	return createUserObj;
};

export const updateUser = updateUserService;

export const deleteUsers = (idUsers, onSuccess) => ({
	type: DELETE_LIST_INVOICE,
	request: {
		url: `${URL_BASE_USERS}/`,
		method: "delete",
		params: { idUsers: idUsers.join(",") },
	},
	meta: {
		onSuccess: () => {
			if (onSuccess) {
				onSuccess();
			}
		},
	},
});

export const editUser = (user, onSuccess, onError) => ({
	type: EDIT_USER,
	request: {
		url: `${URL_BASE_USERS}/`,
		method: "put",
		data: user,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (error) => {
			if (onError) onError();
			if (error.response.status === 400) {
				const message = error.response.data.detail;
				onError(message);
			}
		},
	},
});

export const fetchUser = (id, onSuccess, onError) => ({
	type: FETCH_USER,
	request: {
		url: `${URL_BASE_USERS}/${id}`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const enableOrDisableUser = (id, userStatus, onSuccess, onError) => ({
	type: UPDATE_USER_STATUS,
	request: {
		url: `${URL_UPDATE_USER_STATUS}${id}`,
		method: "put",
		data: { status: userStatus },
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (error) => {
			if (onError) onError();
			if (error.response.status === 400) {
				const message = error.response.data.detail;
				onError(message);
			}
		},
	},
});

export const fetchSettings = (onSuccess, onError) => ({
	type: "FETCH_SETTINGS",
	request: {
		url: `/admin/setting/`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchMailConfig = (type, onSuccess, onError) => ({
	type: FETCH_MAIL_CONFIG,
	request: {
		url: `/import/mail-config/${type}`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateSettings = (data, module, onSuccess, onError) => ({
	type: "update_SETTINGS",
	request: {
		url: `/admin/setting/${module}`,
		method: "put",
		data,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateMailConfig = (data, onSuccess, onError) => ({
	type: UPDATE_MAIL_CONFIG,
	request: {
		url: `/import/mail-config`,
		method: "put",
		data,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const createMailConfig = (data, onSuccess, onError) => ({
	type: UPDATE_MAIL_CONFIG,
	request: {
		url: `/import/mail-config`,
		method: "post",
		data,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchFolderConfig = (id, onSuccess, onError) => ({
	type: FETCH_FOLDER_CONFIG,
	request: {
		url: `/import/folder-config/${id}`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateFolderConfig = (data, onSuccess, onError) => ({
	type: UPDATE_FOLDER_CONFIG,
	request: {
		url: `/import/folder-config`,
		method: "put",
		data,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const deleteTrainingForSupplier = (supplierId, onSuccess, onError) => ({
	type: DELETE_TRAINING_SUPPLIER,
	request: {
		url: `${URL_DELETE_TRAINING_SUPPLIER}${supplierId}`,
		method: "delete",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess("successdeleteTrainingMessage");
			return response;
		},
		onError: (error) => {
			if (error.response.status === 400) {
				onError(error.response);
			} else if (onError) onError(error.response);
		},
	},
});

export const deleteTrainingForClient = (clientId, onSuccess, onError) => ({
	type: DELETE_TRAINING_CLIENT,
	request: {
		url: `${URL_DELETE_TRAINING_CLIENT}${clientId}`,
		method: "delete",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess("successdeleteTrainingMessage");
			return response;
		},
		onError: (error) => {
			if (error.response.status === 400) {
				onError(error.response);
			} else if (onError) onError(error.response);
		},
	},
});
export const lockSupplierTraining = (supplierId) => ({
	type: LOCK_SUPPLIER_TRAINING,
	request: {
		url: `${URL_SUPPLIER}/training/${supplierId}`,
		method: "put",
	},
});

export const lockClientTraining = (clientId) => ({
	type: LOCK_CLIENT_TRAINING,
	request: {
		url: `${URL_CLIENT}/training/${clientId}`,
		method: "put",
	},
});
export const fetchExtractionLevelConfig = (onSuccess, onError) => ({
	type: FETCH_EXTRACTION_LEVEL_PARAMS,
	request: {
		url: `/invoice/extraction-level/extraction-level-params`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const fetchExtractionLevelConfigOrder = (onSuccess, onError) => ({
	type: FETCH_EXTRACTION_LEVEL_PARAMS_ORDER,
	request: {
		url: `/order/extraction-level/extraction-level-params`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateExtractionLevelParam = (data, onSuccess, onError) => ({
	type: UPDATE_EXTRACTION_LEVEL_PARAMS,
	request: {
		url: `/invoice/extraction-level/extraction-level-params`,
		method: "put",
		data,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateExtractionLevelParamOrder = (data, onSuccess, onError) => ({
	type: UPDATE_EXTRACTION_LEVEL_PARAMS_ORDER,
	request: {
		url: `/order/extraction-level/extraction-level-params`,
		method: "put",
		data,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchHotfolderConfig = (id, onSuccess, onError) => ({
	type: FETCH_HOTFOLDER_FTP_CONFIG,
	request: {
		url: `/hotfolder/ftp-config/${id}`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess(response.data);
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateHotfolderConfig = (data, onSuccess, onError) => ({
	type: UPDATE_HOTFOLDER_FTP_CONFIG,
	request: {
		url: `/hotfolder/ftp-config`,
		method: "put",
		data,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchWorkflow = (onSuccess, onError) => ({
	type: FETCH_WORKFLOW,
	request: {
		url: `${URL_WORKFLOW}/invoice`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (e) => {
			if (onError) onError();
			throw e;
		},
	},
});

export const updateWorkflow = (data) => {
	const wfCode = data.code;
	return {
		type: PUT_WORKFLOW,
		request: {
			url: `${URL_WORKFLOW}/invoice/${wfCode}`,
			method: "put",
			data,
			headers: { "Content-Type": "application/json" },
		},
	};
};
export const fetchSuperUsers = () => ({
	type: FETCH_SUPER_USERS,
	request: {
		url: `${URL_BASE_USERS}/omniUser`,
		method: "get",
	},
});

export const validateTransition = (invoiceId, source, target) => ({
	type: FETCH_TRANSITION_ALERTS,
	request: {
		url: `${URL_WORKFLOW}/validate/${source}/${target}/${invoiceId}`,
		method: "get",
	},
});

export const updateWorkflowInvoicesStatus = (source, target, invoices, workflowObj) => ({
	type: "UPDATE_WORKFLOW_INVOICES_STATUS",
	request: {
		url: `${URL_WORKFLOW}/invoice/${workflowObj.code}/status/${source}/${target}`,
		params: { invoiceIds: invoices.map((invoice) => invoice?.id).join() },
		data: { ...workflowObj },
		method: "put",
	},
});

export const checkStatusToDelete = (workflowCode, statusCode) => ({
	type: CHECK_WORKFLOW_INVOICE_TO_DELETE,
	request: {
		url: `${URL_WORKFLOW}/invoice/status/${workflowCode}/${statusCode}`,
		method: "get",
	},
});

export const cloneWorkflow = (oldWfCode, newWfCode, description) => ({
	type: "CLONE_WORKFLOW",
	request: {
		url: `${URL_WORKFLOW}/invoice`,
		params: { oldWfCode },
		data: { code: newWfCode, description },
		method: "post",
	},
});

export const updateUserPermissions = (id, permissions) => ({
	type: "UPDATE_USER_PERMISSION",
	request: {
		url: `${URL_USER_PERMISSIONS}/${id}`,
		method: "put",
		data: permissions,
	},
});
